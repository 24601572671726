import { GridCellProps } from '@progress/kendo-react-grid';
import StatusChip from 'app/components/StatusChip';
import styled from 'styled-components';

type Props = {
  className?: string;
  e: GridCellProps;
  onClick?: (e) => void;
};

const PaymentStatusCell = ({ className, e, onClick }: Props) => {
  const data = e.dataItem?.isPaymentComplete;
  return (
    <td className={className ?? ''}>
      <div>{data === true ? 'Yes' : 'No'}</div>
    </td>
  );
};

export default PaymentStatusCell;
