import React, { useEffect, useState } from 'react';
import { PersonalInfoForm } from './components/PersonalInfoForm';
import styled from 'styled-components';
import { AlumniDivisionForm } from './components/AlumniDivisionForm';
import { PersonalAffiliationsForm } from './components/PersonalAffiliationsForm';
import {
  Alumni,
  AlumniService,
  GeneralLookup,
  GeneralLookupWrapper,
  LookupService,
  RiderService,
} from 'services/openapi';
import useApi from 'services/mavapi/useApi';
import { useNavigate } from 'react-router-dom';
import LoaderWrapper from 'app/components/CircularLoader/LoaderWrapper';
import CircularLoader from 'app/components/CircularLoader';
import { useDispatch, useSelector } from 'react-redux';
import { useManagePageSlice } from 'app/pages/slice';
import { selectPageLoader } from 'app/pages/slice/selectors';
import Cookies from 'js-cookie';
import { heightWeightModel } from 'app/pages/RiderRegistrationPage/components/NotReturningRider';
import CircularProgress from '@mui/joy/CircularProgress';
import Box from '@mui/joy/Box';
import { Stepper, StepperChangeEvent } from '@progress/kendo-react-layout';
import check from '../../assets/img/icon/check.png';
import { useRiderRegistrationStateSlice } from 'app/pages/RiderRegistrationPage/slice';
import { selectPhotoUpload } from 'app/pages/RiderRegistrationPage/slice/selectors';
import isApiError from 'utils/isApiError';
import { useErrorHandlingSlice } from 'app/error/slice';
import { defaultAlumniValue, defaultPhotoValue } from '../../staticData';

type Props = {};
export type RadioValueModel = {
  haveNotCompetedInClassLopeCanter?: boolean | undefined;
  haveNotEarnedTwentyFivePointsInAssocs?: boolean | undefined;
  haveFinishedTopTwoAsAERInNationals?: boolean | undefined;
  haveFinishedTopThreeAaAER?: boolean | undefined;
  haveCompetedTwoSeasonsAsAER?: boolean | undefined;
  usePhotoForPromotion?: boolean | undefined;
};
const items = [
  { label: 'Personal Information', number: 1 },
  { label: 'Alumni Divisions', number: 2 },
  { label: 'Professional Affiliations', number: 3 },
];

const AlumniNewMemberResgistration = (props: Props) => {
  const { error: apiError, isLoading, handleRequest } = useApi();
  const { actions } = useErrorHandlingSlice();
  const { lookupGetAllLookups } = LookupService;
  const { alumniCreateAlumni, alumniUploadTeamPhoto } = AlumniService;
  const { riderGetBackNumberList } = RiderService;
  const { actions: manageAppActions } = useRiderRegistrationStateSlice();
  const [formNumber, setFormNumber] = useState<number>(0);
  const [alumniData, setAlumniData] = useState<Alumni>(defaultAlumniValue);
  const [radioGroupValues, setRadioGroupValues] = useState<RadioValueModel>({
    haveNotCompetedInClassLopeCanter: undefined,
    haveNotEarnedTwentyFivePointsInAssocs: undefined,
    haveFinishedTopTwoAsAERInNationals: undefined,
    haveFinishedTopThreeAaAER: undefined,
    haveCompetedTwoSeasonsAsAER: undefined,
    usePhotoForPromotion: undefined,
  });
  const [teamData, setTeamData] = useState<GeneralLookup[]>([]);
  const [stateData, setStateData] = useState<GeneralLookup[]>([]);
  const [affiliationsData, setAffiliationsData] = useState<GeneralLookup[]>([]);
  const [childDivisonData, setChildDivisonData] = useState<GeneralLookup[]>();
  const [lookUps, setLookUps] = React.useState<Array<GeneralLookupWrapper>>([]);
  const navigate = useNavigate();
  const { actions: PageActions } = useManagePageSlice();
  const pageLoader = useSelector(selectPageLoader);
  const dispatch = useDispatch();
  const [cancelPaymentCookie, setCancelPaymentCookie] = useState();
  const [heightWeight, setHeightWeight] = useState<heightWeightModel>({
    feet: undefined,
    inches: undefined,
    weight: undefined,
  });
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 767);
  const totalPages = 3;
  const determinateValue = ((formNumber + 1) / totalPages) * 100;
  const [photoUploadPayload, setPhotoUploadPayload] = useState<{
    formData?: {
      file?: Blob;
    };
  }>(defaultPhotoValue);
  const [fileAvailability, setFileAvailability] = React.useState<boolean>(true);
  const [uploadFile, setUploadFile] = useState<File | null>();
  const photoUploadDataRedux = useSelector(selectPhotoUpload);
  const [backNumberData, setBackNumberData] = React.useState<
    string[] | undefined
  >([]);
  const [isNotImage, setIsNotImage] = useState(false);

  const handleNextForm = () => {
    setFormNumber(formNumber =>
      formNumber === 2 ? formNumber : formNumber + 1,
    );
    if (formNumber === 2) {
      dispatch(PageActions.setPageLoader(true));
      handleRequest(alumniCreateAlumni(alumniData))
        .then(res => {
          if (res && !isApiError(res)) {
            if (cancelPaymentCookie) {
              Cookies.remove('paymentCancel');
            }
            if (photoUploadPayload?.formData?.file !== undefined) {
              handlePhotoUpload(res.toString(), photoUploadPayload.formData);
            }
            const aluminiNewMemberDataString = JSON.stringify(alumniData);
            const radioValuesString = JSON.stringify(radioGroupValues);
            Cookies.set('payload', aluminiNewMemberDataString);
            Cookies.set('payloadRadiovalues', radioValuesString);
            dispatch(
              actions.dispatchToast({
                errorTxt: 'Alumini rider registration successful',
                show: true,
                errorType: 'success',
              }),
            );
            dispatch(PageActions.setPageLoader(false));
            navigate(`/payment/${payerTypes?.id}/${res}/false/false`);
            dispatch(manageAppActions.setPhotoUpload(photoUploadPayload));
          } else {
            dispatch(
              actions.dispatchToast({
                errorTxt: res.description,
                show: true,
                errorType: 'error',
              }),
            );
            dispatch(PageActions.setPageLoader(false));
          }
        })
        .catch(error => {
          dispatch(
            actions.dispatchToast({
              errorTxt: error.description,
              show: true,
              errorType: 'error',
            }),
          );
          dispatch(PageActions.setPageLoader(false));
        });
    }
  };
  const handlePhotoUpload = (
    riderId: string,
    formData?: {
      file?: Blob;
    },
  ) => {
    handleRequest(alumniUploadTeamPhoto(riderId, formData))
      .then(res => {})
      .catch(error => {});
  };
  const handleBackForm = () => {
    if (formNumber === 0) {
      navigate(-1);
    } else {
      setFormNumber(formNumber =>
        formNumber === 0 ? formNumber : formNumber - 1,
      );
    }
  };
  const [payerTypes, setPayerTypes] = useState<GeneralLookup>();
  const handleGetLookups = () => {
    dispatch(PageActions.setPageLoader(true));
    handleRequest(
      lookupGetAllLookups(
        'STATE,PROFESSIONALAFFILIATIONS,DIVISION,CATEGORY,PAYER_TYPES,ACTIVETEAM',
      ),
    )
      .then(res => {
        if (res) setLookUps(res);

        let varPayerTypes = res?.filter(item => {
          if (item?.type === 'PAYER_TYPES') {
            return item.values;
          }
        });
        let payerTypeObj = varPayerTypes?.[0]?.values?.find(item => {
          if (item?.name === 'Rider') {
            return item ?? {};
          }
        });
        setPayerTypes(payerTypeObj);
        let varTeam = res?.filter(item => {
          if (item?.type === 'ACTIVETEAM') {
            return item.values;
          }
        });
        let varState = res?.filter(item => {
          if (item?.type === 'STATE') {
            return item.values;
          }
        });
        let varAffiliations = res?.filter(item => {
          if (item?.type === 'PROFESSIONALAFFILIATIONS') {
            return item.values?.map(data => {
              return data.name;
            });
          }
        });

        const categoryLookup = res?.find(obj => obj.type === 'CATEGORY');
        let filteredcategory = categoryLookup?.values?.filter(item => {
          if (item.name === 'Alumni') {
            return { item, checked: false };
          }
        });

        setTeamData(varTeam?.[0]?.values!);
        setStateData(varState?.[0]?.values!);
        setAffiliationsData(varAffiliations?.[0]?.values!);
        setChildDivisonData(filteredcategory?.[0].children!);
        dispatch(PageActions.setPageLoader(false));
      })

      .catch(error => {
        console.log('error');
        dispatch(PageActions.setPageLoader(false));
      });
  };
  function getBackNumberList() {
    handleRequest(riderGetBackNumberList())
      .then(data => {
        setBackNumberData(data);
      })
      .catch(() => {});
  }
  const handleChangeStepper = (e: StepperChangeEvent) => {
    const index = Number(e.value);
    if (index >= 0 && index !== formNumber) {
      setFormNumber(index);
    }
  };
  const handleItemClick = (index: number) => {};
  React.useEffect(() => {
    handleGetLookups();
    getBackNumberList();
    const paymentCancelStatus = Cookies.get('paymentCancel');
    setCancelPaymentCookie(paymentCancelStatus);
  }, []);

  React.useEffect(() => {
    const aluminiNewMemberData = Cookies.get('payload');
    const radioValuesCookieData = Cookies.get('payloadRadiovalues');
    if (aluminiNewMemberData && radioValuesCookieData) {
      const aluminiNewMemberDataParse = JSON.parse(aluminiNewMemberData);
      const radioValuesCookieDataParse = JSON.parse(radioValuesCookieData);

      setFormNumber(2);
      setAlumniData(aluminiNewMemberDataParse);
      setRadioGroupValues(radioValuesCookieDataParse);
      setHeightWeight({
        feet: Math.floor(aluminiNewMemberDataParse?.height!),
        inches: Math.round(
          (aluminiNewMemberDataParse?.height! -
            Math.floor(aluminiNewMemberDataParse?.height!)) *
            12,
        ),
        weight: aluminiNewMemberDataParse.weight,
      });
      setPhotoUploadPayload(photoUploadDataRedux ?? {});
      setUploadFile(photoUploadDataRedux?.formData?.file as File);
    }
  }, [cancelPaymentCookie]);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 767);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      {pageLoader ? (
        <LoaderWrapper>
          <CircularLoader />
        </LoaderWrapper>
      ) : (
        <MainWrapper
          className="page-content-wrapper"
          id="AlumniNewMemberResgistration"
        >
          <p className="Team-Heading">New Member</p>
          <div className="Stepper-wrapper">
            {isSmallScreen ? (
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <CircularProgress
                  size="lg"
                  determinate
                  value={determinateValue}
                >
                  {`${formNumber + 1} / ${totalPages}`}
                </CircularProgress>
                {items
                  .filter((_, index) => index === formNumber)
                  .map((item, index) => (
                    <h2 className="circular-stepper-heading" key={index}>
                      {item.label}
                    </h2>
                  ))}
              </Box>
            ) : (
              <Stepper
                value={formNumber}
                onChange={handleChangeStepper}
                items={items}
                className="form-stepper"
              >
                {items.map((item, index) => (
                  <StepperStep
                    key={index}
                    className={`stepper-step ${
                      index < formNumber ? 'completed' : ''
                    }`}
                    onClick={() => handleItemClick(index)}
                  >
                    {index < formNumber ? (
                      <span className="tick-icon">&#10003;</span>
                    ) : (
                      item.number
                    )}
                  </StepperStep>
                ))}
              </Stepper>
            )}
          </div>
          <>
            {formNumber === 0 ? (
              <PersonalInfoForm
                handleNextForm={handleNextForm}
                handleBackForm={handleBackForm}
                alumniData={alumniData}
                setAlumniData={setAlumniData}
                teamData={teamData}
                stateData={stateData}
                setHeightWeight={setHeightWeight}
                heightWeight={heightWeight}
                backNumberData={backNumberData}
              />
            ) : formNumber === 1 ? (
              <AlumniDivisionForm
                handleNextForm={handleNextForm}
                handleBackForm={handleBackForm}
                alumniData={alumniData}
                setAlumniData={setAlumniData}
                radioGroupValues={radioGroupValues}
                setRadioGroupValues={setRadioGroupValues}
                childDivisonData={childDivisonData}
              />
            ) : formNumber === 2 ? (
              <PersonalAffiliationsForm
                handleNextForm={handleNextForm}
                handleBackForm={handleBackForm}
                alumniData={alumniData}
                setAlumniData={setAlumniData}
                radioGroupValues={radioGroupValues}
                setRadioGroupValues={setRadioGroupValues}
                affiliationsData={affiliationsData}
                setPhotoUploadPayload={setPhotoUploadPayload}
                photoUploadPayload={photoUploadPayload}
                setFileAvailability={setFileAvailability}
                fileAvailability={fileAvailability}
                setUploadFile={setUploadFile}
                uploadFile={uploadFile}
                setIsNotImage={setIsNotImage}
                isNotImage={isNotImage}
              />
            ) : (
              <></>
            )}
          </>
        </MainWrapper>
      )}
    </>
  );
};

export default AlumniNewMemberResgistration;
const MainWrapper = styled.div`
  height: 100%;
  .Team-Heading {
    padding-top: 20px;
    text-align: center;
    color: #9597a6;
  }
  //stepper-style
  .circular-stepper-heading {
    color: #8a8a8a;
  }
  .MuiCircularProgress-progress,
  .MuiCircularProgress-sizeLg {
    color: #8a8a8a;
    stroke: #2dce98;
  }
  .css-zitgxn-JoyCircularProgress-track {
    stroke: #434343;
  }
  .k-step-done {
    .k-step-indicator-text {
      position: relative;
      ::after {
        position: absolute;
        content: '';
        z-index: 15;
        background: #2dce98;
        background-image: url(${check});
        background-repeat: no-repeat;
        background-size: inherit;
        background-position: center;
        border-radius: 50px;
        left: -8px;
        height: 26px;
        width: 26px;
        top: -1px;
      }
    }
  }
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 30px;
  flex-direction: column;
  align-items: center;
  .k-stepper.form-stepper {
    pointer-events: none;
  }
  input {
    ::placeholder {
      font-size: 14px;
      text-transform: capitalize;
    }
  }
  .k-stepper .k-step-indicator::after {
    box-shadow: unset !important;
  }
  .k-stepper .k-step-indicator::after {
    border-style: unset !important;
  }
  .k-stepper .k-step-label .k-step-text {
    width: 200px;
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #8a8a8a;
    font-size: 12px;
    max-width: unset !important;
  }
  .k-progressbar {
    background: #5b5b5b;
  }
  .k-progressbar .k-selected {
    background: #2dce98;
  }
  .k-stepper .k-step-done .k-step-indicator {
    border-color: #2dce98;
    color: white;
    background-color: #2dce98;
  }
  .k-stepper .k-step-current .k-step-indicator {
    border-color: #2dce98;
    color: white;
    background-color: #2dce98;
  }
  .k-stepper .k-step-indicator {
    border-color: #5b5b5b;
    color: #c4c4c4;
    background-color: #5b5b5b;
    width: 25px;
    height: auto;
  }
  .k-stepper {
    padding-bottom: 20px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        padding: 0 10px;
        height: 55px;
        gap: 5px;
      }
      .circular-stepper-heading {
        font-size: 13px;
      }
      .MuiCircularProgress-root {
        --CircularProgress-size: 50px;
      }
    }
    .k-stepper .k-step-label .k-step-text {
      width: 55px;
      font-size: 10px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        height: 55px;
        padding: 0 15px;
      }
      .circular-stepper-heading {
        font-size: 18px;
      }
      .MuiCircularProgress-root {
        --CircularProgress-size: 55px;
      }
    }
    .k-stepper .k-step-label .k-step-text {
      width: 55px;
      font-size: 11px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        height: 55px;
        padding: 0 20px;
        gap: 25px;
      }
      .circular-stepper-heading {
        font-size: 22px;
      }
    }
  }
  //Page Responsive
  @media (min-width: 100px) and (max-width: 319px) {
    .form-MainWrapper {
      padding-top: 10px;
      .inner-BorderWrapper {
        padding: 20px 20px;
      }
      .TextFieldWrapper {
        width: 100%;
        &.BackNumberFieldWrapper {
          .BackNumberField-label {
            margin: 0px !important;
          }
        }
        &.height-weight-wrapper {
          .FlexFieldWrapper {
            flex-direction: column;
            align-items: flex-start;
          }
          .note-block {
            font-size: 11px !important;
            padding-top: 22px !important;
            align-items: baseline;
            width: 100%;
            height: 100%;
          }
        }
      }
      .BackFieldWrapper {
        width: 100%;
        .BackNumberWrapper {
          padding: 0px;
        }
        .StyledTextField {
        }
      }
      .GraduationDobWrapper {
        width: 100%;
        flex-direction: column;
        padding: 0x;

        .GraduationSubWrapper {
          width: 100%;
          margin: 0 0 20px 0;
          .SHYear {
            margin: 0px !important;
          }
        }
      }
      .NoteFieldWrapper {
        margin: 0px;
        text-align: justify;
      }
      .StyledTextField {
        margin: 0px 0px;
      }
      .SHdropdown {
        margin: 0px 0px !important;
      }
      #InfoLabelWrapper {
        padding: 0 0px 10px 0px !important;
        label {
          font-size: 11px !important;
        }
      }
      .TextFieldGrid {
        &.height-note-grid {
          padding: 0px 0px !important;
          flex-direction: column-reverse !important;
        }
      }
      .StyledHeightTextField {
        width: 30%;
        /* margin: 0px 0px 0px 20px !important; */
        input {
          width: 100%;
        }
        &.StyledHeightTextField-first {
          margin: 0px !important;
        }
      }
      .StyledWeightTextField {
        width: 30%;
        margin: 0px !important;
        input {
          width: 100%;
        }
      }
      .flex-NoteWrapper {
        width: 30%;
        text-align: center;
      }
      .MainAddressWrapper {
        margin: 0px !important;
        padding: 0px 0px 85px 0px !important;
        .text-left-NoteField {
          width: 100% !important;
          .note-block {
            font-size: 11px;
            letter-spacing: 0.1px;
          }
        }
      }

      #BottomRightWrapper {
        height: 50px;
      }
      .StyledFormSecondaryLabel {
        font-size: 10px !important;
        letter-spacing: 0.2px;
      }
      .HeightFieldWrapper {
        margin-bottom: 20px;
        width: 100%;
      }
      .QuestionWrapper {
        .FormSecondaryLabel {
          font-size: 10px !important;
          letter-spacing: 0.2px;
        }
      }
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .form-MainWrapper {
      padding-top: 10px;
      .inner-BorderWrapper {
        padding: 20px 20px;
      }
      .TextFieldWrapper {
        width: 100%;
        &.BackNumberFieldWrapper {
          .BackNumberField-label {
            margin: 0px !important;
          }
        }
        &.height-weight-wrapper {
          .FlexFieldWrapper {
            flex-direction: column;
            align-items: flex-start;
          }
          .note-block {
            font-size: 11px !important;
            padding-top: 22px !important;
            align-items: baseline;
            width: 100%;
            height: 100%;
          }
        }
      }
      .BackFieldWrapper {
        width: 100%;
        padding: 0px;
        .BackNumberWrapper {
          padding: 0px;
        }
        .StyledTextField {
          margin-left: 0px;
        }
      }
      .enrollment-bottom-para {
        bottom: unset;
        padding: 0 12px;
      }
      .GraduationDobWrapper {
        width: 100%;
        flex-direction: column;
        padding: 0px;
        .GraduationSubWrapper {
          width: 100%;
          margin: 0 0 20px 0;
          .SHYear {
            margin: 0px !important;
          }
        }
      }
      .StyledTextField {
        margin: 0px 0px;
      }
      .SHdropdown {
        margin: 0px 0px !important;
      }
      #InfoLabelWrapper {
        padding: 0 0px 10px 0px !important;
        label {
          font-size: 13px !important;
        }
      }
      .TextFieldGrid {
        padding: 10px 0px !important;
        &.height-note-grid {
          padding: 0px 0px !important;
          flex-direction: column-reverse !important;
        }
      }
      .MainAddressWrapper {
        margin: 0px !important;
        padding: 0px 0px 85px 0px !important;
        .text-left-NoteField {
          width: 100% !important;
        }
      }

      .NoteFieldWrapper {
        margin: 0px !important;
        text-align: justify;

        .note-block {
          font-size: 10px;
          letter-spacing: 0.2px;
        }
      }
      .StyledWeightTextField {
        margin: 0px !important;
      }
      .HeightFieldWrapper {
        margin-bottom: 20px;
        width: 100%;
      }

      .StyledHeightTextField {
        width: 33.33%;
        input {
          width: 100%;
        }
        &.StyledHeightTextField-first {
          margin: 0px !important;
        }
      }
      .StyledWeightTextField {
        width: 31%;
        input {
          width: 100%;
        }
      }
      .flex-NoteWrapper {
        width: 33.33%;
        text-align: center;
      }
      .horizontal-line {
        margin: 20px 0px 15px 0px;
      }

      #BottomRightWrapper {
        height: 50px;
      }
      .StyledFormSecondaryLabel {
        font-size: 10px !important;
        letter-spacing: 0.2px;
      }
      .QuestionWrapper {
        .FormSecondaryLabel {
          font-size: 10px !important;
          letter-spacing: 0.2px;
        }
      }
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .form-MainWrapper {
      .inner-BorderWrapper {
        padding: 20px 20px;
      }

      #InfoLabelWrapper {
        label {
          font-size: 10px !important;
        }
      }
      .TextFieldWrapper {
        width: 100%;
        .SHdropdown {
          margin: 0 8px;
        }
        &.height-weight-wrapper {
          .FlexFieldWrapper {
            flex-direction: column;
            align-items: flex-start;
          }
          .note-block {
            font-size: 12px !important;
            padding-top: 22px !important;
          }
        }
      }
      .BackFieldWrapper {
        width: 100%;
        padding: 0px;
        .BackNumberWrapper {
          padding: 0 8px;
        }
        .StyledTextField {
          margin-left: 0px;
        }
      }
      .GraduationDobWrapper {
        width: 100%;
        flex-direction: column;
        padding: 0 8px;
        .GraduationSubWrapper {
          width: 100%;
          margin: 0 0 20px 0;
          .SHYear {
            margin: 0px !important;
          }
        }
      }
      .NoteFieldWrapper {
        margin: 0 8px;
        text-align: justify;

        .note-block {
          font-size: 10px;
          letter-spacing: 0.2px;
        }
      }
      .StyledWeightTextField {
        margin: 0px !important;
      }
      .HeightFieldWrapper {
        margin-bottom: 20px;
        width: 100%;
      }

      .StyledHeightTextField {
        width: 33.33%;
        input {
          width: 100%;
        }
        &.StyledHeightTextField-first {
          margin: 0px !important;
        }
      }
      .StyledWeightTextField {
        width: 30%;
        input {
          width: 100%;
        }
      }
      .flex-NoteWrapper {
        width: 33.33%;
        text-align: center;
      }
      .FlexFieldWrapper {
        margin: 0 8px;
      }
      .StyledTextField {
        margin: 0px 8px;
      }
      #BottomRightWrapper {
        height: 50px;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .form-MainWrapper {
      .inner-BorderWrapper {
        padding: 20px 30px 30px 30px;
      }

      #InfoLabelWrapper {
        label {
          font-size: 10px !important;
        }
      }
      .TextFieldWrapper {
        width: 100%;
        .SHdropdown {
          margin: 0 8px;
        }
        &.height-weight-wrapper {
          margin: 0 8px;
          width: 100%;
          .note-block {
            font-size: 11px !important;
            padding-top: 22px !important;
          }
        }
      }
      .BackFieldWrapper {
        width: 100%;
        padding: 0px;
        .BackNumberWrapper {
          padding: 0 8px;
        }
        .StyledTextField {
          margin-left: 0px;
        }
      }
      .GraduationDobWrapper {
        width: 100%;
        flex-direction: column;
        padding: 0 8px;
        .GraduationSubWrapper {
          width: 100%;
          margin: 0 0 20px 0;
          .SHYear {
            margin: 0px !important;
          }
        }
      }
      .NoteFieldWrapper {
        margin: 0 8px !important;
        .note-block {
          font-size: 10px;
          letter-spacing: 0.2px;
          text-align: justify;
        }
      }
      .HeightFieldWrapper {
        width: 80%;
      }
      .StyledHeightTextField {
        width: 27.5%;
        margin: 0px 12px;
        input {
          width: 100%;
        }
        &.StyledHeightTextField-first {
          margin: 0px !important;
        }
      }
      .StyledWeightTextField {
        width: 25%;
        margin: 0px !important;
        input {
          width: 100%;
        }
      }

      .flex-NoteWrapper {
        width: 20%;
        text-align: center;
      }
      .StyledTextField {
        margin: 0px 8px;
      }

      #BottomRightWrapper {
        height: 50px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .form-MainWrapper {
      .inner-BorderWrapper {
        padding: 20px 35px 30px 35px;
      }
      .TextNoteWrapper {
        width: 100%;
      }
      .HeightFieldWrapper {
        gap: 10px;
        width: 80%;
      }
      .StyledHeightTextField {
        width: 30%;
        input {
          width: 100%;
        }
      }
      .StyledWeightTextField {
        width: 25%;
        input {
          width: 100%;
        }
      }
      .NoteFieldWrapper {
        margin: 0px 12px;
      }

      .flex-NoteWrapper {
        width: 20%;
        text-align: center;
      }
      .NoteFieldWrapper {
        .note-block {
          text-align: justify;
        }
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .form-MainWrapper {
      .inner-BorderWrapper {
        padding: 20px 40px 30px 40px;
      }
      .FlexFieldWrapper {
        padding: 0 12px;
        gap: 10px;
      }
      .HeightFieldWrapper {
        gap: 10px;
        width: 80%;
        .StyledHeightTextField {
          margin: 0px;
          width: 28%;
          input {
            width: 100%;
          }
        }
      }
      .StyledWeightTextField {
        width: 25%;
        margin: 0px !important;
        input {
          width: 100%;
        }
      }
      .NoteFieldWrapper {
        margin: 0px 12px;
      }
      .flex-NoteWrapper {
        width: 30%;
        text-align: center;
        margin: 0px !important;
      }
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .form-MainWrapper {
      .inner-BorderWrapper {
        padding: 20px 50px 30px 50px;
      }
      .FlexFieldWrapper {
        padding: 0 12px;
        gap: 10px;
      }
      .HeightFieldWrapper {
        gap: 10px;
        width: 80%;
        .StyledHeightTextField {
          margin: 0px;
          width: 30%;
          input {
            width: 100%;
          }
        }
      }
      .StyledWeightTextField {
        width: 25%;
        margin: 0px !important;
        input {
          width: 100%;
        }
      }
      .NoteFieldWrapper {
        margin: 0px 12px;
      }
      .flex-NoteWrapper {
        width: 30%;
        text-align: center;
        margin: 0px !important;
      }
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    .form-MainWrapper {
      .inner-BorderWrapper {
        padding: 20px 55px 40px 55px;
      }
      .FlexFieldWrapper {
        padding: 0 12px;
        gap: 10px;
      }
      .HeightFieldWrapper {
        gap: 10px;
        width: 80%;
        .StyledHeightTextField {
          margin: 0px;
          width: 30%;
          input {
            width: 100%;
          }
        }
      }
      .StyledWeightTextField {
        width: 25%;
        margin: 0px !important;
        input {
          width: 100%;
        }
      }
      .NoteFieldWrapper {
        margin: 0px 12px;
      }
      .flex-NoteWrapper {
        width: 30%;
        text-align: center;
        margin: 0px !important;
      }
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    .form-MainWrapper {
      .inner-BorderWrapper {
        padding: 20px 60px 50px 60px;
      }
      .FlexFieldWrapper {
        padding: 0 12px;
        gap: 10px;
      }
      .HeightFieldWrapper {
        gap: 10px;
        width: 80%;
        .StyledHeightTextField {
          margin: 0px;
          width: 30%;
          input {
            width: 100%;
          }
        }
      }
      .StyledWeightTextField {
        width: 25%;
        margin: 0px !important;
        input {
          width: 100%;
        }
      }
      .NoteFieldWrapper {
        margin: 0px 12px;
      }
      .flex-NoteWrapper {
        width: 30%;
        text-align: center;
        margin: 0px !important;
      }
    }
  }
`;
const StepperStep = styled.button`
  background-color: green !important;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;
  font-weight: bold;

  &.completed {
    background-color: green !important;
  }
`;
