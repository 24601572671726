import React from 'react';
import styled from 'styled-components';
import { TextField } from 'app/components/TextField';
import '@fontsource/roboto';
import { CodeOfConduct } from 'app/components/CodeOfConduct';
import SHdropdown from 'app/components/SHdropdown';
import { Associate, GeneralLookup } from 'services/openapi';
import SimpleReactValidator from 'simple-react-validator';
import CustomDragAndDropFiles from 'app/components/CustomDragAndDropFiles';
import { useDispatch } from 'react-redux';
import { useRiderRegistrationStateSlice } from 'app/pages/RiderRegistrationPage/slice';

type Props = {
  labelName?: string;
  forAssociatePage?: boolean;
  setAssociateFormPayload: React.Dispatch<React.SetStateAction<Associate>>;
  associateFormPayload?: Associate;
  stateData?: GeneralLookup[];
  setValid: React.Dispatch<React.SetStateAction<boolean>>;
  valid: boolean;
  setPhotoUploadPayload: React.Dispatch<
    React.SetStateAction<{
      formData?:
        | {
            file?: Blob | undefined;
          }
        | undefined;
    }>
  >;
  photoUploadPayload: {
    formData?:
      | {
          file?: Blob | undefined;
        }
      | undefined;
  };
  setFileAvailability: React.Dispatch<React.SetStateAction<boolean>>;
  fileAvailability: boolean;
  setUploadFile: React.Dispatch<React.SetStateAction<File | null | undefined>>;
  uploadFile: File | null | undefined;
  rolesData: GeneralLookup[];
  setIsNotImage?: React.Dispatch<React.SetStateAction<boolean>>;
  isNotImage?: boolean;
  simpleValidator: React.MutableRefObject<SimpleReactValidator>;
};

export const AssociateFormFields = ({
  labelName,
  forAssociatePage,
  associateFormPayload,
  setAssociateFormPayload,
  stateData,
  setValid,
  valid,
  setPhotoUploadPayload,
  photoUploadPayload,
  setFileAvailability,
  fileAvailability,
  setUploadFile,
  uploadFile,
  rolesData,
  setIsNotImage,
  isNotImage,
  simpleValidator,
}: Props) => {
  const dispatch = useDispatch();
  const { actions: manageAppActions } = useRiderRegistrationStateSlice();
  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(!formValid);
  }, [associateFormPayload]);
  const handleZipChange = e => {
    const inputValue = e.target.value;
    if (associateFormPayload) {
      if (inputValue.length <= 9 && /^[a-zA-Z0-9]*$/.test(inputValue)) {
        setAssociateFormPayload({
          ...associateFormPayload,
          zip: inputValue,
        });
      }
    }
  };
  const handlePhChange = e => {
    const inputValue = e.target.value;
    if (associateFormPayload) {
      if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
        setAssociateFormPayload({
          ...associateFormPayload,
          primaryPhone: inputValue,
        });
      }
    }
  };
  const handleEmergencyPhChange = e => {
    const inputValue = e.target.value;
    if (associateFormPayload) {
      if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
        setAssociateFormPayload({
          ...associateFormPayload,
          emergencyContactPhone: inputValue,
        });
      }
    }
  };
  const handleFileUpload = e => {
    setIsNotImage?.(false);
    var file = e.target.files[0];
    dispatch(manageAppActions.setPhotoUpload(file));
    setUploadFile?.(file);
    setPhotoUploadPayload?.({
      ...photoUploadPayload,
      formData: { file: file },
    });
  };
  const handleDragFileUpload = e => {
    var dragFile = e?.dataTransfer?.files[0];
    if (dragFile && dragFile.type.startsWith('image/')) {
      dispatch(manageAppActions.setPhotoUpload(dragFile));

      setUploadFile?.(dragFile);
      setPhotoUploadPayload?.({
        ...photoUploadPayload,
        formData: { file: dragFile },
      });
      setIsNotImage?.(false);
    } else {
      setUploadFile?.(undefined);
      setPhotoUploadPayload?.({
        ...photoUploadPayload,
        formData: { file: undefined },
      });
      setIsNotImage?.(true);
    }
  };
  const handleRemoveFile = e => {
    setIsNotImage?.(false);
    setUploadFile?.(undefined);
    setPhotoUploadPayload?.({
      ...photoUploadPayload,
      formData: { file: undefined },
    });
  };
  const handleFileOnCancelClick = e => {
    setIsNotImage?.(false);
    setUploadFile?.(undefined);
    setPhotoUploadPayload?.({
      ...photoUploadPayload,
      formData: { file: undefined },
    });
  };
  React.useEffect(() => {
    if (uploadFile === undefined) {
      setFileAvailability?.(true);
    } else {
      setFileAvailability?.(false);
    }
  }, [uploadFile]);

  return (
    <MainWrapper>
      <TextFieldGrid>
        <TextFieldWrapper>
          <StyledTextField
            onChange={e =>
              setAssociateFormPayload({
                ...associateFormPayload!,
                firstName: e.target.value,
              })
            }
            value={associateFormPayload?.firstName}
            label="First Name"
            type="text"
            name="name"
            validations={'required|max:30'}
            validator={simpleValidator.current}
          />
        </TextFieldWrapper>
        <TextFieldWrapper>
          <StyledTextField
            onChange={e =>
              setAssociateFormPayload({
                ...associateFormPayload!,
                lastName: e.target.value,
              })
            }
            value={associateFormPayload?.lastName}
            label="Last Name"
            type="text"
            name="name"
            validations={'required|max:30'}
            validator={simpleValidator.current}
          />
        </TextFieldWrapper>
        <DropdownWrapper>
          <SHdropdown
            className="drop-down"
            label="Role"
            onDataChange={e => {
              setAssociateFormPayload({
                ...associateFormPayload!,
                role: e,
              });
            }}
            data={rolesData}
            defaultValue={associateFormPayload?.role}
            validations={'required'}
            validator={simpleValidator.current}
          />
        </DropdownWrapper>
        <TextFieldWrapper>
          <StyledTextField
            onChange={e =>
              setAssociateFormPayload({
                ...associateFormPayload!,
                street: e.target.value,
              })
            }
            value={associateFormPayload?.street}
            label="Address"
            type="text"
            name="address"
            validations={'required'}
            validator={simpleValidator.current}
          />
        </TextFieldWrapper>
        <TextFieldWrapper>
          <StyledTextField
            onChange={e =>
              setAssociateFormPayload({
                ...associateFormPayload!,
                city: e.target.value,
              })
            }
            value={associateFormPayload?.city}
            label="City"
            type="text"
            name="city"
            validations={'required|max:40'}
            validator={simpleValidator.current}
            maxLength={40}
          />
        </TextFieldWrapper>
        <DropdownWrapper>
          <SHdropdown
            className="drop-down"
            label="State"
            onDataChange={e => {
              setAssociateFormPayload({
                ...associateFormPayload!,
                state: e,
              });
            }}
            data={stateData}
            defaultValue={associateFormPayload?.state}
            validations={'required'}
            validator={simpleValidator.current}
          />
        </DropdownWrapper>
        <TextFieldWrapper>
          <StyledTextField
            onChange={handleZipChange}
            value={associateFormPayload?.zip}
            label="Zip"
            type="text"
            name="zip"
            validations={'required|alpha_num|min:5|max:9'}
            validator={simpleValidator.current}
          />
        </TextFieldWrapper>
        <TextFieldWrapper>
          <StyledTextField
            onChange={handlePhChange}
            value={associateFormPayload?.primaryPhone}
            label="Phone Number"
            type="tel"
            name="phoneNumber"
            validations={'required|numeric|min:10|max:10'}
            validator={simpleValidator.current}
          />
        </TextFieldWrapper>
        <TextFieldWrapper>
          <StyledTextField
            onChange={e =>
              setAssociateFormPayload({
                ...associateFormPayload!,
                email: e.target.value,
              })
            }
            value={associateFormPayload?.email}
            label="Email:"
            type="email"
            name="email"
            validations={'required|email'}
            validator={simpleValidator.current}
            maxLength={60}
          />
        </TextFieldWrapper>
        <TextFieldWrapper>
          <StyledTextField
            onChange={e =>
              setAssociateFormPayload({
                ...associateFormPayload!,
                emergencyContactName: e.target.value,
              })
            }
            value={associateFormPayload?.emergencyContactName}
            label="Emergency Contact"
            type="text"
            name="emergencyContact"
            validations={'required'}
            validator={simpleValidator.current}
          />
        </TextFieldWrapper>
        <TextFieldWrapper>
          <StyledTextField
            onChange={handleEmergencyPhChange}
            value={associateFormPayload?.emergencyContactPhone}
            label="PH:"
            type="tel"
            name="ph"
            validations={'required|numeric|min:10|max:10'}
            validator={simpleValidator.current}
          />
        </TextFieldWrapper>
        <PhotoUploadWrapper>
          <h3 className="photo-title"> Upload your current photo below. </h3>
          <CustomDragAndDropFiles
            className="dragNdrop"
            onCancel={e => handleFileOnCancelClick(e)}
            onChange={e => handleFileUpload(e)}
            uploadBtnDisabled={!fileAvailability}
            onRemoveFile={handleRemoveFile}
            chooseFileBtnDisabled={!fileAvailability}
            uploadFile={uploadFile}
            accept=".jpg, .jpeg, .png"
            onDragChange={e => handleDragFileUpload(e)}
            isNotImageValidation={isNotImage}
          />
        </PhotoUploadWrapper>
        <CodeOfConduct />
      </TextFieldGrid>
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  color: #9597a6;
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
  height: 100%;
  padding: 20px 0px 0px;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    label {
      font-size: 14px;
    }
  }
`;

const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 130px;
`;
const TextFieldWrapper = styled.div`
  width: 50% !important;
  margin-bottom: 20px;
  &.styled-TextFieldWrapper {
    display: grid;
    align-items: end;
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    width: 100% !important;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 50% !important;
  }
`;
const StyledTextField = styled(TextField)`
  flex: 0 0 50%;
  &.styled-field {
    margin-bottom: 0px !important;
  }
`;
const PhotoUploadWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  .photo-title {
    color: #a7a7a7;
    font-family: 'Roboto';
    font-size: 15px;
    text-align: center;
  }
`;
const DropdownWrapper = styled.div`
  width: 50% !important;
  margin-bottom: 20px;
  p {
    margin: 0px;
    font-family: 'Roboto';
    font-size: 14px;
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    width: 100% !important;
  }
`;
