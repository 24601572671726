/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminCoachInfo } from '../models/AdminCoachInfo';
import type { ChangeCoachActiveStatusRequest } from '../models/ChangeCoachActiveStatusRequest';
import type { Coach } from '../models/Coach';
import type { CoachListWrapper } from '../models/CoachListWrapper';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ManageCoachService {

    /**
     * API to get coach list
     * @param season 
     * @param teamFilter 
     * @param searchInput 
     * @param status 
     * @param sortBy 
     * @param sortOrder 
     * @param pageNumber 
     * @param pageSize 
     * @returns CoachListWrapper Success
     * @throws ApiError
     */
    public static manageCoachGetCoaches(
season?: string,
teamFilter?: Array<string>,
searchInput?: string,
status?: string,
sortBy?: string,
sortOrder?: number,
pageNumber: number = 1,
pageSize: number = 10,
): CancelablePromise<Array<CoachListWrapper>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/coaches/admin/coaches',
            query: {
                'season': season,
                'teamFilter': teamFilter,
                'searchInput': searchInput,
                'status': status,
                'sortBy': sortBy,
                'sortOrder': sortOrder,
                'pageNumber': pageNumber,
                'pageSize': pageSize,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * API to delete coach
     * @param id 
     * @returns string Success
     * @throws ApiError
     */
    public static manageCoachDeleteCoach(
id: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/admin/coaches/coach/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to get coach info
     * @param id 
     * @returns AdminCoachInfo Success
     * @throws ApiError
     */
    public static manageCoachGetCoachInfo(
id: string,
): CancelablePromise<AdminCoachInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/coaches/admin/coaches/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * API to upload Coach Photo
     * @param coachId 
     * @param formData 
     * @returns any Created
     * @throws ApiError
     */
    public static manageCoachUploadCoachPhoto(
coachId: string,
formData?: {
file?: Blob;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/coaches/{coachId}/photo',
            path: {
                'coachId': coachId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to Create Or Update Coach
     * @param requestBody 
     * @returns AdminCoachInfo Created
     * @throws ApiError
     */
    public static manageCoachCreateOrUpdateCoach(
requestBody?: Coach,
): CancelablePromise<AdminCoachInfo> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/coaches/Coach',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to Upload Certificate of Insurance
     * @param coachId 
     * @param formData 
     * @returns string Created
     * @throws ApiError
     */
    public static manageCoachUploadCertificateOfInsurance(
coachId: string,
formData?: {
certificateOfInsurance?: Blob;
},
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/coaches/{coachId}/certificateOfInsurance',
            path: {
                'coachId': coachId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API mark coach active/inactive
     * @param coachId 
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static manageCoachMarkCoachActiveOrInactive(
coachId: string,
requestBody?: ChangeCoachActiveStatusRequest,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/coaches/coaches/{coachId}/changeActiveStatus',
            path: {
                'coachId': coachId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
