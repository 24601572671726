import { CommonPageWrapper } from 'admin/components/wrappers';
import {
  FilterPayLoad,
  showsResultbreadCrumpitems,
  tabShowResultTabArray,
} from 'admin/pages/ShowIndividual/Components/ShowViewProfile/staticData';
import AdminTab from 'app/components/AdminTab';
import BackButton from 'app/components/BackButton';
import CustomBreadCrumb from 'app/components/CustomBreadCrumb';
import { PageMainLabel } from 'app/components/SHLabel';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ElementaryPage } from './ElementaryPage';
import useCommonLookUpDatas from 'hooks/useCommonLookupData';
import useShowApi from 'admin/hooks/useShowApi';
import { HighPointPrJrPage } from './HighPointPrJrPage';
import { GeneralLookup } from 'services/openapi';
import { defaultAddToShowData } from './static';
import { ShowStatusPage } from './ShowStatusPage';
import { ScoreBoardPage } from './ScoreBoardPage';
import LeaderPointPage from './LeaderPointPage';
const ShowResultPage = () => {
  const [payload, setPayload] =
    React.useState<FilterPayLoad>(defaultAddToShowData);
  const [divisonData, setDivisionData] = React.useState<GeneralLookup[]>();
  const [classData, setClassData] = React.useState<GeneralLookup[]>();
  const [showRiderId, setShowRiderId] = React.useState<string | undefined>(
    undefined,
  );
  const [pageSkip, setPageSkip] = React.useState<number | undefined>(0);
  const { id: showId } = useParams();
  const {
    getShowRiderPoints,
    riderShowList,
    addRiderPoint,
    getSeniorJuniorHighPoints,
    loading,
    highPointList,
    getShowScoreBoard,
    showScoreBoardList,
    getShowLeaderPoint,
    showLeaderPointList,
    getShowElementaryHighPoint,
    showElementaryHighPointList,
  } = useShowApi();
  const { getAllLookupData, lookUps } = useCommonLookUpDatas();
  const location = useLocation();
  const navigate = useNavigate();
  const { id, tab } = useParams();
  const [tabSelected, setTabSelected] = useState<number>(0);
  const baseUrl = '/admin/shows/show-result';

  const handleBackClick = () => {
    window.history.back();
  };

  const onTabSelectRedirect = e => {
    const { selected } = e;
    Cookies.set('teamsTab', selected);
    switch (selected) {
      case 0:
        navigate(`${baseUrl}/show-status/${id}`);
        break;
      case 1:
        navigate(`${baseUrl}/high-point-sr-jr/${id}`);
        break;
      case 2:
        navigate(`${baseUrl}/score-board/${id}`);
        break;
      case 3:
        navigate(`${baseUrl}/leader-point/${id}`);
        break;
      case 4:
        navigate(`${baseUrl}/elementary/${id}`);
        break;
      default:
        return '';
    }
  };

  const switchTabsOnBrowserBack = (tab: string) => {
    if (tab) {
      switch (tab) {
        case 'show-status':
          Cookies.set('teamsTab', 0);
          setTabSelected(0);
          break;
        case 'high-point-sr-jr':
          Cookies.set('teamsTab', 1);
          setTabSelected(1);
          break;
        case 'score-board':
          Cookies.set('teamsTab', 2);
          setTabSelected(2);
          break;
        case 'leader-point':
          Cookies.set('teamsTab', 3);
          setTabSelected(3);
          break;
        default:
          return;
      }
    }
  };
  useEffect(() => {
    switchTabsOnBrowserBack(tab ?? '');
  }, [location.pathname]);
  useEffect(() => {
    if (showId) {
      getAllLookupData('TEAM_CATEGORY');
      getSeniorJuniorHighPoints(showId);
      getShowScoreBoard(showId);
      getShowLeaderPoint(showId);
      getShowElementaryHighPoint(showId);
    }
  }, [showId, tab]);

  useEffect(() => {
    getShowRiderPoints(
      showId ?? '',
      payload.divisionId.id,
      payload.categoryId.id,
      payload.classFilter.id ? [payload.classFilter.id] : undefined,
      pageSkip,
    );
  }, []);
  return (
    <CommonPageWrapper>
      <HeadingWrapper>
        <PageMainLabel>Show result</PageMainLabel>
        <CustomBreadCrumb preData={showsResultbreadCrumpitems} />
      </HeadingWrapper>
      <TabWrapper
        className={
          tabSelected === 0
            ? 'show-status'
            : tabSelected === 1
            ? 'high-point-sr-jr'
            : tabSelected === 2
            ? 'score-board'
            : tabSelected === 3
            ? 'leader-point'
            : tabSelected === 4
            ? 'elementary'
            : ''
        }
      >
        <AdminTab
          data={tabShowResultTabArray}
          tabSelected={tabSelected}
          setTabSelected={setTabSelected}
          onTabSelect={onTabSelectRedirect}
        />
        <BackButton className="back-button" onClick={handleBackClick} />
      </TabWrapper>
      {tab === 'show-status' ? (
        <ShowStatusPage
          lookUps={lookUps}
          showId={showId}
          setPayload={setPayload}
          payload={payload}
          setClassData={setClassData}
          getShowRiderPoints={getShowRiderPoints}
          setDivisionData={setDivisionData}
          pageSkip={pageSkip}
          addRiderPoint={addRiderPoint}
          divisonData={divisonData}
          classData={classData}
          loading={loading}
          riderShowList={riderShowList}
          setPageSkip={setPageSkip}
          setShowRiderId={setShowRiderId}
          showRiderId={showRiderId}
        />
      ) : tab === 'high-point-sr-jr' ? (
        <HighPointPrJrPage highPointList={highPointList} loading={loading} />
      ) : tab === 'score-board' ? (
        <ScoreBoardPage showScoreBoardList={showScoreBoardList} />
      ) : tab === 'leader-point' ? (
        <LeaderPointPage showLeaderPointList={showLeaderPointList} />
      ) : tab === 'elementary' ? (
        <ElementaryPage
          showElementaryHighPointList={showElementaryHighPointList}
        />
      ) : (
        ''
      )}
    </CommonPageWrapper>
  );
};

export default ShowResultPage;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px !important;
`;

const TabWrapper = styled.div`
  position: relative;
  &.rides-info {
    .hPRKpe {
      margin: 0 0 2px 0;
    }
  }

  .back-button {
    cursor: pointer;
    position: absolute;
    right: -12px;
    top: 7px;
    z-index: 2;
    span {
      font-weight: 600;
    }
  }
`;
