import styled from 'styled-components';
import { GeneralLookup } from 'services/openapi';
import { AdminCheckBox } from 'app/components/AdminCheckbox';
import { FormSkeletonLoader } from '../FormSkeletonLoader';
type Props = {
  onCheckboxOptionChange?: (data: any, isChecked: boolean) => void;
  data: GeneralLookup[];
  checked?: GeneralLookup[];
  isDisabled?: boolean | undefined;
  label?: string | undefined;
  valueArray?: GeneralLookup[] | null | undefined;
  className?: string;
  isLoader?: boolean;
};

export const MappedCheckboxGrid = ({
  onCheckboxOptionChange,
  data,
  checked,
  isDisabled,
  label,
  valueArray,
  className,
  isLoader,
}: Props) => {
  return (
    <>
      
        <CheckBoxGrid className={`${className} CheckBoxGrid`}>
          {data.map(item => {
            const isSameObject = valueArray?.find(obj => obj.id === item.id);
            return (
              <CheckBoxWrapper className="CheckBoxWrapper">
                {!isLoader ?(
                <AdminCheckBox
                  className="styledCheckBox"
                  label={label ? label : item?.name}
                  value={isSameObject ? true : false}
                  onChange={e => {
                    onCheckboxOptionChange?.(item, e.value);
                  }}
                  checked={checked?.some(value => value.id === item.id)}
                  isDisabled={isDisabled}
                />
                ) : (
        <FormSkeletonLoader lineContent={1} textWidth={100}  />
        )}
              </CheckBoxWrapper>
            );
          })}
        </CheckBoxGrid>
    </>
  );
};

const CheckBoxGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 10px;

  @media (min-width: 100px) and (max-width: 319px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: 320px) and (max-width: 575px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 576px) and (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (min-width: 1449px) and (max-width: 1567px) {
    grid-template-columns: repeat(6, 1fr);
  }
`;
const CheckBoxWrapper = styled.div`
  display: inline-flex;
  align-items: flex-start;
  padding: 5px;
  .sub-label {
    margin-left: 10px;
  }
`;
