import { GridLayoutItem } from '@progress/kendo-react-layout';
import { CustomgridLayoutWrapper } from 'admin/components/wrappers';
import AdminDropDown from 'app/components/AdminDropDown';
import { AdminTextField } from 'app/components/AdminTextField';
import { ProfileImageUploadCard } from 'app/components/ProfileImageUploadCard';
import styled from 'styled-components';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import {
  GeneralLookup,
  GeneralLookupWrapper,
  User,
  UserTypes,
} from 'services/openapi';
import { getLookupArray } from 'utils/common';
import { DropDownModel } from 'app/components/SHdropdown';
import { AdminMultiCheck } from 'app/components/AdminMultiCheck';
import { userTypeCustomData } from '../../../staticDefaultData';

type Props = {
  action?: string | undefined;
  simpleValidator: MutableRefObject<SimpleReactValidator>;
  setUserPayload: Dispatch<SetStateAction<User>>;
  userPayload: User;
  setImageUploadBlobPayload?: Dispatch<SetStateAction<Blob | null>>;
  setTeamDefault: Dispatch<SetStateAction<GeneralLookup | undefined>>;
  teamDefault: GeneralLookup | undefined;
  imgSrc: string | null;
  setImgSrc: Dispatch<SetStateAction<string | null>>;
  setUserPreDataList: Dispatch<SetStateAction<DropDownModel[]>>;
  userPreDataList: DropDownModel[];
  loading: boolean;
  lookUps: GeneralLookupWrapper[];
};

export const UserInfoFormBlock = ({
  action,
  simpleValidator,
  setUserPayload,
  userPayload,
  setImageUploadBlobPayload,
  setTeamDefault,
  teamDefault,
  imgSrc,
  setImgSrc,
  setUserPreDataList,
  userPreDataList,
  loading,
  lookUps,
}: Props) => {
  const isTeamDefault = userPreDataList.find(item => item.id === 2);
  const handleChange = event => {
    const { name, value } = event.target;
    setUserPayload(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleDropdownChange = (
    data: DropDownModel | GeneralLookup,
    name: string,
  ) => {
    setUserPayload(prevData => ({
      ...prevData,
      [name]: data,
    }));
  };
  const handleTeamChange = (data: GeneralLookup, name: string) => {
    setUserPayload(prevData => ({
      ...prevData,
      [name]: data.id,
    }));
    setTeamDefault(data);
  };
  const handleImageSelect = e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        setImageUploadBlobPayload?.(file);
      };
      reader.readAsArrayBuffer(file);
    }
  };
  const handleUserTypeRemove = (selectedList: any, selectedItem: any) => {
    const updatedPreDataList = userPreDataList.filter(
      item => item.id !== selectedItem.id,
    );
    const isSuperAdminRemoved = selectedItem.text === 'Super Admin';
    if (isSuperAdminRemoved) {
      setUserPreDataList([]);
    } else {
      setUserPreDataList(updatedPreDataList);
    }

    const commaSeparated = updatedPreDataList.map(item => {
      return item?.text?.replaceAll(' ', '');
    });

    setUserPayload({
      ...userPayload,
      userTypes: commaSeparated as UserTypes[],
    });

    if (selectedList.some(user => user.text === 'Team User')) {
      setTeamDefault({ id: '', name: '' });
    } else {
      setTeamDefault(undefined);
    }
  };

  const handleUserTypeSelect = (selectedList: any, selectedItem: any) => {
    const isSelectingSuperAdmin = selectedItem.text === 'Super Admin';
    if (isSelectingSuperAdmin) {
      setUserPreDataList([selectedItem]);
    } else {
      const filteredSelectedList = selectedList.filter(
        (item: DropDownModel) => item.text !== 'Super Admin',
      );
      const uniqueFilteredList = Array.from(
        new Map(filteredSelectedList.map(item => [item.id, item])).values(),
      );
      const updatedPreDataList = userPreDataList.filter(
        (item: DropDownModel) => item.text !== 'Super Admin',
      );
      const updatedList: DropDownModel[] = [
        ...new Set<DropDownModel>([
          ...(updatedPreDataList as DropDownModel[]),
          ...(uniqueFilteredList as DropDownModel[]),
        ]),
      ];

      setUserPreDataList(updatedList);
    }

    let commaSeparated: string[];
    if (selectedItem.text === 'Super Admin') {
      commaSeparated = [selectedItem.text.replaceAll(' ', '')];
    } else {
      const newItem = selectedItem.text.replaceAll(' ', '');
      commaSeparated = [...(userPayload.userTypes || []), newItem]
        .filter(item => item !== 'SuperAdmin') // Remove 'SuperAdmin' if it exists
        .filter((item, index, self) => self.indexOf(item) === index); // Remove duplicates
    }
    setUserPayload({
      ...userPayload,
      userTypes: commaSeparated as UserTypes[],
    });
    if (
      selectedList.some(user => user.text === 'Team User') &&
      teamDefault === undefined
    ) {
      setTeamDefault({ id: '', name: '' });
    }
  };

  return (
    <>
      <GridWrapper>
        <CustomgridLayoutWrapper
          rows={[{ height: 'auto' }]}
          cols={[
            { width: 'calc(32.33% - 14px)' },
            { width: 'calc(68.67% - 14px)' },
          ]}
          gap={{ rows: 20, cols: 30 }}
        >
          <GridLayoutItem>
            <ProfileImageUploadCard
              uploadButtonLabel={`${imgSrc ? 'Change Image' : 'Upload Image'}`}
              uploadButtonType="uploadFile"
              imgSrc={imgSrc}
              setImgSrc={setImgSrc}
              onSelectFile={handleImageSelect}
              isLoader={loading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <CustomgridLayoutWrapper
              rows={[{ height: 'auto' }]}
              cols={[
                { width: 'calc(50% - 14px)' },
                { width: 'calc(50% - 14px)' },
              ]}
              gap={{ rows: 20, cols: 10 }}
            >
              <GridLayoutItem>
                <AdminMultiCheck
                  label="User Type"
                  placeholder=""
                  onRemove={handleUserTypeRemove}
                  onSelect={handleUserTypeSelect}
                  data={userTypeCustomData}
                  setPreDataList={setUserPreDataList}
                  preDataList={userPreDataList}
                  className={'MHMultiCheck'}
                  validations="required"
                  validator={simpleValidator.current}
                  isLoader={loading}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <AdminTextField
                  label="User First Name"
                  placeholder=""
                  value={userPayload.firstName ?? ''}
                  onChange={handleChange}
                  name="firstName"
                  validations="required"
                  validator={simpleValidator.current}
                  className="field-style"
                  isLoader={loading}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <AdminTextField
                  label="User Last Name"
                  placeholder=""
                  value={userPayload.lastName ?? ''}
                  onChange={handleChange}
                  name="lastName"
                  validations="required"
                  validator={simpleValidator.current}
                  className="field-style"
                  isLoader={loading}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <AdminTextField
                  label="User Name"
                  placeholder=""
                  value={userPayload.userName ?? ''}
                  onChange={handleChange}
                  name="userName"
                  validations="required"
                  validator={simpleValidator.current}
                  className="field-style"
                  isLoader={loading}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <AdminTextField
                  label="Phone Number"
                  placeholder=""
                  value={userPayload.primaryPhone ?? ''}
                  onChange={e => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
                      setUserPayload?.({
                        ...userPayload,
                        primaryPhone: inputValue,
                      });
                    }
                  }}
                  name="primaryPhone"
                  validations="required"
                  validator={simpleValidator.current}
                  className="field-style"
                  isLoader={loading}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <AdminTextField
                  label="User Email"
                  placeholder=""
                  value={userPayload.email ?? ''}
                  onChange={handleChange}
                  name="email"
                  validations="required"
                  validator={simpleValidator.current}
                  className="field-style"
                  isLoader={loading}
                />
              </GridLayoutItem>
            </CustomgridLayoutWrapper>
          </GridLayoutItem>
        </CustomgridLayoutWrapper>
        <CustomgridLayoutWrapper
          rows={[{ height: 'auto' }]}
          cols={[
            { width: 'calc(33.33% - 8px)' },
            { width: 'calc(33.33% - 6px)' },
            { width: 'calc(33.33% - 9px)' },
          ]}
          gap={{ rows: 20, cols: 10 }}
          style={{ paddingTop: '20px' }}
        >
          <GridLayoutItem>
            <AdminTextField
              label="City"
              placeholder=""
              value={userPayload.city ?? ''}
              onChange={handleChange}
              name="city"
              validations="required"
              validator={simpleValidator.current}
              className="field-style"
              isLoader={loading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminDropDown
              label="State"
              placeholder=""
              onDataChange={data => handleDropdownChange(data, 'state')}
              data={getLookupArray('STATE', lookUps)}
              defaultValue={userPayload.state}
              validations="required"
              validator={simpleValidator.current}
              className="field-style"
              isLoader={loading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminTextField
              label="Zip"
              placeholder=""
              value={userPayload.zip ?? ''}
              onChange={handleChange}
              name="zip"
              validations={'required|max:10'}
              validator={simpleValidator.current}
              className="field-style"
              isLoader={loading}
            />
          </GridLayoutItem>
          <GridLayoutItem>
            <AdminTextField
              label="Address"
              placeholder=""
              value={userPayload.street ?? ''}
              onChange={handleChange}
              name="street"
              validations="required"
              validator={simpleValidator.current}
              className="field-style"
              isLoader={loading}
            />
          </GridLayoutItem>
          {isTeamDefault && (
            <GridLayoutItem>
              <AdminDropDown
                label="Team"
                placeholder=""
                onDataChange={data => handleTeamChange(data, 'teamId')}
                data={getLookupArray('TEAM', lookUps)}
                defaultValue={teamDefault}
                className="field-style"
                validations="required"
                validator={simpleValidator.current}
                isLoader={loading}
              />
            </GridLayoutItem>
          )}
        </CustomgridLayoutWrapper>
      </GridWrapper>
    </>
  );
};

const GridWrapper = styled.div`
  padding: 10px 0 20px;
  .k-grid-layout {
    .checkbox {
      margin-left: 15px;
      margin-top: 30px;
      label {
        color: #a3a3a3;
      }
      @media (min-width: 100px) and (max-width: 1365px) {
        margin-left: 5px;
        margin-right: unset;
      }
    }
  }
`;
