import React from 'react';
import styled from 'styled-components';

type Props = {
  sifDetails: any;
};

const EwdSustainingEnrollentTable = ({ sifDetails }: Props) => {
  return (
    <MainWrapper>
      <table>
        <tbody className="vertical-table-body">
          <tr className="vertical-table-row one">
            <td className="vertical-table-cell">Rider name :</td>
            <td className="vertical-table-cell">School :</td>
            <td className="vertical-table-cell">Home mailing address :</td>
            <td className="vertical-table-cell">Rider phone # :</td>
            <td className="vertical-table-cell">School address :</td>
          </tr>

          <tr className="vertical-table-row two">
            <td className="vertical-table-cell">{sifDetails?.firstName}</td>
            <td className="vertical-table-cell">{sifDetails?.schoolName}</td>
            <td className="vertical-table-cell">{sifDetails?.street}</td>
            <td className="vertical-table-cell">{sifDetails?.primaryPhone}</td>
            <td className="vertical-table-cell">{sifDetails?.schoolStreet}</td>
          </tr>
          <tr className="vertical-table-row tdree">
            <td className="vertical-table-cell">Team name :</td>
            <td className="vertical-table-cell">Expected Graduation year :</td>
            <td className="vertical-table-cell"></td>
            <td className="vertical-table-cell">Rider email :</td>
            <td className="vertical-table-cell"></td>
          </tr>
          <tr className="vertical-table-row four">
            <td className="vertical-table-cell">{sifDetails?.team?.name}</td>
            <td className="vertical-table-cell">
              {sifDetails?.expectedGraduationYear}
            </td>
            <td className="vertical-table-cell"></td>
            <td className="vertical-table-cell">{sifDetails?.email}</td>
            <td className="vertical-table-cell"></td>
          </tr>
          <tr className="vertical-table-row five">
            <td className="vertical-table-cell">Rider division :</td>
            <td className="vertical-table-cell">Current grade :</td>
            <td className="vertical-table-cell"></td>
            <td className="vertical-table-cell">Parent email :</td>
            <td className="vertical-table-cell"></td>
          </tr>
          <tr className="vertical-table-row six">
            <td className="vertical-table-cell">
              {sifDetails?.division?.name}
            </td>
            <td className="vertical-table-cell">
              {sifDetails?.currentGrade ? sifDetails?.currentGrade.name : ''}
            </td>
            <td className="vertical-table-cell"></td>
            <td className="vertical-table-cell">{sifDetails?.parentEmail}</td>
            <td className="vertical-table-cell"></td>
          </tr>
        </tbody>
      </table>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  @media (min-width: 100px) and (max-width: 899px) {
    .vertical-table-cell {
      max-width: 100px;
      overflow: auto;
    }
  }
  .vertical-table-body {
    display: flex;
    text-align: left;
  }

  .vertical-table-row {
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }

  .vertical-table-cell {
    flex-basis: 100px;
    // border: 1px solid #ccc;
    padding: 8px;
  }
  .two,
  .four,
  .six {
    font-weight: 600;
    width: 40%;
  }
  .one,
  .three,
  .five {
    width: 25%;
  }
`;
export default EwdSustainingEnrollentTable;
