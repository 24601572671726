import { GridCellProps } from '@progress/kendo-react-grid';
import { CellPositionLabel } from 'admin/components/labels';

type Props = {
  className?: string;
  e: GridCellProps;
  onClick?: (e) => void;
};

const CoachPositionCell = ({ className, e, onClick }: Props) => {
  const data = e.dataItem.position;
  return (
    <td className={className ?? ''}>
      <div>
        <CellPositionLabel>{data}</CellPositionLabel>
      </div>
    </td>
  );
};

export default CoachPositionCell;
