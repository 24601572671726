import { GridCellProps } from '@progress/kendo-react-grid';
import styled from 'styled-components';
type Props = {
  className?: string;
  e: GridCellProps;
  onClick?: (e) => void;
};

const IdProfileCell = ({ className, e, onClick }: Props) => {
  const data = e?.dataItem?.teamId;
  return (
    <StyledTd className={className ?? ''}>
      <TeamWrapper>{data}</TeamWrapper>
    </StyledTd>
  );
};

export default IdProfileCell;
const TeamWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;
const StyledTd = styled.td``;
