import React from 'react';
import styled from 'styled-components';
import search from '../AdminSearchField/assets/search.svg';
import CloseIcon from './assets/close';

export type AdminSearchComponentProps = {
  searchTerm?: string;
  setSearchTerm?: React.Dispatch<React.SetStateAction<string>>;
  onSearchTextEntering?: React.ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  className?: string | undefined;
  onCloseClick?: () => void;
};

export const AdminSearch = ({
  onSearchTextEntering,
  searchTerm,
  setSearchTerm,
  placeholder,
  className,
  onCloseClick,
}: AdminSearchComponentProps) => {
  const onEnterText = e => {
    onSearchTextEntering?.(e);
  };
  return (
    <Wrapper className={className}>
      <SearchWrapper onSearchTextEntering={onEnterText} searchTerm={searchTerm}>
        {searchTerm?.length! > 0 && (
          <CloseBtn
            onClick={() => {
              setSearchTerm?.('');
              onCloseClick?.();
            }}
            className="icon-wrapper"
          >
            <CloseIcon />
          </CloseBtn>
        )}
        <SearchField
          className="search-input"
          value={searchTerm}
          placeholder={placeholder}
          onChange={e => {
            onSearchTextEntering?.(e);
          }}
        />
      </SearchWrapper>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100%;
`;
const SearchWrapper = styled.div<AdminSearchComponentProps>`
  position: relative;
  display: flex;
  &::before {
    content: '';
    display: flex;
    position: absolute;
    top: 17px;
    left: 12px;
    z-index: 1;
    width: 17px;
    height: 17px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    background-size: 14px;
    background-image: url(${search});
    background-repeat: no-repeat;
  }
`;
const CloseBtn = styled.div`
  position: absolute;
  right: 12px;
  top: 11%;
  z-index: 1;
  font-family: 'Inter';
  font-weight: 500;
  color: #737373;
  cursor: pointer;
  .icon-wrapper {
    svg {
      path {
        fill: #6d28d9;
      }
    }
  }
`;
const SearchField = styled.input`
  width: 100% !important;
  border: 1px solid #e5e5e5 !important;
  border-radius: 6px !important;
  min-height: 32px !important;
  all: unset;
  padding: 0 30px 0 38px;
  font-family: 'Inter';
  position: relative;
  color: #000000;
  font-weight: 400;
  line-height: 20px;
  &:hover {
    cursor: text;
  }
  &::placeholder {
    color: #a3a3a3;
    font-weight: 400;
    font-family: 'Inter';
    font-size: 14px;
  }
`;
