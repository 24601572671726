import { EWD } from 'services/openapi';

export const nextYear = new Date().getFullYear() + 1;
export const defaultValue: EWD = {
  id: null,
  isReturningRider: false,
  firstName: '',
  lastName: '',
  expectedGraduationYear: nextYear,
  dateOfBirth: '',
  height: 0,
  weight: 0,
  primaryPhone: '',
  email: '',
  street: '',
  city: '',
  zip: '',
  backNumber: '',
  state: {
    id: '',
    name: '',
    children: null,
  },
  parent: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    street: '',
    city: '',
    state: {
      id: '',
      name: '',
      children: null,
    },
    zip: '',
  },
  team: {
    id: '',
    name: '',
    children: null,
  },
  divisionClassOne: {
    id: '',
    name: '',
    children: null,
  },
  divisionClassTwo: {
    id: '',
    name: '',
    children: null,
  },

  isTrial: false,
  professionalAffiliationMap: [],
  usePhotoForPromotion: true,
  emergencyContactName: '',
  emergencyContactPhone: '',
};
export const defaultPhotoValue = {
  formData: {
    file: undefined,
  },
};
