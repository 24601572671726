import React, { useRef, useState } from 'react';
import CustomDataGrid from 'app/components/CustomDataGrid/Index';
import IconCell from './cells/IconCell';
import styled from 'styled-components';
import { CoachTableMainLabel } from 'admin/components/labels';
import AdminCustomButton from 'app/components/AdminCustomButton';
import IndexCell from './cells/IndexCell';
import CoachPositionCell from './cells/CoachPositionCell';
import CoachProfileCell from './cells/CoachProfileCell';
import useTeamDetailsApi from '../../hooks/useTeamDetailsApi';
import { TableSkeletonLoader } from 'app/components/TableSkeletonLoader';
import { AdminCustomPopUp } from 'app/components/AdminCustomPopUp';
import AdminDropDown from 'app/components/AdminDropDown';
import { GeneralLookup } from 'services/openapi';
import SimpleReactValidator from 'simple-react-validator';
import { CheckBox } from 'app/components/CheckBox';
import { CustomDeletePopup } from 'app/components/CustomDeletePopup';
import useCommonLookUpDatas from 'hooks/useCommonLookupData';
import { getLookupArray } from 'utils/common';
import { useLocation } from 'react-router-dom';
type Props = {
  id?: string;
};

const TeamCoachesList = ({ id }: Props) => {
  const CoachTypeData = [
    { id: '1', name: 'Coach' },
    { id: '2', name: 'Assistant Coach' },
  ];
  const {
    getAllLookupData,
    lookUps,
    loading: lookUpLoading,
  } = useCommonLookUpDatas();
  const {
    fetchManageGetTeamCoaches,
    teamCoachList,
    loading,
    visible,
    setVisible,
    deleteTeamCoach,
    coachList,
    addCoachToTeam,
    coachTypeData,
    setcoachTypeData,
    coachNameData,
    setcoachNameData,
    isMainCoach,
    setIsMainCoach,
  } = useTeamDetailsApi();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [showDeletePopup, setShowDeletePopup] = React.useState<boolean>(false);
  const [coachID, setCoachID] = useState('');
  const currentLocation = useLocation();
  const isPastDataFlag =currentLocation.pathname.includes("past-data")
  //functions
  const handleAddNew = () => {
    setVisible(true);
  };
  const handleClose = () => {
    simpleValidator.current.hideMessages();
    setVisible(false);
    setIsMainCoach(false);
    setcoachTypeData(undefined);
    setcoachNameData(undefined);
  };
  //map array with index
  function mapArrayWithIndex(arr) {
    let indexedArr = arr?.map((item, ind) => {
      return { ...item, index: ind + 1 };
    });
    return indexedArr;
  }

  const columnsData = [
    {
      field: '',
      title: '',
      cell: e => <IndexCell e={e} onClick={handleRowClick} />,
      width: '150',
    },
    {
      field: '',
      title: '',
      cell: e => <CoachPositionCell e={e} onClick={handleRowClick} />,
    },
    {
      field: '',
      title: '',
      cell: e => <CoachProfileCell e={e} onClick={handleRowClick} />,
    },
    {
      field: '',
      title: '',
      cell: e => <IconCell e={e} onDeleteClick={handleDeleteClick} isPastDataFlag={isPastDataFlag}/>,
    },
  ];

  const [errorVisible, setErrorVisible] = useState(false);

  const handleRowClick = e => {};
  const handleDeleteClick = e => {
    setCoachID(e.id);
    setShowDeletePopup(true);
  };

  const handleAddClick = e => {
    handleAddNew();
  };

  const handleCheckboxChange = e => {
    setErrorVisible(!errorVisible);
    setIsMainCoach(e.value);
  };
  const handleDeleteTeamCoache = () => {
    deleteTeamCoach(coachID);
    setShowDeletePopup(false);
  };

  //handleChangeCoachType
  const handleChangeCoachType = (item: GeneralLookup) => {
    if (coachTypeData?.id !== item.id) {
      setcoachNameData(undefined);
    }
    setcoachTypeData(item);
  };

  //handleChangeCoach
  const handleChangeCoach = item => {
    setcoachNameData(item);
  };

  //submitAddCoach
  const handleSubmitAddCoach = () => {
    addCoachToTeam(id ?? '', coachNameData?.id, isMainCoach);
    simpleValidator.current.hideMessages();
  };

  React.useEffect(() => {
    fetchManageGetTeamCoaches(id ?? '');
  }, [coachList]);

  React.useEffect(() => {
    getAllLookupData('ACTIVECOACH');
  }, [coachTypeData]);

  React.useEffect(() => {
    simpleValidator.current.visibleFields = [];
  }, [visible]);

  return (
    <Wrapper>
      <TableHeadWrapper>
        <TableSubWrapper>
          <CoachTableMainLabel>Team Coaches</CoachTableMainLabel>
         {!isPastDataFlag && 
          <AdminCustomButton
            buttonType="primary"
            isActive={false}
            className="fill-button"
            onClick={handleAddClick}
          >
            Add New
          </AdminCustomButton>}
        </TableSubWrapper>
      </TableHeadWrapper>
      {loading || lookUpLoading ? (
        <TableSkeletonLoader rowContent={8} width={100} headingWidth={80} />
      ) : (
        <CustomDataGrid
          data={mapArrayWithIndex(teamCoachList)}
          columns={columnsData}
          totalCount={teamCoachList?.length}
        />
      )}
      {visible && (
        <AdminCustomPopUp
          visible={visible}
          setVisible={setVisible}
          popupLabel={'Add New Coach'}
          closeToggle={handleClose}
          onClickBtn2={handleSubmitAddCoach}
          defaultPopupFooter={true}
          Btn1Label="Cancel"
          Btn2Label="Save"
          disabledBtn2={!coachTypeData || !coachNameData ? true : false}
          children={
            <FlexWrapper>
              <Line />
              <AdminDropDown
                className="drop-down"
                data={CoachTypeData}
                validator={simpleValidator.current}
                validations="required"
                onDataChange={handleChangeCoachType}
                defaultValue={coachTypeData}
                label="Coach Type"
              ></AdminDropDown>
              {coachTypeData?.id === '1' && (
                <CheckboxWrapper>
                  <CheckBox
                    label="Main Coach"
                    className="main-coach"
                    onChange={handleCheckboxChange}
                    checked={isMainCoach}
                  />
                </CheckboxWrapper>
              )}
              <AdminDropDown
                className="drop-down"
                data={getLookupArray('ACTIVECOACH', lookUps)}
                validator={simpleValidator.current}
                validations="required"
                onDataChange={handleChangeCoach}
                defaultValue={coachNameData}
                label="Coach Name"
              ></AdminDropDown>
            </FlexWrapper>
          }
        />
      )}
      <CustomDeletePopup
        deleteIcon={true}
        headerUnderline={false}
        popupSubLabel="Are you sure you want to delete this coach?"
        className="body-scroll-lock height-full delete-update"
        popupLabel="Delete coach"
        setVisible={setShowDeletePopup}
        visible={showDeletePopup}
        align="centre"
        btnLabel1="Cancel"
        btnLabel2="Delete"
        handlebtn2Click={handleDeleteTeamCoache}
        handlebtn1Click={() => {
          setShowDeletePopup(false);
        }}
      />
    </Wrapper>
  );
};

export default TeamCoachesList;
const Wrapper = styled.div`
  border: 1px solid #eaecf0;
  border-radius: 6px;
  padding: 20px 0;
  .k-grid-header {
    border: none !important;
    display: none;
  }
  .kIlOIw .action-icon-wrapper {
    cursor: pointer;
  }
`;
const TableHeadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 20px 20px;
  border-bottom: 1px solid #eaecf0;
  .search-field {
    max-width: 260px;
  }
`;
const TableSubWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  .fill-button {
    margin: 0 0 0 10px;
  }
`;
const FlexWrapper = styled.div`
  padding: 10px 20px 15px 20px;
  .drop-down {
    padding: 6px 0 10px 0;
    .label {
      font-size: 12px !important;
      color: rgb(23, 23, 23) !important;
    }
    .k-input-value-text {
      font-size: 12px !important;
      color: rgb(23, 23, 23) !important;
    }
  }
`;
const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid #eaecf0;
  margin-bottom: 15px;
`;
const CheckboxWrapper = styled.div`
  padding-bottom: 15px;
  > div {
    margin: 12px 0 0;
  }
  .main-coach {
    .k-checkbox {
      background-color: #ffffff !important;
      border-color: #caced8 !important;
    }
  }
`;
const LabelWrapper = styled.div`
  color: #f44336;
  font-size: 12px;
  display: inline-block;
`;
