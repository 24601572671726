import { Switch, SwitchChangeEvent } from '@progress/kendo-react-inputs';

type Props = {
  onChange?: ((event: SwitchChangeEvent) => void) | undefined;
  value?: string | number | boolean | string[] | null | undefined;
  defaultChecked?: boolean | undefined;
  checked?: boolean | undefined;
};

export const SHToggleButton = ({
  onChange,
  value,
  checked,
  defaultChecked,
}: Props) => {
  console.log(defaultChecked, 'defaultChecked');
  return (
    <Switch
      onChange={onChange}
      checked={checked}
      value={value}
      defaultChecked={defaultChecked}
    />
  );
};
