import { GridCellProps } from '@progress/kendo-react-grid';
import { SHToolTip } from 'app/components/SHToolTip';
import styled from 'styled-components';

type Props = {
  className?: string;
  e: GridCellProps;
};

const CoachAffiliationCell = ({ className, e }: Props) => {
  const data = e?.dataItem;
  return (
    <td className={className ?? ''}>
       <SHToolTip position="top">
      <ContactWrapper title={data?.affiliations.join(', ')}>
        {data?.affiliations.join(', ')}
      </ContactWrapper>
      </SHToolTip>
      <div></div>
    </td>
  );
};

export default CoachAffiliationCell;
const ContactWrapper = styled.div`
    width: 100%;
    min-width: 150px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: pre-wrap;
`;
