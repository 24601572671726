import React from 'react';
import styled from 'styled-components';
import arrow from './assets/img/icon/arrow.png';

type Props = {
  userType?: string;
};
const data = [
  { id: 1, text: 'Know the current YEDA rules and abide by them.' },
  {
    id: 2,
    text: 'Put the emotional and physical wellbeing of All YEDA riders, staff, and coaches ahead of your personal desire to win.',
  },
  {
    id: 3,
    text: ' Respect all YEDA staff and officials and refrain from questioning them in a disrespectful or abusive manner.',
  },
  {
    id: 4,
    text: 'Maintain control over your emotions and avoid language, actions, or gestures that could be interpreted as disrespectful, demeaning, hostile, or humiliating.',
  },
  {
    id: 5,
    text: 'Set the example of being humble and generous when winning, and proud and courteous in defeat.',
  },
  {
    id: 6,
    text: 'Ensure that all riders, parents, and other personal associated with your team always demonstrate good sportsmanship and maturity.',
  },
];
export const CodeOfConduct = ({ userType }: Props) => {
  return (
    <MainWrapper>
      <p className="para-one">
        <i>YEDA Code of Conduct</i>
      </p>
      <p className="para-two">
        By registering as a {userType} in YEDA you agree to the following:
      </p>
      <ul>
        {data.map((value, index) => (
          <MappedDataWrapper key={index}>
            <img src={arrow} alt={value?.text} />
            <li>{value.text}</li>
          </MappedDataWrapper>
        ))}
      </ul>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  width: 100%;
  /* height: 100%; */
  border: 1px solid #585858;
  border-radius: 7px;
  margin: 30px 0px 20px;
  padding: 20px;
  ul {
    padding-left: 13px;
  }
  p {
    font-family: 'Roboto';
  }
  .para-one {
    color: #ff9900;
    font-size: 16px;
    border-bottom: 1px solid #585858;
  }
  .para-two {
    padding-bottom: 10px;
    color: #a7a7a7;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .para-one {
      font-size: 13px;
    }
    .para-two {
      font-size: 11px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .para-one {
      font-size: 13px;
    }
    .para-two {
      font-size: 11px;
    }
    ul {
      padding-left: 15px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .para-one {
      font-size: 14px;
    }
    .para-two {
      font-size: 12px;
    }
    ul {
      padding-left: 20px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .para-one {
      font-size: 13px;
    }
    .para-two {
      font-size: 15px;
    }
    ul {
      padding-left: 25px;
    }
  }
`;

const MappedDataWrapper = styled.div`
  display: flex;
  align-items: baseline;
  li {
    padding-left: 10px;
    list-style-type: none;
    padding-bottom: 10px;
    font-family: 'Roboto';
    font-size: 14px;
    color: #a7a7a7;
  }
  img {
    width: 11px;
    height: 11px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    li {
      font-size: 11px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    li {
      font-size: 11px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    li {
      font-size: 12px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    li {
      font-size: 13px;
    }
  }
`;
