import React, { useState } from 'react';
import styled from 'styled-components';
import '@fontsource/oswald';
import '@fontsource/roboto';
import { ShowyedaLogo } from 'app/components/ShowyedaLogo';
import { Outlet } from 'react-router-dom';
import horseBg from './assets/img/bg/coach-reg-new.jpg';
import { useLocation } from 'react-router-dom';
//Font-arimo
import '@fontsource/arimo';
import 'lazysizes';
import Cookies from 'js-cookie';

type Props = {};

const CoachRegistrationLayout = (props: Props) => {
  var coachInsuranceUrl = '/coach-registration/coach-insurance';
  var asssistantCoachUrl = '/coach-registration/assistant-coach';
  const location = useLocation();
  const currentSeason = Cookies.get('isAdminLiveFiltered');

  return (
    <MainWrapper>
      <LeftWrapper className="LeftWrapper lazyload">
        <ShowyedaLogo />
        <div className="left-header-block">
          {location.pathname === coachInsuranceUrl ? (
            <p>
              <span className="note-bold-text">NOTE: </span>Incomplete and
              unsigned applications will be returned for completion. Coverage
              cannot be bound until the Company approves your completed
              application. The Company’s receipt of premium does not bind
              coverage. Approval by Underwriting required.
            </p>
          ) : (
            ''
          )}
        </div>
        {/* <BottomImgWrapper className="BottomImgWrapper">
          <ImgTag src={horseBg} />
        </BottomImgWrapper> */}
      </LeftWrapper>
      <RightWrapper className="RightWrapper">
        <BorderWrapper className="BorderWrapper">
          <TopWrapper className="TopWrapper">
            <ToplabelWrapper>
              {location.pathname === coachInsuranceUrl ? (
                <h3 className="toplabel-header">
                  YEDA Coach Liability Insurance Application
                  <span
                    style={{
                      fontFamily: 'Arimo',
                    }}
                    className="insurance-span"
                  >
                    Insurance provided by Equisure, Inc. and Certain
                    Underwriters at Lloyds, London
                  </span>
                </h3>
              ) : location.pathname === asssistantCoachUrl ? (
                <h3 className="toplabel-header">
                  YEDA Assistant Coach Registration Form
                  <span
                    style={{
                      fontFamily: 'Arimo',
                    }}
                  >
                    {currentSeason === undefined
                      ? '2023 - 2024'
                      : currentSeason}
                  </span>
                </h3>
              ) : (
                <h3 className="toplabel-header">
                  YEDA Coach Registration Form
                  <span
                    style={{
                      fontFamily: 'Arimo',
                    }}
                  >
                    {currentSeason === undefined
                      ? '2023 - 2024'
                      : currentSeason}
                  </span>
                </h3>
              )}
            </ToplabelWrapper>
          </TopWrapper>
          <Outlet />
        </BorderWrapper>
      </RightWrapper>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  //Responsive
  @media (min-width: 100px) and (max-width: 319px) {
    .TopWrapper {
      .toplabel-header {
        font-size: 12px;
      }
    }
    .LeftWrapper {
      display: none;
    }
    .RightWrapper {
      width: 100%;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .TopWrapper {
      .toplabel-header {
        font-size: 13px;
      }
    }
    .LeftWrapper {
      display: none;
    }
    .RightWrapper {
      width: 100%;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .LeftWrapper {
      display: none;
    }
    .RightWrapper {
      width: 100%;
    }
    .TopWrapper {
      top: -10px;
      .toplabel-header {
        font-size: 14px;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .LeftWrapper {
      p {
        font-size: 12px;
      }
    }
    .TopWrapper {
      .toplabel-header {
        font-size: 18px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .LeftWrapper {
      p {
        font-size: 14px;
      }
    }
    .TopWrapper {
      .toplabel-header {
        font-size: 19px;
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .LeftWrapper {
      p {
        font-size: 15px;
      }
    }
    .TopWrapper {
      .toplabel-header {
        font-size: 20px;
      }
    }
  }
  @media (min-width: 1366px) and (max-width: 1439px) {
    .LeftWrapper {
      p {
        font-size: 16px;
      }
    }
    .TopWrapper {
      .toplabel-header {
        font-size: 21px;
      }
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    .LeftWrapper {
      p {
        font-size: 17px;
      }
    }
    .TopWrapper {
      .toplabel-header {
        font-size: 23px;
      }
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    .LeftWrapper {
      p {
        font-size: 19px;
      }
    }
    .TopWrapper {
      .toplabel-header {
        font-size: 25px;
      }
    }
  }
`;
const LeftWrapper = styled.div`
  width: 36%;
  /* background: #1b1b1b; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  padding: 60px 35px;
  position: relative;
  background: url(${horseBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  align-items: end;
  .left-header-block {
    background: black;
    opacity: 0.7;
    padding: 0 20px;
    position: absolute;
    top: 30%;
    left: 0;
  }

  p {
    color: #898989;
    font-size: 18px;
    font-family: 'Oswald';

    .note-bold-text {
      font-size: 18px;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
  span {
    color: #ffffff;
    font-family: 'Oswald';
    font-size: 14px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    p {
      font-size: 11px;
    }
    span {
      font-size: 11px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    p {
      font-size: 13px;
    }
    span {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    p {
      font-size: 14px;
    }
    span {
      font-size: 14px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    p {
      font-size: 11px;
      .note-bold-text {
        font-size: 11px;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    p {
      font-size: 13px;
      .note-bold-text {
        font-size: 13px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    p {
      font-size: 14px;
      .note-bold-text {
        font-size: 14px;
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    p {
      font-size: 15px;
      .note-bold-text {
        font-size: 15px;
      }
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    p {
      font-size: 16px;
      .note-bold-text {
        font-size: 16px;
      }
    }
  }
`;
const BottomImgWrapper = styled.div`
  position: absolute;
  bottom: 4%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
`;
const ImgTag = styled.img`
  width: 100%;
  max-width: 530px;
  min-height: calc(100vh - 367px);
  max-height: 500px;
  object-fit: contain;
  object-position: center;
  @media (min-width: 576px) and (max-width: 767px) {
    max-height: calc(100vh - 338px);
  }
  @media (min-width: 768px) and (max-width: 991px) {
    max-height: calc(100vh - 338px);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 280px;
    max-height: calc(100vh - 266px);
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    width: 290px;
    max-height: calc(100vh - 274px);
  }
  @media (min-width: 1366px) and (max-width: 1439px) {
    width: 320px;
    height: calc(100vh - 304px);
  }
  @media (min-width: 1440px) {
    height: calc(100vh - 304px);
  }
`;
const TryImg = styled.img`
  width: 340px;
  position: absolute;
  z-index: 2;
  bottom: 150px;
  left: -20px;
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 85px;
    bottom: 105px;
    left: -12px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    width: 110px;
    left: -10px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    width: 110px;
    bottom: 190px;
    left: -15px;
  }
`;
const RightWrapper = styled.div`
  width: 64%;
  height: 100%;
  background: #2a2a2a;
  padding: 30px 35px 20px;
  p {
    color: #9597a6;
    font-family: 'Roboto';
    font-size: 14px;
  }
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  @media (min-width: 100px) and (max-width: 319px) {
    padding: 30px 15px 20px;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    padding: 30px 15px 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding: 30px 15px 20px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding: 30px 15px 20px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 30px 15px 20px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
  }
`;
const BorderWrapper = styled.div`
  height: 100%;
  border: 1px solid #585858;
  border-radius: 20px;
  position: relative;
  @media (min-width: 100px) and (max-width: 319px) {
  }
  @media (min-width: 320px) and (max-width: 575px) {
  }
`;

const TopWrapper = styled.div`
  /* background: #2a2a2a; */
  padding: 0px 10px;
  position: absolute;
  margin: 0 auto;
  width: 100%;
  top: -18px;
  /* position: absolute;
  top: -12px;
  left: 230px; */
  h3 {
    font-weight: 500;
    color: #ffffff;
    margin: 0px !important;
    font-family: 'Roboto';
    text-align: center;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    // padding: 15px 40px 0px;
    h3 {
      font-size: 16px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    // padding: 20px 60px 0px;
    h3 {
      font-size: 17px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    // padding: 20px 80px 0px;
    h3 {
      font-size: 18px;
    }
  }
`;
const ToplabelWrapper = styled.div`
  position: relative;
  margin: 0 0 0 0;
  background: none;
  display: flex;
  justify-content: center;
  h3 {
    display: inline-grid;
    background: #2a2a2a !important;
    font-weight: 500;
    color: #ffffff;
    padding-top: 10px;
    margin: 0px !important;
    font-family: 'Roboto';
    font-family: 'Arimo' !important;
    font-size: 22px;
    text-align: center;
    padding: 0 15px;
  }
  .insurance-span {
    font-size: 13px;
    letter-spacing: 0.2px;
    font-family: 'Arimo' !important;
    line-height: 25px;
    color: #9597a6;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .insurance-span {
      font-size: 7px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .insurance-span {
      font-size: 8px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .insurance-span {
      font-size: 9px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .insurance-span {
      font-size: 10px;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    /* padding: 15px 40px 0px; */
    h3 {
      font-size: 16px;
    }
    .insurance-span {
      font-size: 11px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    /* padding: 20px 60px 0px; */
    h3 {
      font-size: 17px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    /* padding: 20px 80px 0px; */
    h3 {
      font-size: 18px;
    }
  }
`;

export default CoachRegistrationLayout;
