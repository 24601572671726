import React from 'react';
import {
  DropDownList,
  DropDownListBlurEvent,
  DropDownListChangeEvent,
  DropDownListOpenEvent,
  DropDownListCloseEvent,
} from '@progress/kendo-react-dropdowns';
import styled from 'styled-components';
import '@fontsource/arimo';
import '@fontsource/roboto';
import SimpleReactValidator from 'simple-react-validator';
import arrow from '../AdminDropDown/assets/arrow.svg';
import { FormSkeletonLoader } from '../FormSkeletonLoader';

type Props = {
  onChange?: (event: DropDownListChangeEvent) => void;
  data?: number;
  value?: number | undefined;
  defaultValue?: number | undefined;
  className?: string;
  label?: string;
  validator?: SimpleReactValidator;
  validations?: string;
  hideStar?: boolean;
  disabled?: boolean;
  isLoader?: boolean;
};

const AdminCustomYearPicker = ({
  onChange,
  value,
  defaultValue,
  className,
  label,
  validations,
  validator,
  hideStar,
  disabled,
  isLoader,
}: Props) => {
  const simpleValidator = React.useRef(validator);
  const [open, setOpen] = React.useState<boolean>(false);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const startYear = currentYear;
  const endYear = currentYear + 12;
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, index) => startYear + index,
  );
  const [dropData, setDropData] = React.useState<any>();

  const popupSettingsValue = {
    animate: true,
    className: 'admincustom-dropdown-component',
  };

  React.useEffect(() => {
    console.log('React.useEffect called');
    setDropData(years.slice());
  }, []);
  const handleChange = (event: DropDownListChangeEvent) => {
    onChange?.(event.target.value);
  };
  const handleBlur = (e: DropDownListBlurEvent) => {
    setOpen(false);
    simpleValidator?.current?.showMessageFor(label ? label : 'field');
  };
  const handleOnClose = (e: DropDownListCloseEvent) => {
    setOpen(false);
  };
  const handleClick = (e: DropDownListOpenEvent) => {
    setOpen(!open);
  };

  return (
    <Wrapper className={`SHdropdown ${className}`}>
      {!isLoader ? (
          <>
      <div className="label">
        {label ?? 'Label'}
        <span className="red-star">{validations && !hideStar ? '*' : ''}</span>
      </div>
      <StyleDropDownList
        className={`${StyleDropDownList} ${
          open ? 'inverted-arrow' : 'default'
        }`}
        defaultValue={defaultValue}
        value={value}
        data={dropData}
        onChange={handleChange}
        onBlur={handleBlur}
        onOpen={handleClick}
        onClose={handleOnClose}
        popupSettings={popupSettingsValue}
        iconClassName="inverted-custom-arrow"
        disabled={disabled}
      />
      {simpleValidator?.current?.message(
        label ? label : 'field',
        value,
        validations ?? '',
      )}
      </>
        ) : (
          <FormSkeletonLoader textHeight={44} labelHeight={20} isLabel />
        )}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  .srv-validation-message {
    color: #f44336;
    font-size: 12px;
    display: inline-block;
  }
  .red-star {
    color: red;
    font-weight: bold;
  }
  &.error {
    .k-picker-solid {
      border: 1px solid #f44336 !important;
    }
  }
  &.default {
    .k-picker-solid {
      border: 1px solid #caced8 !important;
    }
  }

  .k-list {
    max-height: 200px;
    background: #ffffff !important;
    color: #475467 !important;
  }
  .label {
    font-family: 'Inter';
    font-size: 13px;
    color: #a3a3a3;
    margin-bottom: 5px;
  }
  :has(input:disabled) {
    .red-star {
      display: none !important;
    }
  }
  //Responsive
  @media (min-width: 100px) and (max-width: 319px) {
    .label {
      font-size: 10px !important;
    }
    .k-input-value-text {
      font-size: 11px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .label {
      font-size: 11px !important;
    }
    .k-input-value-text {
      font-size: 12px !important;
    }
  }
  @media (min-width: 576px) and (max-width: 1365px) {
    .label {
      font-size: 12px !important;
    }
    .k-input-value-text {
      font-size: 13px !important;
    }
  }
`;
const StyleDropDownList = styled(DropDownList)`
  max-height: 44px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 2px;
  background: #ffffff !important;
  color: #475467 !important;
  border-radius: 8px !important;
  border: 1px solid #caced8 !important;
  box-shadow: none !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  border-radius: 10px;
  font-family: 'Inter' !important;
  font-weight: 400 !important;
  &.k-disabled {
    opacity: 1;
    border-color: #f5f5f5 !important;
    background-color: #fafafa !important;
    button {
      display: none;
    }
  }
  cursor: pointer;
  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    box-shadow: none;
  }
  &.custom-dropdown-component {
    background: red;
    .k-popup {
      .k-input-solid {
        background: #f44336 !important;
      }
    }
  }

  .k-input-inner {
  }
  .k-input-value-text {
    margin: 2px 0px 0px -4px;
    font-family: 'Inter' !important;
    font-weight: 400 !important;
    font-size: 15px;
    letter-spacing: 0.5px;
  }

  &.inverted-arrow {
    .k-button {
      border: none !important;
      color: #ffffff;

      .inverted-custom-arrow {
        content: '';
        position: absolute;
        width: 13px;
        height: 13px;
        background-image: url(${arrow});
        bottom: 0px;
        right: 10px;
        transform: rotate(180deg) !important;

        &:before {
          display: none !important;
        }
      }
    }
  }

  &.default {
    .k-button {
      border: none !important;
      color: #ffffff;

      .inverted-custom-arrow {
        content: '';
        position: absolute;
        width: 13px;
        height: 13px;
        background-image: url(${arrow});
        top: 0px;
        right: 14px;

        &:before {
          display: none !important;
        }
      }
    }
  }
  //Component Responsive
  @media (min-width: 100px) and (max-width: 319px) {
    height: 30px !important;
    font-size: 12px;
    &.inverted-arrow,
    &.default {
      .k-button {
        .k-button-icon {
          svg {
            width: 21px;
          }
        }
      }
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    height: 30px !important;
    font-size: 12px;
    &.inverted-arrow,
    &.default {
      .k-button {
        .k-button-icon {
          svg {
            width: 23px;
          }
        }
      }
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    &.inverted-arrow,
    &.default {
      .k-button {
        .k-button-icon {
          svg {
            width: 22px;
          }
        }
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    &.inverted-arrow,
    &.default {
      .k-button {
        .k-button-icon {
          svg {
            width: 23px;
          }
        }
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    &.inverted-arrow,
    &.default {
      .k-button {
        .k-button-icon {
          svg {
            width: 23px;
          }
        }
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    &.inverted-arrow,
    &.default {
      .k-button {
        .k-button-icon {
          svg {
            width: 24px;
          }
        }
      }
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    &.inverted-arrow,
    &.default {
      .k-button {
        .k-button-icon {
          svg {
            width: 25px;
          }
        }
      }
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    &.inverted-arrow,
    &.default {
      .k-button {
        .k-button-icon {
          svg {
            width: 27px;
          }
        }
      }
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    &.inverted-arrow,
    &.default {
      .k-button {
        .k-button-icon {
          svg {
            width: 30px;
          }
        }
      }
    }
  }
`;
export default AdminCustomYearPicker;
