import { CommonPageWrapper, HeadingWrapper } from 'admin/components/wrappers';
import { PageMainLabel } from 'app/components/SHLabel';
import styled from 'styled-components';
import SchoolTeamLeaderPointTable from './SchoolTeamLeaderPointTable';
import LeaderPointTeamHeaderCell from 'admin/pages/TeamIndividual/components/TeamRiderListing/Cells/LeaderPointTeamHederCell';
import TeamNameLabelCell from 'admin/pages/TeamIndividual/components/TeamRiderListing/Cells/TeamNameLabelCell';
import { ScoreBoard } from 'services/openapi';
import {
  finalPlacingObject,
  teamHeaderObject,
  totalHeaderObject,
  updateData,
} from '../static';
import { ScorePointCell } from 'admin/pages/TeamIndividual/components/TeamRiderListing/Cells/ScorePointCell';

type Props = { showScoreBoardList: ScoreBoard | undefined };

export const ScoreBoardPage = ({ showScoreBoardList }: Props) => {
  const headerClassListJuniorHighSchoolteam =
    showScoreBoardList?.juniorHighSchoolteam?.classes;
  const headerClassListSeniorHighSchoolteam =
    showScoreBoardList?.seniorHighSchoolteam?.classes;
  const updatedJuniorHeaderCells = [
    teamHeaderObject,
    ...(typeof headerClassListJuniorHighSchoolteam === 'object' &&
    headerClassListJuniorHighSchoolteam !== null
      ? headerClassListJuniorHighSchoolteam
      : []),
    totalHeaderObject,
    finalPlacingObject,
  ];
  const updatedSeniorHeaderCells = [
    teamHeaderObject,
    ...(typeof headerClassListSeniorHighSchoolteam === 'object' &&
    headerClassListSeniorHighSchoolteam !== null
      ? headerClassListSeniorHighSchoolteam
      : []),
    totalHeaderObject,
    finalPlacingObject,
  ];
  const mappedSeniorHeaderCells = updatedSeniorHeaderCells?.map(headerCell => {
    const fieldName = headerCell?.code?.toLowerCase();
    return {
      field: fieldName,
      width: fieldName === 'team' ? '200' : '100',
      headerCell: props => (
        <LeaderPointTeamHeaderCell title={headerCell?.name!} />
      ),
      cell: e => {
        const cellComponent = ['team', 'total_point', 'final_placing'].includes(
          fieldName!,
        ) ? (
          <TeamNameLabelCell
            e={e}
            value={
              fieldName === 'team'
                ? e.dataItem.teamName
                : fieldName === 'total_point'
                ? e.dataItem.total
                : fieldName === 'final_placing'
                ? e.dataItem.finalPlacing
                : ''
            }
          />
        ) : (
          <ScorePointCell value={e.dataItem[headerCell?.code!]} />
        );

        return cellComponent;
      },
    };
  });
  const mappedJuniorHeaderCells = updatedJuniorHeaderCells?.map(headerCell => {
    const fieldName = headerCell?.code?.toLowerCase();
    return {
      field: fieldName,
      width: fieldName === 'team' ? '200' : '100',
      headerCell: props => (
        <LeaderPointTeamHeaderCell title={headerCell?.name!} />
      ),
      cell: e => {
        const cellComponent = ['team', 'total_point', 'final_placing'].includes(
          fieldName!,
        ) ? (
          <TeamNameLabelCell
            e={e}
            value={
              fieldName === 'team'
                ? e.dataItem.teamName
                : fieldName === 'total_point'
                ? e.dataItem.total
                : fieldName === 'final_placing'
                ? e.dataItem.finalPlacing
                : ''
            }
          />
        ) : (
          <ScorePointCell value={e.dataItem[headerCell?.code!]} />
        );

        return cellComponent;
      },
    };
  });
  const dataJuniorHighSchoolteam =
    showScoreBoardList?.juniorHighSchoolteam?.results;
  const updatedJuniorData = updateData(dataJuniorHighSchoolteam!);
  const updatedJuniorHighSchoolteamDataData = updatedJuniorData?.map(item => {
    const { classes, ...rest } = item;
    return rest;
  });
  const dataSeniorHighSchoolteam =
    showScoreBoardList?.seniorHighSchoolteam?.results;
  const updatedSeniorData = updateData(dataSeniorHighSchoolteam!);
  const updatedSeniorHighSchoolteamDataData = updatedSeniorData?.map(item => {
    const { classes, ...rest } = item;
    return rest;
  });
  return (
    <StyledCommonPageWrapper style={{ marginBottom: '50px' }}>
      <TitleCardWrapper>
        <HeadingWrapper className="heading-wrapper">
          <PageMainLabel className="label-wrapper">
            Senior High School Team
          </PageMainLabel>
        </HeadingWrapper>
      </TitleCardWrapper>
      <SchoolTeamLeaderPointTable
        data={updatedSeniorHighSchoolteamDataData}
        columns={mappedSeniorHeaderCells}
      />
      <TitleCardWrapper className="junior-high-school">
        <HeadingWrapper className="heading-wrapper">
          <PageMainLabel className="label-wrapper">
            Junior High School Team
          </PageMainLabel>
        </HeadingWrapper>
      </TitleCardWrapper>
      <SchoolTeamLeaderPointTable
        data={updatedJuniorHighSchoolteamDataData}
        columns={mappedJuniorHeaderCells}
      />
    </StyledCommonPageWrapper>
  );
};

const StyledCommonPageWrapper = styled(CommonPageWrapper)`
  padding-top: 26px;
`;
const TitleCardWrapper = styled.div`
  &.junior-high-school {
    margin-top: 52px;
  }
  margin: 0px 0px 0px 0px;
  background-color: #e7ecff;
  .heading-wrapper {
    padding: 14px 0px 14px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    .label-wrapper {
      font-family: 'Inter';
      font-size: 14px;
      font-weight: 600;
      line-height: 14.52px;
      color: #000000;
      margin: unset !important;
    }
  }
`;
