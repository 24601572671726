import AdminCustomButton from 'app/components/AdminCustomButton';
import styled from 'styled-components';

type Props = {
  className?: string;
  onEditClick?: (e) => void;
  isEdit: boolean;
  onCancelClick?: (e) => void;
  onSaveClick?: (e) => void;
  saveDisabled?: boolean;
  editDisabled?: boolean;
  cancelDisabled?: boolean;
  saveText?: string;
  cancelText?: string;
  editText?: string;
};

const EditSaveButton = ({
  className,
  onEditClick,
  isEdit,
  onCancelClick,
  onSaveClick,
  saveDisabled,
  editDisabled,
  cancelDisabled,
  saveText,
  cancelText,
  editText,
}: Props) => {
  return (
    <Wrapper className={className ?? ''}>
      {isEdit ? (
        <AdminCustomButton
          onClick={onEditClick}
          buttonType={'secondary'}
          disabled={editDisabled}
        >
          {editText ? editText : 'Edit'}
        </AdminCustomButton>
      ) : (
        <ActionWrapper>
          <AdminCustomButton
            buttonType="cancel"
            onClick={onCancelClick}
            disabled={cancelDisabled}
          >
            {cancelText ? cancelText : 'Cancel'}
          </AdminCustomButton>
          <AdminCustomButton
            buttonType="save"
            onClick={onSaveClick}
            disabled={saveDisabled}
          >
            {saveText ? saveText : 'Save'}
          </AdminCustomButton>
        </ActionWrapper>
      )}
    </Wrapper>
  );
};

export default EditSaveButton;
const Wrapper = styled.div``;
const ActionWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
