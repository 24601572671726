/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Sponsor } from '../models/Sponsor';
import type { SponsorWrapper } from '../models/SponsorWrapper';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ManageSponsorService {

    /**
     * @param searchInput 
     * @param sortBy 
     * @param sortOrder 
     * @param pageNumber 
     * @param pageSize 
     * @returns SponsorWrapper Success
     * @throws ApiError
     */
    public static manageSponsorGetSponsors(
searchInput?: string,
sortBy?: string,
sortOrder?: number,
pageNumber: number = 1,
pageSize: number = 10,
): CancelablePromise<SponsorWrapper> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/sponsor',
            query: {
                'searchInput': searchInput,
                'sortBy': sortBy,
                'sortOrder': sortOrder,
                'pageNumber': pageNumber,
                'pageSize': pageSize,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns string Created
     * @throws ApiError
     */
    public static manageSponsorAddSponser(
requestBody?: Sponsor,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/sponsor',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static manageSponsorEditSponser(
requestBody?: Sponsor,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/sponsor',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * API to delete sponsor
     * @param id 
     * @returns string Success
     * @throws ApiError
     */
    public static manageSponsorDeleteShow(
id: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/admin/sponsor/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
