import AddDocumentPopup from 'app/components/AddDocumentPopup';
import { AddRemoveIconButton } from 'app/components/AddRemoveIconButton';
import SHdropdown from 'app/components/SHdropdown';
import React, { useEffect, useRef, useState } from 'react';
import { DocumentService, GeneralLookup } from 'services/openapi';
import styled from 'styled-components';
import DocumentIcon from './assets/img/icon/document.png';
import addIcon from './assets/img/icon/add.png';
import removeIcon from './assets/img/icon/remove.png';
import downloadIcon from './assets/img/icon/download.png';
import deleteIcon from './assets/img/icon/delete.png';
import { CheckBox } from 'app/components/CheckBox';
import { SHToggleButton } from 'app/components/ToggleButton';
import SimpleReactValidator from 'simple-react-validator';
import { DefaultValueItemType } from '../..';
import useApi from 'services/mavapi/useApi';
import { ApiError } from 'services/openapi';
import isApiError from 'utils/isApiError';
import useToast from 'hooks/Toast';
import { useDispatch } from 'react-redux';
import { useErrorHandlingSlice } from 'app/error/slice';

type Props = {
  seasonData: GeneralLookup;
  setSeasonData: (value: React.SetStateAction<GeneralLookup>) => void;
  typeData: GeneralLookup;
  setTypeData: React.Dispatch<React.SetStateAction<GeneralLookup>>;
  documentData: {
    season: GeneralLookup[];
    type: GeneralLookup[];
    file: Blob | undefined;
  };
  setDocumentData: React.Dispatch<
    React.SetStateAction<{
      season: GeneralLookup[];
      type: GeneralLookup[];
      file: Blob | undefined;
    }>
  >;
  setIsRemoveClicked: React.Dispatch<React.SetStateAction<boolean>>;
  isRemoveClicked: boolean;
  setShowAddDocument: React.Dispatch<React.SetStateAction<boolean>>;
  showAddDocument: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  isActive: boolean;
  fileData: DefaultValueItemType[];
  setFileData: React.Dispatch<React.SetStateAction<DefaultValueItemType[]>>;
  // onToggleChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
  handleGetFileList: () => void;
};

export const Document = ({
  seasonData,
  setSeasonData,
  typeData,
  setTypeData,
  documentData,
  setDocumentData,
  setIsRemoveClicked,
  isRemoveClicked,
  setShowAddDocument,
  showAddDocument,
  setIsActive,
  isActive,
  fileData,
  setFileData,
  // onToggleChange,
  handleGetFileList,
}: Props) => {
  const {
    documentChangeDocumentActiveStatus,
    documentDeleteDocuments,
    documentUploadDocument,
  } = DocumentService;
  const { error: apiError, handleRequest } = useApi();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [valid, setValid] = useState<boolean>(false);
  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { actions } = useErrorHandlingSlice();
  const [, setError] = React.useState<unknown | null>();

  const handleSeasonChange = e => {
    setSeasonData?.(e);
  };
  const handleTypeChange = e => {
    setTypeData?.(e);
  };
  const handleAddClick = e => {
    setShowAddDocument(true);
  };
  const handleDeleteClick = (id, e) => {
    handleRequest(documentDeleteDocuments([id]))
      .then(res => {
        if (res && !isApiError(res)) {
          handleGetFileList();
        } else {
          dispatch(
            actions.dispatchToast({
              errorTxt: res.description,
              show: true,
              errorType: 'error',
            }),
          );
        }
      })
      .catch(() => setError(apiError));
  };
  const handleRemoveConfirmClick = e => {
    handleRequest(documentDeleteDocuments(checkedIds))
      .then(res => {
        if (res && !isApiError(res)) {
          setIsRemoveClicked(false);
          handleGetFileList();
        } else {
          dispatch(
            actions.dispatchToast({
              errorTxt: res.description,
              show: true,
              errorType: 'error',
            }),
          );
        }
      })
      .catch(() => setError(apiError));
  };

  const handleToggleChange = (id, e) => {
    setFileData(prevData =>
      prevData.map(item => {
        console.log(item.id, id, 'fileId');
        if (item.id === id) {
          return { ...item, isActive: e.target.checked };
        } else {
          return { ...item, isActive: false };
        }
        // return item;
      }),
    );
    handleRequest(
      documentChangeDocumentActiveStatus({
        id: id,
        setAsActive: e.target.checked,
      }).then(res => {}),
    );
  };
  const handleCheckboxChange = (id, e) => {
    if (e.value) {
      setCheckedIds(prevIds => [...prevIds, id]);
    } else {
      setCheckedIds(prevIds => prevIds.filter(checkedId => checkedId !== id));
    }
  };
  const handleDownloadClick = (item, e) => {
    const url = item.filePath;
    const name = item.name;
    const downloadUrl = `https://yeda-api-dev.onesmarter.in/${url}`;
    fetch(downloadUrl)
      .then(response => response.blob())
      .then(blob => {
        const objectUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = objectUrl;
        window.open(objectUrl);
        link.download = encodeURIComponent(name);
        link.click();
      });
  };
  const handleSubmit = () => {
    const apiPayload = {
      File: documentData.file as Blob,
      'Season.Id': seasonData.id,
      'Season.Name': seasonData.name,
      'Type.Id': typeData.id,
      'Type.Name': typeData.name,
      SetAsActive: isChecked,
    };
    handleRequest(documentUploadDocument(apiPayload))
      .then(res => {
        if (res && !isApiError(res)) {
          setShowAddDocument?.(!showAddDocument);
          handleGetFileList();
        } else {
          dispatch(
            actions.dispatchToast({
              errorTxt: res.description,
              show: true,
              errorType: 'error',
            }),
          );
        }
      })
      .catch(error => setError(apiError));
  };
  useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(!formValid);
  }, [seasonData, typeData]);

  return (
    <Wrapper className="Wrapper">
      <h4>Document</h4>
      <RightContentWrapper>
        <TopMainWrapper>
          <div className="left-side"></div>
          <TopWrapper>
            <TextFieldGrid>
              <TextFieldWrapper>
                <SHdropdown
                  className="drop-down"
                  label="Season"
                  onDataChange={handleSeasonChange}
                  data={documentData.season}
                  defaultValue={seasonData}
                  validations={'required'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
              <TextFieldWrapper>
                <SHdropdown
                  className="drop-down"
                  label="Type"
                  onDataChange={handleTypeChange}
                  data={documentData.type}
                  defaultValue={typeData}
                  validations={'required'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
            </TextFieldGrid>
            <ButtonWrapper>
              {isRemoveClicked ? (
                <AddRemoveIconButton
                  classNameBack="btn-Cancel"
                  classNameNxt="btn-Confirm"
                  onClickNxt={e => handleRemoveConfirmClick(e)}
                  onClickBack={e => {
                    setIsRemoveClicked(false);
                  }}
                  // disableNext={valid}
                  srcAdd=""
                  srcRemove=""
                  next="Confirm"
                  back="Cancel"
                />
              ) : (
                <AddRemoveIconButton
                  classNameBack="btn-Remove"
                  classNameNxt="btn-Add"
                  onClickNxt={handleAddClick}
                  onClickBack={e => {
                    setIsRemoveClicked(true);
                  }}
                  disableNext={valid}
                  srcAdd={addIcon}
                  srcRemove={removeIcon}
                  next="Add"
                  back="Remove"
                />
              )}

              <AddDocumentPopup
                show={showAddDocument}
                setShow={setShowAddDocument}
                documentData={documentData}
                setDocumentData={setDocumentData}
                typeData={typeData}
                seasonData={seasonData}
                // onChange={handleDocumentChange}
                onSubmit={handleSubmit}
                setIsChecked={setIsChecked}
                isChecked={isChecked}
              />
            </ButtonWrapper>
          </TopWrapper>
        </TopMainWrapper>
        <MappedFileWrapper>
          {fileData &&
            fileData.map((item, index) => {
              console.log(item.id, 'item.id');
              return (
                <CheckFileWrapper>
                  <CheckboxWrapper>
                    {isRemoveClicked ? (
                      <CheckBox
                        onChange={e => handleCheckboxChange(item.id, e)}
                      />
                    ) : (
                      ''
                    )}
                  </CheckboxWrapper>
                  <FileWrapper>
                    <NameToggleWrapper>
                      <div>
                        <CircleWrapper>
                          <p>{item.name.charAt(0).toUpperCase()}</p>
                        </CircleWrapper>
                        <p className="file-name">{item.name}</p>
                      </div>
                      <SHToggleButton
                        key={item.id}
                        onChange={e => handleToggleChange(item.id, e)}
                        checked={item.isActive}
                      />
                    </NameToggleWrapper>
                    <DownloadDeleteWrapper>
                      <AddRemoveIconButton
                        classNameBack="btn-delete"
                        classNameNxt="btn-download"
                        onClickNxt={e => handleDownloadClick(item, e)}
                        onClickBack={e => handleDeleteClick(item.id, e)}
                        // disableNext={
                        //   valid || photoUploadPayload.formData?.file == undefined
                        //     ? true
                        //     : false
                        // }
                        srcAdd={downloadIcon}
                        srcRemove={deleteIcon}
                        next="Download"
                        back="Delete"
                      />
                    </DownloadDeleteWrapper>
                  </FileWrapper>
                </CheckFileWrapper>
              );
            })}
        </MappedFileWrapper>
      </RightContentWrapper>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  height: calc(100vh - 150px);
  background-color: #161616;
  width: 100%;
  padding: 10px 10px 10px 10px;
  h4 {
    height: 10%;
    color: rgb(255 255 255 / 87%);
    font-weight: 500;
    font-size: 13px;
    color: rgb(255 255 255 / 87%);
    padding: 15px 0px;
    border-bottom: 1px solid #2e2e2e;
    margin: 0px;
  }
  p {
    color: rgb(255 255 255 / 87%);
  }
  @media (min-width: 320px) and (max-width: 575px) {
    padding: 2px 10px 2px 0px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding: 3px 20px 3px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding: 5px;
  }
`;
const RightContentWrapper = styled.div`
  margin: 20px 20px 0 0;
  height: 90%;
  @media (min-width: 320px) and (max-width: 991px) {
    padding-top: 20px;
    margin: 0;
  }
`;
const TopMainWrapper = styled.div`
  display: flex;
  height: 20%;
  gap: 10px;
  margin-bottom: 20px;

  .left-side {
    width: 4%;
  }
  @media (min-width: 100px) and (max-width: 575px) {
    height: 30%;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    gap: 5px;
  }
`;
const TopWrapper = styled.div`
  margin: 20px 0px 40px;
  width: 96%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* position: relative; */
  @media (min-width: 100px) and (max-width: 575px) {
    position: unset;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-end;
  }
`;

const TextFieldGrid = styled.div`
  display: flex;
  width: 60%;
  gap: 20px;
  @media (min-width: 100px) and (max-width: 319px) {
    display: block;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    display: block;
    width: 100%;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    display: block;
  }
`;
const TextFieldWrapper = styled.div`
  width: 30% !important;
  &.styled-TextFieldWrapper {
    display: grid;
    align-items: end;
  }
  .drop-down {
    color: #9597a6;
    margin: unset;
  }
  .k-input-value-text {
    font-size: 11px !important;
  }
  .k-picker-solid {
    height: 25px;
    padding: 2px !important;
  }

  @media (min-width: 100px) and (max-width: 319px) {
    width: 100% !important;
    .k-input-value-text {
      font-size: 9px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    width: 100% !important;
    .k-input-value-text {
      font-size: 10px !important;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 70% !important;
    margin-top: 10px;
    .k-input-value-text {
      font-size: 11px !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 30% !important;
    .k-input-value-text {
      font-size: 11px !important;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .k-input-value-text {
      font-size: 12px !important;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .k-input-value-text {
      font-size: 13px !important;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .k-input-value-text {
      font-size: 14px !important;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    .k-input-value-text {
      font-size: 15px !important;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    .k-input-value-text {
      font-size: 16px !important;
    }
  }
`;
const MappedFileWrapper = styled.div`
  width: 100%;
  height: 80%;
  overflow: auto;
  @media (min-width: 100px) and (max-width: 575px) {
    height: calc(70% - 50px);
    margin-top: 66px;
  }

  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
`;
const ButtonWrapper = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
  padding-top: 18px;
  /* position: absolute;
  right: 5px;
  top: 17px; */
  .btn-Confirm,
  .btn-Cancel {
    .image-wrapper {
      display: none;
    }
  }
  > div {
    gap: 20px;
    @media (min-width: 576px) and (max-width: 768px) {
      display: grid !important;
      .k-button {
        width: 100%;
      }
    }
  }
  .k-button {
    margin: unset;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    width: 70% !important;
    padding-bottom: 10px;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    width: 55% !important;
    padding-bottom: 10px;
  }
  @media (min-width: 576px) and (max-width: 768px) {
    /* position: unset; */
    width: 25% !important;
    margin: 5px 0px;
    padding-top: 25px;

    .k-button-text {
      > div {
        gap: 10px !important;
      }
    }
  }
`;
const FileWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 10%;
  width: 96%;
  gap: 20px;
  background-color: #212121;
  border: 1px solid #3b3c3c;
  border-radius: 10px;
  padding: 5px;

  .k-switch-md {
    width: 35px;
    height: 15px;
  }

  .k-switch-md .k-switch-track {
    width: 35px;
    height: 15px;
  }
  .k-switch-thumb {
    display: block;
    position: absolute;
    transform: translate(-40%, -50%);
    background-color: rgb(255 255 255 / 87%);
  }
  .k-switch-md.k-switch-off .k-switch-thumb-wrap {
    left: 8px;
  }
  .k-switch-off .k-switch-track {
    background-color: #1b1b1b;
    border-color: #404141;
  }
  .k-switch-md .k-switch-thumb {
    width: 20px;
    height: 20px;
  }
  .k-switch-md.k-switch-on .k-switch-thumb-wrap {
    left: calc(100% - 12px);
  }
  .k-switch :focus {
    outline: unset !important;
  }

  .k-switch-on .k-switch-track {
    border-color: transparent;
    background-color: #36b959;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    display: block;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    display: block;
  }
  /* @media (min-width: 576px) and (max-width: 767px) {
    display: block;
  } */
`;
const CheckFileWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 20px;
  @media (min-width: 100px) and (max-width: 319px) {
  }
  @media (min-width: 320px) and (max-width: 575px) {
    gap: 12px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    gap: 5px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    gap: 5px;
  }
`;
const CheckboxWrapper = styled.div`
  width: 4%;
  /* @media (min-width: 320px) and (max-width: 575px) {
    width: 4%;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 4%;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 4%;
  } */
`;
const CircleWrapper = styled.div`
  border: 1px solid #707070;
  background-color: #121212;
  border-radius: 50%;
  padding: 5px;
  height: 30px;
  width: 30px;
  p {
    padding: 0px;
    margin: 0px;
    font-size: 12px;
    text-align: center;
  }
`;
const NameToggleWrapper = styled.div`
  width: 60%;
  gap: 20px;
  padding: 0px 7px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .file-name {
    font-size: 12px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    width: 100%;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    width: 100%;
  }
  /* @media (min-width: 576px) and (max-width: 767px) {
    width: 100%;
  } */
`;
const DownloadDeleteWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 40%;
  .btn-download,
  .btn-delete {
    background-color: transparent !important;
    border-color: #306fdb !important;
    min-width: 70px !important;
    height: 20px;
    p {
      color: #306fdb !important;
      font-size: 10px;
    }
    .image-wrapper {
      width: 9px;
      padding-bottom: 3px;
    }
  }
  @media (min-width: 100px) and (max-width: 319px) {
    width: 100%;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    width: 100%;
  }
  /* @media (min-width: 576px) and (max-width: 767px) {
    width: 100%;
  } */
`;
