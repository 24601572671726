import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  RadioGroupChangeEvent,
  RadioButtonProps,
  RadioGroup,
} from '@progress/kendo-react-inputs';
import { GeneralLookup } from 'services/openapi';
import SimpleReactValidator from 'simple-react-validator';
//Default data for Yes & No
const Defaultdata = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

type Props = {
  data?: RadioButtonProps[];
  // onChange?: (event: RadioGroupChangeEvent) => void;
  lookupData?: GeneralLookup[];
  onChange?: (event: RadioGroupChangeEvent, value: GeneralLookup) => void;
  value?: string | number | boolean | null | undefined | boolean;
  name?: string;
  validator?: SimpleReactValidator;
  validations?: string;
  className?: string;
  label?: string;
  radioButtonTheme?: 'light';
  disabled?: boolean;
};

const RadioButtonGroup = ({
  data,
  onChange,
  value,
  name,
  lookupData,
  validations,
  validator,
  className,
  label,
  radioButtonTheme,
  disabled = false,
}: Props) => {
  const simpleValidator = React.useRef(validator);
  const [checked, setChecked] = React.useState<
    string | number | boolean | null | undefined | boolean
  >(value);
  console.log(checked, 'checked');
  const [allData, setAllData] = React.useState<any>();
  const convertedData = lookupData?.map((item: GeneralLookup) => ({
    id: item.id, // Keep the id as a string
    text: item.name,
    label: item.name,
    value: item.id,
  }));
  const handleChange = e => {
    var value = e?.value;
    if (lookupData) {
      var valueLookup = lookupData.find(obj => {
        return obj.id === value;
      });
      onChange?.(e, valueLookup!);
    } else {
      onChange?.(e, e.value);
    }
  };

  useEffect(() => {
    setChecked(value);
    setAllData(convertedData ? convertedData : data ? data : Defaultdata);
  }, []);
  return (
    <MainWrapper
      className={`RadioButtonGroupWrapper ${
        validator && !simpleValidator?.current?.fieldValid(label!)
          ? 'error'
          : 'default'
      } ${className}`}
    >
      <RadioGroup
        data={data ?? Defaultdata}
        // value={checked}
        defaultValue={checked}
        onChange={handleChange}
        name={name}
        className={`radio-button ${className} ${radioButtonTheme}`}
        disabled={disabled}
      />
      {simpleValidator?.current?.message(
        label ? label : 'field',
        value,
        validations ?? '',
      )}
    </MainWrapper>
  );
};

export default RadioButtonGroup;
const MainWrapper = styled.div`
  margin: 0px 20px;
  .srv-validation-message {
    color: #f44336;
    font-size: 12px;
    display: inline-block;
  }
  &.error {
    .k-picker-solid {
      border: 1px solid #f44336 !important;
    }
  }
  &.default {
    .k-picker-solid {
      border: transparent !important;
    }
  }
  margin: 0px 12px;
  display: flex;
  align-items: center;
  label {
    padding-left: 5px;
    color: #ffffff;
  }
  .light {
    label {
      color: #9597a6 !important;
    }
  }

  ul {
    display: flex !important;
    flex-direction: row !important;
    list-style: none !important;
    padding: 0 0 0 15px !important;
    li {
      margin-right: 20px !important;
      display: inline-flex !important;
      align-items: center !important;
    }
  }
  input[type='radio'] {
    background-color: transparent !important;
    width: 20px;
    height: 20px;
    border: 2px solid #707070 !important;
    &:checked {
      background-color: #0d6efd !important;
      accent-color: #0d6efd !important;
    }
    &:focus {
      border-color: #0d6efd;
      box-shadow: none !important;
    }
    /* cursor: pointer !important;
    background-color: transparent !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid #808080;
    border-radius: 50%;
    outline: none;
    -webkit-transition: border-color 0.3s;
    transition: border-color 0.3s; */
  }
  .k-radio-list {
    gap: 50px;
  }
  // Responsive
  @media (min-width: 100px) and (max-width: 319px) {
    ul label {
      font-size: 10px !important;
      text-transform: lowercase;
      ::first-letter {
        text-transform: uppercase !important;
      }
    }
    .k-radio-list {
      gap: 10px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    ul label {
      font-size: 12px !important;
      text-transform: lowercase;
      ::first-letter {
        text-transform: uppercase !important;
      }
    }
    .k-radio-list {
      gap: 15px !important;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    ul label {
      font-size: 13px;
      ::first-letter {
        text-transform: uppercase !important;
      }
    }
    .k-radio-list {
      gap: 20px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    ul label {
      font-size: 14px;
      ::first-letter {
        text-transform: uppercase !important;
      }
    }
    .k-radio-list {
      gap: 25px;
    }
    //height: 35px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    //height: 40px;
    .k-radio-list {
      gap: 30px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    //height: 45px;
    .k-radio-list {
      gap: 35px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    //height: 50px;
    .k-radio-list {
      gap: 40px;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    //height: 60px;
    .k-radio-list {
      gap: 45px;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    //height: 70px;
  }
`;
