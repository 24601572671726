import { DropDownModel } from 'app/components/SHdropdown';
import { GeneralLookup } from 'services/openapi';

export const periviousValue: GeneralLookup = {
  id: '',
  name: '',
  code: null,
  children: null,
  otherName: null,
  isAdminLive: null,
};

export const searchedBy: DropDownModel[] = [
  // { id: 1, text: 'Team/Category/Division' },
  { id: 2, text: 'Back Number' },
  { id: 3, text: 'Name' },
];

export const defaultValue = {
  formData: {
    file: undefined,
  },
};

export const previousEnrolRadioValuesDefaultvalue = {
  hasStayedInSameDivisionForTwoYears: null,
  achievedTopTwoInIndividualNationals: null,
  achievedTopThreeInDivision: null,
  divisionCompliesWithSectionThirty: null,
};

export const lookipCategoryDefaultValue = {
  id: '',
  name: '',
  code: '',
  children: null,
  otherName: '',
  isAdminLive: null,
};

export const placementRadioValuesDefaultValue = {
  pwhQuestionOne: null,
  owhQuestionOne: null,
  ewhQuestionOne: null,
  rwhQuestionOne: null,
  swhQuestionOne: null,
  swhQuestionTwo: null,
  dwhQuestionOne: null,
  drQuestionOne: null,
  drQuestionTwo: null,
};

export type prevRadioModel = {
  hasStayedInSameDivisionForTwoYears: boolean | null;
  achievedTopTwoInIndividualNationals: boolean | null;
  achievedTopThreeInDivision: boolean | null;
  divisionCompliesWithSectionThirty: boolean | null;
};
