import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import '@fontsource/oswald';
import horseBg from './assets/img/bg/team-reg-new.png';
import { PersonalInfoForm } from './component/PersonalInfoForm';
import { FinancialInfoForm } from './component/FinancialInfoForm';
import tryitImg from './assets/img/bg/tryit.png';
import { ShowyedaLogo } from 'app/components/ShowyedaLogo';
import {
  GeneralLookup,
  LookupService,
  TeamRegistration,
  TeamService,
} from 'services/openapi';
import useApi from 'services/mavapi/useApi';
import { useNavigate } from 'react-router-dom';
import { useManagePageSlice } from '../slice';
import { selectPageLoader } from '../slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import LoaderWrapper from 'app/components/CircularLoader/LoaderWrapper';
import CircularLoader from 'app/components/CircularLoader';
import Cookies from 'js-cookie';
import CircularProgress from '@mui/joy/CircularProgress';
import Box from '@mui/joy/Box';
import { Stepper, StepperChangeEvent } from '@progress/kendo-react-layout';
import check from './assets/img/icon/check.png';
import isApiError from 'utils/isApiError';
import { useErrorHandlingSlice } from 'app/error/slice';
import { selectPhotoUpload } from '../RiderRegistrationPage/slice/selectors';
import { defaultPhotoValue } from '../RegistrationPage';
import { useRiderRegistrationStateSlice } from '../RiderRegistrationPage/slice';

type Props = {};
const items = [
  { label: 'Personal Information', number: 1 },
  { label: 'Financial Information', number: 2 },
];

export const TryitRegistrationPage = (props: Props) => {
  const navigate = useNavigate();
  const { error: apiError, handleRequest } = useApi();
  const { teamAddNewTeam, teamGenerateTeamCode, teamUploadTeamPhoto } =
    TeamService;
  const { lookupGetAllLookups } = LookupService;
  const [, setError] = React.useState<unknown | null>();
  const [isNotImage, setIsNotImage] = useState(false);

  const initialData: TeamRegistration = {
    name: '',
    mainCoach: undefined,
    mainContact: '',
    email: '',
    phone: '',
    street: '',
    city: '',
    state: {
      id: '',
      name: '',
      children: null,
    },
    zip: '',
    category: [],
    financialResponsibility: {
      name: '',
      phone: '',
      street: '',
      city: '',
      state: {
        id: '',
        name: '',
        children: null,
      },
      zip: '',
    },
    isTrial: true,
    teamCode: '',
    isReturning: false,
    teamIdOld: null,
    associateCoachList: null,
    assistantCoachList: null,
    liveRankingLink: '',
    description: '',
  };
  //for Loader
  const [tryItTeamData, setTryItTeamData] =
    useState<TeamRegistration>(initialData);
  const { actions: PageActions } = useManagePageSlice();
  const pageLoader = useSelector(selectPageLoader);
  const dispatch = useDispatch();
  const { actions } = useErrorHandlingSlice();
  const { actions: manageAppActions } = useRiderRegistrationStateSlice();
  const [cancelPaymentCookie, setCancelPaymentCookie] = useState<boolean>();
  const [payerTypes, setPayerTypes] = useState<GeneralLookup>();
  const [stateData, setStateData] = useState<GeneralLookup[]>([]);
  const [categoryData, setCategoryData] = useState<GeneralLookup[]>([]);
  const [coachData, setCoachData] = useState<GeneralLookup[]>([]);
  const [assistantCoachData, setAssistantCoachData] = useState<GeneralLookup[]>(
    [],
  );
  const [formNumber, setFormNumber] = useState<number>(0);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 767);
  const totalPages = 2;
  const determinateValue = ((formNumber + 1) / totalPages) * 100;
  const [photoUploadPayload, setPhotoUploadPayload] = useState<{
    formData?: {
      file?: Blob;
    };
  }>(defaultPhotoValue);
  const [fileAvailability, setFileAvailability] = React.useState<boolean>(true);
  const [uploadFile, setUploadFile] = useState<File | null>();
  const photoUploadDataRedux = useSelector(selectPhotoUpload);
  const currentSeason = Cookies.get('isAdminLiveFiltered');

  const handleRegBack = () => {
    setFormNumber(0);
  };
  const handleBack = () => {
    Cookies.remove('payload');
    Cookies.remove('paymentCancel');
    navigate('/login');
  };
  const handleNxt = () => {
    setFormNumber(1);
  };

  //HAndle get lookups
  const handleGetLookups = () => {
    dispatch(PageActions.setPageLoader(true));
    handleRequest(
      lookupGetAllLookups(
        'PAYER_TYPES,TEAM_CATEGORY,STATE,COACH,ASSISTANT_COACH',
      ),
    )
      .then(res => {
        let varPayerTypes = res?.filter(item => {
          if (item?.type === 'PAYER_TYPES') {
            return item.values;
          }
        });
        let payerTypeObj = varPayerTypes?.[0]?.values?.find(item => {
          if (item?.name === 'Team') {
            return item ?? {};
          }
        });
        let varState = res?.filter(item => {
          if (item?.type === 'STATE') {
            return item.values;
          }
        });
        let varCategories = res?.find(obj => obj.type === 'TEAM_CATEGORY');
        let varCoach = res?.filter(item => {
          if (item?.type === 'COACH') {
            return item.values;
          }
        });
        let varAssistantCoach = res?.filter(item => {
          if (item?.type === 'ASSISTANT_COACH') {
            return item.values;
          }
        });
        setAssistantCoachData(varAssistantCoach?.[0]?.values!);
        setCoachData(varCoach?.[0]?.values!);
        setCategoryData(varCategories?.values ?? []);
        setStateData(varState?.[0]?.values!);
        setPayerTypes(payerTypeObj);
        dispatch(PageActions.setPageLoader(false));
      })

      .catch(error => {});
  };
  const createTryItTeam = (data: TeamRegistration) => {
    dispatch(PageActions.setPageLoader(true));
    handleRequest(teamAddNewTeam(tryItTeamData))
      .then(res => {
        if (res && !isApiError(res)) {
          if (cancelPaymentCookie) {
            Cookies.remove('paymentCancel');
          }

          const teamNewDataString = JSON.stringify(tryItTeamData);
          Cookies.set('payload', teamNewDataString);
          if (photoUploadPayload.formData?.file !== undefined) {
            handlePhotoUpload(res.toString(), photoUploadPayload.formData);
            dispatch(manageAppActions.setPhotoUpload(photoUploadPayload));
          }
          navigate(`/payment/${payerTypes?.id}/${res}/false/false`);
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Team created successfully',
              show: true,
              errorType: 'success',
            }),
          );
        } else {
          dispatch(
            actions.dispatchToast({
              errorTxt: res.description,
              show: true,
              errorType: 'error',
            }),
          );
        }
        dispatch(PageActions.setPageLoader(false));
      })
      .catch(() => setError(apiError));
  };
  const handlePhotoUpload = (
    teamId: string,
    formData?: {
      file?: Blob;
    },
  ) => {
    handleRequest(teamUploadTeamPhoto(teamId, formData))
      .then(res => {})
      .catch(error => {});
  };
  const [teamCodeValue, setTeamCodeValue] = useState<string>();
  const generateTeamCode = (teamName?: string) => {
    handleRequest(teamGenerateTeamCode(teamName))
      .then(res => {
        if (res && !isApiError(res)) {
          setTeamCodeValue(res);
          setTryItTeamData({
            ...tryItTeamData,
            teamCode: res!,
          });
        } else {
          setTeamCodeValue(res.description);
        }
      })
      .catch(() => setError(apiError));
  };
  const handleChangeStepper = (e: StepperChangeEvent) => {
    const index = Number(e.value);
    if (index >= 0 && index !== formNumber) {
      setFormNumber(index);
    }
  };
  const handleItemClick = (index: number) => {};

  useEffect(() => {
    const tryItTeamDataCookie = Cookies.get('payload');
    handleGetLookups();
    const paymentCancelStatus = Cookies.get('paymentCancel');
    setCancelPaymentCookie(paymentCancelStatus);
    if (tryItTeamDataCookie) {
      setFormNumber(1);
      const updatedValue = JSON.parse(tryItTeamDataCookie);
      setTryItTeamData(updatedValue);
      setTeamCodeValue(updatedValue.teamCode);
      setPhotoUploadPayload(photoUploadDataRedux ?? {});
      setUploadFile(photoUploadDataRedux?.formData?.file as File);
    }
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 767);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      {pageLoader ? (
        <LoaderWrapper>
          <CircularLoader />
        </LoaderWrapper>
      ) : (
        <MainWrapper className="team-MainWrapper">
          <LeftWrapper className="team-LeftWrapper">
            <ShowyedaLogo />
            <div className="left-header-block">
              <p>Payment of $30</p>
              <p>
                This gives the team temporary YEDA Membership to show for a
                weekend. If the team decides within 30 days to upgrade to a
                regular membership this $30 will be applied to the regular YEDA
                team membership.
              </p>
            </div>
            <BottomImgWrapper>
              <TryImg src={tryitImg} />
            </BottomImgWrapper>
          </LeftWrapper>
          <RightWrapper className="team-RightWrapper">
            <TopWrapper className="TopWrapper">
              <h3 className="toplabel-header">
                {`YEDA Team Registration Form TRY IT`} <br />
                {currentSeason === undefined ? '2023 - 2024' : currentSeason}
              </h3>
              <h3 className="Team-Heading">New Team</h3>
              <div className="Stepper-wrapper">
                {isSmallScreen ? (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    <CircularProgress
                      size="lg"
                      determinate
                      value={determinateValue}
                    >
                      {`${formNumber + 1} / ${totalPages}`}
                    </CircularProgress>
                    {items
                      .filter((_, index) => index === formNumber)
                      .map((item, index) => (
                        <h2 className="circular-stepper-heading" key={index}>
                          {item.label}
                        </h2>
                      ))}
                  </Box>
                ) : (
                  <Stepper
                    value={formNumber}
                    onChange={handleChangeStepper}
                    items={items}
                    className="form-stepper"
                  >
                    {items.map((item, index) => (
                      <StepperStep
                        key={index}
                        className={`stepper-step ${
                          index < formNumber ? 'completed' : ''
                        }`}
                        onClick={() => handleItemClick(index)}
                      >
                        {index < formNumber ? (
                          <span className="tick-icon">&#10003;</span>
                        ) : (
                          item.number
                        )}
                      </StepperStep>
                    ))}
                  </Stepper>
                )}
              </div>
              {formNumber === 1 ? (
                <FinancialInfoForm
                  tryItTeamData={tryItTeamData}
                  setTryItTeamData={setTryItTeamData}
                  handleRegBack={handleRegBack}
                  createNewTeam={createTryItTeam}
                  stateData={stateData}
                  setPhotoUploadPayload={setPhotoUploadPayload}
                  photoUploadPayload={photoUploadPayload}
                  setFileAvailability={setFileAvailability}
                  fileAvailability={fileAvailability}
                  setUploadFile={setUploadFile}
                  uploadFile={uploadFile}
                  setIsNotImage={setIsNotImage}
                  isNotImage={isNotImage}
                />
              ) : formNumber === 0 ? (
                <PersonalInfoForm
                  tryItTeamData={tryItTeamData}
                  setTryItTeamData={setTryItTeamData}
                  handleBack={handleBack}
                  handleNxt={handleNxt}
                  stateData={stateData}
                  categoryData={categoryData}
                  coachData={coachData}
                  assistantCoachData={assistantCoachData}
                  generateTeamCode={generateTeamCode}
                  teamCodeValue={teamCodeValue}
                />
              ) : (
                ''
              )}
            </TopWrapper>
          </RightWrapper>
        </MainWrapper>
      )}
    </>
  );
};
const StepperStep = styled.button`
  background-color: green !important;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;
  font-weight: bold;

  &.completed {
    background-color: green !important;
  }
`;

const MainWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  .circular-stepper-heading {
    color: #8a8a8a;
  }
  .MuiCircularProgress-progress,
  .MuiCircularProgress-sizeLg {
    color: #8a8a8a;
    stroke: #2dce98;
  }
  .css-zitgxn-JoyCircularProgress-track {
    stroke: #434343;
  }
  .k-step-done {
    .k-step-indicator-text {
      position: relative;
      ::after {
        position: absolute;
        content: '';
        z-index: 15;
        background: #2dce98;
        background-image: url(${check});
        background-repeat: no-repeat;
        background-size: inherit;
        background-position: center;
        border-radius: 50px;
        left: -8px;
        height: 26px;
        width: 26px;
        top: -1px;
      }
    }
  }
  .k-stepper.form-stepper {
    pointer-events: none;
  }
  input {
    ::placeholder {
      font-size: 14px;
      text-transform: capitalize;
    }
  }
  .k-stepper .k-step-indicator::after {
    box-shadow: unset !important;
  }
  .k-stepper .k-step-indicator::after {
    border-style: unset !important;
  }
  .k-stepper .k-step-label .k-step-text {
    width: 200px;
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #8a8a8a;
    font-size: 12px;
    max-width: unset !important;
  }
  .k-progressbar {
    background: #5b5b5b;
  }
  .k-progressbar .k-selected {
    background: #2dce98;
  }
  .k-stepper .k-step-done .k-step-indicator {
    border-color: #2dce98;
    color: white;
    background-color: #2dce98;
  }
  .k-stepper .k-step-current .k-step-indicator {
    border-color: #2dce98;
    color: white;
    background-color: #2dce98;
  }
  .k-stepper .k-step-indicator {
    border-color: #5b5b5b;
    color: #c4c4c4;
    background-color: #5b5b5b;
    width: 25px;
    height: auto;
  }
  .k-stepper {
    padding-bottom: 20px;
  }
  .Stepper-wrapper {
    margin-bottom: 20px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        padding: 0 10px;
        height: 55px;
        gap: 5px;
      }
      .circular-stepper-heading {
        font-size: 13px;
      }
      .MuiCircularProgress-root {
        --CircularProgress-size: 50px;
      }
    }
    .k-stepper .k-step-label .k-step-text {
      width: 55px;
      font-size: 10px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        height: 55px;
        padding: 0 15px;
      }
      .circular-stepper-heading {
        font-size: 18px;
      }
      .MuiCircularProgress-root {
        --CircularProgress-size: 55px;
      }
    }
    .k-stepper .k-step-label .k-step-text {
      width: 55px;
      font-size: 11px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        height: 55px;
        padding: 0 20px;
        gap: 25px;
      }
      .circular-stepper-heading {
        font-size: 22px;
      }
    }
  }
  //Page Responsive
  @media (min-width: 100px) and (max-width: 319px) {
    .TopWrapper {
      .toplabel-header {
        font-size: 12px;
      }
    }
    .team-LeftWrapper {
      display: none;
    }
    .team-RightWrapper {
      width: 100%;
      .sub-MainWrapper {
      }
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .TopWrapper {
      .toplabel-header {
        font-size: 12px;
      }
    }
    .team-LeftWrapper {
      display: none;
    }
    .team-RightWrapper {
      width: 100%;
      .sub-MainWrapper {
      }
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .TopWrapper {
      .toplabel-header {
        font-size: 12px;
      }
    }
    .team-LeftWrapper {
      display: none;
    }
    .team-RightWrapper {
      width: 100%;
      .sub-MainWrapper {
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .TopWrapper {
      .toplabel-header {
        font-size: 18px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .TopWrapper {
      .toplabel-header {
        font-size: 19px;
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .TopWrapper {
      .toplabel-header {
        font-size: 20px;
      }
    }
  }
  @media (min-width: 1366px) and (max-width: 1449px) {
    .TopWrapper {
      .toplabel-header {
        font-size: 21px;
      }
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    .TopWrapper {
      .toplabel-header {
        font-size: 23px;
      }
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    .TopWrapper {
      .toplabel-header {
        font-size: 25px;
      }
    }
  }
`;
const LeftWrapper = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: url(${horseBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  .left-header-block {
    background: black;
    opacity: 0.7;
    padding: 0 20px;
    position: absolute;
    top: 45%;
  }
  p {
    color: #d1d1d1;
    font-family: 'Oswald';
    font-size: 14px;
  }
  span {
    color: #ffffff;
    font-family: 'Oswald';
    font-size: 14px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .left-header-block {
      p {
        font-size: 10px;
      }
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .left-header-block {
      span,
      p {
        font-size: 11px;
      }
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .left-header-block {
      p {
        font-size: 12px;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .left-header-block {
      p {
        font-size: 13px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .left-header-block {
      p {
        font-size: 13px;
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .left-header-block {
      p {
        font-size: 14px;
      }
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .left-header-block {
      span,
      p {
        font-size: 14px;
      }
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    .left-header-block {
      p {
        font-size: 15px;
      }
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    .left-header-block {
      p {
        font-size: 16px;
      }
    }
  }
`;
const BottomImgWrapper = styled.div`
  filter: drop-shadow(2px 4px 6px black);
  position: absolute;
  top: 39%;
  right: 2%;
  @media (min-width: 576px) and (max-width: 1367px) {
    top: 26%;
  }
`;
const TryImg = styled.img`
  max-width: 150px;
  z-index: 2;
  bottom: 60%;
  left: 15%;
  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 767.98px) {
    width: 60px;
    left: 20%;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) and (max-width: 991.98px) {
    width: 70px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 80px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    width: 100px;
  }
  @media (min-width: 1366px) and (max-width: 1447.8px) {
    width: 110px;
  }
  @media (min-width: 1448px) {
    width: 130px;
  }
`;

const RightWrapper = styled.div`
  width: 60%;
  height: 100%;
  background: #2a2a2a;
  padding-bottom: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-bottom: 60px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    padding-bottom: 60px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    padding-bottom: 70px;
  }
`;

const TopWrapper = styled.div`
  width: 100%;
  background: #2a2a2a;
  padding: 20px 40px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 100%;
  h3 {
    font-weight: 500;
    color: #ffffff;
    padding-top: 10px;
    margin: 0px !important;
    text-align: center;
  }
  .Team-Heading {
    color: #9597a6;
  }
  @media (min-width: 100px) and (max-width: 768px) {
    padding: 20px 20px 0px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    h3 {
      font-size: 15px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    h3 {
      font-size: 16px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    h3 {
      font-size: 17px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    h3 {
      font-size: 18px;
    }
  }
`;
