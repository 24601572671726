import { GridCellProps } from '@progress/kendo-react-grid';
import { DetailArrowIcon } from 'app/assets/icons';
import { AdminProfileInfo } from 'app/components/AdminProfileInfo';
import styled from 'styled-components';

type Props = {
  className?: string;
  e: GridCellProps;
  onClick?: (e) => void;
  onViewClick?: (e) => void;
};

const ShowNameCell = ({ className, e, onClick, onViewClick }: Props) => {
  const data = e.dataItem;
  return (
    <td
      style={e.style}
      className={e.className}
      onClick={() => onViewClick?.(data)}
    >
      <CustomTableColumnWrapper>
        <AdminProfileInfo src={data?.imageUrl} name={data?.showName} />
        <div>
          <DetailArrowIcon />
        </div>
      </CustomTableColumnWrapper>
    </td>
  );
};

export default ShowNameCell;
const CustomTableColumnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px 0 0px;
`;
