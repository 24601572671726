import React, { useState } from 'react';
import styled from 'styled-components';
import '@fontsource/oswald';
import '@fontsource/roboto';
import { ShowyedaLogo } from 'app/components/ShowyedaLogo';
import { Outlet } from 'react-router-dom';
import logo from './assets/img/bg/header.png';
import horseBg from './assets/img/bg/AlumniRetunring.jpg';
import tryitImg from './assets/img/bg/tryit.png';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
//Font-arimo
import '@fontsource/arimo';

type Props = {};

const AlumniTryItLayout = (props: Props) => {
  var currentUrl = '/alumni-registration/returning-member';
  const location = useLocation();
  const currentSeason = Cookies.get('isAdminLiveFiltered');

  return (
    <MainWrapper>
      <LeftWrapper className="LeftWrapper">
        <LeftSubWrapper className="LeftSubWrapper">
          <ShowyedaLogo />
          <div className="left-header-block">
            <p>
              <span className="note-bold-text"></span> This gives the coach
              temporary yeda membership to coach for a weekend. if the coach
              decides within 30 days to upgrade to a regular membership this $20
              will be applied to a regular yeda coach membership.
            </p>
          </div>
          <BottomImgWrapper className="BottomImgWrapper">
            <TryImg src={tryitImg} />
            {/* <ImgTag src={horseBg} /> */}
          </BottomImgWrapper>
        </LeftSubWrapper>
      </LeftWrapper>
      <RightWrapper className="RightWrapper">
        <BorderWrapper className="BorderWrapper">
          <TopWrapper className="TopWrapper">
            <ToplabelWrapper>
              <h3>
                {location.pathname === currentUrl ? (
                  'YEDA Rider Registration Form '
                ) : (
                  <>
                    TRY IT YEDA <br />
                    Alumni Registration Form{' '}
                    {currentSeason === undefined
                      ? '2023 - 2024'
                      : currentSeason}
                  </>
                )}
              </h3>
            </ToplabelWrapper>
          </TopWrapper>
          <Outlet />
        </BorderWrapper>
      </RightWrapper>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  @media (min-width: 100px) and (max-width: 319px) {
    .LeftWrapper {
      display: none;
    }
    .RightWrapper {
      width: 100%;
      padding: 30px 15px 20px;
      .inner-BorderWrapper {
        padding: 20px 20px 0px 20px;
      }
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .LeftWrapper {
      display: none;
    }
    .RightWrapper {
      width: 100%;
      padding: 30px 15px 20px;
      .inner-BorderWrapper {
        padding: 20px 20px 0px 20px;
      }
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .LeftWrapper {
      display: none;
    }
    .RightWrapper {
      width: 100%;
      padding: 30px 15px 20px;
      .inner-BorderWrapper {
        padding: 20px 20px 0px 20px;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .LeftWrapper {
      .left-header-block {
        p {
          font-size: 12px;
        }
      }
    }
    .RightWrapper {
      padding: 30px 15px 20px;
      .inner-BorderWrapper {
        padding: 20px 20px 0px 20px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .LeftWrapper {
      .left-header-block {
        p {
          font-size: 14px;
        }
      }
    }
    .RightWrapper {
      padding: 30px 15px 20px;
      .inner-BorderWrapper {
        padding: 20px 20px 0px 20px;
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .LeftWrapper {
      .left-header-block {
        p {
          font-size: 15px;
        }
      }
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .LeftWrapper {
      .left-header-block {
        p {
          font-size: 16px;
        }
      }
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    .LeftWrapper {
      .left-header-block {
        p {
          font-size: 19px;
        }
      }
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    .LeftWrapper {
      .left-header-block {
        p {
          font-size: 24px;
        }
      }
    }
  }
`;
const LeftWrapper = styled.div`
  width: 36%;
  /* background: #1b1b1b; */
  height: 100%;
  position: relative;
  background: url(${horseBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  .left-header-block {
    background: black;
    opacity: 0.7;
    padding: 0 20px;
    position: absolute;
    top: 30%;
  }
`;
const LeftSubWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  padding: 10px 20px;
  /* position: relative; */

  p {
    color: #898989;
    font-size: 18px;
    font-family: 'Oswald';

    .note-bold-text {
      font-size: 18px;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
  span {
    color: #ffffff;
    font-family: 'Oswald';
    font-size: 14px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 20px 20px;
    p {
      font-size: 11px;
    }
    span {
      font-size: 11px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    padding: 40px 28px;
    p {
      font-size: 13px;
    }
    span {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    padding: 60px 35px;
    p {
      font-size: 14px;
    }
    span {
      font-size: 14px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    p {
      font-size: 13px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    p {
      font-size: 13px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 20px 20px;
    p {
      font-size: 14px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    padding: 40px 28px;
    p {
      font-size: 15px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    padding: 60px 35px;
    p {
      font-size: 16px;
    }
  }
`;
const BottomImgWrapper = styled.div`
  filter: drop-shadow(2px 4px 6px black);
  position: absolute;
  top: 22%;
  right: 2%;
  /* width: 100%; */
  /* display: flex;
  align-items: center;
  justify-content: end; */
`;
const ImgTag = styled.img`
  width: 100%;
  max-width: 530px !important;
  min-height: calc(100vh - 367px);
  max-height: 600px;
  object-fit: contain;
  object-position: center;
  /* position: relative; */
  @media (min-width: 576px) and (max-width: 767px) {
    max-height: calc(100vh - 338px);
  }
  @media (min-width: 768px) and (max-width: 991px) {
    max-height: calc(100vh - 338px);
  }
  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    width: 300px;
    height: 400px;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 767.98px) {
    width: 300px;
    height: 400px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) and (max-width: 991.98px) {
    width: 300px;
    height: 400px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 300px;
    height: 400px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    width: 350px;
    height: 400px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    width: 350px;
    height: 400px;
  }
  @media (min-width: 1448px) {
    width: 400px;
    height: calc(100vh - 260px);
  }
`;
const TryImg = styled.img`
  max-width: 130px;
  /* position: absolute; */
  top: 10%;
  left: 46px;
  z-index: 1;
  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    width: 80px;
    top: 13%;
    left: 70px;
    /* left: 20%; */
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 767.98px) {
    width: 80px;
    top: 13%;
    left: 70px;
    /* left: 20%; */
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) and (max-width: 991.98px) {
    width: 80px;
    /* left: 15%; */
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 90px;
    /* left: 20%; */
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    width: 100px;
    /* left: 26%; */
    /* width: 110px;
    left: -10px; */
  }
  @media (min-width: 1366px) and (max-width: 1447.8px) {
    width: 110px;
    /* left: 30%; */
    /* width: 110px;
    bottom: 190px;
    left: -15px; */
  }
  @media (min-width: 1448px) and (max-width: 1719px) {
    /* left: 20%; */
    width: 120px;
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    width: 140px;
  }
`;
const RightWrapper = styled.div`
  width: 64%;
  height: 100%;
  background: #2a2a2a;
  padding: 30px 35px 20px;
  p {
    color: #9597a6;
    font-family: 'Roboto';
    font-size: 14px;
  }
  // display: flex;
  // flex-direction: column;
  // align-items: center;

  @media (min-width: 992px) and (max-width: 1199px) {
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
  }
`;
const BorderWrapper = styled.div`
  height: 100%;
  border: 1px solid #585858;
  border-radius: 20px;
  /* padding: 20px 60px 10px; */
  /* display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center; */
  position: relative;
  /* overflow: hidden; */
`;

const TopWrapper = styled.div`
  /* background: #2a2a2a; */
  padding: 0px 10px;
  position: absolute;
  margin: 0 auto;
  width: 100%;
  top: -18px;
  /* position: absolute;
  top: -12px;
  left: 230px; */
  h3 {
    font-weight: 500;
    color: #ffffff;
    margin: 0px !important;
    font-family: 'Roboto';
    text-align: center;
  }
  @media (min-width: 100px) and (max-width: 319px) {
  }
  @media (min-width: 320px) and (max-width: 575px) {
    top: -20px;
    z-index: 10;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    // padding: 15px 40px 0px;
    top: -14px;
    h3 {
      font-size: 16px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    // padding: 20px 60px 0px;
    h3 {
      font-size: 17px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    // padding: 20px 80px 0px;
    h3 {
      font-size: 18px;
    }
  }
`;
const ToplabelWrapper = styled.div`
  position: relative;
  margin: 0 0 0 0;
  background: none;
  display: flex;
  justify-content: center;
  h3 {
    display: inline-grid;
    background: #2a2a2a !important;
    font-weight: 500;
    color: #ffffff;
    padding-top: 10px;
    margin: 0px !important;
    font-family: 'Roboto';
    font-family: 'Arimo' !important;
    font-size: 22px;
    text-align: center;
    padding: 0 15px;
  }
  .insurance-span {
    font-size: 13px !important;
    letter-spacing: 0.2px;
    font-family: 'Arimo' !important;
    line-height: 25px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .insurance-span {
      font-size: 10px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .insurance-span {
      font-size: 9px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .insurance-span {
      font-size: 10px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .insurance-span {
      font-size: 11px;
    }
  }
  @media (min-width: 100px) and (max-width: 319px) {
    padding: 6px 0 0 0;
    h3 {
      font-size: 13px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    padding: 6px 0 0 0;
    h3 {
      font-size: 13px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding: 6px 0 0 0;
    h3 {
      font-size: 14px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding: 6px 0 0 0;
    h3 {
      font-size: 18px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    h3 {
      font-size: 19px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    padding: 0 60px 0;
    h3 {
      font-size: 20px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    padding: 0 80px 0;
    h3 {
      font-size: 21px;
    }
  }
`;

export default AlumniTryItLayout;
