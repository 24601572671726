import { CommonPageWrapper, HeadingWrapper } from 'admin/components/wrappers';
import { PageMainLabel } from 'app/components/SHLabel';
import React, { useRef, useState } from 'react';
import RiderInformationFirstBlock from './RiderInformationFirstBlock';
import AdminTab from 'app/components/AdminTab';
import RiderInformationFrom from './RiderInformationFrom';
import ParentDetails from './ParentDetails';
import styled from 'styled-components';
import BackButton from 'app/components/BackButton';
import AdminCustomButton from 'app/components/AdminCustomButton';
import CustomBreadCrumb from 'app/components/CustomBreadCrumb';
import { useParams } from 'react-router-dom';
import { AdminRiderRegistration, GeneralLookup } from 'services/openapi';
import SchoolDetails from './SchoolDetails';
import {
  breadCrumpitems,
  defaultRiderValue,
  parentDetailsTabValidation,
  riderInfoTabValidation,
  schoolTabValidation,
  tabRiderNamesArray,
} from './staticDatas';
import useCommonLookUpDatas from 'hooks/useCommonLookupData';
import useRiderApi from 'admin/hooks/useRiderApi';
import {
  findMatchingDivisionClassAndRemoveSelected,
  findObjectById,
  getLookupArray,
  useNavigateToRoute,
} from 'utils/common';
import SimpleReactValidator from 'simple-react-validator';
import { CommonLoader } from 'app/components/CommonLoader';

const RiderCreateViewPage = () => {
  const redirect = useNavigateToRoute();
  let { action, id: riderId } = useParams();
  const {
    fetchRiderInfo,
    updateOrCreateRiders,
    riderInfo,
    loading,
    riderDataLoading,
    checkBacknumberTaken,
    isBacknumberTaken,
    setIsBacknumberTaken,
  } = useRiderApi();

  const {
    getAllLookupData,
    lookUps,
    loading: lookUpLoading,
  } = useCommonLookUpDatas();
  const [tabSelected, setTabSelected] = useState<number>(0);
  const [riderData, setRiderdata] =
    React.useState<AdminRiderRegistration>(defaultRiderValue);
  const [defaultRiderData, setDefaultRiderdata] =
    React.useState<AdminRiderRegistration>(defaultRiderValue);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [categoryListFromTeam, setCategoryListFromTeam] = React.useState<
    Array<GeneralLookup>
  >([]);
  const [divisionOneList, setDivisionOnelist] = React.useState<
    Array<GeneralLookup>
  >([]);
  const [divisionTwoList, setDivisionTwoList] = React.useState<
    Array<GeneralLookup>
  >([]);
  const [triggor, setTriggor] = React.useState(false);
  const [triggorTwo, setTriggorTwo] = React.useState(false);
  const [isCategoryNotInTeam, setIsCategoryNotInTeam] = useState(false);
  const [valid, setValid] = useState<boolean>(false);
  const [imgSrc, setImgSrc] = React.useState<string | null>('');
  const [imageFile, setImageFile] = React.useState<Blob>();
  const handleChange = event => {
    const { name, value } = event.target;
    const nameArray = name.split('.');
    const updateNestedState = (prevState, keys, newValue) => {
      const [firstKey, ...remainingKeys] = keys;
      if (remainingKeys.length === 0) {
        return {
          ...prevState,
          [firstKey]: newValue,
        };
      }
      return {
        ...prevState,
        [firstKey]: updateNestedState(
          prevState[firstKey],
          remainingKeys,
          newValue,
        ),
      };
    };

    setRiderdata(prevData => updateNestedState(prevData, nameArray, value));
  };
  const onSaveClick = () => {
    let isFormValid = simpleValidator.current.allValid();
    let fieldsData = simpleValidator.current.fields;
    let emailValid = fieldsData['Rider Email'];
    let isriderInfoTabValid = riderInfoTabValidation(riderData);
    let isSchoolTabValid = schoolTabValidation(riderData);
    let isParentDetailsTabValid = parentDetailsTabValidation(riderData);
    if (!emailValid) {
      setTabSelected(0);
    }
    if (valid || isFormValid) {
      if (isriderInfoTabValid || !emailValid) {
        setTabSelected(0);
      } else if (isSchoolTabValid) {
        setTabSelected(1);
      } else if (isParentDetailsTabValid) {
        setTabSelected(2);
      } else {
        updateOrCreateRiders(riderData, imageFile);
      }
      if (isriderInfoTabValid || isSchoolTabValid || isParentDetailsTabValid) {
        simpleValidator.current.showMessages();
        setTriggor(!triggor);
      }
    } else {
      if (isriderInfoTabValid) {
        setTabSelected(0);
      } else if (isSchoolTabValid) {
        setTabSelected(1);
      } else if (isParentDetailsTabValid) {
        setTabSelected(2);
      }
      simpleValidator.current.showMessages();
      setTriggor(!triggor);
    }
  };
  const onCancelClick = () => {
    setTriggorTwo(!triggorTwo);
    setIsCategoryNotInTeam(false);
    simpleValidator.current.hideMessages();
    simpleValidator.current.visibleFields = [];
    setImgSrc(riderInfo?.imageUrl!);
    setRiderdata(defaultRiderData);
    setImageFile(undefined);
  };

  const handleBackClick = () => {
    if (action === 'edit') {
      redirect(`/admin/riders/view/profile-info/${riderId}`);
    } else {
      redirect(`/admin/riders`);
    }
  };
  React.useEffect(() => {
    if (riderInfo) {
      const updatedObject = {
        ...riderInfo,
        sifAmountPaid: riderInfo.sifAmountPaidPerYear,
        sifAmountPaidPerYear: undefined,
        sifJoinedDate: riderInfo.sifJoinDate,
        sifJoinDate: undefined,
        sifPoint: riderInfo.sifPoint,
      };
      setImgSrc(riderInfo?.imageUrl!);
      setRiderdata(updatedObject as AdminRiderRegistration);
      setDefaultRiderdata(updatedObject as AdminRiderRegistration);
    }
  }, [riderInfo]);
  React.useEffect(() => {
    let teamLookUpList = getLookupArray('TEAM', lookUps);
    const matchingObject = findObjectById(teamLookUpList, riderInfo?.team?.id!);
    if (matchingObject) {
      const { children } = matchingObject;
      setCategoryListFromTeam(children!);
    }
    let categoryLookUpList = getLookupArray('CATEGORY', lookUps);
    const matchingCategoryObject = findObjectById(
      categoryLookUpList,
      riderInfo?.category?.id!,
    );
    if (matchingCategoryObject) {
      const { children } = matchingCategoryObject;
      setDivisionOnelist(children!);
    }
    let division = getLookupArray('DIVISION', lookUps);
    let filteredDivisionTwoList = findMatchingDivisionClassAndRemoveSelected(
      riderInfo?.divisionClassOne!,
      division,
      divisionOneList,
    ); // for division two dropdown data filtering
    setDivisionTwoList(filteredDivisionTwoList);
  }, [lookUps]);
  React.useEffect(() => {
    getAllLookupData('CATEGORY,TEAM,DIVISION,ACTIVESTATUS,STATE,GRADE');
    if (riderId) {
      fetchRiderInfo(riderId);
    }
  }, [riderId]);
  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(formValid);
  }, [riderData, imageFile, riderInfo]);
  return (
    <>
      <CommonPageWrapper>
        {loading && <CommonLoader loaderType="main" />}
        <HeadingWrapper>
          <PageMainLabel>Rider Information</PageMainLabel>
          <CustomBreadCrumb preData={breadCrumpitems} />
        </HeadingWrapper>
        <BtnWrapper>
          <div>
            <BackButton onClick={handleBackClick} />
          </div>
          <div className="btn-action">
            <AdminCustomButton buttonType="cancel" onClick={onCancelClick}>
              Cancel
            </AdminCustomButton>
            <AdminCustomButton buttonType="save" onClick={onSaveClick}>
              Save
            </AdminCustomButton>
          </div>
        </BtnWrapper>
        <RiderInformationFirstBlock
          riderData={riderData}
          handleChange={handleChange}
          setRiderdata={setRiderdata}
          action={action}
          lookUps={lookUps}
          categoryListFromTeam={categoryListFromTeam}
          setCategoryListFromTeam={setCategoryListFromTeam}
          divisionOneList={divisionOneList}
          setDivisionOnelist={setDivisionOnelist}
          divisionTwoList={divisionTwoList}
          setDivisionTwoList={setDivisionTwoList}
          simpleValidator={simpleValidator}
          setImgSrc={setImgSrc}
          imgSrc={imgSrc}
          setImageFile={setImageFile}
          riderDataLoading={riderDataLoading || lookUpLoading}
          isCategoryNotInTeam={isCategoryNotInTeam}
          setIsCategoryNotInTeam={setIsCategoryNotInTeam}
          checkBacknumberTaken={checkBacknumberTaken}
          isBacknumberTaken={isBacknumberTaken}
          setIsBacknumberTaken={setIsBacknumberTaken}
        />
        {!(riderDataLoading || lookUpLoading) && (
          <TabWrapper>
            <AdminTab
              data={tabRiderNamesArray}
              tabSelected={tabSelected}
              setTabSelected={setTabSelected}
            />
          </TabWrapper>
        )}
        <div className="sub-wrapper">
          {tabSelected === 0 ? (
            <RiderInformationFrom
              riderData={riderData}
              handleChange={handleChange}
              setRiderdata={setRiderdata}
              lookUps={lookUps}
              simpleValidator={simpleValidator}
              action={action}
              riderDataLoading={riderDataLoading || lookUpLoading}
            />
          ) : tabSelected === 1 ? (
            <SchoolDetails
              riderData={riderData}
              handleChange={handleChange}
              setRiderdata={setRiderdata}
              lookUps={lookUps}
              simpleValidator={simpleValidator}
              riderDataLoading={riderDataLoading || lookUpLoading}
            />
          ) : tabSelected === 2 ? (
            <ParentDetails
              riderData={riderData}
              handleChange={handleChange}
              setRiderdata={setRiderdata}
              lookUps={lookUps}
              simpleValidator={simpleValidator}
              riderDataLoading={riderDataLoading || lookUpLoading}
            />
          ) : (
            ''
          )}
        </div>
      </CommonPageWrapper>
    </>
  );
};

export default RiderCreateViewPage;
const TabWrapper = styled.div`
  margin-top: 20px;
`;
const BtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 15px 0;
  @media (min-width: 1480px) and (max-width: 1899px) {
    padding: 15px 20px 15px 0;
  }
  @media (min-width: 1899px) and (max-width: 4000px) {
    padding: 15px 35px 15px 0;
  }
  .btn-action {
    display: flex;
    align-items: center;
    gap: 15px;
  }
`;
