import { CardSubWrapper, CardWrapper } from 'admin/components/wrappers';
import { RideShowBackNoCell } from 'admin/pages/TeamIndividual/components/TeamRiderListing/RiderShowbackNoCell';
import DivisionCell from 'app/components/CustomDataGrid/Cells/DivisionCell';
import RiderResultName from 'app/components/CustomDataGrid/Cells/RiderResultName';
import TeamResultName from 'app/components/CustomDataGrid/Cells/TeamResultName';
import CustomDataGrid from 'app/components/CustomDataGrid/Index';
import { RiderPoint, RiderPointListWrapper } from 'services/openapi';
import styled from 'styled-components';
import { ShowsScoreActionCell } from 'app/components/CustomDataGrid/Cells/ShowsScoreActionCell';
type Props = {
  riderShowList: RiderPointListWrapper | undefined;
  setPageSkip: React.Dispatch<React.SetStateAction<number | undefined>>;
  pageSkip: number | undefined;
  refreshFunction: (page: number) => void;
  setShowRiderId: React.Dispatch<React.SetStateAction<string | undefined>>;
  showRiderId: string | undefined;
  onSaveClick: (requestBody: RiderPoint) => void;
};
const RiderInformationTable = ({
  riderShowList,
  setPageSkip,
  pageSkip,
  refreshFunction,
  setShowRiderId,
  showRiderId,
  onSaveClick,
}: Props) => {
  const handlePageChange = event => {
    setPageSkip(event.page.skip);
    refreshFunction(event.page.skip);
  };
  const RiderColumn = [
    {
      field: 'teamId',
      title: 'Team',
      cell: e => <TeamResultName e={e} />,
      width: '250',
    },
    {
      field: 'riderName',
      title: 'Rider',
      cell: e => <RiderResultName e={e} />,
      width: '150',
    },
    {
      field: 'name',
      title: 'Back#',
      cell: e => <RideShowBackNoCell e={e} />,
      width: '100',
    },

    {
      field: 'name',
      title: 'Division',
      cell: e => <DivisionCell e={e} />,
      width: '150',
    },
    {
      field: 'totalPoints',
      title: 'Total Points',
      cell: e => (
        <ShowsScoreActionCell
          e={e}
          setShowRiderId={setShowRiderId}
          showRiderId={showRiderId}
          onSaveClick={onSaveClick}
        />
      ),
      width: '300',
    },
  ];
  return (
    <>
      <CardWrapper style={{ paddingTop: '15px' }}>
        <CardSubWrapper className="d-flex"></CardSubWrapper>
        <GridWrapper>
          <CustomDataGrid
            showPagination
            columns={RiderColumn}
            data={riderShowList?.riderPointList!}
            pageSize={10}
            totalCount={riderShowList?.pageDetails?.count}
            onPageChange={event => {
              handlePageChange(event);
            }}
            skip={pageSkip}
          />
        </GridWrapper>
      </CardWrapper>
    </>
  );
};

export default RiderInformationTable;

const GridWrapper = styled.div`
  .k-grid-header tr th {
    border-top: 1px solid #eaecf0;
  }
  .k-grid-container .k-grid-content tr td:nth-last-child(1) {
    padding: 0px 24px 0px 0px !important;
  }
  .k-grid-content tr {
    &:hover {
      td {
        background: #f0f0f0 !important;
      }
    }
  }
  .k-grid-content tr td {
    cursor: pointer;
  }
  .k-grid-content tr td.team-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }
`;
