import { GridCellProps } from '@progress/kendo-react-grid';
import StatusChip from 'app/components/StatusChip';

type Props = {
  className?: string;
  e: GridCellProps;
};

const ShowStatusCell = ({ className, e }: Props) => {
  const status = e.dataItem.status; // Access status directly as a string
  return (
    <td className={className ?? ''}>
      {status === null ? (
        ''
      ) : status?.name === 'Active' ? ( // Check if status is 'Active'
        <StatusChip className="" StatusType={'active'} text={status?.name} />
      ) : (
        <StatusChip
          className=""
          StatusType={'inactive-team'}
          text={status?.name}
        />
      )}
    </td>
  );
};

export default ShowStatusCell;
