import React, { useState } from 'react';
import styled from 'styled-components';
import '@fontsource/oswald';
import '@fontsource/roboto';
import { BackNextButton } from 'app/components/BackNextButton';
import { useNavigate } from 'react-router-dom';
import TeamListPopUp from 'app/components/TeamListPopUp';
import SHButton from 'app/components/SHButton';
import {
  DocumentService,
  GeneralLookup,
  LookupService,
} from 'services/openapi';
import { useDispatch, useSelector } from 'react-redux';
import useApi from 'services/mavapi/useApi';
import { useManagePageSlice } from '../slice';
import Cookies from 'js-cookie';

export type Option = {
  label?: string;
  value?: string;
};

const TryitEWDMembershipPage = () => {
  const currentSeason = Cookies.get('isAdminLiveFiltered');
  const { lookupGetAllLookups } = LookupService;
  const { documentActiveDocumentByType } = DocumentService;
  const { error: apiError, handleRequest } = useApi();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { actions: PageActions } = useManagePageSlice();
  const [visible, setVisible] = React.useState<boolean>(false);
  const [documentTypeData, setDocumentTypeData] = useState<GeneralLookup[]>([]);

  const handleBack = () => {
    navigate('/login');
  };

  const handleNxt = () => {
    navigate('/tryit-ewd-membership-form/new-member');
  };

  const handleInfoIcon = () => {
    setVisible(!visible);
  };
  const handlegetAllLookupData = (names?: string) => {
    dispatch(PageActions.setPageLoader(true));
    handleRequest(lookupGetAllLookups(names))
      .then(data => {
        if (data) {
          dispatch(PageActions.setPageLoader(false));
          let typeData = data?.filter(item => {
            if (item?.type === 'DOCUMENT_TYPES') {
              return item.values;
            }
          });
          setDocumentTypeData(typeData[0]?.values);
        }
      })
      .catch(() => {
        dispatch(PageActions.setPageLoader(false));
      });
  };
  const handleDiagonosisFormDownload = () => {
    const payload = documentTypeData[0].id;
    handleRequest(documentActiveDocumentByType(payload)).then(res => {
      const url = res.filePath;
      const name = res.name;
      const downloadUrl = `https://yeda-api-dev.onesmarter.in/${url}`;
      fetch(downloadUrl)
        .then(response => response.blob())
        .then(blob => {
          const objectUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = objectUrl;
          window.open(objectUrl);
          link.download = encodeURIComponent(name);
          link.click();
        });
    });
  };
  React.useEffect(() => {
    handlegetAllLookupData('CATEGORY,DOCUMENT_TYPES');
  }, []);
  return (
    <MainWrapper>
      <ContentWrapper>
        <p className="para">
          Kindly check the list of Teams which have renewed memberships for the
          year till now. If you do not find the team you wish to be a part of,
          check back later.
        </p>
        <h4 onClick={handleInfoIcon}>
          Team list{' '}
          {currentSeason === undefined ? '2023 - 2024' : currentSeason}
        </h4>
        {visible && <TeamListPopUp onClose={handleInfoIcon} />}
        <p className="para-one">
          A membership registration forms for Equestrian with Disabilities must
          be accompanied by a Diagnosis and Adaptive Equipment Form.
        </p>
        <p className="para-two">
          <i>
            Payment of $80 fee must be accompanied with this form ($10 of the
            $80 will go towards the SIF as the YEDA Back # fee)
          </i>
        </p>
        <DownloadWrapper>
          <p>Download the Ewd Diagnosis form below</p>
          <SHButton
            children="Download Form"
            onClick={handleDiagonosisFormDownload}
          />
        </DownloadWrapper>
      </ContentWrapper>

      <ButtonWrapper className="bg-color-change">
        <BackNextButton
          classNameBack="btn-back"
          classNameNxt="btn-nxt"
          onClickNxt={handleNxt}
          onClickBack={handleBack}
          next="Next"
          back="Cancel"
        />
      </ButtonWrapper>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #2a2a2a;
  position: relative;
  p {
    color: #ffffff;
    font-family: 'Roboto';
    font-size: 14px;
  }
`;
const ContentWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  padding: 40px 0px 100px;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
  p {
    margin: 0px;
  }
  .para {
    color: #ff9900;
    padding: 30px 0px 10px;
  }
  .para-one {
    color: #9597a6;
    padding: 20px 0px 10px;
  }
  .para-two {
    color: #ff9900;
    border-bottom: 1px solid #585858;
    padding: 0px 0px 10px;
  }
  h4 {
    color: #306fdb;
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
  }
`;
const DownloadWrapper = styled.div`
  padding-top: 30px;
  button {
    background-color: #306fdb;
    border-color: #306fdb;
    color: #ffffff;
    cursor: pointer;
  }
  p {
    padding-bottom: 10px;
    color: #9597a6;
    font-family: 'Roboto';
    font-size: 14px;
  }
`;

const RadioButtonWrapper = styled.div`
  display: flex;
  padding: 60px 0px;
  gap: 50px;
  p,
  label {
    color: #9597a6;
    font-family: 'Roboto';
    font-size: 14px;
  }
  .k-radio {
    background-color: transparent;
    border: 1px solid #ced4da;
  }
  .k-radio:checked,
  .k-radio.k-checked {
    border-color: 1px solid #0d6efd;
    color: white;
    background-color: #0d6efd;
  }
  .k-radio-list {
    flex-flow: row;
    gap: 50px;
  }
  .k-radio-item,
  .k-radio-list-item {
    gap: 15px;
  }
`;
const ButtonWrapper = styled.div`
  height: 70px;
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: 0px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
  }
  .blue {
    background-color: #306fdb;
    color: white;
  }

  /* Button styles for grey color */
  .grey {
    background-color: #595959;
    color: white;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
  }
`;

export default TryitEWDMembershipPage;
