/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Associate } from '../models/Associate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AssociateService {

    /**
     * API to Create Associate
     * @param requestBody 
     * @returns string Created
     * @throws ApiError
     */
    public static associateCreateAssociate(
requestBody?: Associate,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/associate/associate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to upload Associate Photo
     * @param associateId 
     * @param formData 
     * @returns any Created
     * @throws ApiError
     */
    public static associateUploadAssociatePhoto(
associateId: string,
formData?: {
file?: Blob;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/associate/{associateId}/photo',
            path: {
                'associateId': associateId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

}
