import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Stepper, StepperChangeEvent } from '@progress/kendo-react-layout';
import TryitPersonalInfoFields from './components/TryitPersonalInfoFields';
import TryitParentDetails from './components/TryitParentDetails';
import TryitSchoolDetails from './components/TryitSchoolDetails';

type Props = {
  onClickBackPersonal?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};
const items = [
  { label: 'Personal Information', number: 1 },
  { label: 'Parent', number: 2 },
  { label: 'School', number: 3 },
];

const TryitRiderInformation = ({ onClickBackPersonal }: Props) => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState<number>(0);

  const handleChangeStepper = (e: StepperChangeEvent) => {
    const index = Number(e.value);
    if (index >= 0 && index !== value) {
      setValue(index);
    }
  };
  const handleItemClick = (index: number) => {};

  const handlePersonalNxt = () => {
    setValue(1);
  };
  const handleParentNxtBack = () => {
    setValue(0);
  };
  const handleParentNxt = () => {
    setValue(2);
  };
  const handleSchoolNxtBack = () => {
    setValue(1);
  };
  const handleSchoolNxt = () => {
    navigate(
      '/tryit-rider-registration-page/returning-member/membership-placement',
    );
  };

  return (
    <MainWrapper>
      <Stepper value={value} onChange={handleChangeStepper} items={items}>
        {items.map((item, index) => (
          <StepperStep
            key={index}
            className={`stepper-step ${index < value ? 'completed' : ''}`}
            onClick={() => handleItemClick(index)}
          >
            {index < value ? (
              <span className="tick-icon">&#10003;</span>
            ) : (
              item.number
            )}
          </StepperStep>
        ))}
      </Stepper>
      <>
        {value === 0 && (
          <TryitPersonalInfoFields
            // onClickBackPersonal={onClickBackPersonal}
            onClickNxtPersonal={handlePersonalNxt}
          />
        )}
        {value === 1 && (
          <TryitParentDetails
            onClickBackParent={handleParentNxtBack}
            onClickNxtParent={handleParentNxt}
          />
        )}
        {value === 2 && (
          <TryitSchoolDetails
            onClickBackSchool={handleSchoolNxtBack}
            onClickNxtSchool={handleSchoolNxt}
          />
        )}
      </>
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 40px;
  input {
    ::placeholder {
      font-size: 14px;
      text-transform: capitalize;
    }
  }
  .k-stepper .k-step-label .k-step-text {
    width: 200px;
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #8a8a8a;
    font-size: 12px;
    max-width: unset !important;
  }
  .k-progressbar {
    background: #5b5b5b;
  }
  .k-progressbar .k-selected {
    background: #2dce98;
  }
  .k-stepper .k-step-done .k-step-indicator {
    border-color: #2dce98;
    color: white;
    background-color: #2dce98;
  }
  .k-stepper .k-step-current .k-step-indicator {
    border-color: #2dce98;
    color: white;
    background-color: #2dce98;
  }
  .k-stepper .k-step-indicator {
    border-color: #5b5b5b;
    color: #c4c4c4;
    background-color: #5b5b5b;
    width: 25px;
    height: auto;
  }
`;
const StepperStep = styled.button`
  background-color: green !important;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;
  font-weight: bold;

  &.completed {
    background-color: green !important;
  }
`;
export default TryitRiderInformation;
