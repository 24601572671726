import React, { useState } from 'react';
import styled from 'styled-components';
import '@fontsource/roboto';
import { BackNextButton } from 'app/components/BackNextButton';
import RoundRadioBtn from 'app/components/RoundRadioBtn';
import { Option } from 'app/pages/TryitRiderRegistrationPage';
import TermsandConditons from 'app/components/TermsandConditions';
import { CheckBox } from 'app/components/CheckBox';
import { useNavigate } from 'react-router-dom';

type Props = {
  onClickBackAssignment?:
    | React.MouseEventHandler<HTMLButtonElement>
    | undefined;
  onClickNxtAssignment?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};
const data: Option[] = [
  { label: 'No', value: 'false' },
  { label: 'Yes', value: 'true' },
];

const TryitMembershipForm = ({
  onClickNxtAssignment,
  onClickBackAssignment,
}: Props) => {
  const navigate = useNavigate();
  const handleReturningSelection = () => {};
  const handleSave = () => {
    navigate(
      '/tryit-rider-registration-page/returning-member/tryit-individual-placement',
    );
  };
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <MainWrapper>
      <OverflowWrapper>
        <InfoWrapper>
          <p className="para-three">
            <i>NOTE:</i>
          </p>
          <p>
            If you have competed in “recognized shows” you may be asked to
            submit a printed copy of your competition/points records along with
            this form. For YEDA purposes, “recognized shows” includes AQHA,
            NRHA, NSBA, APHA, PtHA, ApHC, IBHA, ABRA & POA, USEF, and any other
            association that keeps national records.
          </p>
        </InfoWrapper>
        <ParaWrapper>
          <p className="para-two">Photo Relaease:</p>
        </ParaWrapper>
        <RadioButtonWrapper>
          <p>
            May your likeness in photos taken in photos by our official
            photographers be used in promotional literature for YEDA?
          </p>
          <RoundRadioBtn data={data} onChange={handleReturningSelection} />
        </RadioButtonWrapper>
        <p>
          If no, please enclose a current photo of the rider so that we can
          ensure their likeness is not used.
        </p>
        <CheckboxWrapper>
          <TermsandConditons />
          <CheckBox />
        </CheckboxWrapper>
      </OverflowWrapper>
      {/* <p className="bottom-para">
        Please fill Placement Form in the next page to proceed to payment
      </p> */}
      <ButtonWrapper className="bg-color-change">
        <BackNextButton
          classNameBack="btn-back"
          classNameNxt="btn-nxt"
          onClickNxt={handleSave}
          onClickBack={handleBack}
          next="Save"
          back="Back"
        />
      </ButtonWrapper>
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 155px);
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 20px;

  label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  .bottom-para {
    font-size: 12px;
    z-index: 2;
    position: absolute;
    bottom: -15px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    // padding-top: 20px;
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    // padding-top: 20px;
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    // padding-top: 20px;
    label {
      font-size: 14px;
    }
  }
`;
const OverflowWrapper = styled.div`
  overflow: auto;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
`;

const ParaWrapper = styled.div`
  width: 100%;
  .para-one {
    color: #ffffff !important;
    font-family: 'Roboto';
    font-size: 12px;
    border: none;
    margin: 0px !important;
  }
  .para-two {
    color: #ff9900;
    font-family: 'Roboto';
    font-size: 12px;
    border-bottom: 1px solid #585858;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  .para-three {
    color: #9597a6;
    font-family: 'Roboto';
    font-size: 12px;
  }
`;
const CheckboxWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding-top: 15px;
  span {
    color: #306fdb;
    text-decoration: underline;
  }
`;
const RadioButtonWrapper = styled.div`
  p {
    color: #a7a7a7;
    font-size: 12px;
  }
  .radiobutton {
    gap: 30px;
    display: flex;
    color: white;
  }
  .k-radio {
    background-color: transparent;
    border: 1px solid #ced4da;
  }
  .k-radio:checked,
  .k-radio.k-checked {
    border-color: 1px solid #0d6efd;
    color: white;
    background-color: #0d6efd;
  }
  .k-radio-list {
    flex-flow: row-reverse;
    justify-content: flex-end;
    gap: 50px;
    padding-left: 20px;
  }
  .k-radio-item,
  .k-radio-list-item {
    color: #9597a6;
    gap: 10px;
  }
`;
const InfoWrapper = styled.div`
  width: 100%;
  border: 1px solid #585858;
  border-radius: 7px;
  margin: 10px 0px 20px;
  padding: 20px;
  background: #27292d;
  p {
    font-family: 'Roboto';
    font-size: 12px;
  }
  .para-three {
    color: #ff9900;
    font-size: 12px;
    border-bottom: 1px solid #585858;
  }
  .para-two {
    padding-bottom: 10px;
  }
`;
const ButtonWrapper = styled.div`
  height: 70px;
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: -75px;
    left: -3px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
  }
`;

export default TryitMembershipForm;
