import { GridCellProps } from '@progress/kendo-react-grid';

type Props = {
  e: GridCellProps;
};

export const BackNumberCell = ({ e }: Props) => {
  const data = e.dataItem.backNumber;
  return (
    <td style={{ alignItems: 'center' }}>
      <div>{data}</div>
    </td>
  );
};
