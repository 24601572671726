import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import { RiderRegistrationState } from './types';
import { GeneralLookup, RegisterRider } from 'services/openapi';
import { prevRadioModel, radioModel } from '../components/NotReturningRider';

export const initialState: RiderRegistrationState = {
  category: undefined,
  isReturningRider: false,
  coachId: '',
  newRiderData: {
    id: null,
    parent: {},
    school: {},
    season: {
      id: '',
      name: '',
      code: null,
      children: [],
      otherName: null,
      isAdminLive: false,
    },
    currentGrade: {
      id: '',
      name: '',
      code: null,
      children: [],
      otherName: null,
      isAdminLive: false,
    },
    team: {
      id: '',
      name: '',
      code: null,
      children: [],
      otherName: null,
      isAdminLive: false,
    },
    category: {
      id: '',
      name: '',
      code: null,
      children: [],
      otherName: null,
      isAdminLive: false,
    },
    divisionClassOne: {
      id: '',
      name: '',
      code: null,
      children: [],
      otherName: null,
      isAdminLive: false,
    },
    divisionClassTwo: {
      id: '',
      name: '',
      code: null,
      children: [],
      otherName: null,
      isAdminLive: false,
    },
    placementData: {
      pwhQuestionOne: false,
      owhQuestionOne: false,
      ewhQuestionOne: false,
      rwhQuestionOne: false,
      swhQuestionOne: false,
      swhQuestionTwo: false,
      dwhQuestionOne: false,
      drQuestionOne: false,
      drQuestionTwo: false,
    },
    riderAssociations: [],
    isReturningRider: false,
    backNumber: '',
    firstName: '',
    lastName: '',
    expectedGraduationYear: 0,
    dateOfBirth: '',
    height: 0,
    weight: 0,
    primaryPhone: '',
    email: '',
    street: '',
    city: '',
    state: {
      id: '',
      name: '',
      code: null,
      children: [],
      otherName: null,
      isAdminLive: false,
    },
    zip: '',
    emergencyContactName: null,
    emergencyContactPhone: null,
    hasStayedInSameDivisionForTwoYears: false,
    achievedTopTwoInIndividualNationals: false,
    achievedTopThreeInDivision: false,
    divisionCompliesWithSectionThirty: false,
    isTrial: false,
    usePhotoForPromotion: false,
    riderIdOld: null,
  },
  periviousValue: {
    seasonList: {
      id: '',
      name: '',
      code: null,
      children: null,
      otherName: null,
      isAdminLive: null,
    },
    category: {
      id: '',
      name: '',
      code: null,
      children: null,
      otherName: null,
      isAdminLive: null,
    },
    division: {
      id: '',
      name: '',
      code: null,
      children: null,
      otherName: null,
      isAdminLive: null,
    },
  },
  placementRadioValues: {
    pwhQuestionOne: null,
    owhQuestionOne: null,
    ewhQuestionOne: null,
    rwhQuestionOne: null,
    swhQuestionOne: null,
    swhQuestionTwo: null,
    dwhQuestionOne: null,
    drQuestionOne: null,
    drQuestionTwo: null,
    usePhotoForPromotion: null,
  },
  previousEnrolRadioValues: {
    hasStayedInSameDivisionForTwoYears: null,
    achievedTopTwoInIndividualNationals: null,
    achievedTopThreeInDivision: null,
    divisionCompliesWithSectionThirty: null,
  },
  photoUpload: {
    formData: {
      file: undefined,
    },
  },
};

const slice = createSlice({
  name: 'riderRegistrationState',
  initialState,
  reducers: {
    setCategory(state, action: PayloadAction<GeneralLookup | undefined>) {
      state.category = action.payload;
    },
    setIsReturningRider(state, action: PayloadAction<boolean>) {
      state.isReturningRider = action.payload;
    },
    setCoachId(state, action: PayloadAction<string>) {
      state.coachId = action.payload;
    },
    setNewRiderData(state, action: PayloadAction<RegisterRider>) {
      state.newRiderData = action.payload;
    },
    setPreviousValue(
      state,
      action: PayloadAction<{
        seasonList: GeneralLookup;
        category: GeneralLookup;
        division: GeneralLookup;
      }>,
    ) {
      state.periviousValue = action.payload;
    },
    setPlacementRadioValues(state, action: PayloadAction<radioModel>) {
      state.placementRadioValues = action.payload;
    },
    setPreviousEnrolRadioValues(state, action: PayloadAction<prevRadioModel>) {
      state.previousEnrolRadioValues = action.payload;
    },
    setPhotoUpload(
      state,
      action: PayloadAction<{
        formData?: {
          file?: Blob;
        };
      }>,
    ) {
      state.photoUpload = action.payload;
    },
  },
});

export const { actions: manageAppActions } = slice;

export const useRiderRegistrationStateSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
