import { GridCellProps } from '@progress/kendo-react-grid';
import { AdminProfileInfo } from 'app/components/AdminProfileInfo';
import styled from 'styled-components';

type Props = {
  className?: string;
  e: GridCellProps;
  onClick?: (e) => void;
};

const ShowRidereTeamCell = ({ className, e, onClick }: Props) => {
  const data = e.dataItem;
  return (
    <StyledTd style={e.style} className={e.className}>
      <AdminProfileInfo src={data?.imageUrl} name={data?.team} />
    </StyledTd>
  );
};

export default ShowRidereTeamCell;
const StyledTd = styled.td``;
