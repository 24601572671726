import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import '@fontsource/roboto';
import { FormSecondaryLabel, InfoLabel } from 'app/components/SHLabel';
import { Dropdown } from 'app/components/Dropdown';
import SHdropdown from 'app/components/SHdropdown';
import { TextField } from 'app/components/TextField';
import { BackNextButton } from 'app/components/BackNextButton';
import InfoIconButton from 'app/components/InfoIconButton';
import SHButton from 'app/components/SHButton';
import { CheckBox } from 'app/components/CheckBox';
import RadioButtonGroup from 'app/components/RadioButtonGroup';
import { AlumniNoticeInfo } from 'app/components/AlumniInformation/AlumniNoticeInfo';
import { CoachIsuranceInfoOne } from 'app/components/CoachIsuranceInfo/CoachIsuranceInfoOne';
import { CoachIsuranceInfoTwo } from 'app/components/CoachIsuranceInfo/CoachIsuranceInfoTwo';
import Warning from '../../assets/img/bg/warning.png';
import useApi from 'services/mavapi/useApi';
import {
  ApiError,
  CoachService,
  CreateInsuranceApplication,
  GeneralLookup,
  LookupService,
} from 'services/openapi';
import SimpleReactValidator from 'simple-react-validator';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import useToast from 'hooks/Toast';
import { useManagePageSlice } from 'app/pages/slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectPageLoader } from 'app/pages/slice/selectors';
import LoaderWrapper from 'app/components/CircularLoader/LoaderWrapper';
import CircularLoader from 'app/components/CircularLoader';
import SHLightModeDropDown from 'app/components/SHLightModeDropDown';
import FraudWarningPopUp from 'app/pages/CoachRegistrationPage/components/CoachInsurance/components/FraudWarningPopUp';

type Props = {
  formNumber?: number;
  setFormNumber?: React.Dispatch<React.SetStateAction<number>>;
  setCoachInsuracePayload: React.Dispatch<
    React.SetStateAction<CreateInsuranceApplication>
  >;
  coachInsuracePayload: CreateInsuranceApplication;
  handleNextForm?: (event) => void;
  handleBackForm?: (event) => void;
  selectedCoachId?: string;
  stateData: GeneralLookup[];
  affiliationsData: GeneralLookup[];
  setValid: React.Dispatch<React.SetStateAction<boolean>>;
  valid: boolean;
};

export const CoachInsuranceForm = ({
  formNumber,
  setFormNumber,
  handleNextForm,
  handleBackForm,
  selectedCoachId,
  setCoachInsuracePayload,
  coachInsuracePayload,
  affiliationsData,
  stateData,
  setValid,
  valid,
}: Props) => {
  const currentUserId = Cookies.get('userId');
  const navigate = useNavigate();
  const { error: apiError, isLoading, handleRequest } = useApi();
  const { coachCreateInsuranceApplication } = CoachService;
  const { lookupGetAllLookups } = LookupService;

  const [showFraudWarning, setShowFraudWarning] =
    React.useState<boolean>(false);
  const simpleValidator = React.useRef(new SimpleReactValidator());

  const { coachGetCoach } = CoachService;
  const { actions: PageActions } = useManagePageSlice();
  const pageLoader = useSelector(selectPageLoader);
  const dispatch = useDispatch();
  const [cancelPaymentCookie, setCancelPaymentCookie] = useState(false);
  const getCoachInsuranceDetails = (id: string) => {
    handleRequest(
      coachGetCoach(id).then(res => {
        setCoachInsuracePayload({
          ...coachInsuracePayload,
          firstName: res.firstName ?? '',
          lastName: res.lastName ?? '',
          street: res.street ?? '',
          state: res?.state,
          zip: res.zip ?? '',
          primaryPhone: res.primaryPhone ?? '',
          email: res.email ?? '',
          city: res.city ?? '',
        });
      }),
    );
  };

  const [payerTypes, setPayerTypes] = useState<GeneralLookup>();
  const handleGetLookups = () => {
    handleRequest(lookupGetAllLookups('STATE,TEAM,PAYER_TYPES'))
      .then(res => {
        console.log('res');

        let varPayerTypes = res?.filter(item => {
          if (item?.type === 'PAYER_TYPES') {
            return item.values;
          }
        });
        let payerTypeObj = varPayerTypes?.[0]?.values?.find(item => {
          if (item?.name === 'Coach') {
            return item ?? {};
          }
        });
        console.log(payerTypeObj, 'payerTypeObj');

        setPayerTypes(payerTypeObj);
        let varState = res?.filter(item => {
          if (item?.type === 'STATE') {
            return item.values;
          }
        });
        let varAffiliations = res?.filter(item => {
          if (item?.type === 'TEAM') {
            return item.values?.map(data => {
              return data.name;
            });
          }
        });
        dispatch(PageActions.setPageLoader(false));
        console.log(varState, 'stateTeam');
        console.log(varAffiliations, 'varAffiliations');
      })

      .catch(error => {
        dispatch(PageActions.setPageLoader(false));
        console.log('error');
      });
  };

  const handleAgeRadioBtnChange = e => {
    setCoachInsuracePayload({
      ...coachInsuracePayload,
      isAboveTwentyOne: e?.value,
    });
  };
  const handleClaimRadioBtnChange = e => {
    setCoachInsuracePayload({
      ...coachInsuracePayload,
      hasClaimAgainstActivity: e?.value,
    });
  };
  const handleZipChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 9 && /^[a-zA-Z0-9]*$/.test(inputValue)) {
      setCoachInsuracePayload({
        ...coachInsuracePayload,
        zip: inputValue,
      });
    }
  };
  const handlePhChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
      setCoachInsuracePayload({
        ...coachInsuracePayload,
        primaryPhone: inputValue,
      });
    }
  };
  const [rerenderCount, setRerenderCount] = useState(0);
  if (rerenderCount > 0) {
  } else {
    const paymentCancelStatus = Cookies.get('paymentCancel');
    setCancelPaymentCookie(paymentCancelStatus);
    if (paymentCancelStatus) {
      const coachRegistrationData = Cookies.get('insurancePayload');
      if (coachRegistrationData) {
        const coachRegistrationDataParse = JSON.parse(coachRegistrationData);
        setCoachInsuracePayload(coachRegistrationDataParse);
      }
    }
    setRerenderCount(prevCount => prevCount + 1);
  }
  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(!formValid);
  }, [coachInsuracePayload]);
  useEffect(() => {
    getCoachInsuranceDetails(currentUserId);
  }, []);

  return (
    <>
      {pageLoader ? (
        <LoaderWrapper>
          <CircularLoader />
        </LoaderWrapper>
      ) : (
        <MainWrapper id="PersonalInfoFormMainWrapper">
          <SubMainWrapper className="SubMainWrapper">
            <BorderWrapper className="inner-BorderWrapper">
              <div style={{ paddingBottom: '70px' }}>
                <InfoLabelWrapper id="InfoLabelWrapper">
                  <div>
                    <StyledInfoLabel>
                      Please Mark all professional affiliations
                    </StyledInfoLabel>
                  </div>
                </InfoLabelWrapper>
                <TextFieldGrid>
                  <TextFieldWrapper>
                    <StyledTextField
                      onChange={e =>
                        setCoachInsuracePayload({
                          ...coachInsuracePayload,
                          firstName: e.target.value,
                        })
                      }
                      value={coachInsuracePayload.firstName}
                      label="First Name"
                      type="text"
                      name="firstName"
                      validations={'required|max:30'}
                      validator={simpleValidator.current}
                      inputClassName="lightMode"
                    />
                  </TextFieldWrapper>
                  <TextFieldWrapper>
                    <StyledTextField
                      onChange={e =>
                        setCoachInsuracePayload({
                          ...coachInsuracePayload,
                          lastName: e.target.value,
                        })
                      }
                      value={coachInsuracePayload.lastName}
                      label="Last Name"
                      type="text"
                      name="lastName"
                      validations={'required|max:30'}
                      validator={simpleValidator.current}
                      inputClassName="lightMode"
                    />
                  </TextFieldWrapper>
                  <TextFieldWrapper>
                    <StyledTextField
                      onChange={e => {
                        setCoachInsuracePayload({
                          ...coachInsuracePayload,
                          street: e.target.value,
                        });
                      }}
                      value={coachInsuracePayload.street}
                      label="Street Address (mailing)"
                      type="text"
                      name="Street Address"
                      validations={'required'}
                      validator={simpleValidator.current}
                      inputClassName="lightMode"
                    />
                  </TextFieldWrapper>
                  <TextFieldWrapper>
                    <StyledTextField
                      onChange={e => {
                        setCoachInsuracePayload({
                          ...coachInsuracePayload,
                          city: e.target.value,
                        });
                      }}
                      value={coachInsuracePayload.city}
                      label=" City"
                      type="text"
                      name=" City"
                      validations={'required|max:40'}
                      validator={simpleValidator.current}
                      inputClassName="lightMode"
                    />
                  </TextFieldWrapper>
                  <TextFieldWrapper>
                    <SHLightModeDropDown
                      label="State"
                      onDataChange={data => {
                        if (coachInsuracePayload) {
                          setCoachInsuracePayload({
                            ...coachInsuracePayload,
                            state: data,
                          });
                        }
                      }}
                      defaultValue={coachInsuracePayload.state}
                      data={stateData}
                      validations={'required'}
                      validator={simpleValidator.current}
                    />
                  </TextFieldWrapper>

                  <TextFieldWrapper className="TextFieldWrapper">
                    <StyledTextField
                      onChange={handleZipChange}
                      value={coachInsuracePayload.zip}
                      label="Zip"
                      type="text"
                      name="Zip"
                      validations={'required|min:5|max:9'}
                      validator={simpleValidator.current}
                      inputClassName="lightMode"
                    />
                  </TextFieldWrapper>
                  <TextFieldWrapper className="TextFieldWrapper">
                    <StyledTextField
                      onChange={handlePhChange}
                      value={coachInsuracePayload.primaryPhone}
                      label="Phone Number:"
                      type="tel"
                      name="phoneNumber"
                      validations={'required|alpha_num|numeric|min:10|max:10'}
                      validator={simpleValidator.current}
                      inputClassName="lightMode"
                    />
                  </TextFieldWrapper>
                  <TextFieldWrapper className="TextFieldWrapper">
                    <StyledTextField
                      onChange={e => {
                        setCoachInsuracePayload({
                          ...coachInsuracePayload,
                          email: e.target.value,
                        });
                      }}
                      value={coachInsuracePayload.email}
                      label="Email:"
                      type="email"
                      name="email"
                      validations={'required|email'}
                      validator={simpleValidator.current}
                      maxLength={60}
                      inputClassName="lightMode"
                    />
                  </TextFieldWrapper>
                </TextFieldGrid>
                <QuestionWrapper>
                  <StyledFormSecondaryLabel>
                    Are you over the age of 21?
                  </StyledFormSecondaryLabel>
                  <RadioWrapper>
                    <RadioButtonGroup
                      onChange={handleClaimRadioBtnChange}
                      value={coachInsuracePayload.isAboveTwentyOne}
                      radioButtonTheme="light"
                    />
                  </RadioWrapper>
                </QuestionWrapper>
                <TextFieldGrid>
                  <TextFieldWrapper className="TextFieldWrapper">
                    <StyledSHdropdown
                      onDataChange={data => {
                        if (coachInsuracePayload) {
                          setCoachInsuracePayload({
                            ...coachInsuracePayload,
                            team: data,
                          });
                        }
                      }}
                      defaultValue={coachInsuracePayload.team}
                      data={affiliationsData}
                      label="YEDA Team you are affiliated with"
                      validations={'required'}
                      validator={simpleValidator.current}
                    />
                  </TextFieldWrapper>
                  <TextFieldWrapper className="TextFieldWrapper">
                    <StyledTextField
                      onChange={e => {
                        setCoachInsuracePayload({
                          ...coachInsuracePayload,
                          teamLocation: e.target.value,
                        });
                      }}
                      value={coachInsuracePayload.teamLocation}
                      label="Location of Team"
                      type="text"
                      name="Location of Team"
                      validations={'required'}
                      validator={simpleValidator.current}
                      inputClassName="lightMode"
                    />
                  </TextFieldWrapper>
                  <QuestionWrapper className="QuestionWrapper">
                    <StyledFormSecondaryLabel>
                      Have you had a claim in the last 3 years or an incident
                      that could result in a claim against you for your
                      Professional activities?
                    </StyledFormSecondaryLabel>
                    <RadioWrapper>
                      <RadioButtonGroup
                        onChange={handleAgeRadioBtnChange}
                        value={coachInsuracePayload.hasClaimAgainstActivity}
                        radioButtonTheme="light"
                      />
                    </RadioWrapper>
                  </QuestionWrapper>
                </TextFieldGrid>
                <FraudWrapper>
                  <FraudIcon src={Warning} alt="Warning" />
                  <StyledInfoLabel onClick={() => setShowFraudWarning(true)}>
                    FRAUD WARNING NOTICES
                  </StyledInfoLabel>
                </FraudWrapper>
                <CoachIsuranceInfoTwo />
              </div>
            </BorderWrapper>
            <FraudWarningPopUp
              show={showFraudWarning}
              setShow={setShowFraudWarning}
              themeButton="light"
            />
          </SubMainWrapper>
        </MainWrapper>
      )}
    </>
  );
};
const StyledFormSecondaryLabel = styled(FormSecondaryLabel)`
  @media (min-width: 100px) and (max-width: 319px) {
    font-size: 11px !important;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    font-size: 11px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 12px !important;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 13px !important;
  }
`;
const MainWrapper = styled.div`
  color: #9597a6;
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-top: 50px;
  position: relative;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
  .first-para,
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-top: 40px;
    .first-para,
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    padding-top: 45px;
    .first-para,
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    padding-top: 50px;
    .first-para,
    label {
      font-size: 14px;
    }
  }
`;
const StyledInfoLabel = styled(InfoLabel)`
  @media (min-width: 100px) and (max-width: 319px) {
    font-size: 12px !important;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    font-size: 12px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 13px !important;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 14px !important;
  }
`;

const SubMainWrapper = styled.div`
  /* height: calc(100vh - 130px); */
  width: 100%;
`;
const BorderWrapper = styled.div`
  height: 100%;
  overflow: auto;
  /* border: 1px solid #585858; */
  border-radius: 20px;
  width: 100%;
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0 auto;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: #201e1e !important;
    border-radius: 10px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    padding: 20px 0px;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    padding: 20px 20px 0px 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding: 20px 20px 0px 20px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding: 20px 20px 0px 20px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 20px 20px 0px 20px;
  }
`;
const InfoLabelWrapper = styled.div`
  padding: 0 15px 10px 15px;
  text-align: start;
  width: 100%;
  div {
    border-bottom: 1px solid #434343;
    padding: 6px 0;
  }
`;
const FraudWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  label {
    margin-left: 10px !important;
    text-decoration: underline !important;
    cursor: pointer;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    img {
      height: 18px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    img {
      height: 18px !important;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    img {
      height: 19px !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    img {
      height: 20px !important;
    }
  }
`;
const FraudIcon = styled.img``;
const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  .RadioButtonGroupWrapper {
    margin: 0 !important;
    ul {
      padding: 0 !important;
    }
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .RadioButtonGroupWrapper {
      ul label {
        font-size: 11px !important;
      }
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .RadioButtonGroupWrapper {
      ul label {
        font-size: 11px !important;
      }
    }
  }

  @media (min-width: 576px) and (max-width: 767px) {
    .RadioButtonGroupWrapper {
      ul label {
        font-size: 12px !important;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .RadioButtonGroupWrapper {
      ul label {
        font-size: 13px !important;
      }
    }
  }
`;
const QuestionWrapper = styled.div`
  width: 100%;
  padding: 0 10px 10px 10px;
  /* border-bottom: 1px solid #434343; */
  display: flex;
  flex-direction: column;
  ${FormSecondaryLabel} {
    margin: 0 0 15px 0;
  }
`;
const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 30px 0px;
  align-items: flex-start;

  @media (min-width: 992px) and (max-width: 1199px) {
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
  }
`;

const TextFieldWrapper = styled.div`
  width: 50% !important;
  margin-bottom: 20px;
  &.styled-TextFieldWrapper {
    display: grid;
    align-items: end;
  }

  /* padding: 0px 20px; */
  @media (min-width: 320px) and (max-width: 767.98px) {
    width: 100% !important;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 50% !important;
  }
`;
const StyledSHdropdown = styled(SHLightModeDropDown)`
  @media (min-width: 100px) and (max-width: 319px) {
    .label {
      font-size: 11px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .label {
      font-size: 11px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .label {
      font-size: 12px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .label {
      font-size: 13px;
    }
  }
`;
const StyledTextField = styled(TextField)`
  .lightMode {
    background: #ededed;
    color: #000000;
  }
  flex: 0 0 50%;
  &.styled-field {
    margin-bottom: 0px !important;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .label {
      font-size: 11px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .label {
      font-size: 11px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .label {
      font-size: 12px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .label {
      font-size: 13px;
    }
  }
`;

const BottomRightWrapper = styled.div`
  z-index: 1;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 20px 20px;
  backdrop-filter: blur(10px);
  position: absolute;
  bottom: 0;
  margin-bottom: 5px;
  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    /* position: absolute;
    bottom: 40px;
    padding-top: 30px;
    background: linear-gradient(
      to bottom,
      rgb(48 48 48 / 79%) 0%,
      rgb(42 42 42) 100%
    ); */
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 40px;
    &.bg-color-change {
      bottom: 25px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 45px;
    &.bg-color-change {
      bottom: 30px;
      padding-top: 20px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 60px;
    &.bg-color-change {
      bottom: 20px;
      padding-top: 20px;
    }
  }
`;
const ButtonWrapper = styled.div``;
