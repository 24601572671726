import { GridCellProps } from '@progress/kendo-react-grid';
import { SHToolTip } from 'app/components/SHToolTip';
import styled from 'styled-components';

type Props = {
  classname?: string;
  e: GridCellProps;
};

export const PositionCell = ({ classname, e }: Props) => {
  const data = e.dataItem.sum === 0 ? '' : e?.dataItem;
  const value =
    data.rank === 1
      ? 'Higher Point Rider'
      : data.rank === 2
      ? 'Reserve Rider'
      : '';
  return (
    <td className={classname ?? ''}>
      <SHToolTip position="top">
        <PositionWrapper
          className={`${
            data.rank === 1
              ? 'higher-point-rider'
              : data.rank === 2
              ? 'reserve-rider'
              : ''
          }`}
          title={value}
        >
          {value}
        </PositionWrapper>
      </SHToolTip>
    </td>
  );
};

const PositionWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  min-width: 150px;
  color: #475467;
  padding-left: 8px;
  font-weight: 600;
  display: block;
  text-align: center;
  &.higher-point-rider {
    color: #ff0000;
  }
  &.reserve-rider {
    color: #001bff;
  }
`;
