import AdminDropDown from 'app/components/AdminDropDown';
import React, { useRef, useState } from 'react';
import { GeneralLookup } from 'services/openapi';
import SimpleReactValidator from 'simple-react-validator';
import styled from 'styled-components';

type Props = {
  //   trialData?: GeneralLookup;
};
const AddTeamCouch = (props: Props) => {
  const simpleValidator = useRef(new SimpleReactValidator());
  const trailData = [
    { id: '1', name: 'Option 1' },
    { id: '2', name: 'Option 2' },
    { id: '3', name: 'Option 3' },
    { id: '4', name: 'Option 4' },
    { id: '5', name: 'Option 5' },
  ];
  const [dropDownData, setDropDownData] = useState<GeneralLookup>();
  return (
    <FlexWrapper>
      <Line />
      <AdminDropDown
        className="drop-down popup-dropdown"
        data={trailData}
        validator={simpleValidator.current}
        validations="required"
        onDataChange={data => {
          setDropDownData(data);
        }}
        defaultValue={dropDownData}
        label="Coach Type"
      ></AdminDropDown>
      <AdminDropDown
        className="drop-down"
        data={trailData}
        validator={simpleValidator.current}
        validations="required"
        onDataChange={data => {
          setDropDownData(data);
        }}
        defaultValue={dropDownData}
        label="Coach Name"
      ></AdminDropDown>
    </FlexWrapper>
  );
};

export default AddTeamCouch;

const FlexWrapper = styled.div`
  padding: 10px 10px 15px 10px;
  .drop-down {
    padding-bottom: 15px;
    .label {
      font-size: 12px;
      color: rgb(23, 23, 23);
    }
  }
`;
const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid #eaecf0;
  margin-bottom: 15px;
`;
