import * as React from 'react';
import {
  RadioGroup,
  RadioGroupChangeEvent,
} from '@progress/kendo-react-inputs';
import { Option } from 'app/pages/TryitRiderRegistrationPage';
import { GeneralLookup } from 'services/openapi';
import SimpleReactValidator from 'simple-react-validator';
import styled, { css } from 'styled-components';

type RadioBtnProps = {
  data?: Option[];
  lookupData?: GeneralLookup[];
  onChange: (event: RadioGroupChangeEvent, value: GeneralLookup) => void;
  value?: string | number | null;
  validator?: SimpleReactValidator;
  validations?: string;
  className?: string;
  label?: string;
};
const RoundRadioBtn = ({
  data,
  onChange,
  value,
  lookupData,
  validations,
  validator,
  className,
  label,
}: RadioBtnProps) => {
  const simpleValidator = React.useRef(validator);
  const convertedData = lookupData?.map((item: GeneralLookup) => ({
    id: item.id || '', // Keep the id as a string
    text: item.name || '',
    label: item.name || '',
    value: item.id || undefined,
  }));
  const handleChange = e => {
    var value = e?.value;
    if (lookupData) {
      var valueLookup = lookupData.find(obj => {
        return obj.id === value;
      });
      onChange?.(e, valueLookup!);
    }
  };
  const [allData, setAllData] = React.useState<any>();
  React.useEffect(() => {
    setAllData(convertedData ? convertedData : data);
  }, [lookupData]);
  console.log(convertedData, 'convertedData');
  return (
    <Wrapper
      className={`${
        validator && !simpleValidator?.current?.fieldValid(label!)
          ? 'error'
          : 'default'
      } ${className}`}
    >
      <RadioGroup data={allData} onChange={handleChange} value={value} />
      {simpleValidator?.current?.message(
        label ? label : 'field',
        value,
        validations ?? '',
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0px 20px;
  .srv-validation-message {
    color: #f44336;
    font-size: 12px;
    display: inline-block;
  }
  &.error {
    .k-picker-solid {
      border: 1px solid #f44336 !important;
    }
  }
  &.default {
    .k-picker-solid {
      border: 1px solid #f44336 !important;
    }
  }
  input[type='radio'] {
    background-color: transparent !important;
    width: 20px;
    height: 20px;
    border: 2px solid #707070 !important;
    &:checked {
      background-color: #0d6efd !important;
      accent-color: #0d6efd !important;
    }
    &:focus {
      border-color: #0d6efd;
      box-shadow: none !important;
    }
    /* cursor: pointer !important;
    background-color: transparent !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid #808080;
    border-radius: 50%;
    outline: none;
    -webkit-transition: border-color 0.3s;
    transition: border-color 0.3s; */
  }
  margin: 0px 12px;
`;
export default RoundRadioBtn;
