import React, { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout';

type Props = {
  data: {
    tab: string;
    id: number;
  }[];
  onTabSelect?: (e: TabStripSelectEventArguments) => void;
  tabSelected?: number;
  setTabSelected?: Dispatch<SetStateAction<number>>;
  isLast?: boolean;
};

export const AdminTab = ({
  data,
  onTabSelect,
  tabSelected,
  setTabSelected,
  isLast,
}: Props) => {
  // Handle UserCount Tab Change
  const handleSelect = (e: TabStripSelectEventArguments) => {
    onTabSelect?.(e);
    setTabSelected?.(e.selected);
  };

  return (
    <CustomTabStripWrapper className="customTab-strip-wrapper">
      <CustomTabStrip
        className={`CustomTabStrip user-count-tab ${isLast ? 'color-red' : ''}`}
        selected={tabSelected}
        onSelect={e => handleSelect(e)}
      >
        {data?.map(item => {
          return <TabStripTab title={`${item.tab}`} />;
        })}
      </CustomTabStrip>
    </CustomTabStripWrapper>
  );
};

const CustomTabStripWrapper = styled.div`
  margin: 0 0 25px 0;
  border-bottom: 1px solid #eaecf0;
`;

const CustomTabStrip = styled(TabStrip)`
  &.user-count-tab {
    background: #ffffff;
    .k-content {
      display: none !important;
    }
    &.color-red {
      .k-item.k-last {
        color: #ab0000 !important;
      }
    }

    .k-tabstrip-items-wrapper {
      border-bottom-width: 0px !important;
      .k-tabstrip-items {
        display: inline-flex;
        align-items: center;
        flex-wrap: nowrap;
        overflow: hidden;
        &::-webkit-scrollbar {
          width: 2px;
          height: 3px;
        }
        &::-webkit-scrollbar-track {
          background: transparent !important;
          border-radius: 5px;
          width: 2px;
          height: 2px;
        }
        &::-webkit-scrollbar-thumb {
          width: 2px;
          height: 2px;
          background: #e5e5e5 !important;
          border-radius: 50px;
        }
      }
      .k-item {
        border: 0px !important;
        font-family: 'Inter';
        color: #737373;
        font-weight: 500;
        font-size: 14px;
        margin: 0 14px 0 0 !important;
        border-bottom: 2px solid transparent !important;
        .k-link {
          padding: 8px 25px 14px !important;
        }
        &.k-active {
          color: #4764ff !important;
          background: transparent !important;
          border-radius: 0 !important;
          border-bottom: 3px solid #4764ff !important;
          font-weight: 600;
          font-size: 14px;
          font-family: 'Inter';
        }
      }
    }
  }
`;

export default AdminTab;
