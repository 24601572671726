/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GroupList } from '../models/GroupList';
import type { GroupListWrapper } from '../models/GroupListWrapper';
import type { RiderPointSummary } from '../models/RiderPointSummary';
import type { ShowDetailsReportSummary } from '../models/ShowDetailsReportSummary';
import type { SIFReportSummary } from '../models/SIFReportSummary';
import type { TeamPointWrapper } from '../models/TeamPointWrapper';
import type { TeamRosterSummary } from '../models/TeamRosterSummary';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReportService {

    /**
     * API to get riderpoint report details
     * @param seasonId 
     * @param categoryId 
     * @param divisionId 
     * @param classId 
     * @param pageNumber 
     * @param pageSize 
     * @returns RiderPointSummary Success
     * @throws ApiError
     */
    public static reportGetRiderPointReportDetails(
seasonId: string,
categoryId: string,
divisionId: string,
classId: string,
pageNumber: number = 1,
pageSize: number = 10,
): CancelablePromise<RiderPointSummary> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/reports/riderpoint',
            query: {
                'seasonId': seasonId,
                'categoryId': categoryId,
                'divisionId': divisionId,
                'classId': classId,
                'pageNumber': pageNumber,
                'pageSize': pageSize,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to download riderpoint report
     * @param seasonId 
     * @param categoryId 
     * @param divisionId 
     * @param classId 
     * @returns string Success
     * @throws ApiError
     */
    public static reportGetRiderPointReport(
seasonId: string,
categoryId: string,
divisionId: string,
classId: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/reports/riderpoint/download',
            query: {
                'seasonId': seasonId,
                'categoryId': categoryId,
                'divisionId': divisionId,
                'classId': classId,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to get team roster report details
     * @param seasonId 
     * @param teamId 
     * @param pageNumber 
     * @param pageSize 
     * @returns TeamRosterSummary Success
     * @throws ApiError
     */
    public static reportGetTeamRosterReportDetails(
seasonId?: string,
teamId?: string,
pageNumber: number = 1,
pageSize: number = 10,
): CancelablePromise<TeamRosterSummary> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/reports/teamroster',
            query: {
                'seasonId': seasonId,
                'teamId': teamId,
                'pageNumber': pageNumber,
                'pageSize': pageSize,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to download team roster report
     * @param seasonId 
     * @param teamId 
     * @returns string Success
     * @throws ApiError
     */
    public static reportGetTeamRosterReport(
seasonId?: string,
teamId?: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/reports/teamroster/download',
            query: {
                'seasonId': seasonId,
                'teamId': teamId,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to get team point
     * @param seasonId 
     * @returns TeamPointWrapper Success
     * @throws ApiError
     */
    public static reportGetTeamPointsDetails(
seasonId: string,
): CancelablePromise<TeamPointWrapper> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/reports/teampoint',
            query: {
                'seasonId': seasonId,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to download teampoint report
     * @param seasonId 
     * @returns string Success
     * @throws ApiError
     */
    public static reportGetTeamPointReport(
seasonId?: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/reports/teampoint/download',
            query: {
                'seasonId': seasonId,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API show details report details
     * @param showId 
     * @param pageNumber 
     * @param pageSize 
     * @returns ShowDetailsReportSummary Success
     * @throws ApiError
     */
    public static reportGetShowDetailsReportDetails(
showId?: string,
pageNumber: number = 1,
pageSize: number = 10,
): CancelablePromise<ShowDetailsReportSummary> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/reports/showDetails',
            query: {
                'showId': showId,
                'pageNumber': pageNumber,
                'pageSize': pageSize,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to SIF report
     * @returns SIFReportSummary Success
     * @throws ApiError
     */
    public static reportGetSifReportDetails(): CancelablePromise<SIFReportSummary> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/reports/sif',
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to SIF report download
     * @returns string Success
     * @throws ApiError
     */
    public static reportGetSifReport(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/reports/sif/download',
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API show details download
     * @param showId 
     * @returns string Success
     * @throws ApiError
     */
    public static reportGetShowDetailsReportDownload(
showId?: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/reports/showDetails/download',
            query: {
                'showId': showId,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API get ShowRiderReportGroups list
     * @param showId 
     * @param seasonId 
     * @returns GroupListWrapper Success
     * @throws ApiError
     */
    public static reportGetShowRiderReportGroupList(
showId: string,
seasonId: string,
): CancelablePromise<GroupListWrapper> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/reports/ShowRiderReportGroups/{showId}',
            path: {
                'showId': showId,
            },
            query: {
                'seasonId': seasonId,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API Create ShowRiderReportGroups list
     * @param showId 
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static reportUpdateShowRiderReportGroups(
showId: string,
requestBody: Array<GroupList>,
): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/reports/ShowRiderReportGroups/{showId}',
            path: {
                'showId': showId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

}
