import { GridCellProps } from '@progress/kendo-react-grid';

type Props = {
  className?: string;
  e: GridCellProps;
};

export const RiderNameCell = ({ className, e }: Props) => {
  const data = e.dataItem;
  return <td className={className ?? ''}>{data?.riderName}</td>;
};
