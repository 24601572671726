import { useErrorHandlingSlice } from 'app/error/slice';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useApi from 'services/mavapi/useApi';
import {
  UserListWrapper,
  ManageUserService,
  UserInfo,
  User,
} from 'services/openapi';
import { useNavigateToRoute } from 'utils/common';
const useUserApi = () => {
  const [loading, setLoading] = useState(false);
  const [userViewLoading, setUserViewLoading] = useState(false);
  const [, setError] = useState<unknown | null>();
  const { error: apiError, handleRequest } = useApi();
  const {
    manageUserGetUsers,
    manageUserGetUserInfo,
    manageUserDeleteUsers,
    manageUserCreateOrEditUser,
    manageUserUploadUserImage,
    manageUserEditUser,
  } = ManageUserService;
  const [userProfileData, setUserProfileData] = useState<UserListWrapper>();
  const [userViewData, setUserViewData] = useState<UserInfo>();
  const dispatch = useDispatch();
  const { actions } = useErrorHandlingSlice();
  const { action } = useParams();
  const redirect = useNavigateToRoute();
  const [imageUploadBlobPayload, setImageUploadBlobPayload] =
    useState<Blob | null>(null);
  const [userEditInfo, setUserEditInfo] = useState<User>();
  const fetchManageGetUser = (
    searchInput?: string,
    sortBy?: string,
    sortOrder?: number,
    pageNumber: number = 1,
    pageSize: number = 10,
  ) => {
    setLoading(true);
    let pageNumCall = pageNumber ? pageNumber / 10 + 1 : 1;
    handleRequest(
      manageUserGetUsers(searchInput, sortBy, sortOrder, pageNumCall, pageSize),
    )
      .then(res => {
        if (res) {
          setUserProfileData(res);
        }
        setLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };
  const getUserProfileViewData = (id: string, action?: string) => {
    setUserViewLoading(true);
    handleRequest(manageUserGetUserInfo(id))
      .then(res => {
        if (res) {
          setUserViewData(res);
        }
        setUserViewLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setUserViewLoading(false);
      });
  };
  const deleteUser = (
    id: string,
    searchInput?: string,
    sortBy?: string,
    sortOrder?: number,
    pageNumber: number = 0,
    pageSize: number = 10,
  ) => {
    setLoading(true);
    handleRequest(manageUserDeleteUsers(id))
      .then(res => {
        if (res) {
          dispatch(
            actions.dispatchToast({
              errorTxt: 'User deleted successfully',
              show: true,
              errorType: 'success',
            }),
          );
          fetchManageGetUser(
            searchInput,
            sortBy,
            sortOrder,
            pageNumber,
            pageSize,
          );
        }
        setLoading(false);
      })
      .catch(apiError => {
        setError(apiError);
        setLoading(false);
      });
  };
  const createUser = (requestBody?: User, imageFile?: Blob) => {
    setLoading(true);
    const formData = {
      file: imageFile,
    };
    handleRequest(manageUserCreateOrEditUser(requestBody))
      .then(res => {
        setUserEditInfo(res);
        setLoading(false);
        if (res.code === 'EmailAlreadyInUse') {
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Email is already in use',
              show: true,
              errorType: 'error',
            }),
          );
          setLoading(false);
        } else if (typeof res === 'string') {
          if (imageFile) {
            updateOrCreateUserImageUpload(res ?? '', formData, action);
          } else {
            setLoading(false);
            redirect(`/admin/users/${'view'}/${res}`);
            dispatch(
              actions.dispatchToast({
                errorTxt: 'User Created Successfully',
                show: true,
                errorType: 'success',
              }),
            );
          }
        }
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };
  const updateOrCreateUserImageUpload = (
    id: string,
    formData?: {
      file?: Blob;
    },
    action?: string,
  ) => {
    setLoading(true);
    handleRequest(manageUserUploadUserImage(id, formData))
      .then(res => {
        redirect(`/admin/users/${'view'}/${id}`);
        if (action === 'update') {
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Updated User Image',
              show: true,
              errorType: 'success',
            }),
          );
        }
        if (action === 'create') {
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Added User Image',
              show: true,
              errorType: 'success',
            }),
          );
        }
        setLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };
  const EditUser = (requestBody?: User, imageFile?: Blob) => {
    setLoading(true);
    const formData = {
      file: imageFile,
    };
    handleRequest(manageUserEditUser(requestBody))
      .then(res => {
        setUserEditInfo(res);
        if (res.code === 'EmailAlreadyInUse') {
          setLoading(false);
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Email is already in use',
              show: true,
              errorType: 'error',
            }),
          );
        } else if (typeof res === 'string') {
          if (imageFile) {
            updateOrCreateUserImageUpload(res ?? '', formData, action);
          } else {
            redirect(`/admin/users/${'view'}/${res}`);
            dispatch(
              actions.dispatchToast({
                errorTxt: 'User Updated Successfully',
                show: true,
                errorType: 'success',
              }),
            );
          }
        }
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };
  return {
    loading,
    fetchManageGetUser,
    userProfileData,
    userViewData,
    getUserProfileViewData,
    deleteUser,
    createUser,
    setImageUploadBlobPayload,
    imageUploadBlobPayload,
    EditUser,
    userEditInfo,
    userViewLoading,
  };
};
export default useUserApi;
