import { LeaderPointTeam, ShowScoreBoardTeam } from 'services/openapi';

export const defaultAddToShowData = {
  showId: { id: '', name: '' },
  divisionId: { id: '', name: '' },
  categoryId: { id: '', name: '' },
  classFilter: { id: '', name: '' },
};
export const deafultGeneralLookupData = {
  id: '',
  name: '',
};

export const teamHeaderObject = {
  id: '',
  name: 'Team',
  code: 'TEAM',
  value: null,
};
export const totalHeaderObject = {
  id: '',
  name: 'Total',
  code: 'TOTAL_POINT',
  value: null,
};
export const finalPlacingObject = {
  id: '',
  name: 'Final Placings',
  code: 'FINAL_PLACING',
  value: null,
};

export const updateData = (data: ShowScoreBoardTeam[]) => {
  return data?.map(team => {
    const classesObject = {};
    team?.classes?.forEach(clss => {
      classesObject[clss?.code!] = clss?.value;
    });
    return {
      ...team,
      ...classesObject,
    };
  });
};

// leader point data convertion function
export const leaderPointDataConvertion = data => {
  const updatedData = updateDataLeaderPoint(data!);
  const updatedteamDataData = updatedData?.map(item => {
    const { classes, ...rest } = item;
    return rest;
  });
  return updatedteamDataData;
};
const updateDataLeaderPoint = (data: LeaderPointTeam[]) => {
  return data?.map(team => {
    const classesObject = {};
    team?.classes?.forEach(clss => {
      let fieldName1 = `${clss?.code}_BACKNO`;
      let fieldName2 = `${clss?.code}_NAME`;
      classesObject[fieldName1] = clss?.riderBackNo;
      classesObject[fieldName2] = clss?.riderName;
    });
    return {
      ...team,
      ...classesObject,
    };
  });
};
