import React from 'react';
import styled from 'styled-components';
import '@fontsource/oswald';
import '@fontsource/roboto';
import { BackNextButton } from 'app/components/BackNextButton';
import { useNavigate } from 'react-router-dom';
// import BackNumberTableComponent from 'app/components/BackNumberListComponent';
import TeamListPopUp from 'app/components/TeamListPopUp';
export type Option = {
  label?: string;
  value?: string;
};

const TryitRiderRegistrationPage = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = React.useState<boolean>(false);

  const handleNxtBack = () => {
    navigate('/login');
  };

  const handleNxt = () => {
    navigate('/tryit-rider-registration-page/new-member');
  };

  const handleInfoIcon = () => {
    setVisible(!visible);
  };

  return (
    <MainWrapper className="form-MainWrapper">
      <OverflowWrapper>
        <ContentWrapper>
          <p className="para">
            Kindly check the list of Teams which have renewed memberships for
            the year till now. If you do not find the team you wish to be a part
            of, check back later.
          </p>
          <h4 onClick={handleInfoIcon}>Team list 2023-24</h4>
          {visible && <TeamListPopUp onClose={handleInfoIcon} />}
          <p className="para-one ">
            <i>Try it Membership is $20</i>
          </p>
        </ContentWrapper>
        <ButtonWrapper className="bg-color-change">
          <BackNextButton
            classNameBack="btn-back"
            classNameNxt="btn-nxt"
            onClickNxt={handleNxt}
            onClickBack={handleNxtBack}
            next="Next"
            back="Back"
          />
        </ButtonWrapper>
      </OverflowWrapper>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 60px;
  background: #2a2a2a;
  position: relative;
  p {
    margin: 0px;
  }
  .para {
    color: #ff9900;
    padding: 60px 0px 10px;
  }
  .para-one {
    color: #ff9900;
    padding: 20px 0px 10px;
    font-family: 'Roboto';
    font-size: 14px;
    border-bottom: 1px solid #585858;
  }
  h4 {
    color: #306fdb;
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    padding: 0 5px;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    padding: 0 10px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding: 0 20px;
  }
`;
const OverflowWrapper = styled.div`
  overflow: auto;
  height: calc(100vh - 85px);
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 5px;
    background: transparent !important;
    border-radius: 10px;
  }
`;
const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0px 100px;
`;

const ButtonWrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;
  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    backdrop-filter: blur(5px);
  }
  .blue {
    background-color: #306fdb;
    color: white;
  }
  .grey {
    background-color: #595959;
    color: white;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
  }
`;

export default TryitRiderRegistrationPage;
