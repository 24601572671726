import React from 'react';
import {
  Card,
  CardTitle,
  CardBody,
  CardActions,
} from '@progress/kendo-react-layout';
import tag from './assets/img/icon/tag.png';
import styled from 'styled-components';

type Props = {
  paraTitle?: string;
  img?: string;
  title?: string;
  phNo?: string;
  mailId?: string;
  titleFullform?: string;
  bgColor?: 'one' | 'two' | 'three';
  titleType?: 'show' | 'hidden';
  className?: string;
};

const SHCard = ({
  paraTitle,
  img,
  title,
  phNo,
  mailId,
  titleFullform,
  bgColor,
  titleType,
  className,
}: Props) => {
  return (
    <MainWrapper className={className}>
      <TagWrapper>
        <img src={tag} />
        <p className="para-title">{paraTitle}</p>
      </TagWrapper>
      <Card
        // style={{ width: 220, height: 270 }}
        className={`card-wrapper ${bgColor}`}
      >
        <StyledCardBody>
          <img src={img} />
          <h4>{title}</h4>
          <CardTitle className={`title ${titleType}`}>
            {titleFullform}
          </CardTitle>
          <p>{phNo}</p>
          <p>{mailId}</p>
        </StyledCardBody>
      </Card>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  position: relative;
  width: 33%;
  height: 100%;
  .card-wrapper {
    border-radius: 25px;
    height: 100%;
    // width: 190px;
    // height: 200px;

    &.one {
      background-color: #fff8f5;
    }
    &.two {
      background-color: #f7f8ff;
    }
    &.three {
      background-color: #edf7fd;
    }
  }
  .title {
    &.show {
      display: block;
    }
    &.hidden {
      display: none;
    }
  }
  .k-card-title {
    font-size: 11px;
  }
`;
const StyledCardBody = styled(CardBody)`
  padding: 40px 10px 10px;
  text-align: center;
  height: 100%;

  p {
    margin: 0px;
    font-size: 9px;
  }
  h4 {
    font-size: 13px;
    margin: 0px;
  }
  img {
    padding-bottom: 5px;
    width: 45px;
  }
  @media (min-width: 100px) and (max-width: 768px) {
    img {
      padding-bottom: 0px;
      width: 45px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    p {
      margin: 0px;
      font-size: 9px;
    }
    h4 {
      font-size: 13px;
      margin: 0px;
    }
    img {
      padding-bottom: 5px;
      width: 45px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    p {
      font-size: 8px;
    }
    h4 {
      font-size: 11px;
    }
    img {
      padding-bottom: 5px;
      width: 50px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    p {
      font-size: 9px;
    }
    h4 {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    p {
      font-size: 9px;
    }
    h4 {
      font-size: 13px;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    p {
      font-size: 9px;
    }
    h4 {
      font-size: 13px;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    p {
      font-size: 9px;
    }
    h4 {
      font-size: 13px;
    }
  }
`;
const TagWrapper = styled.div`
  position: absolute;
  z-index: 1;
  left: -13px;
  top: 10px;
  .para-title {
    position: absolute;
    top: -10px;
    left: 20px;
    font-size: 11px;
  }
  img {
    width: 100px;
    height: auto;
  }
`;
export default SHCard;
