import styled from 'styled-components';
interface svgProps {
  color?: string;
  width?: string;
  height?: string;
  strokeWidth?: string;
  stroke?: string;
  className?: string;
}
function CloseIcon({ color, width, height, className }: svgProps) {
  return (
    <SvgWrapper
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : '11.37'}
      height={height ? height : '11.371'}
      viewBox="0 0 11.37 11.371"
    >
      <g id="noun-close-288100" transform="translate(19.84 -889.158)">
        <path
          id="Path_510"
          data-name="Path 510"
          d="M204.311,123.464a.91.91,0,0,0-1.286,0l-4.133,4.133-4.132-4.133a.91.91,0,0,0-1.286,1.286l4.132,4.133-4.132,4.132a.91.91,0,0,0,1.286,1.287l4.132-4.133,4.133,4.133a.91.91,0,0,0,1.286-1.287l-4.133-4.132,4.133-4.133a.91.91,0,0,0,0-1.286"
          transform="translate(-213.047 765.96)"
          fill={color ? color : '#a3a3a3'}
          fillRule="evenodd"
        />
      </g>
    </SvgWrapper>
  );
}
const SvgWrapper = styled('svg')({});
export default CloseIcon;
