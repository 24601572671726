import React from 'react';
import styled from 'styled-components';

type Props = {
  title?: string;
  totalTryitTeams?: number;
  totalScratch?: number;
  totalRiders?: number;
  totalRides?: number;
  totalTryitRides?: number;
};

const AdminShowRiderCard = ({
  title,
  totalTryitTeams,
  totalScratch,
  totalRiders,
  totalTryitRides,
  totalRides,
}: Props) => {
  return (
    <Wrapper>
      <div className="card-wrapper">
        <div className="card-title">{title}</div>
        <div className="stats-container">
          <div className="stats-row-1">
            <div className="stats-item-1">
              Total Tryit Teams:<span> {totalTryitTeams}</span>
            </div>
            <div className="stats-item-1">
              Total Scratch:<span> {totalScratch?.toString()}</span>
            </div>
          </div>
          <div className="stats-row-2">
            <div className="stats-item-2">
              Total Riders:<span> {totalRiders}</span>
            </div>
            <div className="stats-item-2">
              Total Rides: <span>{totalRides}</span>
            </div>
            <div className="stats-item-2">
              Total Tryit Rides: <span>{totalTryitRides}</span>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default AdminShowRiderCard;

const Wrapper = styled.div`
  .card-wrapper {
    display: flex;
    justify-content: space-between;
    justify-content: space-between;
    border: 1px solid #e7ecff;
    border-radius: 13px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #e7ecff;
    width: 100%;
    justify-content: space-between;
  }

  .card-title {
    font-size: 15px;
    font-weight: 500;
    font-family: 'Inter';
    color: #000000;
    width: 60%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0;
    display: block;
    align-content: center;
    @media (min-width: 100px) and (max-width: 1366px) {
      width: 35%;
    }
    @media (min-width: 1367px) and (max-width: 1550px) {
      width: 50%;
    }
  }

  .stats-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 40%;
    padding: 0px;
    @media (min-width: 100px) and (max-width: 1366px) {
      width: 65%;
    }
    @media (min-width: 1367px) and (max-width: 1550px) {
      width: 50%;
    }
  }

  .stats-row-1 {
    display: flex;
    justify-content: flex-end;
    > .stats-item-1 {
      padding-right: 10px !important;
    }
  }

  .stats-row-2 {
    display: flex;
    justify-content: flex-end;
  }

  .stats-item-1 {
    font-size: 15px;
    font-family: 'Inter';
    padding: 5px;
    color: #475467;
    text-align: end;
    span {
      display: inline-block;
      max-width: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: bottom;
    }
  }
  .stats-item-2 {
    font-size: 13px;
    font-family: 'Inter';
    padding: 5px;
    color: #475467;
    text-align: end;
    span {
      display: inline-block;
      max-width: 45px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: bottom;
    }
    @media (min-width: 100px) and (max-width: 1200px) {
      padding: 3px;
      span {
        max-width: 36px;
      }
    }
  }
`;
