import { useState } from 'react';
import useApi from 'services/mavapi/useApi';
import { ReportService, SIFReportSummary } from 'services/openapi';

const useSIFReportsApi = () => {
  const [, setError] = useState<unknown | null>();
  const { error: apiError, handleRequest } = useApi();
  const [loading, setLoading] = useState(false);
  const { reportGetSifReportDetails, reportGetSifReport } = ReportService;

  // States
  const [SIFData, setSIFData] = useState<SIFReportSummary>();

  const getSIFDetails = () => {
    setLoading(true);
    handleRequest(reportGetSifReportDetails())
      .then(res => {
        setSIFData(res);
        setLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };

  const downloadSIFReport = (): Promise<string> => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      handleRequest(reportGetSifReport())
        .then(res => {
          resolve(res);
          setLoading(false);
        })
        .catch(() => {
          reject(apiError);
          setLoading(false);
        });
    });
  };

  return {
    loading,
    getSIFDetails,
    SIFData,
    downloadSIFReport,
  };
};

export default useSIFReportsApi;
