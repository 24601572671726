import styled from 'styled-components';

type Props = {
  className?: string;
  title: string;
};

const LeaderPointTeamHeaderCell = ({ className, title }: Props) => {
  return (
    <CustomLabelWrapper className={className ?? ''}>
      <div className="label-wrapper">{title}</div>
    </CustomLabelWrapper>
  );
};

export default LeaderPointTeamHeaderCell;
const CustomLabelWrapper = styled.th`
  .label-wrapper {
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;
    color: #475467;
    @media (min-width: 100px) and (max-width: 1400px) {
      font-size: 10px;
    }
  }
`;
