import { useManagePageSlice } from 'app/pages/slice';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useApi from 'services/mavapi/useApi';
import {
  RiderService,
  GeneralRider,
  RegisterRider,
  LookupService,
  GeneralLookup,
} from 'services/openapi';
export const useRiderRegistrationApi = () => {
  const dispatch = useDispatch();
  const { actions: PageActions } = useManagePageSlice();
  const { lookupGetActiveTeamByCategory } = LookupService;
  const [loading, setLoading] = useState(false);
  const [, setError] = useState<unknown | null>();
  const { error: apiError, handleRequest } = useApi();
  const { riderSearchRiders, riderGetRider, riderGetBackNumberList } =
    RiderService;
  const [riderSearchList, setRiderSearchList] = useState<Array<GeneralRider>>();
  const [riderInfo, setRiderInfo] = useState<RegisterRider>();
  const [riderBackNumberList, setRiderBackNumberList] =
    useState<Array<string>>();
  const [teamLookup, setTeamLookup] = useState<GeneralLookup[]>();

  const getRidersList = (
    seasonId?: string,
    backNumber?: string,
    name?: string,
    categoryId?: string,
    divisionId?: string,
    teamId?: string,
  ) => {
    setLoading(true);
    handleRequest(
      riderSearchRiders(
        seasonId,
        backNumber,
        name,
        categoryId,
        divisionId,
        teamId,
      ),
    )
      .then(res => {
        if (res) {
          setRiderSearchList(res);
        }
        setLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };

  const getRiderInfo = (riderId?: string, seasonId?: string) => {
    setLoading(true);
    handleRequest(riderGetRider(riderId, seasonId))
      .then(res => {
        if (res) {
          setRiderInfo(res);
        }
        setLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };

  const getRiderBackNumberList = () => {
    setLoading(true);
    handleRequest(riderGetBackNumberList())
      .then(res => {
        if (res) {
          setRiderBackNumberList(res);
        }
        setLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };
  const getAllLookupByCategory = (categoryId: string) => {
    handleRequest(lookupGetActiveTeamByCategory(categoryId))
      .then(data => {
        if (data) {
          setTeamLookup(data);
        }
      })
      .catch(() => {
        setError(apiError);
      });
  };

  return {
    loading,
    getRidersList,
    riderSearchList,
    getRiderInfo,
    riderInfo,
    getRiderBackNumberList,
    riderBackNumberList,
    getAllLookupByCategory,
    teamLookup,
  };
};
