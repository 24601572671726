import { defaultLookup } from 'admin/pages/RiderIndividual/components/RiderCreatePage/staticDatas';
import { GeneralLookup } from 'services/openapi';

export const breadCrumpitemsUser = [
  {
    id: '',
    text: 'Showyeda admin',
    disabled: true,
  },
  {
    id: '/admin/shows',
    text: 'Shows',
  },
  {
    id: 'navigateBack',
    text: 'Shows Info',
  },
  {
    id: '',
    text: 'Add Rider',
    disabled: true,
  },
];

export const defaulShowstRiderValue: ShowRiderWrapper = {
  id: '',
  team: defaultLookup,
  teamImageUrl: 'string',
  rider: defaultLookup,
  riderImageUrl: 'string',
  height: '5',
  weight: '75',
  grade: '5',
  backNumber: 'qwerty',
  state: 'State A',
  class: 'Reining',
  category: defaultLookup,
  divisionClassOne: defaultLookup,
};

export type ShowRiderWrapper = {
  id: string;
  team: GeneralLookup;
  teamImageUrl: string;
  rider: GeneralLookup;
  riderImageUrl: string;
  height: string;
  weight: string;
  grade: string;
  backNumber: string;
  state: string;
  class: string;
  category: GeneralLookup;
  divisionClassOne: GeneralLookup;
};

export const userTypeCustomData = [
  { id: 0, text: 'Data Entry User' },
  { id: 1, text: 'Super Admin' },
  { id: 2, text: 'Team User' },
];
