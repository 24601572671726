export const breadCrumpitemsUser = [
  {
    id: '',
    text: 'Showyeda admin',
    disabled: true,
  },
  {
    id: '/admin/settings/profile-settings',
    text: 'profile-settings',
  },
  {
    id: '',
    text: 'Profile Info',
    disabled: true,
  },
];

export const userProfileDefaultData = {
  id: null,
  firstName: '',
  lastName: '',
  userName: '',
  email: '',
  phoneNumber: '',
  imageUrl: '',
  street: '',
  city: '',
  state: {
    id: '',
    name: '',
  },
  zip: '',
};