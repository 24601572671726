import { BackNextButton } from 'app/components/BackNextButton';
import { TextField } from 'app/components/TextField';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import SHdropdown, { DropDownModel } from 'app/components/SHdropdown';
import {
  ApiError,
  GeneralLookup,
  GeneralLookupWrapper,
  GeneralRider,
  LookupService,
  NonCompeting,
  RiderService,
} from 'services/openapi';
import useApi from 'services/mavapi/useApi';
import { useDispatch, useSelector } from 'react-redux';
import SearchButton from 'app/components/SearchButton';
import Cookies from 'js-cookie';
import SimpleReactValidator from 'simple-react-validator';
import CircularLoader from 'app/components/CircularLoader';
import { useManagePageSlice } from 'app/pages/slice';
import { selectPageLoader } from 'app/pages/slice/selectors';
import RiderTable from 'app/pages/RiderRegistrationPage/components/ReturningRider/components/RiderTable';
import { NonCompetingRiderInformation } from '../NonCompetingRiderInformation';
import { selectPhotoUpload } from 'app/pages/RiderRegistrationPage/slice/selectors';
import { useRiderRegistrationStateSlice } from 'app/pages/RiderRegistrationPage/slice';
import isApiError from 'utils/isApiError';
import { useErrorHandlingSlice } from 'app/error/slice';

type Props = {};
const defaultValue: NonCompeting = {
  firstName: '',
  lastName: '',
  primaryPhone: '',
  email: '',
  street: '',
  city: '',
  state: {
    id: '',
    name: '',
    children: null,
  },
  zip: '',
  emergencyContactName: '',
  emergencyContactPhone: '',
  isReturningRider: true,
};
const defaultPhotoValue = {
  formData: {
    file: undefined,
  },
};

const NonCompetingReturningRider = (props: Props) => {
  const searchedBy: DropDownModel[] = [
    // { id: 1, text: 'Team/Category/Division' },
    { id: 2, text: 'Back Number' },
    { id: 3, text: 'Name' },
  ];
  const dispatch = useDispatch();

  const [nonCompetingpayload, setNonCompetingpayload] =
    useState<NonCompeting>(defaultValue);
  const navigate = useNavigate();
  const [lookUps, setLookUps] = React.useState<Array<GeneralLookupWrapper>>([]);
  const { error: apiError, handleRequest } = useApi();
  const { actions } = useErrorHandlingSlice();
  const [, setError] = React.useState<ApiError | unknown>();
  const { lookupGetAllLookups } = LookupService;
  const {
    riderSearchRiders,
    riderCreateNonCompeting,
    riderGetRider,
    riderUploadRiderPhoto,
  } = RiderService;
  const { actions: manageAppActions } = useRiderRegistrationStateSlice();
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [backNumber, setBackNumber] = useState('');
  const [riderTableData, setRidertableData] = useState<Array<GeneralRider>>();

  const [showLoader, setShowLoader] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [riderId, setRiderId] = useState<string | number | null | undefined>();
  const [valid, setValid] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(true);
  const [stateData, setStateData] = useState<GeneralLookup[]>([]);
  const { actions: PageActions } = useManagePageSlice();
  const pageLoader = useSelector(selectPageLoader);
  useState<GeneralLookup[]>();
  const [nameValue, setNameChange] = useState('');
  const [cancelPaymentCookie, setCancelPaymentCookie] = useState<boolean>();
  const [categoryDatas, setCategoryDatas] = useState<GeneralLookup[]>();
  const [divisionDatas, setDivisionDatas] = useState<GeneralLookup[]>();
  const [categoryDefaultDatas, setCategoryDefaultDatas] =
    useState<GeneralLookup[]>();
  const [seasonId, setSeasonId] = useState<string>();
  const [payerTypes, setPayerTypes] = useState<GeneralLookup>();
  const [isBackNumberCheck, setIsBackNumberCheck] = useState<boolean | null>(
    null,
  );
  const [photoUploadPayload, setPhotoUploadPayload] = useState<{
    formData?: {
      file?: Blob;
    };
  }>(defaultPhotoValue);
  const [fileAvailability, setFileAvailability] = React.useState<boolean>(true);
  const [uploadFile, setUploadFile] = useState<File | null>();
  const photoUploadDataRedux = useSelector(selectPhotoUpload);

  function getAllLookupData(names?: string) {
    dispatch(PageActions.setPageLoader(true));
    handleRequest(lookupGetAllLookups(names))
      .then(data => {
        if (data) {
          setLookUps(data);
          let varState = data?.filter(item => {
            if (item?.type === 'STATE') {
              return item.values;
            }
          });
          data
            .find(obj => {
              return obj.type === 'SEASON';
            })
            ?.values?.find(item => {
              if (item.isAdminLive === true) {
                return setSeasonId(item?.id!);
              }
            });
          data
            .find(obj => {
              return obj.type === 'SEASON';
            })
            ?.values?.find(item => {
              if (item.isAdminLive === true) {
                return setCategoryDefaultDatas(item?.children!);
              }
            });
          let varPayerTypes = data?.filter(item => {
            if (item?.type === 'PAYER_TYPES') {
              return item.values;
            }
          });
          let payerTypeObj = varPayerTypes?.[0]?.values?.find(item => {
            if (item?.name === 'Rider') {
              return item ?? {};
            }
          });

          setPayerTypes(payerTypeObj);
          setStateData(varState?.[0]?.values!);
          dispatch(PageActions.setPageLoader(false));
        }
      })
      .catch(() => {
        setError(apiError);
        dispatch(PageActions.setPageLoader(false));
      });
  }

  function getOldRiderDetails(id) {
    const seasonIdPayload = seasonValue?.id! ? seasonValue?.id! : seasonId!;
    handleRequest(riderGetRider(id, seasonIdPayload))
      .then(res => {
        if (res) {
          setNonCompetingpayload({
            ...nonCompetingpayload,
            firstName: res?.firstName!,
            lastName: res?.lastName!,
            primaryPhone: res?.primaryPhone?.replaceAll('-', '')!,
            email: res?.email!,
            street: res?.street!,
            city: res?.city!,
            state: res.state!,
            zip: res?.zip!,
            emergencyContactName: '',
            emergencyContactPhone: '',
            isReturningRider: true,
          });
          setShowInfo(!showInfo);
        }
      })
      .catch(() => setError(apiError));
  }

  const handleClick = (
    seasonId: string,
    backNumber: string,
    riderName: string,
    categoryId: string,
    divisionId: string,
    teamId: string,
  ) => {
    setShowLoader(true);
    handleRequest(
      riderSearchRiders(
        seasonId,
        backNumber,
        riderName,
        categoryId,
        divisionId,
        teamId,
      ),
    )
      .then(res => {
        if (res) {
          setRidertableData(res);
          setShowTable(true);
        }

        setShowLoader(false);
      })
      .catch(() => setError(apiError));
  };
  const handleBack = () => {
    setShowTable(false);
  };
  const handleNxt = () => {
    getOldRiderDetails(riderId);
    Cookies.set('riderId', riderId);
  };
  const [checkedMap, setCheckedMap] = React.useState(null);
  const handleCheckboxClick = riderId => {
    if (isChecked === riderId) {
      setCheckedMap(null);
    } else {
      setCheckedMap(riderId);
      setIsChecked(false);
    }
    setRiderId(riderId);
  };

  const handleSubmit = () => {
    dispatch(PageActions.setPageLoader(true));
    handleRequest(riderCreateNonCompeting(nonCompetingpayload))
      .then(res => {
        if (res && !isApiError(res)) {
          if (cancelPaymentCookie) {
            Cookies.remove('paymentCancel');
          }
          if (photoUploadPayload.formData?.file !== undefined) {
            handlePhotoUpload(res.toString(), photoUploadPayload.formData);
          }
          const nonCompetingDataString = JSON.stringify(nonCompetingpayload);
          Cookies.set('payload', nonCompetingDataString);
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Submitted successfully',
              show: true,
              errorType: 'success',
            }),
          );
          navigate(`/payment/${payerTypes?.id}/${res}/false/false`);
          dispatch(manageAppActions.setPhotoUpload(photoUploadPayload));
        } else {
          dispatch(
            actions.dispatchToast({
              errorTxt: res.description,
              show: true,
              errorType: 'error',
            }),
          );
        }
        dispatch(PageActions.setPageLoader(false));
      })
      .catch(error => {
        dispatch(
          actions.dispatchToast({
            errorTxt: error.description,
            show: true,
            errorType: 'error',
          }),
        );
        dispatch(PageActions.setPageLoader(false));
      });
  };
  const handlePhotoUpload = (
    riderId: string,
    formData?: {
      file?: Blob;
    },
  ) => {
    handleRequest(riderUploadRiderPhoto(riderId, formData))
      .then(res => {})
      .catch(error => {});
  };

  const handleBackNumberChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 4 && /^\d*$/.test(inputValue)) {
      setBackNumber(inputValue);
      if (inputValue === '') {
        setValid(true);
      } else {
        setValid(false);
      }
    }
    if (inputValue.length >= 3) {
      setIsBackNumberCheck(false);
    } else {
      setIsBackNumberCheck(true);
    }
  };
  const [lookUpShow, setLookUpShow] = useState(false);
  const [backNumberShow, setBackNumberShow] = useState(true);
  const [nameShow, setNameShow] = useState(false);
  const [defaultDropDownValue, setDefaultDropDownValue] =
    useState<DropDownModel>({ id: 2, text: 'Back Number' });
  const [isName, setIsName] = useState(false);
  const [isTeam, setIsTeam] = useState(true);
  const [isCategory, setIsCategoty] = useState(true);
  const [isDivision, setIsDivision] = useState(true);
  const selectSreachedby = e => {
    setIsChecked(true);
    setDefaultDropDownValue(e);
    setIsTeam(false);
    setIsCategoty(false);
    setIsDivision(false);
    setShowTable(false);
    if (e.id === 1) {
      setLookUpShow(true);
      setBackNumberShow(false);
      setNameShow(false);
      setValid(false);
      setIsName(false);
    } else if (e.id === 2) {
      setBackNumberShow(true);
      setLookUpShow(false);
      setNameShow(false);
      setValid(true);
      setIsName(false);
    } else {
      setNameShow(true);
      setLookUpShow(false);
      setBackNumberShow(false);
      setValid(false);
      setIsName(true);
    }
  };

  const [seasonValue, setSeasonValue] = useState<GeneralLookup>();
  const [teamValue, setTeamValue] = useState<GeneralLookup>();
  const [categoryValue, setCategoryValue] = useState<GeneralLookup>();
  const [categoryDefaultValue, setCategoryDefaultValue] =
    useState<GeneralLookup>();
  const [divisionValue, setDivisionChange] = useState<GeneralLookup>();
  const [isTermsChecked, setIsTermsChecked] = useState<boolean>(false);

  const seasonChange = e => {
    setSeasonValue(e);
    setCategoryDatas(e.children);
  };
  const teamChangeData = e => {
    setTeamValue(e);
    setIsTeam(false);
    setIsCategoty(false);
    setIsDivision(false);
  };
  const onDivisionChange = e => {
    setDivisionChange(e);
    setIsTeam(false);
    setIsCategoty(false);
    setIsDivision(false);
  };
  const onCategoryChange = e => {
    setCategoryValue(e);
    setCategoryDefaultValue(e);
    setDivisionDatas(e.children);
    setIsTeam(false);
    setIsCategoty(false);
    setIsDivision(false);
  };

  React.useEffect(() => {
    getAllLookupData('DIVISION,CATEGORY,TEAM,STATE,SEASON,PAYER_TYPES');
    // handleGetTeamLookup();
    const paymentCancelStatus = Cookies.get('paymentCancel');
    setCancelPaymentCookie(paymentCancelStatus);
    const riderDataCookie = Cookies.get('payload');
    if (riderDataCookie) {
      setShowInfo(true);
      const updatedValue = JSON.parse(riderDataCookie);
      setNonCompetingpayload(updatedValue);
      setIsTermsChecked(!isTermsChecked);
      setPhotoUploadPayload(photoUploadDataRedux ?? {});
      setUploadFile(photoUploadDataRedux?.formData?.file as File);
      setIsTermsChecked(!isTermsChecked);
    }
  }, []);

  return (
    <>
      {pageLoader ? (
        <LoaderWrapper>
          <CircularLoader />
        </LoaderWrapper>
      ) : (
        <MainWrapper className="page-content-wrapper">
          {showInfo ? (
            <NonCompetingRiderInformation
              stateData={stateData}
              nonCompetingpayload={nonCompetingpayload}
              setNonCompetingpayload={setNonCompetingpayload}
              setValid={setValid}
              valid={valid}
              setIsTermsChecked={setIsTermsChecked}
              isTermsChecked={isTermsChecked}
              handleSubmit={handleSubmit}
              setPhotoUploadPayload={setPhotoUploadPayload}
              photoUploadPayload={photoUploadPayload}
              setFileAvailability={setFileAvailability}
              fileAvailability={fileAvailability}
              setUploadFile={setUploadFile}
              uploadFile={uploadFile}
            />
          ) : (
            <>
              {showLoader ? (
                <LoaderWrapper>
                  <CircularLoader showText={true} />
                </LoaderWrapper>
              ) : (
                <FormMainWrapper className="form-MainWrapper">
                  <p className="Team-Heading">Returning Member</p>
                  <p className="para-one">
                    <i>
                      Please enter the data from your previous year to retrieve
                      your account.
                    </i>
                  </p>
                  <OverflowWrapper className="OverflowWrapper">
                    <TextFieldGrid className="TextFieldGrid">
                      <TextFieldWrapper>
                        <SHdropdown
                          className="drop-down"
                          label="Season"
                          data={
                            lookUps.find(obj => {
                              return obj.type === 'SEASON';
                            })?.values ?? []
                          }
                          defaultValue={
                            seasonValue
                              ? seasonValue
                              : lookUps
                                  .find(obj => {
                                    return obj.type === 'SEASON';
                                  })
                                  ?.values?.find(
                                    item => item.isAdminLive === true,
                                  ) ?? undefined
                          }
                          onDataChange={event => {
                            seasonChange(event);
                          }}
                        />
                      </TextFieldWrapper>
                      <TextFieldWrapper>
                        <SHdropdown
                          className="drop-down"
                          label="Searched by"
                          genericData={searchedBy}
                          genericDefaultValue={defaultDropDownValue}
                          onGeneralDataChange={event => {
                            selectSreachedby(event);
                          }}
                        />
                      </TextFieldWrapper>
                      {lookUpShow && (
                        <>
                          {' '}
                          <TextFieldWrapper>
                            <SHdropdown
                              className="drop-down"
                              label="Team"
                              data={
                                lookUps.find(obj => {
                                  return obj.type === 'TEAM';
                                })?.values ?? []
                              }
                              onDataChange={event => {
                                teamChangeData(event);
                              }}
                              defaultValue={teamValue}
                            />
                          </TextFieldWrapper>
                          <TextFieldWrapper>
                            <SHdropdown
                              className="drop-down"
                              label="Category"
                              data={
                                categoryDatas
                                  ? categoryDatas
                                  : categoryDefaultDatas
                              }
                              onDataChange={event => {
                                onCategoryChange(event);
                              }}
                              defaultValue={categoryValue}
                            />
                          </TextFieldWrapper>
                          <TextFieldWrapper>
                            <SHdropdown
                              className="drop-down"
                              label="Division"
                              data={divisionDatas}
                              onDataChange={event => {
                                onDivisionChange(event);
                              }}
                              defaultValue={divisionValue}
                            />
                          </TextFieldWrapper>
                        </>
                      )}
                      {backNumberShow && (
                        <>
                          <TextFieldWrapper>
                            <StyledTextField
                              label="Back Number (3 or 4 digits)"
                              type="text"
                              name="backNumber"
                              value={backNumber}
                              onChange={handleBackNumberChange}
                              validations={'required|min:3|max:4'}
                              validator={simpleValidator.current}
                            />
                          </TextFieldWrapper>
                        </>
                      )}
                      {nameShow && (
                        <>
                          <TextFieldWrapper>
                            <StyledTextField
                              label="Name"
                              type="text"
                              name="name"
                              value={nameValue}
                              onChange={e => {
                                setNameChange(e.target.value);
                                setIsName(e.target.value.trim() === '');
                              }}
                              validations={'required|max:40'}
                              validator={simpleValidator.current}
                            />
                          </TextFieldWrapper>
                        </>
                      )}
                    </TextFieldGrid>
                    <SearchButtonWrapper>
                      <SearchButton
                        onClick={() => {
                          handleClick(
                            seasonValue?.id! ? seasonValue?.id! : seasonId!,
                            backNumber,
                            nameValue,
                            categoryValue?.id!
                              ? categoryValue?.id!
                              : categoryDefaultValue?.id!,
                            divisionValue?.id!,
                            teamValue?.id!,
                          );
                        }}
                        disableNext={
                          (isBackNumberCheck !== null
                            ? isBackNumberCheck
                            : false) || isName
                        }
                      >
                        Search
                      </SearchButton>
                    </SearchButtonWrapper>
                    {showTable ? (
                      <TableButtonWrapper>
                        <RiderTable
                          riderTableData={
                            riderTableData?.length ? riderTableData : []
                          }
                          handleCheckboxClick={handleCheckboxClick}
                          checkedMap={checkedMap}
                        />
                        <InfoWrapper>
                          <p className="para-heading">
                            <i>PLEASE NOTE:</i>
                          </p>
                          <p className="para-content">
                            You are able to edit and update information in
                            Returning Member Registration.
                          </p>
                        </InfoWrapper>
                        <ButtonWrapper className="bg-color-change">
                          <BackNextButton
                            classNameBack="btn-back"
                            classNameNxt="btn-nxt"
                            onClickNxt={handleNxt}
                            onClickBack={handleBack}
                            back="Cancel"
                            next="Next"
                            disableNext={isChecked}
                          />
                        </ButtonWrapper>
                      </TableButtonWrapper>
                    ) : (
                      ''
                    )}
                  </OverflowWrapper>
                </FormMainWrapper>
              )}
            </>
          )}
        </MainWrapper>
      )}
    </>
  );
};
const MainWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  position: relative;
  flex-direction: column;

  .para-one {
    color: #ff9900;
    font-size: 12px;
    border-bottom: 1px solid #5d5c5c;
    padding: 20px 0px 10px;
    margin-bottom: 30px;
  }
  label {
    color: #9597a6;
    font-family: 'Roboto';
    font-size: 14px;
  }
  //Responsive

  @media (min-width: 100px) and (max-width: 319px) {
    .form-MainWrapper {
      padding: 10px 2px 0px 2px;

      .inner-BorderWrapper {
        padding: 20px 20px;
      }
      .OverflowWrapper {
        padding: 10px 2px 100px 2px;
      }
      .TextFieldWrapper {
        width: 100%;
        .drop-down {
          margin: 0px;
        }
        &.BackNumberFieldWrapper {
          .BackNumberField-label {
            margin: 0px !important;
          }
        }
        &.height-weight-wrapper {
          .FlexFieldWrapper {
            flex-direction: column;
            align-items: flex-start;
          }
          .note-block {
            font-size: 11px !important;
            padding-top: 22px !important;
            align-items: baseline;
            width: 100%;
            height: 100%;
          }
        }
      }
      .BackFieldWrapper {
        width: 100%;
        .StyledTextField {
        }
      }
      .GraduationDobWrapper {
        width: 100%;
        flex-direction: column;
        padding: 0px;

        .GraduationSubWrapper {
          width: 100%;
          margin: 0 0 20px 0;
          .SHYear {
            margin: 0px !important;
          }
        }
      }
      .HeightInchWeightWrapper {
        width: 100%;
        padding: 0px;
      }
      .StyledTextField {
        margin: 0px 0px;
      }
      #InfoLabelWrapper {
        padding: 0 0px 10px 0px !important;
        label {
          font-size: 11px !important;
        }
      }
      .TextFieldGrid {
        &.height-note-grid {
          padding: 0px 0px !important;
          flex-direction: column-reverse !important;
        }
      }
      .StyledHeightTextField {
        margin: 0px 0px 0px 20px !important;
        &.StyledHeightTextField-first {
          margin: 0px !important;
        }
      }
      .StyledWeightTextField {
        margin: 0px !important;
      }
      .MainAddressWrapper {
        margin: 0px !important;
        padding: 0px 0px 85px 0px !important;
        .text-left-NoteField {
          width: 100% !important;
          .note-block {
            font-size: 11px;
            letter-spacing: 0.1px;
          }
        }
      }
      .flex-NoteWrapper {
        margin: 0px;
        .note-block {
          font-size: 11px;
        }
      }

      #BottomRightWrapper {
        height: 50px;
      }
      .StyledFormSecondaryLabel {
        font-size: 10px !important;
        letter-spacing: 0.2px;
      }
      .QuestionWrapper {
        .FormSecondaryLabel {
          font-size: 10px !important;
          letter-spacing: 0.2px;
        }
      }
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .form-MainWrapper {
      padding: 10px 5px 0px 5px;

      .inner-BorderWrapper {
        padding: 20px 20px;
      }
      .OverflowWrapper {
        padding: 20px 6px 100px 6px;
      }
      .TextFieldWrapper {
        width: 100%;
        .drop-down {
          margin: 0px;
        }
        &.BackNumberFieldWrapper {
          .BackNumberField-label {
            margin: 0px !important;
          }
        }
        &.height-weight-wrapper {
          .FlexFieldWrapper {
            flex-direction: column;
            align-items: flex-start;
          }
          .note-block {
            font-size: 11px !important;
            padding-top: 22px !important;
            align-items: baseline;
            width: 100%;
            height: 100%;
          }
        }
      }
      .BackFieldWrapper {
        width: 100%;
        padding: 0px;
        .StyledTextField {
          margin-left: 0px;
        }
      }
      .GraduationDobWrapper {
        width: 100%;
        flex-direction: column;
        padding: 0px;
        .GraduationSubWrapper {
          width: 100%;
          margin: 0 0 20px 0;
          .SHYear {
            margin: 0px !important;
          }
        }
      }
      .HeightInchWeightWrapper {
        width: 100%;
        padding: 0px;
      }
      .StyledTextField {
        margin: 0px 0px;
      }

      #InfoLabelWrapper {
        padding: 0 0px 10px 0px !important;
        label {
          font-size: 13px !important;
        }
      }
      .TextFieldGrid {
        padding: 10px 0px !important;
        &.height-note-grid {
          padding: 0px 0px !important;
          flex-direction: column-reverse !important;
        }
      }
      .MainAddressWrapper {
        margin: 0px !important;
        padding: 0px 0px 85px 0px !important;
        .text-left-NoteField {
          width: 100% !important;
          .note-block {
            font-size: 11px;
            letter-spacing: 0.1px;
          }
        }
      }

      .NoteFieldWrapper {
        margin: 0px !important;
        .note-block {
          font-size: 10px;
          letter-spacing: 0.2px;
        }
      }
      .StyledWeightTextField {
        margin: 0px !important;
      }

      .StyledHeightTextField {
        &.StyledHeightTextField-first {
          margin: 0px !important;
        }
      }
      .horizontal-line {
        margin: 20px 0px 15px 0px;
      }

      #BottomRightWrapper {
        height: 50px;
      }
      .StyledFormSecondaryLabel {
        font-size: 10px !important;
        letter-spacing: 0.2px;
      }
      .QuestionWrapper {
        .FormSecondaryLabel {
          font-size: 10px !important;
          letter-spacing: 0.2px;
        }
      }
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .form-MainWrapper {
      padding: 10px 10px 0px 10px;
      .inner-BorderWrapper {
        padding: 20px 20px;
      }
      .OverflowWrapper {
        padding: 20px 10px 100px 10px;
      }

      #InfoLabelWrapper {
        label {
          font-size: 10px !important;
        }
      }
      .TextFieldWrapper {
        width: 100%;
        &.height-weight-wrapper {
          .note-block {
            font-size: 8px !important;
            padding-top: 22px !important;
          }
        }
        .drop-down {
          margin: 0 8px;
        }
      }
      .BackFieldWrapper {
        width: 100%;
        padding: 0px;
        .BackNumberWrapper {
          padding: 0 8px;
        }
        .StyledTextField {
        }
      }
      .GraduationDobWrapper {
        width: 100%;
        flex-direction: column;
        padding: 0 8px;
        .GraduationSubWrapper {
          width: 100%;
          margin: 0 0 20px 0;
          .SHYear {
            margin: 0px !important;
          }
        }
      }
      .HeightInchWeightWrapper {
        width: 100%;
        padding: 0 8px;
      }
      .StyledTextField {
        margin: 0px 8px;
      }
      .note-block {
        font-size: 10px;
      }
      #BottomRightWrapper {
        height: 50px;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .form-MainWrapper {
      padding: 20px 10px 0px 10px;
      .OverflowWrapper {
        padding: 20px 0px 100px 0px;
      }
      .TextFieldWrapper {
        width: 100%;
        &.height-weight-wrapper {
          .note-block {
            font-size: 8px !important;
            padding-top: 22px !important;
          }
        }
        .drop-down {
          margin: 0 8px;
        }
      }
      .BackFieldWrapper {
        width: 100%;
        padding: 0px;
        .BackNumberWrapper {
          padding: 0 8px;
        }
        .StyledTextField {
        }
      }
      .current-grade {
      }
      .GraduationDobWrapper {
        width: 100%;
        flex-direction: column;
        padding: 0 8px;
        .GraduationSubWrapper {
          width: 100%;
          margin: 0 0 20px 0;
          .SHYear {
            margin: 0px !important;
          }
        }
      }
      .HeightInchWeightWrapper {
        width: 100%;
        padding: 0 8px;
      }
      .StyledTextField {
        margin: 0px 8px;
      }
      .note-block {
        font-size: 10px;
      }
      #BottomRightWrapper {
        height: 50px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .form-MainWrapper {
      .BackFieldWrapper {
        width: 50%;
        padding: 0px;
        .BackNumberWrapper {
        }
        .StyledTextField {
          input {
            width: 100%;
          }
        }
      }
      .GraduationDobWrapper {
        width: 100%;
        flex-direction: column;
        .GraduationSubWrapper {
          width: 100%;
          margin: 0 0 20px 0;
          .SHYear {
            margin: 0px !important;
          }
        }
      }
      .HeightInchWeightWrapper {
        width: 100%;
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
  }
`;
const FormMainWrapper = styled.div`
  padding: 20px 60px 100px 60px;
  height: 100%;
  .Team-Heading {
    padding-top: 30px;
    text-align: center;
    color: #9597a6;
  }
`;
const TextFieldWrapper = styled.div`
  width: 50% !important;
  margin-bottom: 20px;
  color: #9597a6;
  &.styled-TextFieldWrapper {
    display: grid;
    align-items: end;
  }

  @media (min-width: 100px) and (max-width: 319px) {
    width: 100% !important;
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    width: 100% !important;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 50% !important;
  }
`;
const LoaderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    position: absolute;
    top: 60%;
  }
  span {
    color: #9597a6;
  }
`;
const OverflowWrapper = styled.div`
  overflow: auto;
  height: calc(100vh - 240px);
  padding-bottom: 100px;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
`;
const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;
const StyledTextField = styled(TextField)`
  flex: 0 0 50%;
  &.styled-field {
    margin-bottom: 0px !important;
  }
`;

const TableButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .k-grid-content {
    ::-webkit-scrollbar {
      width: 2px;
      margin-left: 3px;
      height: 10px;
    }
    ::-webkit-scrollbar-track {
      background: transparent !important;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      width: 2px;
      background: #201e1e !important;
      border-radius: 10px;
    }
  }
`;
const InfoWrapper = styled.div`
  width: 100%;
  border-radius: 10px;
  margin: 30px 0px 20px;
  padding: 2px 20px;
  background: #27292d;
  .para-heading {
    color: #ff9900;
    border-bottom: 1px solid #5d5c5c;
    font-size: 14px;
  }
  .para-content {
    font-family: 'Roboto';
    color: #a7a7a7;
    font-size: 12px;
    padding: 10px 0px;
  }

  @media (min-width: 100px) and (max-width: 319px) {
    .para-content {
      font-size: 10px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .para-content {
      font-size: 10px;
    }
  }
  @media (min-width: 576px) and (max-width: 1199px) {
    .para-content {
      font-size: 11px;
    }
  }

  @media (min-width: 1200px) and (max-width: 1719px) {
    .para-content {
      font-size: 12px;
    }
  }

  @media (min-width: 1720px) and (max-width: 4000px) {
    .para-content {
      font-size: 13px;
    }
  }
`;
const SearchButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 30px;
  button {
    width: 100px;
    height: 33px;
    margin: 0px 10px;
    border-radius: 20px;
    background: #5b5a5a;
    border: 1px solid #8b8b8b;
  }
`;
const ButtonWrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;
  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: -48px;
    left: 0px;
    border-radius: 20px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    backdrop-filter: blur(5px);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
  }
`;
export default NonCompetingReturningRider;
