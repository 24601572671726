import { RootState } from 'types';

export const selectCategory = (state: RootState) =>
  state?.riderRegistrationState?.category!;
export const selectIsReturningRider = (state: RootState) =>
  state?.riderRegistrationState?.isReturningRider!;
export const selectCoachId = (state: RootState) => state?.manageCoach?.coachId!;
export const selectNewRiderData = (state: RootState) =>
  state?.riderRegistrationState?.newRiderData!;
export const selectPreviousEnrolData = (state: RootState) =>
  state?.riderRegistrationState?.periviousValue;
export const selectPlacementRadioValue = (state: RootState) =>
  state?.riderRegistrationState?.placementRadioValues;
export const selectPreviousEnrolRadioValue = (state: RootState) =>
  state?.riderRegistrationState?.previousEnrolRadioValues;
export const selectPhotoUpload = (state: RootState) =>
  state?.riderRegistrationState?.photoUpload;
