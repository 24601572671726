import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from 'services/mavapi/useApi';
import {
  CloverMakePayment,
  GeneralLookup,
  GetPayableAmountDetails,
  LookupService,
  PaymentService,
} from 'services/openapi';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import PaymentSuccessMessage from 'app/components/PaymentSuccessMessage';
import { useManagePageSlice } from '../slice';
import { selectPageLoader } from '../slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import LoaderWrapper from 'app/components/CircularLoader/LoaderWrapper';
import CircularLoader from 'app/components/CircularLoader';

type Props = {};

const PaymentPage = (props: Props) => {
  const navigate = useNavigate();
  //URL param
  const {
    payerType: PayerType,
    payerId: PayerId,
    isSif: IsSif,
    isSifSustain: IsSifSustain,
  } = useParams();
  //Services
  const { paymentMakeCloverPayment, paymentGetPayableAmount } = PaymentService;
  const { lookupGetAllLookups } = LookupService;
  const { error: apiError, handleRequest } = useApi();
  const [showSuccessMessage, setShowSuccessMessage] =
    React.useState<boolean>(false);

  //UseRefs
  const colverinstance = React.useRef<any>();
  const ColverElements = React.useRef<any>();
  const isElements = React.useRef(false);
  const cardNumber = React.useRef<any>();
  const cardDate = React.useRef<any>();
  const cardCvv = React.useRef<any>();
  const cardPostalCode = React.useRef<any>();
  //UseStates
  const [cardError, setCardError] = React.useState<string>('');
  const [cardDateError, setCardDateError] = React.useState<string>('');
  const [cardCvvError, setCardCvvError] = React.useState<string>('');
  const [cardZipError, setCardZipError] = React.useState<string>('');
  const [lookUps, setLookUps] = React.useState<Array<GeneralLookup>>([]);
  const [cancelPaymentCookie, setCancelPaymentCookie] = useState(false);

  const defaultpayload = {
    payerId: '', //'96A5E8E9-0483-4F93-70A1-08DB9845F4B7',
    payerType: {
      id: '', //'7c2e287f-85cb-4b24-8494-07a3383b924d',
      name: '', //'Team',
      children: null,
      otherName: null,
    },
    token: '',
    isSIF: false,
    isSIFSustain: false,
    addInsurance: false,
  };

  //clover payment payload
  const [cloverPayload, setCloverPayload] =
    React.useState<CloverMakePayment>(defaultpayload);
  const [getPaymentAmount, setGetPaymentAmount] =
    useState<GetPayableAmountDetails>();
  const styles = {
    body: {
      fontFamily: 'Roboto, Open Sans, sans-serif',
      fontSize: '16px',
    },
    input: {
      fontSize: '13px',
      letterSpacing: '1px',
      height: '35px',
    },
  };
  const { actions: PageActions } = useManagePageSlice();
  const pageLoader = useSelector(selectPageLoader);
  const dispatch = useDispatch();
  //Get lookups
  function getAllLookupData(names?: string) {
    handleRequest(lookupGetAllLookups(names))
      .then(data => {
        if (data) {
          const stateLookup = data.find(obj => obj.type === 'PAYER_TYPES');
          if (stateLookup) {
            setLookUps(stateLookup.values ?? []);
            let filteredPayerType = stateLookup.values?.filter(item => {
              return item?.id === PayerType;
            });
            console.log(filteredPayerType, stateLookup, 'filteredPayerType');
            //set clover payload from params
            setGetPaymentAmount({
              ...getPaymentAmount,
              payerId: PayerId!,
              payerType: filteredPayerType?.[0]!,
              isSIF: IsSif === 'false' ? false : true,
              isSIFSustain: IsSifSustain === 'false' ? false : true,
              addInsurance: Boolean(myInsure),
            });
            setCloverPayload({
              ...cloverPayload,
              payerId: PayerId!,
              payerType: filteredPayerType?.[0]!,
              isSIF: IsSif === 'false' ? false : true,
              isSIFSustain: IsSifSustain === 'false' ? false : true,
            });
          }
        }
      })
      .catch(() => console.log(apiError));
  }

  const myInsure = Cookies.get('addInsurance');
  console.log(myInsure, 'myInsure');
  //UseEffect for setting  Lookup & payload from url params
  React.useEffect(() => {
    getAllLookupData('PAYER_TYPES');
    // setIsIssuranceAdded(myInsure);
    const paymentCancelStatus = Cookies.get('paymentCancel');
    setCancelPaymentCookie(paymentCancelStatus);
  }, []);
  // const NewRiderData = useSelector(selectNewRiderData);
  // console.log(NewRiderData, 'newRiderData');

  //UseEffect for loading new Clover instance

  React.useEffect(() => {
    // const script = document.createElement('script');
    // script.src = 'URL_TO_CLOVER_SDK';
    // script.async = true;
    // document.body.appendChild(script);

    // script.onload = () => {
    //   // The Clover SDK script has been loaded and is now available
    //   // You can use Clover SDK functions here
    // };

    return () => {
      // Clean up: remove the script when the component is unmounted
      // document.body.removeChild(script);
    };
  }, []);

  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.sandbox.dev.clover.com/sdk.js';
    script.async = true;

    const divEl = document.createElement('div');
    divEl.id = 'scriptWrapper';
    divEl.appendChild(script);

    document.body.appendChild(divEl);

    script.onload = () => {
      // The Clover SDK script has been loaded and is now available
      // You can use Clover SDK functions here
      if (!colverinstance?.current && !isElements.current) {
        colverinstance.current = new window['Clover'](
          'c4c8843c8f24ac56fc689d9103908b2d', // this is the sandbox public key
        );
        const elements = colverinstance?.current?.elements();

        //Create card elements
        cardNumber.current = elements.create('CARD_NUMBER', styles);
        cardDate.current = elements.create('CARD_DATE', styles);
        cardCvv.current = elements.create('CARD_CVV', styles);
        cardPostalCode.current = elements.create('CARD_POSTAL_CODE', styles);
        console.log(cardCvv, 'cardCvv');

        //Mount card elements
        cardNumber?.current!.mount('#card-number');
        cardDate?.current!.mount('#card-date');
        cardCvv?.current!.mount('#card-cvv');
        cardPostalCode?.current!.mount('#card-postal-code');

        //   onChange & OnBlur Events
        // Handle real-time validation errors from the card Element.

        //onChange & OnBlur Events for Card Number
        cardNumber.current.addEventListener('change', function (event) {
          console.log('cardNumber changed', event);
          setCardError(event.CARD_NUMBER.error ?? '');
        });
        cardNumber.current.addEventListener('blur', function (event) {
          console.log(`cardNumber blur ${JSON.stringify(event)}`);
          setCardError(event.CARD_NUMBER.error ?? '');
        });

        //onChange & OnBlur Events for  Card Date
        cardDate.current.addEventListener('change', function (event) {
          console.log(`cardDate changed ${JSON.stringify(event)}`);
          setCardDateError(event.CARD_DATE.error ?? '');
        });
        cardDate.current.addEventListener('blur', function (event) {
          console.log(`cardDate blur ${JSON.stringify(event)}`);
          setCardDateError(event.CARD_DATE.error ?? '');
        });

        //onChange & OnBlur Events for  Card Cvv
        cardCvv.current.addEventListener('change', function (event) {
          console.log(`cardCvv changed ${JSON.stringify(event)}`);
          setCardCvvError(event.CARD_CVV.error ?? '');
        });
        cardCvv.current.addEventListener('blur', function (event) {
          console.log(`cardCvv blur ${JSON.stringify(event)}`);
          setCardCvvError(event.CARD_CVV.error ?? '');
        });

        //onChange & OnBlur Events for  Card Postal code
        cardPostalCode.current.addEventListener('change', function (event) {
          console.log(`cardPostalCode changed ${JSON.stringify(event)}`);
          setCardZipError(event.CARD_POSTAL_CODE.error ?? '');
        });

        cardPostalCode.current.addEventListener('blur', function (event) {
          console.log(`cardPostalCode blur ${JSON.stringify(event)}`);
          setCardZipError(event.CARD_POSTAL_CODE.error ?? '');
        });

        script.onload = null;
      }

      isElements.current = true;
    };
    return () => {
      // Clean up: remove the script when the component is unmounted
      document.body.removeChild(divEl);
    };
  }, []);

  //handle update payment
  const handlePayment = cloverPayload => {
    const finalPayload = { ...cloverPayload, addInsurance: Boolean(myInsure) };
    dispatch(PageActions.setPageLoader(true));
    handleRequest(paymentMakeCloverPayment(finalPayload))
      .then(res => {
        console.log(res, 'pay success');
        if (res) {
          if (cancelPaymentCookie) {
            Cookies.remove('paymentCancel');
          }
          dispatch(PageActions.setPageLoader(false));
          const isSifCreated = Cookies.get('SifCreated');
          if (finalPayload.payerType.name) {
            if (isSifCreated) {
              navigate('/rider-landing-page/dashboard');
            }
            // commenting this code for future use
            // else if (myInsure) {
            //   navigate('/rider-landing-page/coach-dashboard');
            // }
            else setShowSuccessMessage(true);
          }
        }
      })
      .catch(error => {
        console.log(error, 'error');
      });
  };
  const [payableAmount, setPayableAmount] = useState<number | undefined>();
  function handleGetAmount(getPaymentAmount: GetPayableAmountDetails) {
    const finalPayload = { ...getPaymentAmount };
    handleRequest(paymentGetPayableAmount(finalPayload))
      .then(res => {
        console.log(res);
        setPayableAmount(res?.payableAmount);
      })
      .catch(error => {
        console.log(error, 'error');
      });
  }
  useEffect(() => {
    if (getPaymentAmount) {
      handleGetAmount(getPaymentAmount);
    }
  }, [getPaymentAmount]);
  //handlePayment Submit
  const handleSubmit = e => {
    e.preventDefault();
    colverinstance?.current
      ?.createToken()
      .then(result => {
        //submit error
        if (result.errors) {
          console.log(result, 'result of err');
          result?.errors?.CARD_NUMBER!
            ? setCardError(result?.errors?.CARD_NUMBER!)
            : setCardError('');
          result?.errors?.CARD_DATE!
            ? setCardDateError(result?.errors?.CARD_DATE!)
            : setCardDateError('');
          result?.errors?.CARD_CVV!
            ? setCardCvvError(result?.errors?.CARD_CVV!)
            : setCardCvvError('');
          result?.errors?.CARD_POSTAL_CODE!
            ? setCardZipError(result?.errors?.CARD_POSTAL_CODE!)
            : setCardZipError('');
        }
        //Success
        else {
          console.log(result, 'result');
          setCardError('');
          setCardCvvError('');
          setCardDateError('');
          setCardZipError('');
          handlePayment({ ...cloverPayload, token: result?.token });
        }
      })
      .catch(error => {
        console.log(error, 'error');
      });
  };
  const handleCancelPay = () => {
    const paymentCancelString = JSON.stringify(true);
    Cookies.set('paymentCancel', paymentCancelString);
    navigate(-1);
  };

  return (
    <>
      {pageLoader ? (
        <LoaderWrapper>
          <CircularLoader />
        </LoaderWrapper>
      ) : (
        <PageWrapper>
          <div className="container">
            <h5>Payment Details</h5>
            <form
              action="/charge"
              method="post"
              id="payment-form"
              onSubmit={handleSubmit}
            >
              <div className="form-row top-row">
                <div id="card-number" className="field full-width"></div>
                <div
                  className="input-errors"
                  id="card-number-errors"
                  role="alert"
                >
                  {cardError ?? ''}
                </div>
              </div>
              <div className="row">
                <div className="form-row">
                  <div id="card-cvv" className="field third-width"></div>
                  <div
                    className="input-errors"
                    id="card-cvv-errors"
                    role="alert"
                  >
                    {cardCvvError ?? ''}
                  </div>
                </div>

                <div className="form-row">
                  <div id="card-date" className="field third-width"></div>
                  <div
                    className="input-errors"
                    id="card-date-errors"
                    role="alert"
                  >
                    {cardDateError ?? ''}
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div id="card-postal-code" className="field third-width"></div>
                <div
                  className="input-errors"
                  id="card-postal-code-errors"
                  role="alert"
                >
                  {cardZipError ?? ''}
                </div>
              </div>
              <div id="card-errors" role="alert"></div>
              <div id="card-response" role="alert"></div>
              <div className="button-container">
                <button className="button-cancel" onClick={handleCancelPay}>
                  Cancel
                </button>
                <button className="button-pay">Pay ${payableAmount}</button>
              </div>
            </form>
          </div>
          <PaymentSuccessMessage
            show={showSuccessMessage}
            setShow={setShowSuccessMessage}
            payer={cloverPayload?.payerType!}
          />
        </PageWrapper>
      )}
    </>
  );
};

export default PaymentPage;
const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #192130;
  padding: 0 15px;
  .container {
    background-color: #ffffff;
    border-radius: 15px 15px 0 0;
    box-shadow: 0 0 6px 0 rgba(141, 151, 158, 0.2);
    padding: 30px 25px 25px 25px;
    width: 100%;
    max-width: 374px;
    margin: -75px 0 0 0;
    height: 376px;
    form {
      padding: 0 0 40px 0;
    }
    h5 {
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 2px;
      padding: 0 10px;
      margin: 0;
    }
  }
  .row {
    display: flex;
  }
  .container * {
    font-family: Roboto, 'Open Sans', sans-serif;
    font-size: 16px;
  }

  .container {
    .form-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 10px;
    }
  }

  .container .form-row.top-row {
    margin-top: 16px;
  }

  .input-errors {
    font-size: 12px;
    position: absolute;
    margin: 40px 0 0 0;
    color: #f44336;
  }

  .container .form-row .field {
    box-sizing: border-box;
    border-bottom: 1px solid #dee0e1;
    border-radius: 3px;
    height: 36px;
    margin-bottom: 30px;
    padding: 0;
  }

  .container .button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 15px 10px 0 10px;
    gap: 10px;
    .button-cancel {
      background-color: #dc4c64;
      :hover {
        background-color: #dc4c64;
      }
    }
  }

  .container .button-container button {
    background-color: #4dd0e1;
    border: none;
    color: #ffffff;
    display: inline-block;
    font-size: 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 12px 15px;
    width: 100%;
    max-width: 150px;
    cursor: pointer;
    &:hover {
      background-color: #53bfcd;
    }
  }

  #payment-request-button {
    width: 160px;
    height: 40px;
    margin: 0 auto;
  }

  @media (min-width: 300px) {
    body {
      width: auto;
    }
  }

  @media (min-width: 750px) {
    body {
      width: 400px;
    }

    /* .container {
      height: 500px;
    } */

    .container .form-row .field {
      width: 100%;
    }

    /* .container .form-row .field.third-width {
      width: 218px;
    } */
  }

  .hr {
    width: 100%;
    height: 10px;
    border-bottom: 1px solid black;
    text-align: center;
    margin: 20px 0;
  }

  .hr span {
    font-size: 10px;
    background-color: #fff;
    padding: 0 10px;
  }
`;
