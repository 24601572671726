import React from 'react';
import styled from 'styled-components';
import arrow from './assets/img/icon/arrow.png';
import '@fontsource/arimo';

type Props = {
  userType?: string;
};

export const CoachIsuranceInfoTwo = ({ userType }: Props) => {
  return (
    <MainWrapper>
      <p className="para-one">
        <i>Information</i>
      </p>
      <p className="para-two">
        THE UNDERSIGNED IS AUTHORIZED BY THE INSURED AND DECLARES THAT THE
        STATEMENTS SET FORTH HEREIN AND ALL WRITTEN STATEMENTS AND MATERIALS
        FURNISHED TO THE INSURER IN CONJUNCTION WITH THIS APPLICATION ARE TRUE.
        SIGNING OF THIS APPLICATION DOES NOT BIND THE INSURED OR THE INSURER TO
        COMPLETE THE INSURANCE, BUT IT IS AGREED THAT THE STATEMENTS CONTAINED
        IN THIS APPLICATION, ANY SUPPLEMENTAL APPLICATIONS, AND THE MATERIALS
        SUBMITTED HEREWITH ARE THE BASIS OF THE CONTRACT SHOULD A POLICY BE
        ISSUED AND HAVE BEEN RELIED UPON BY THE INSURER IN ISSUING ANY POLICY.
      </p>
      <p className="para-two">
        THE APPLICATION AND MATERIALS SUBMITTED WITH IT SHALL BE RETAINED ON
        FILE WITH THE INSURER AND SHALL BE DEEMED ATTACHED TO AND BECOME PART OF
        THE POLICY IF ISSUED. THE INSURER IS AUTHORIZED TO MAKE ANY
        INVESTIGATION AND INQUIRY IN CONNECTION WITH THIS APPLICATION AS IT
        DEEMS NECESSARY. PROVIDED, HOWEVER, THIS PARAGRAPH DOES NOT APPLY IN THE
        STATES OF UTAH AND WISCONSIN.
      </p>
      <p className="para-two">
        NOTE TO UTAH AND WISCONSIN RESIDENTS: ALL WRITTEN STATEMENTS AND
        MATERIALS FURNISHED TO THE INSURER IN CONJUNCTION WITH THIS APPLICATION
        ARE MADE A PART HEREOF PROVIDED THIS APPLICATION AND SUCH MATERIALS ARE
        ATTACHED TO THE POLICY AT THE TIME OF ITS DELIVERY.
      </p>
      <p className="para-two">
        THE INSURED AGREES THAT IF THE INFORMATION SUPPLIED ON THIS APPLICATION
        CHANGES BETWEEN THE DATE OF THIS APPLICATION AND THE EFFECTIVE DATE OF
        THE INSURANCE, THE INSURED WILL, IN ORDER FOR THE INFORMATION TO BE
        ACCURATE ON THE EFFECTIVE DATE OF THE INSURANCE, IMMEDIATELY NOTIFY THE
        INSURER OF SUCH CHANGES, AND THE INSURER MAY WITHDRAW OR MODIFY ANY
        OUTSTANDING QUOTATIONS OR AUTHORIZATIONS OR AGREEMENTS TO BIND THE
        INSURANCE.
      </p>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  width: 100%;
  border: 1px solid #585858;
  border-radius: 7px;
  margin: 30px 0px 20px;
  padding: 20px;
  p {
    font-family: 'Roboto';
  }
  .para-one {
    color: #ff9900;
    font-size: 16px;
    border-bottom: 1px solid #585858;
  }
  .para-two {
    padding-bottom: 10px;
    font-family: 'Arimo';
    font-weight: 600;
    letter-spacing: 0.2px;
    font-size: 14px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .para-one {
      font-size: 13px !important;
    }
    p {
      font-size: 11px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .para-one {
      font-size: 13px !important;
    }
    p {
      font-size: 11px !important;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .para-one {
      font-size: 14px !important;
    }
    p {
      font-size: 12px !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .para-one {
      font-size: 15px !important;
    }
    p {
      font-size: 13px !important;
    }
  }
`;

const MappedDataWrapper = styled.div`
  display: flex;
  align-items: baseline;
  li {
    padding-left: 10px;
    list-style-type: none;
    padding-bottom: 10px;
    font-family: 'Roboto';
    font-size: 14px;
  }
  img {
    width: 11px;
    height: 11px;
  }
`;
