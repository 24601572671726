import styled from 'styled-components';

type Props = {
  className?: string;
  StatusType?: string;
  text?: string;
};

const StatusChip = ({ className, text, StatusType }: Props) => {
  return (
    <StatusChipWrapper
      className={`status ${className} ${StatusType} custom-chip`}
    >
      {text}
    </StatusChipWrapper>
  );
};

export default StatusChip;
const StatusChipWrapper = styled.div`
  width: fit-content;
  max-width: 100%;
  font-family: 'Inter';
  font-weight: 500;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 15px;
  text-transform: capitalize;
  min-width: 67px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  display: flex;
  justify-content: center;
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 500;
  &.text {
    text-transform: capitalize;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 12px;
    padding: 0;
    overflow: unset;
  }
  &.active {
    color: #067647;
    border: 1px solid #067647;
  }
  &.inactive-team {
    color: #9f0404;
    border: 1px solid #9f0000;
  }
  &.inactive-team-profile {
    color: #ff5252;
    background: #ffefef;
    border: 1px solid #ffb2b2;
  }
  &.active-paid {
    color: #175cd3;
    background: #eff8ff;
    border: 1px solid #b2ddff;
  }
  &.active-not-paid {
    color: #6941c6;
    background: #f9f5ff;
    border: 1px solid#E9D7FE;
  }
  &.not-assigned {
    color: #c10303;
    background: #ffffff;
    border: 1px solid#C10303;
    border-radius: 4px;
    padding: 6px 15px;
    height: 30px;
  }
  &.inactive {
    color: #ff5252;
    background: #ffefef;
    border: 1px solid #ffb2b2;
  }
`;
