import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import { ManageCoachState } from './types';

export const initialState: ManageCoachState = {
  coachId: '',
  insuranceUpload: {
    formData: {
      certificateOfInsurance: undefined,
    },
  },
};

const slice = createSlice({
  name: 'manageCoach',
  initialState,
  reducers: {
    setCoachId(state, action: PayloadAction<string>) {
      state.coachId = action.payload;
    },
    setInsuranceUpload(
      state,
      action: PayloadAction<{
        formData: {
          certificateOfInsurance: Blob;
        };
      }>,
    ) {
      state.insuranceUpload = action.payload;
    },
  },
});

export const { actions: manageAppActions } = slice;

export const useManageCoachSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
