import { PageMainLabel, SubLabelSemiBold } from 'app/components/SHLabel';
import styled from 'styled-components';
import BackButton from 'app/components/BackButton';
import { CommonPageWrapper } from 'admin/components/wrappers';
import CustomBreadCrumb from 'app/components/CustomBreadCrumb';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigateToRoute } from 'utils/common';
import useCoachApi from 'admin/hooks/useCoachApi';
import { CoachViewProfile } from './components/CoachViewProfile';


export const CoachIndividualPage = () => {
  const location = useLocation();
  const currentLocation = useLocation();
  const isPastDataFlag = currentLocation.pathname.includes('past-data');
  const breadCrumpitems = [
    {
      id: '',
      text: 'Showyeda admin',
      disabled: true,
    },
    {
      id: isPastDataFlag ? '/admin/past-data/coaches' : '/admin/coaches',
      text: isPastDataFlag ? 'Past-Data/Coaches' : 'Coaches',
    },
    {
      id: '',
      text: 'Coach Info',
      disabled: true,
    },
  ];
  const redirect = useNavigateToRoute(); 
  const { getCoachProfileViewData, coachViewData, coachDataLoading} = useCoachApi();
  const { id: coachId } = useParams();

  const handleBackClick = () => {
     if (isPastDataFlag){
    redirect('/admin/past-data/coaches');}
    else{redirect('/admin/coaches');}
    
  };
  const handleEditClick = () => {
    redirect(`/admin/coaches/update/${coachId}`);
  };
  //useEffect
  useEffect(() => {
    if (location.pathname.includes('/coaches/view') && coachId ) {
      getCoachProfileViewData(coachId ?? '');
    }
  }, [location, coachId]);
  return (
    <CommonPageWrapper>
      <HeadingWrapper>
        <PageMainLabel>Coach Information</PageMainLabel>
        <CustomBreadCrumb preData={breadCrumpitems} />
      </HeadingWrapper>

      <HeaderProfileWrapper>
        <SubLabelSemiBold>Coach Profile</SubLabelSemiBold>
        <BackButton className="back-button" onClick={handleBackClick} />
      </HeaderProfileWrapper>
      <CoachViewProfile
        coachViewData={coachViewData}
        handleEditClick={handleEditClick}
        loading={coachDataLoading}
      />
    </CommonPageWrapper>
  );
};
const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderProfileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
