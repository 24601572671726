import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { CommonPageWrapper } from 'admin/components/wrappers';
import { PageMainLabel } from 'app/components/SHLabel';
import CustomBreadCrumb, {
  breadCrumbDataModel,
} from 'app/components/CustomBreadCrumb';
import AdminDropDown from 'app/components/AdminDropDown';
import AdminCustomButton from 'app/components/AdminCustomButton';
import { getLookupArray } from 'utils/common';
import useCommonLookUpDatas from 'hooks/useCommonLookupData';
import useShowRiderReportGroupApi from 'admin/hooks/useShowRiderReportGroupApi';
import { TableSkeletonLoader } from 'app/components/TableSkeletonLoader';
import CustomDataGrid from 'app/components/CustomDataGrid/Index';

const teamRosterBreadCrumbItems = [
  {
    id: '',
    text: 'Showyeda admin',
    disabled: true,
  },
  {
    id: '',
    text: 'Show rider report groups',
    disabled: true,
  },
];

export const ShowRiderReportGroups = () => {
  const { getAllLookupData, lookUps, showLookUps, getShowLookupWithSeasinId } =
    useCommonLookUpDatas();
  const [breadcrumbPreData, setBreadcrumbPreData] = useState<
    breadCrumbDataModel[]
  >(teamRosterBreadCrumbItems);
  const [selectedShow, setSelectedShow] = useState({
    id: '',
    name: '',
  });
  const [selectedSeason, setSelectedSeason] = useState({
    id: '',
    name: '',
  });
  const [saveBtnDisabled, setSaveBtnDisabled] = useState<boolean>(true);
  const {
    loading,
    getShowRiderReportGroupList,
    riderReportGroupList,
    setRiderReportGroupList,
    updateShowRiderReportGroupsList,
  } = useShowRiderReportGroupApi();

  const handleSeasonChange = data => {
    setSelectedSeason(data);
    setSelectedShow({
      id: '',
      name: '',
    });
    getShowLookupWithSeasinId(data.id);
  };

  const handleShowChange = data => {
    setSelectedShow(data);
  };
  const handleLoadGroup = () => {
    getShowRiderReportGroupList(selectedShow?.id, selectedSeason.id);
  };
  const handleSaveGroupOrders = () => {
    updateShowRiderReportGroupsList(
      selectedShow?.id,
      riderReportGroupList?.groupList ?? [],
    );
    setSaveBtnDisabled(true);
  };

  const gridData = riderReportGroupList?.groupList?.map((item, index) => ({
    no: index + 1,
    class: `${item?.group?.name ?? ''} ${item?.division?.name ?? ''} ${
      item?.class?.name ?? ''
    } ${item?.splitNo?.name ?? ''}`,
    sortOrder: item?.sortNo,
    rawData: item, // Keep the original data for reference if needed
  }));
  const handleSortDropdoenClick = () => {
    if (gridData?.length === 1) {
      setSaveBtnDisabled(false);
    }
  };
  const columns = [
    { field: 'no', title: 'No.', width: '70px' },
    { field: 'class', title: 'Class' },
    {
      field: 'sortOrder',
      title: 'Sort Order',
      cell: props => (
        <SortOrderWrapper>
          <GridClassDropdown
            placeholder="Select Class"
            data={riderReportGroupList?.class as any}
            onClose={handleSortDropdoenClick}
            onDataChange={e => {
              const newValue = e;
              const updatedGroupList = riderReportGroupList?.groupList?.map(
                item => {
                  if (item === props.dataItem.rawData) {
                    return {
                      ...item,
                      sortNo: newValue,
                    };
                  }
                  return item;
                },
              );

              setRiderReportGroupList(prevState => ({
                ...prevState,
                groupList: updatedGroupList,
              }));
              setSaveBtnDisabled(false);
            }}
            defaultValue={props.dataItem.rawData.sortNo}
            isLoader={false}
          />
        </SortOrderWrapper>
      ),
    },
  ];

  useEffect(() => {
    getAllLookupData('SEASON');
  }, []);

  return (
    <StyledCommonPageWrapper>
      <TitleWrapper>
        <PageMainLabel>Show Rider Report Groups</PageMainLabel>
        <CustomBreadCrumb
          preData={breadcrumbPreData}
          setPreData={setBreadcrumbPreData}
        />
      </TitleWrapper>
      <ButtonWrapper>
        <FilterWrapper>
          <DropdownWrapper>
            <AdminDropDown
              label="Season"
              placeholder="Select Season"
              data={getLookupArray('SEASON', lookUps)}
              onDataChange={handleSeasonChange}
              defaultValue={selectedSeason}
              isLoader={false}
            />
            <AdminDropDown
              label="Shows"
              placeholder="Select Show"
              data={showLookUps}
              onDataChange={handleShowChange}
              defaultValue={selectedShow}
              isLoader={false}
              disabled={selectedSeason.id === ''}
            />
          </DropdownWrapper>
          <ActionWrapper>
            <AdminCustomButton
              buttonType="save"
              onClick={handleLoadGroup}
              disabled={selectedSeason.id === '' || selectedShow.id === ''}
              className="action-button"
            >
              Load Groups
            </AdminCustomButton>
          </ActionWrapper>
        </FilterWrapper>
      </ButtonWrapper>
      {gridData ? (
        <>
          {loading ? (
            <TableSkeletonLoader
              rowContent={10}
              width={100}
              headingWidth={40}
            />
          ) : (
            <CustomDataGrid data={gridData} columns={columns} />
          )}
          {riderReportGroupList?.class !== null ? (
            <BottomButtonWrapper>
              <AdminCustomButton
                buttonType="save"
                onClick={handleSaveGroupOrders}
                className="action-button"
                disabled={saveBtnDisabled}
              >
                Save Group Orders
              </AdminCustomButton>
            </BottomButtonWrapper>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
    </StyledCommonPageWrapper>
  );
};

const StyledCommonPageWrapper = styled(CommonPageWrapper)`
  padding-bottom: 52px;
  .k-table-thead {
    .k-link {
      cursor: default;
      :hover {
        cursor: default;
      }
    }
  }
  @media (min-height: 100px) and (max-height: 560px) {
    padding-bottom: 0px;
  }
  .k-virtual-content {
    max-height: calc(100vh - 322px);
    min-height: unset !important;
    height: 100% !important;

    @media (min-height: 100px) and (max-height: 560px) {
      max-height: calc(100vh - 311px);
    }
  }
  height: 100vh;
  .top-header-style {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    div:nth-child(1) {
      width: 100%;
    }
    .dynamic-title {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .date {
      font-size: 13px !important;
      font-weight: 400 !important;
      padding-top: 7px !important;
    }
  }
  .k-grid-header {
    table {
      tr {
        th {
          padding: 10px 4px 9px 4px !important;
          vertical-align: top;
          &:has(.green-bg) {
            background-color: #8ee08e !important;
          }
          &:nth-child(n + 5) {
            position: unset !important;
            border-right: unset !important;
            border-left: unset !important;
            text-align: center;
          }
        }
      }
    }
  }
  .k-grid-container {
    table {
      tr td:nth-child(n + 5) {
        position: unset !important;
        border-right: unset !important;
        border-left: unset !important;
        > div {
          text-align: center;
        }
      }
    }
  }
  .k-grid-content tr {
    .k-grid-content-sticky {
      cursor: unset !important;
      :hover {
        cursor: unset !important;
        background: inherit !important;
      }
    }
    :hover {
      td {
        background: inherit !important;
      }
    }
  }
  .k-grid tbody > tr:not(.k-detail-row):hover,
  .k-grid tbody > tr:not(.k-detail-row).k-hover {
    background-color: #dddddd !important;
  }

  .k-grid tr {
    height: 50px;
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0 30px 0;
  .fill-button {
    margin-right: 0px;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  .fill-button {
    button {
      margin-top: 5px;
      height: 31px;
    }
    &:has(.k-disabled) {
      cursor: not-allowed;
    }
  }
`;
const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 0px 20px 0px;
`;
const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 40%;
  gap: 15px;
  .SHdropdown {
    width: 50%;
    .k-dropdownlist {
      max-height: 31px;
      border-radius: 8px !important;
    }
  }
  .k-input-inner {
    padding: 0 5px !important;
  }
  .k-input-value-text {
    margin: 2px 4px 2px 4px;
    font-size: 12px !important;
    letter-spacing: 0px;
    color: #1e1e1e;
    text-align: left;
  }
`;
const ActionWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: start;
  width: 40%;
  padding-top: 25px;
  padding-left: 15px;
  .k-button {
    height: 31px !important;
    span {
      margin: 0px 15px;
    }
  }
  .action-button:has(.k-disabled) {
    cursor: not-allowed;
  }
`;
const BottomButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 15px;
`;
const GridClassDropdown = styled(AdminDropDown)`
  .label {
    display: none;
  }
  .k-dropdownlist {
    max-height: 40px;
  }
`;
const SortOrderWrapper = styled.td`
  .SHdropdown {
    width: 150px;
    .k-dropdownlist {
      max-height: 31px;
      border-radius: 8px !important;
    }
  }
  .k-input-inner {
    padding: 0 5px !important;
  }
  .k-input-value-text {
    margin: 2px 4px 2px 4px;
    font-size: 12px !important;
    letter-spacing: 0px;
    color: #1e1e1e;
    text-align: left;
  }
`;
