import React, { useState } from 'react';
import styled from 'styled-components';
import '@fontsource/roboto';
import { FormSecondaryLabel, InfoLabel } from 'app/components/SHLabel';
import { BackNextButton } from 'app/components/BackNextButton';
import { QualifyingInformation } from 'app/components/AlumniInformation/QualifyingInformation';
import RadioButtonGroup from 'app/components/RadioButtonGroup';
import { Alumni, GeneralLookup, GeneralLookupWrapper } from 'services/openapi';
import RoundRadioBtn from 'app/components/RoundRadioBtn';
import SimpleReactValidator from 'simple-react-validator';
import { RadioValueModel } from '../../../AlumniNewMemberResgistration';

type Props = {
  formNumber?: number;
  setFormNumber?: React.Dispatch<React.SetStateAction<number>>;
  handleNextForm?: (event) => void;
  handleBackForm?: (event) => void;
  childDivisonData: GeneralLookup[] | undefined;
  aluminiRiderData: Alumni;
  setAluminiRiderData: React.Dispatch<React.SetStateAction<Alumni>>;
  lookUp: GeneralLookupWrapper[];
  radioGroupValues: RadioValueModel;
  setRadioGroupValues: React.Dispatch<React.SetStateAction<RadioValueModel>>;
};

export const AlumniDivisionForm = ({
  formNumber,
  setFormNumber,
  handleNextForm,
  handleBackForm,
  childDivisonData,
  aluminiRiderData,
  setAluminiRiderData,
  radioGroupValues,
  setRadioGroupValues,
  lookUp,
}: Props) => {
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [valid, setValid] = useState<boolean>(false);
  const [filteredRadioData, setFilteredRadioData] = useState<
    GeneralLookup[] | undefined
  >([]);
  const [isRadioButtonSelected, setIsRadioButtonSelected] = useState(false);
  const [isSapphireOne, setIsSapphireOne] = useState(false);
  const [isSapphireTwo, setIsSapphireTwo] = useState(false);
  const [isSapphireThree, setIsSapphireThree] = useState(false);

  const handleReturningSelection = (event, value) => {
    const selectedValue = value;
    setAluminiRiderData(prevData => {
      return {
        ...prevData,
        divisionClass: selectedValue,
      };
    });
  };

  const handlehaveNotCompetedClassLopeCanter = (e, val) => {
    setAluminiRiderData({
      ...aluminiRiderData,
      haveNotCompetedInClassLopeCanter: val,
    });
    setRadioGroupValues({
      ...radioGroupValues,
      haveNotCompetedInClassLopeCanter: val,
    });
    if (isSapphireOne || isSapphireTwo || isSapphireThree) {
    } else {
      if (val) {
        setFilteredRadioData(childDivisonData);
      }
    }
    setIsRadioButtonSelected(true);
  };
  const handlehaveNotEarnedTwentyFivePoints = (e, val) => {
    setAluminiRiderData({
      ...aluminiRiderData,
      haveCompetedTwoSeasonsAsAER: val,
    });
    setRadioGroupValues({
      ...radioGroupValues,
      haveNotEarnedTwentyFivePointsInAssocs: val,
    });
    if (isSapphireTwo || isSapphireThree) {
    } else {
      if (val === true) {
        const filteredDataEmerald = childDivisonData?.filter(
          item => !item.name.includes('Opal'),
        );
        if (filteredDataEmerald) {
          setFilteredRadioData(filteredDataEmerald);
          setIsSapphireOne(false);
        }
      }
      if (val === false) {
        const filteredDataSapphire = childDivisonData?.filter(
          gem => !gem.name.includes('Opal') && !gem.name.includes('Emerald'),
        );
        if (filteredDataSapphire) {
          setFilteredRadioData(filteredDataSapphire);
          setIsSapphireOne(true);
        }
      }
    }
    setIsRadioButtonSelected(true);
  };
  const handlehaveFinishedTopTwoAsAERInNationals = (e, val) => {
    setAluminiRiderData({
      ...aluminiRiderData,
      haveFinishedTopTwoAsAERInNationals: val,
    });
    setRadioGroupValues({
      ...radioGroupValues,
      haveFinishedTopTwoAsAERInNationals: val,
    });
    if (isSapphireOne || isSapphireThree) {
    } else {
      if (val === true) {
        const filteredDataSapphire = childDivisonData?.filter(
          gem => !gem.name.includes('Opal') && !gem.name.includes('Emerald'),
        );
        if (filteredDataSapphire) {
          setFilteredRadioData(filteredDataSapphire);
          setIsSapphireTwo(true);
        }
      }
      if (val === false) {
        const filteredDataEmerald = childDivisonData?.filter(
          gem => !gem.name.includes('Opal'),
        );
        if (filteredDataEmerald) {
          setFilteredRadioData(filteredDataEmerald);
          setIsSapphireTwo(false);
        }
      }
    }
    setIsRadioButtonSelected(true);
  };
  const handlehaveFinishedTopThreeAaAER = (e, val) => {
    setAluminiRiderData({
      ...aluminiRiderData,
      haveFinishedTopThreeAaAER: val,
    });
    setRadioGroupValues({
      ...radioGroupValues,
      haveFinishedTopThreeAaAER: val,
    });
    if (isSapphireOne || isSapphireTwo) {
    } else {
      if (val === true) {
        const filteredDataSapphire = childDivisonData?.filter(
          gem => !gem.name.includes('Opal') && !gem.name.includes('Emerald'),
        );
        if (filteredDataSapphire) {
          setFilteredRadioData(filteredDataSapphire);
          setIsSapphireThree(true);
        }
      }
      if (val === false) {
        const filteredDataEmerald = childDivisonData?.filter(
          gem => !gem.name.includes('Opal'),
        );
        if (filteredDataEmerald) {
          setFilteredRadioData(filteredDataEmerald);
          setIsSapphireThree(false);
        }
      }
    }
    setIsRadioButtonSelected(true);
  };
  const handlehaveCompetedTwoSeasonsAsAER = (e, val) => {
    setAluminiRiderData({
      ...aluminiRiderData,
      haveCompetedTwoSeasonsAsAER: val,
    });
    setRadioGroupValues({
      ...radioGroupValues,
      haveCompetedTwoSeasonsAsAER: val,
    });
    if (isSapphireOne || isSapphireTwo || isSapphireThree) {
    } else {
      if (val === true) {
        const filteredDataEmerald = childDivisonData?.filter(
          gem => !gem.name.includes('Opal'),
        );
        if (filteredDataEmerald) {
          setFilteredRadioData(filteredDataEmerald);
        }
      }
      if (val === false) {
        setFilteredRadioData(childDivisonData);
      }
    }
    setIsRadioButtonSelected(true);
  };

  //DummyData
  const Defaultdata1 = [
    { label: 'YES (ALUMNI EMERALD)', value: true },
    { label: 'NO (ALUMNI SAPPHIRE)', value: false },
  ];
  const Defaultdata2 = [
    { label: 'YES (ALUMNI SAPPHIRE) ', value: true },
    { label: 'NO (ALUMNI EMERALD)', value: false },
  ];
  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(!formValid);
  }, [aluminiRiderData]);
  return (
    <MainWrapper className="form-MainWrapper" id="PersonalInfoFormMainWrapper">
      <SubMainWrapper className="SubMainWrapper">
        <BorderWrapper className="inner-BorderWrapper">
          <QualifyingInformation />
          <StyledFormSecondaryLabel>
            OPAL RIDERS ARE WALK-JOG STUDENTS WHO ARE BEGINNING RIDERS WHO HAVE
            NOT COMPETED IN MOUNTED COMPETITION WHICH REQUIRE THEM TO CANTER OR
            LOPE.
          </StyledFormSecondaryLabel>
          <QuestionWrapper className="QuestionWrapper">
            <FormSecondaryLabel>
              IS THIS STATEMENT TRUE? I HAVE NEVER COMPETED IN A CLASS THAT
              REQUIRES ME TO LOPE OR CANTER.
            </FormSecondaryLabel>
            <RadioWrapper>
              <RadioButtonGroup
                onChange={handlehaveNotCompetedClassLopeCanter}
                value={radioGroupValues.haveNotCompetedInClassLopeCanter}
                name="haveNotCompetedInClassLopeCanter"
              />
            </RadioWrapper>
          </QuestionWrapper>
          <QuestionWrapper className="QuestionWrapper">
            <FormSecondaryLabel>IS THIS STATEMENT TRUE?</FormSecondaryLabel>
            <FormSecondaryLabel>
              I HAVE NOT EARNED 25 NATIONAL POINTS FROM ANY NATIONAL ASSOCIATION
              INCLUDING NOVICE BUT EXCLUDING ROPING, GAMES, HALTER, SHOWMANSHIP
              10 & UNDER CLASSES, AND/OR NONLOPING CLASSES.
            </FormSecondaryLabel>
            <RadioWrapper>
              <RadioButtonGroup
                data={Defaultdata1}
                onChange={handlehaveNotEarnedTwentyFivePoints}
                value={radioGroupValues?.haveNotEarnedTwentyFivePointsInAssocs}
                name="haveNotEarnedTwentyFivePointsInAssocs"
              />
            </RadioWrapper>
          </QuestionWrapper>
          <QuestionWrapper className="QuestionWrapper">
            <FormSecondaryLabel>
              RETURNING RIDERS WHO WERE IN THE ALUMNI EMERALD DIVISION DID YOU
              FINISH 1ST OR 2ND IN THE ALUMNI EMERALD PATTERN OR RAIL 2023
              NATIONAL INDIVIDUAL CHAMPIONSHIP CLASS?
            </FormSecondaryLabel>
            <RadioWrapper>
              <RadioButtonGroup
                data={Defaultdata2}
                onChange={handlehaveFinishedTopTwoAsAERInNationals}
                value={radioGroupValues?.haveFinishedTopTwoAsAERInNationals}
                name="haveFinishedTopTwoAsAERInNationals"
              />
            </RadioWrapper>
          </QuestionWrapper>
          <QuestionWrapper className="QuestionWrapper">
            <FormSecondaryLabel>
              IF YOU WERE ALUMNI EMERALD IN THE 2022 - 2023 SEASON, DID YOU
              FINISH RANKED IN THE TOP 3 FOR THE 2022-2023 SHOW SEASON?
            </FormSecondaryLabel>
            <FormSecondaryLabel>
              WHERE YOU ALUMNI SAPPHIRE LAST YEAR?
            </FormSecondaryLabel>
            <RadioWrapper>
              <RadioButtonGroup
                data={Defaultdata2}
                onChange={handlehaveFinishedTopThreeAaAER}
                value={radioGroupValues?.haveFinishedTopThreeAaAER}
                name="haveFinishedTopThreeAaAER"
              />
            </RadioWrapper>
          </QuestionWrapper>
          <QuestionWrapper className="QuestionWrapper">
            <FormSecondaryLabel>
              RETURNING RIDERS WHO WHERE IN THE ALUMNI EMERALD DIVISION HAVE YOU
              COMPLETED TWO SEASONS COMPETING AS AN ALUMNI EMERALD RIDER?
            </FormSecondaryLabel>
            <RadioWrapper>
              <RadioButtonGroup
                onChange={handlehaveCompetedTwoSeasonsAsAER}
                value={radioGroupValues?.haveCompetedTwoSeasonsAsAER}
                name="haveCompetedTwoSeasonsAsAER"
              />
            </RadioWrapper>
          </QuestionWrapper>
          <InfoLabelWrapper id="InfoLabelWrapper">
            <div>
              <InfoLabel>2023 - 2024 Alumni Division: (Select one)</InfoLabel>
            </div>
          </InfoLabelWrapper>
          <TextFieldGrid>
            <CheckBoxWrapper className="CheckBoxWrapper">
              <RoundRadioBtn
                lookupData={
                  isRadioButtonSelected ? filteredRadioData : childDivisonData
                }
                onChange={handleReturningSelection}
                value={aluminiRiderData?.divisionClass?.id}
                validations={'required'}
                validator={simpleValidator.current}
              />
            </CheckBoxWrapper>
          </TextFieldGrid>
          <MainAddressWrapper>
            <TextFieldWrapper className="text-left-NoteField">
              <NoteFieldWrapper className="text-left">
                <span className="note-block">
                  <span className="note-head"></span> If you have competed in
                  “recognized shows” you may be asked to submit a printed copy
                  of your competition/points records along with this form. For
                  YEDA purposes, “recognized shows” includes AQHA, NRHA, NSBA,
                  APHA, PtHA, ApHC, IBHA, ABRA & POA. USEF and any other
                  association that keeps national records.
                </span>
              </NoteFieldWrapper>
            </TextFieldWrapper>
          </MainAddressWrapper>
        </BorderWrapper>
      </SubMainWrapper>
      <BottomRightWrapper id="BottomRightWrapper">
        <ButtonWrapper>
          <BackNextButton
            classNameBack="btn-back"
            classNameNxt="btn-nxt"
            onClickNxt={handleNextForm}
            onClickBack={handleBackForm}
            disableNext={valid}
            next="Next"
            back="Back"
          />
        </ButtonWrapper>
      </BottomRightWrapper>
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  color: #9597a6;
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
  height: calc(100vh - 242px);
  /* overflow: auto; */
  padding-top: 50px;
  position: relative;
  .first-para,
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-top: 40px;
    .first-para,
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    padding-top: 45px;
    .first-para,
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    padding-top: 50px;
    .first-para,
    label {
      font-size: 14px;
    }
  }
`;
const SubMainWrapper = styled.div`
  height: 100%;
  width: 100%;
`;
const BorderWrapper = styled.div`
  height: 100%;
  overflow: auto;
  /* border: 1px solid #585858; */
  border-radius: 20px;
  padding: 20px 60px 0px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
  position: relative;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: #201e1e !important;
    border-radius: 10px;
  }
  @media (min-width: 100px) and (max-width: 575px) {
    padding: 10px 5px 0px 5px;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    padding: 10px 10px 0px;
  }
`;
const InfoLabelWrapper = styled.div`
  padding: 10px 10px 10px 0px;
  text-align: start;
  width: 100%;
  div {
    border-bottom: 1px solid #434343;
    padding: 16px 0 6px;
  }
`;

const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 40px 20px;
  .styledCheckBox {
    label {
      color: #ffffff;
      color: #ffffff;
      font-family: 'Arimo';
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 1px;
    }
  }

  /* gap: 15px; */
  @media (min-width: 992px) and (max-width: 1199px) {
    /* gap: 5px; */
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    /* gap: 10px; */
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    /* gap: 15px; */
  }
`;
const NoteFieldWrapper = styled.div`
  margin: 0px 20px;
  &.text-left {
    margin: 0px 0px;
  }
  text-align: left;
  .note-block {
    display: inline-grid;
    font-size: 12px;
    .note-head {
    }
  }
  &.flex-NoteWrapper {
    .note-block {
      display: inline-grid;
      font-size: 12px;
      padding-top: 30px;
      .note-head {
      }
    }
  }
`;
const TextFieldWrapper = styled.div`
  /* flex: 0 0 50%;
  margin-bottom: 20px;*/
  width: 50% !important;
  margin-bottom: 20px;
  &.text-left-NoteField {
    width: 100% !important;
    .note-block {
      font-size: 13px;
    }
  }
  &.styled-TextFieldWrapper {
    display: grid;
    align-items: end;
  }

  /* padding: 0px 20px; */
  @media (min-width: 320px) and (max-width: 767.98px) {
    width: 100% !important;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 50% !important;
  }
`;
const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const QuestionWrapper = styled.div`
  width: 100%;
  padding: 30px 0 30px 0;
  border-bottom: 1px solid #434343;
  display: flex;
  flex-direction: column;
  ${FormSecondaryLabel} {
    margin: 0 0 15px 0;
  }
`;
const StyledFormSecondaryLabel = styled(FormSecondaryLabel)`
  margin: 0 0 20px 0;
  color: #a7a7a7 !important;
`;

const CheckBoxWrapper = styled.div`
  display: inline-flex;
  align-items: flex-start;
  .sub-label {
    margin-left: 10px;
  }
`;

const MainAddressWrapper = styled.div`
  margin-bottom: 40px;
  padding: 0 0 60px 0;
  .horizontal-line {
    display: flex;
    align-items: baseline;
    margin: 20px 20px 15px 20px;
  }
  .horizontal-line span {
    margin: 0 10px 0 0;
    white-space: nowrap;
    color: #4f4f4f;
    font-size: 15px;
  }
  .horizontal-line::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #434343;
    height: 3px;
  }
`;
const BottomRightWrapper = styled.div`
  z-index: 1;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 20px 20px;
  backdrop-filter: blur(10px);
  position: absolute;
  bottom: -17px;
  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    /* position: absolute;
    bottom: 40px;
    padding-top: 30px;
    background: linear-gradient(
      to bottom,
      rgb(48 48 48 / 79%) 0%,
      rgb(42 42 42) 100%
    ); */
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 40px;
    &.bg-color-change {
      bottom: 25px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 45px;
    &.bg-color-change {
      bottom: 30px;
      padding-top: 20px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 60px;
    &.bg-color-change {
      bottom: 20px;
      padding-top: 20px;
    }
  }
`;
const ButtonWrapper = styled.div``;
