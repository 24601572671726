import { GridCellProps } from '@progress/kendo-react-grid';
import StatusChip from 'app/components/StatusChip';
import styled from 'styled-components';

type Props = {
  className?: string;
  e: GridCellProps;
  onClick?: (e) => void;
};

const TeamStatusCell = ({ className, e, onClick }: Props) => {
  const data = e.dataItem?.status;
  return (
    <td className={className ?? ''}>
      {data ? (
        data.name === 'Active' ? ( // Check if status is 'Active'
          <StatusChip className="" StatusType={'active'} text={data.name} />
        ) : (
          <StatusChip
            className=""
            StatusType={'inactive-team'}
            text={data.name}
          />
        )
      ) : (
        <NoDataView>---</NoDataView>
      )}
    </td>
  );
};

export default TeamStatusCell;


const NoDataView = styled.div`
  display: inline-block;
  min-width: 67px;
  text-align: center;
`;
