import React, { useEffect } from 'react';
import styled from 'styled-components';
import SimpleReactValidator from 'simple-react-validator';

type Props = {
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string | number;
  placeholder?: string;
  label?: string;
  type?: string;
  style?: any;
  name?: string;
  className?: string;
  disabled?: boolean;
  inputClassName?: string;
  validator?: SimpleReactValidator;
  validations?: string;
  border?: string;
  maxLength?: number | undefined;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
  readOnly?: boolean | undefined;
  autoComplete?: string;
};
interface WrapperProps extends Props {
  border?: string;
}
export const TextField = ({
  onChange,
  value,
  placeholder,
  label,
  type,
  style,
  name,
  className,
  disabled,
  inputClassName,
  validator,
  validations,
  border,
  maxLength,
  onKeyDown,
  onKeyUp,
  readOnly,
  autoComplete,
}: Props) => {
  const simpleValidator = React.useRef(validator);
  const handleBlur = () => {
    simpleValidator?.current?.showMessageFor(label ? label : 'field');
  };

  return (
    <InputWrapper
      className={`${className} ${
        validator && !simpleValidator?.current?.fieldValid(label!)
          ? 'error'
          : 'default'
      }`}
    >
      <div className="label">{label}</div>
      <input
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        type={type}
        style={style}
        name={name}
        disabled={disabled}
        className={inputClassName}
        onBlur={handleBlur}
        maxLength={maxLength}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        readOnly={readOnly}
        autoComplete={autoComplete ? autoComplete : 'new-password'}
      />
      {simpleValidator?.current?.message(
        label ? label : 'field',
        value,
        validations ?? '',
      )}
    </InputWrapper>
  );
};
const InputWrapper = styled.div`
  margin: 0px 12px;
  .srv-validation-message {
    color: #f44336;
    font-size: 12px;
    display: inline-block;
  }
  &.error {
    input {
      border-bottom: 1px solid #f44336 !important;
    }
  }
  &.default {
    input {
      border: transparent !important;
    }
  }
  input {
    background: #434343;
    border-color: transparent;
    height: 35px;
    width: 100%;
    padding: 0 10px;
    color: #ffffff;
    border-radius: 6px;
    text-size-adjust: 100%;
    ::placeholder {
      color: #9597a6;
      padding-left: 10px;
      font-weight: 500;
      font-size: 12px;
    }
    :focus-visible {
      outline: unset !important;
    }
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: #ffffff !important;
    background-clip: text !important;
    box-shadow: inset 0 0 0 50px #434343 !important;
    border-radius: 6px;
    border: none !important;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  .label {
    font-family: 'Roboto';
  }
  //Responsive
  @media (min-width: 100px) and (max-width: 319px) {
    .label {
      font-size: 11px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .label {
      font-size: 12px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .label {
      font-size: 12px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .label {
      font-size: 13px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .label {
      font-size: 13px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .label {
      font-size: 14px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .label {
      font-size: 16px;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    .label {
      font-size: 17px;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    .label {
      font-size: 17px;
    }
  }
  //Component Responsive
  @media (min-width: 100px) and (max-width: 319px) {
    input {
      height: 30px;
      font-size: 12px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    input {
      height: 30px;
      font-size: 12px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    input {
      font-size: 12px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    input {
      font-size: 13px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    input {
      font-size: 13px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    input {
      font-size: 14px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    input {
      font-size: 16px;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    input {
      font-size: 17px;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    input {
      font-size: 17px;
    }
  }
`;
