import React from 'react';
import { SpinnerCircularFixed } from 'spinners-react';
import styled from 'styled-components';

type Props = {
  showText?: boolean;
  text?: string;
};

const CircularLoader = ({ showText, text }: Props) => {
  return (
    <LoaderWrapper>
      <SpinnerCircularFixed
        size={50}
        thickness={175}
        speed={100}
        color="rgba(48, 111, 219, 1)"
        secondaryColor="rgba(112, 112, 112, 1)"
      />
      {showText && <span>Please wait while we retrieve your information</span>}
    </LoaderWrapper>
  );
};

export default CircularLoader;
const LoaderWrapper = styled.div`
  text-align: center;
  span {
    display: block;
  }
`;
