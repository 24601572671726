import { CommonPageWrapper, HeadingWrapper } from 'admin/components/wrappers';
import AdminCustomButton from 'app/components/AdminCustomButton';
import { AdminSearch } from 'app/components/AdminSearchField';
import CustomBreadCrumb from 'app/components/CustomBreadCrumb';
import { BlurredLabel, PageMainLabel } from 'app/components/SHLabel';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDebounce } from 'hooks/useDebounce';
import useCommonLookUpDatas from 'hooks/useCommonLookupData';
import { getLookupArray, useNavigateToRoute } from 'utils/common';
import CoachesTable from './CoachesTable';
import useCoachApi from 'admin/hooks/useCoachApi';
import { useLocation, useParams } from 'react-router-dom';
import { defaultCoachValue } from '../CoachIndividualPage/components/coachCreateEditPage/staticDatas';
import { Coach, GeneralLookup } from 'services/openapi';
import AdminDropDown from 'app/components/AdminDropDown';
import AdminMultiSelectDropDown from 'admin/components/AdminMultiSelectDropdown';
import { ShowMangeRiderFilteredData } from 'admin/components/AdminMultiSelectDropdown/FilteredList';

const CoachesPage = () => {
  const currentLocation = useLocation();
  const isPastDataFlag = currentLocation.pathname.includes('past-data');
  const breadCrumpitems = [
    {
      id: '',
      text: 'Showyeda admin',
      disabled: true,
    },
    {
      id: '',
      text: isPastDataFlag ? 'Past-Data' : 'Coaches',
      disabled: true,
    },
  ];
  if (isPastDataFlag) {
    breadCrumpitems.push({
      id: '',
      text: 'Coaches',
      disabled: true,
    });
  }
  const {
    fetchManageGetCoaches,
    coachProfileData,
    loading,
    CoachActiveOrInactive,
    deleteCoachList,
  } = useCoachApi();
  const redirect = useNavigateToRoute();
  const { id: coachId } = useParams();
  const { getAllLookupData, lookUps } = useCommonLookUpDatas();
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [active, setActive] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [pageSkip, setPageSkip] = useState<number | undefined>(0);
  const debounceCoachSearch = useDebounce(searchTerm);
  const handleItemClick = (item: string) => {};
  const handleItemIdClick = (itemId: string) => {
    setSelectedItemId(itemId === selectedItemId ? null : itemId);
  };
  const [coachPayload, setCoachPayload] =
    React.useState<Coach>(defaultCoachValue);
  const [sortField, setSortField] = useState<string>('coachName');
  const [sortOrder, setSortOrder] = useState<number>(0);
  const [lookupData, setLookupData] = useState('');
  const [teamIds, setTeamIds] = useState('');
  const [preDataList, setPreDataList] =
    useState<{ name: string; id: string; type: string }[]>();
  const isTeam = preDataList?.find(item => item.type === 'TEAM');
  const teamIdsArray = teamIds ? teamIds.split(',') : [];
  const teamId = isTeam ? teamIdsArray : [];
  const handleDropdownChange = (data: GeneralLookup, name: string) => {
    fetchManageGetCoaches(
      data.id,
      teamId,
      debounceCoachSearch,
      selectedItemId ?? '',
      sortField,
      sortOrder,
      0,
      10,
    );
    setCoachPayload(prevData => ({
      ...prevData,
      [name]: data,
    }));
  };
  const handleSearch = e => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    if (debounceCoachSearch.length > 0) {
      fetchManageGetCoaches(
        '',
        teamId,
        debounceCoachSearch,
        selectedItemId ?? '',
        sortField,
        sortOrder,
        0,
        10,
      );
    } else if (
      (debounceCoachSearch.length === 0 && selectedItemId) ||
      selectedItemId === null
    ) {
      fetchManageGetCoaches(
        '',
        teamId,
        debounceCoachSearch,
        selectedItemId ?? '',
        sortField,
        sortOrder,
        0,
        10,
      );
    } else {
      fetchManageGetCoaches(
        '',
        teamId,
        debounceCoachSearch,
        selectedItemId ?? '',
        sortField,
        sortOrder,
        pageSkip,
        10,
      );
    }
    if (pageSkip !== 0) {
      if (debounceCoachSearch?.length! >= 0 || selectedItemId) {
        setPageSkip(0);
      }
    }
  }, [debounceCoachSearch, selectedItemId, teamIds]);
  useEffect(() => {
    getAllLookupData('ACTIVESTATUS,SEASON,TEAM');
  }, []);
  return (
    <>
      <CommonPageWrapper>
        <HeadingWrapper>
          <PageMainLabel>Coaches</PageMainLabel>
          <CustomBreadCrumb preData={breadCrumpitems} />
        </HeadingWrapper>
        <HeadingWrapper>
          <ButtonWrapper>
            {!isPastDataFlag && (
              <AdminCustomButton
                buttonType="primary"
                isActive={false}
                className="fill-button"
                onClick={() => {
                  setActive(!active);
                  if (!coachId) {
                    redirect(`/admin/coaches/create`);
                  }
                }}
              >
                Add New Coach
              </AdminCustomButton>
            )}
            <BlurredLabel style={{ whiteSpace: 'nowrap' }}>
              Popular filter
            </BlurredLabel>
            {getLookupArray('ACTIVESTATUS', lookUps).map(item => (
              <AdminCustomButton
                key={item.id}
                buttonType="primary"
                isActive={false}
                className={
                  selectedItemId?.includes(item?.id)
                    ? 'active-inactive'
                    : 'Inactive-button'
                }
                onClick={() => {
                  handleItemIdClick(item?.id);
                }}
              >
                {item?.name}
              </AdminCustomButton>
            ))}
            <AdminMultiSelectDropDown
              data={ShowMangeRiderFilteredData}
              setLookupData={setLookupData}
              filteredData={getLookupArray(lookupData, lookUps)}
              lookUps={lookUps}
              setTeamIds={setTeamIds}
              lookupData={lookupData}
              setPreDataList={setPreDataList}
              preDataList={preDataList}
            />
            {isPastDataFlag && (
              <CustomDropDown
                label=""
                placeholder="Select season"
                onDataChange={data => handleDropdownChange(data, 'season')}
                defaultValue={coachPayload.season}
                data={getLookupArray('SEASON', lookUps)}
                className="field-style filter-dropdown-select"
                popupStyles="medium-dropdown"
              />
            )}
          </ButtonWrapper>
          <div style={{ width: '283px', paddingTop: '30px' }}>
            <AdminSearch
              placeholder="Search by name"
              onSearchTextEntering={handleSearch}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          </div>
        </HeadingWrapper>
        {/* Coaches Table */}
        <CoachesTable
          setPageSkip={setPageSkip}
          pageSkip={pageSkip}
          debounceCoachSearch={debounceCoachSearch}
          selectedItemId={selectedItemId}
          lookup={getLookupArray('ACTIVESTATUS', lookUps)}
          fetchManageGetCoaches={fetchManageGetCoaches}
          coachProfileData={coachProfileData}
          deleteCoachList={deleteCoachList}
          CoachActiveOrInactive={CoachActiveOrInactive}
          sortField={sortField}
          sortOrder={sortOrder}
          setSortField={setSortField}
          setSortOrder={setSortOrder}
          loading={loading}
          teamId={teamId}
        />
      </CommonPageWrapper>
    </>
  );
};
export default CoachesPage;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding: 30px 0 0;
  .field-style {
    display: flex;
  }
`;
const CustomDropDown = styled(AdminDropDown)`
  width: 160px;
  .k-dropdownlist {
    max-height: 31px;
    border-radius: 6px !important;
  }
  .k-input-inner {
    padding: 0 5px !important;
  }
  .k-input-value-text {
    margin: 2px 4px 2px 4px;
    font-size: 12px !important;
    letter-spacing: 0px;
    color: #1e1e1e;
    text-align: left;
  }
`;
