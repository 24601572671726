import { CommonPageWrapper, HeadingWrapper } from 'admin/components/wrappers';
import { PageMainLabel } from 'app/components/SHLabel';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import AdminDropDown from 'app/components/AdminDropDown';
import AdminCustomButton from 'app/components/AdminCustomButton';
import useCommonLookUpDatas from 'hooks/useCommonLookupData';
import { GeneralLookup, TeamRosterRiderClass } from 'services/openapi';
import { getLookupArray } from 'utils/common';
import { TableSkeletonLoader } from 'app/components/TableSkeletonLoader';
import CustomBreadCrumb, {
  breadCrumbDataModel,
} from 'app/components/CustomBreadCrumb';
import { SHToolTip } from 'app/components/SHToolTip';
import useTeamRosterApi from 'admin/hooks/useTeamRosterApi';
import CustomDataGrid from 'app/components/CustomDataGrid/Index';
import { ReportValueCell } from '../cells/ReportValueCell';
import { ReportHeaderCell } from '../cells/ReportHeaderCell';
import {
  defaultRosterFilterModel,
  teamRosterBreadCrumbItems,
  defaultRosterFilterData,
  staticTeamRosterColumns,
} from '../static';
import { GridColumnProps } from '@progress/kendo-react-grid';
import { RosterValueCell } from './cell/valueCell';
import { RosterMappedValueCell } from './cell/mappedValueCell';

type Props = {};

export const TeamRoster = ({}: Props) => {
  const {
    loading,
    getTeamRosterReportDetails,
    teamRosterData,
    setTeamRosterData,
    downloadTeamRosterReport,
  } = useTeamRosterApi();
  const { getAllLookupData, lookUps, getTeamLookupWithSeasonId, teamLookUps } =
    useCommonLookUpDatas();
  const [filterPayload, setFilterPayload] = useState<defaultRosterFilterModel>(
    defaultRosterFilterData,
  );
  const [pageSkip, setPageSkip] = useState<number | undefined>(0);
  const [breadcrumbPreData, setBreadcrumbPreData] = useState<
    breadCrumbDataModel[]
  >(teamRosterBreadCrumbItems);

  const handleDownloadRiderpointReport = async () => {
    try {
      const url = await downloadTeamRosterReport(
        filterPayload.season.id,
        filterPayload.team.id,
      );
      // Extract filename from URL
      const filename = url.split('/').pop();

      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const objectUrl = URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = objectUrl;
          link.download = filename!; // Use the extracted filename

          // Append link to body (required for Firefox)
          document.body.appendChild(link);

          // Trigger download
          link.click();

          // Clean up
          document.body.removeChild(link);
          URL.revokeObjectURL(objectUrl);
        })
        .catch(error => {});

      return url;
    } catch (error) {
      throw error;
    }
  };
  const handlePageChange = event => {
    setPageSkip(event.page.skip);
    getTeamRosterReportDetails(
      filterPayload.season.id,
      filterPayload.team.id,
      event.page.skip,
      10,
    );
  };
  const handleSeasonChange = data => {
    setFilterPayload({
      ...filterPayload,
      season: data,
      team: { id: '', name: '' },
    });
    getTeamLookupWithSeasonId(data.id);
  };
  const handleTeamChange = data => {
    setFilterPayload({
      ...filterPayload,
      team: data,
    });
  };

  const handleSaveClick = () => {
    if (filterPayload) {
      getTeamRosterReportDetails(
        filterPayload.season.id,
        filterPayload.team.id,
        pageSkip,
        10,
      );
    }
  };
  const handleCancelClick = () => {
    setTeamRosterData(undefined);
    setFilterPayload(defaultRosterFilterData);
  };

  const mappedColumns = [...staticTeamRosterColumns].map((column, index) => ({
    field: column.field,
    cell: (e: any): React.ReactElement | null => {
      const fieldValue = e.dataItem[column?.field];
      if (column.field === 'class') {
        const category = e.dataItem?.category?.name ?? '';
        const division = e.dataItem?.division?.name ?? '';
        return (
          <RosterValueCell firstTitle={category} secondTitle={division} e={e} />
        );
      } else if (Array.isArray(fieldValue)) {
        if (fieldValue.length === 0) {
          return <RosterValueCell />;
        } else {
          return (
            <td style={e?.style} className={e?.className}>
              {fieldValue.map((item: TeamRosterRiderClass, i: number) => (
                <RosterMappedValueCell
                  title={item.classCode ?? ''}
                  value={item.value ?? ''}
                  e={e}
                />
              ))}
            </td>
          );
        }
      } else if (column?.field && column?.field in e.dataItem) {
        return <RosterValueCell firstTitle={e.dataItem[column?.field]} e={e} />;
      }
      return null;
    },
    locked: true,
    width: column.width,
    headerCell: e => (
      <ReportHeaderCell title={column.title} index={index} length={4} e={e} />
    ),
  }));
  useEffect(() => {
    getAllLookupData('TEAM,SEASON');
  }, []);
  return (
    <StyledCommonPageWrapper>
      <TitleWrapper>
        <PageMainLabel>Team Roster Report</PageMainLabel>
        <CustomBreadCrumb
          preData={breadcrumbPreData}
          setPreData={setBreadcrumbPreData}
        />
      </TitleWrapper>
      <ButtonWrapper>
        <FilterWrapper>
          <DropdownWrapper>
            <AdminDropDown
              label="Season"
              placeholder="Select Season"
              data={getLookupArray('SEASON', lookUps)}
              onDataChange={handleSeasonChange}
              defaultValue={filterPayload.season}
              isLoader={false}
            />
            <AdminDropDown
              label="Team"
              placeholder="Select Team"
              data={teamLookUps}
              onDataChange={handleTeamChange}
              defaultValue={filterPayload.team}
              isLoader={false}
              disabled={filterPayload.season.id === ''}
            />
          </DropdownWrapper>
          <ActionWrapper>
            <AdminCustomButton
              buttonType="cancel"
              onClick={handleCancelClick}
              disabled={
                filterPayload.season.id === '' || filterPayload.team.id === ''
              }
              className="action-button"
            >
              Cancel
            </AdminCustomButton>
            <AdminCustomButton
              buttonType="save"
              onClick={handleSaveClick}
              disabled={
                filterPayload.season.id === '' || filterPayload.team.id === ''
              }
              className="action-button"
            >
              Filter
            </AdminCustomButton>
          </ActionWrapper>
        </FilterWrapper>
        <AdminCustomButton
          buttonType="primary"
          isActive={false}
          className="fill-button"
          onClick={handleDownloadRiderpointReport}
          disabled={
            typeof teamRosterData !== 'object' ||
            teamRosterData === null ||
            teamRosterData === undefined ||
            teamRosterData.riders?.length
              ? false
              : true
          }
        >
          Download
        </AdminCustomButton>
      </ButtonWrapper>
      {loading ? (
        <TableSkeletonLoader rowContent={10} width={100} headingWidth={40} />
      ) : (
        <CustomDataGrid
          data={teamRosterData?.riders ?? []}
          columns={mappedColumns}
          onPageChange={event => {
            handlePageChange(event);
          }}
          pageSize={10}
          totalCount={teamRosterData?.pager?.count}
          skip={pageSkip}
          showPagination
        />
      )}
    </StyledCommonPageWrapper>
  );
};

const StyledCommonPageWrapper = styled(CommonPageWrapper)`
  padding-bottom: 52px;
  @media (min-height: 100px) and (max-height: 560px) {
    padding-bottom: 0px;
  }
  .k-virtual-content {
    max-height: calc(100vh - 363px);
    min-height: unset !important;
    height: 100% !important;

    @media (min-height: 100px) and (max-height: 560px) {
      max-height: calc(100vh - 311px);
    }
  }
  height: 100vh;
  .top-header-style {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    div:nth-child(1) {
      width: 100%;
    }
    .dynamic-title {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .date {
      font-size: 13px !important;
      font-weight: 400 !important;
      padding-top: 7px !important;
    }
  }
  .k-grid-header {
    table {
      tr {
        th {
          padding: 10px 4px 9px 4px !important;
          vertical-align: top;
          &:has(.green-bg) {
            background-color: #8ee08e !important;
          }
          &:nth-child(n + 5) {
            position: unset !important;
            border-right: unset !important;
            border-left: unset !important;
            text-align: center;
          }
        }
      }
    }
  }
  .k-grid-container {
    table {
      tr td:nth-child(n + 5) {
        position: unset !important;
        border-right: unset !important;
        border-left: unset !important;
        > div {
          text-align: center;
        }
      }
    }
  }
  .k-grid-content tr {
    .k-grid-content-sticky {
      cursor: unset !important;
      :hover {
        cursor: unset !important;
        background: inherit !important;
      }
    }
    :hover {
      td {
        background: inherit !important;
      }
    }
  }
  .k-grid tbody > tr:not(.k-detail-row):hover,
  .k-grid tbody > tr:not(.k-detail-row).k-hover {
    background-color: #dddddd !important;
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0 30px 0;
  .fill-button {
    margin-right: 0px;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  .fill-button {
    button {
      margin-top: 5px;
      height: 31px;
    }
    &:has(.k-disabled) {
      cursor: not-allowed;
    }
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 0px 20px 0px;
`;
const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 40%;
  gap: 15px;
  .SHdropdown {
    width: 50%;
    .k-dropdownlist {
      max-height: 31px;
      border-radius: 8px !important;
    }
  }
  .k-input-inner {
    padding: 0 5px !important;
  }
  .k-input-value-text {
    margin: 2px 4px 2px 4px;
    font-size: 12px !important;
    letter-spacing: 0px;
    color: #1e1e1e;
    text-align: left;
  }
`;
const ActionWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: start;
  width: 40%;
  padding-top: 25px;
  padding-left: 15px;
  .k-button {
    height: 31px !important;
    span {
      margin: 0px 15px;
    }
  }
  .action-button:has(.k-disabled) {
    cursor: not-allowed;
  }
`;
