import styled from 'styled-components';
import '@fontsource/roboto';
import React, { useState } from 'react';
import { BackNextButton } from 'app/components/BackNextButton';
import RoundRadioBtn from 'app/components/RoundRadioBtn';
import arrowImg from './assets/img/icon/arrow.png';
import { EWD, GeneralLookup } from 'services/openapi';
import RadioButtonGroup from 'app/components/RadioButtonGroup';
import SimpleReactValidator from 'simple-react-validator';
import SHdropdown from 'app/components/SHdropdown';
import CustomDragAndDropFiles from 'app/components/CustomDragAndDropFiles';
import { useRiderRegistrationStateSlice } from 'app/pages/RiderRegistrationPage/slice';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';

type Props = {
  onClickBackDivions?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  handleEwdNewMemberSubmit: (requestBody?: EWD) => void;
  setNewMemberData: React.Dispatch<React.SetStateAction<EWD>>;
  newMemberData: EWD;
  categoryData?: GeneralLookup[];
  ewdDivision: GeneralLookup[] | undefined;
  seasonDivison: (string[] | undefined)[] | undefined;
  setIsphotoforPromotion: React.Dispatch<React.SetStateAction<boolean | null>>;
  isphotoforPromotion: boolean | null;
  setPhotoUploadPayload: React.Dispatch<
    React.SetStateAction<{
      formData?:
        | {
            file?: Blob | undefined;
          }
        | undefined;
    }>
  >;
  photoUploadPayload: {
    formData?:
      | {
          file?: Blob | undefined;
        }
      | undefined;
  };
  setFileAvailability: React.Dispatch<React.SetStateAction<boolean>>;
  fileAvailability: boolean;
  setUploadFile: React.Dispatch<React.SetStateAction<File | null | undefined>>;
  uploadFile: File | null | undefined;
  setIsNotImage?: React.Dispatch<React.SetStateAction<boolean>>;
  isNotImage?: boolean;
};
const EWDDivisionsNew = ({
  onClickBackDivions,
  handleEwdNewMemberSubmit,
  setNewMemberData,
  newMemberData,
  categoryData,
  ewdDivision,
  seasonDivison,
  setIsphotoforPromotion,
  isphotoforPromotion,
  setPhotoUploadPayload,
  photoUploadPayload,
  setFileAvailability,
  fileAvailability,
  setUploadFile,
  uploadFile,
  setIsNotImage,
  isNotImage,
}: Props) => {
  const currentSeason = Cookies.get('isAdminLiveFiltered');
  const dispatch = useDispatch();
  const { actions: manageAppActions } = useRiderRegistrationStateSlice();
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [valid, setValid] = useState<boolean>(false);
  const [filteredDivisonTwo, setFilteredDivisonTwo] = useState<GeneralLookup[]>(
    [],
  );

  const handlePhotoPromotionSelection = event => {
    setNewMemberData({ ...newMemberData, usePhotoForPromotion: event.value });
    setIsphotoforPromotion(event.value);
  };
  const handleDivisionOneChange = e => {
    const filteringTitles = ['Asst', 'Ind'];
    const selectedGem =
      seasonDivison &&
      seasonDivison[0]?.filter(item => {
        return e.name?.includes(item);
      });
    const titleSelected = filteringTitles.find(title => {
      return e.name?.includes(title);
    });
    const filteredDivisonArray = ewdDivision?.filter(item =>
      selectedGem && titleSelected
        ? item.name.includes(selectedGem.toString()) &&
          item.name.includes(titleSelected)
        : false,
    );
    const divisonTwofiltered = filteredDivisonArray?.filter(item => item !== e);
    setNewMemberData({
      ...newMemberData,
      divisionClassOne: e,
      divisionClassTwo: undefined,
    });
    setFilteredDivisonTwo(divisonTwofiltered ?? []);
  };
  const handleDivisionTwo = e => {
    setNewMemberData({ ...newMemberData, divisionClassTwo: e });
  };
  const handleFileUpload = e => {
    setIsNotImage?.(false);
    var file = e.target.files[0];
    dispatch(manageAppActions.setPhotoUpload(file));

    setUploadFile(file);
    setPhotoUploadPayload({
      ...photoUploadPayload,
      formData: { file: file },
    });
  };
  const handleDragFileUpload = e => {
    var dragFile = e?.dataTransfer?.files[0];
    if (dragFile && dragFile.type.startsWith('image/')) {
      dispatch(manageAppActions.setPhotoUpload(dragFile));

      setUploadFile?.(dragFile);
      setPhotoUploadPayload?.({
        ...photoUploadPayload,
        formData: { file: dragFile },
      });
      setIsNotImage?.(false);
    } else {
      setUploadFile?.(undefined);
      setPhotoUploadPayload?.({
        ...photoUploadPayload,
        formData: { file: undefined },
      });
      setIsNotImage?.(true);
    }
  };
  const handleRemoveFile = e => {
    setIsNotImage?.(false);
    setUploadFile(undefined);
    setPhotoUploadPayload({
      ...photoUploadPayload,
      formData: { file: undefined },
    });
  };
  const handleFileOnCancelClick = e => {
    setIsNotImage?.(false);
    setUploadFile(undefined);
    setPhotoUploadPayload({
      ...photoUploadPayload,
      formData: { file: undefined },
    });
  };
  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(!formValid);
  }, [isphotoforPromotion]);

  React.useEffect(() => {
    if (uploadFile === undefined) {
      setFileAvailability(true);
    } else {
      setFileAvailability(false);
    }
  }, [uploadFile]);
  return (
    <MainWrapper>
      <OverflowWrapper>
        <p className="para-two">
          <i>EWD Division: (select one)</i>
        </p>
        <p className="para-three">
          * First you have to choose Division 1 and then choose Division 2.
        </p>
        {/* <RadioBtnWrapper>
          <RoundRadioBtn
            lookupData={ewdDivision}
            onChange={handleReturningSelection}
            value={newMemberData.divisionClass.id}
            validations={'required'}
            validator={simpleValidator.current}
          />
        </RadioBtnWrapper> */}
        <TextFieldGrid>
          <TextFieldWrapper className="TextFieldWrapper ">
            <SHdropdown
              className="drop-down"
              label="Division 1"
              data={ewdDivision}
              onDataChange={handleDivisionOneChange}
              defaultValue={newMemberData.divisionClassOne}
              validations={'required'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper ">
            <SHdropdown
              className="drop-down"
              label="Division 2"
              data={filteredDivisonTwo}
              onDataChange={handleDivisionTwo}
              defaultValue={newMemberData.divisionClassTwo}
            />
          </TextFieldWrapper>
        </TextFieldGrid>
        <PhotoWrapper className="horizontal-line">
          <p className="photo">Photo Release</p>
        </PhotoWrapper>
        <p>
          May your likeness in photos taken in photos by our official
          photographers be used in promotional literature for YEDA.
        </p>
        <div className="photoCheckboxWrapper">
          <YesNoWrapper>
            <RadioButtonGroup
              onChange={handlePhotoPromotionSelection}
              value={isphotoforPromotion}
              validations={'required'}
              validator={simpleValidator.current}
            />
          </YesNoWrapper>
          <span className="small-span">
            (If no, please enclose a current photo of the rider so that we can
            ensure their likeness is not used.)
          </span>
        </div>
        {isphotoforPromotion === false && (
          <PhotoUploadWrapper>
            <h3 className="photo-title"> Upload your current photo below. </h3>
            <CustomDragAndDropFiles
              className="dragNdrop"
              onCancel={e => handleFileOnCancelClick(e)}
              onChange={e => handleFileUpload(e)}
              uploadBtnDisabled={!fileAvailability}
              onRemoveFile={handleRemoveFile}
              chooseFileBtnDisabled={!fileAvailability}
              uploadFile={uploadFile}
              accept=".jpg, .jpeg, .png"
              onDragChange={e => handleDragFileUpload(e)}
              isNotImageValidation={isNotImage}
            />
          </PhotoUploadWrapper>
        )}
        <InfoWrapper>
          <p className="para-heading">
            <i>PLEASE NOTE</i>
          </p>
          <p className="para-content">
            Per Rule YEDA Rulebook each participant in the equestrians with
            disabilities competition must be 10 years of age or in the 4th grade
            or older. With a diagnosed mental or physical condition attested to
            by a licensed medical physician. The Special Diagnosis form must be
            completed, signed by a licensed medical doctor and returned to YEDA
            with this membership form.
          </p>
          <p className="para-heading">
            <i>NOTICE</i>
          </p>
          <p className="para-content">
            YEDA DOES NOT ASSUME ANY RESPONSIBILITY FOR THE SAFETY OF
            PARTICIPANTS AND/OR ATTENDEES AT ALL EQUINE EVENTS. EQUINE EVENTS
            ARE INHERENTLY RISKY. “INHERENT RISK OF AN EQUINE ACTIVITY" MEANS A
            DANGER OR CONDITION THAT IS AN INTEGRAL PART OF AN EQUINE ACTIVITY,
            INCLUDING, BUT NOT LIMITED TO, ANY OF THE FOLLOWING:(A) THE
            PROPENSITY OF AN EQUINE TO BEHAVE IN WAYS THAT MAY RESULT IN INJURY,
            DEATH, OR LOSS TO PERSONS ON OR AROUND THE EQUINE; (B) THE
            UNPREDICTABILITY OF AN EQUINE'S REACTION TO SOUNDS, SUDDEN MOVEMENT,
            UNFAMILIAR OBJECTS, PERSONS, OR OTHER ANIMALS; (C) HAZARDS,
            INCLUDING, BUT NOT LIMITED TO, SURFACE OR SUBSURFACE CONDITIONS; (D)
            A COLLISION WITH ANOTHER EQUINE, ANOTHER ANIMAL, A PERSON, OR AN
            OBJECT; OR (E) THE POTENTIAL OF AN EQUINE ACTIVITY PARTICIPANT TO
            ACT IN A NEGLIGENT MANNER THAT MAY CONTRIBUTE TO INJURY, DEATH, OR
            LOSS TO THE PERSON OF THE PARTICIPANT OR TO OTHER PERSONS,
            INCLUDING, BUT NOT LIMITED TO, FAILING TO MAINTAIN CONTROL OVER AN
            EQUINE OR FAILING TO ACT WITHIN THE ABILITY OF THE PARTICIPANT. THE
            PARENT OR GUARDIAN, ON BEHALF OF THEIR PARTICIPATING MINOR, ASSUMES
            ALL RISK OF PERSONAL INJURY OR PROPERTY DAMAGE OCCURRING AS A RESULT
            OF THE PARTICIPATION AND DOES HEREBY RELEASE AND DISCHARGE YEDA AND
            SHOW MANAGEMENT, THEIR RESPECTIVE OFFICERS, DIRECTORS,
            REPRESENTATIVES, AND EMPLOYEES FROM ANY AND ALL LIABILITY, WHENEVER
            OR HOWEVER ARISING FROM SUCH PARTICIPATION.
          </p>
          <ListDataWrapper>
            <ul>
              <div className="list-style">
                <img src={arrowImg} alt="List-arrow" />
                <li>
                  COACH GUARANTEES THEY ARE RESPONSIBLE FOR ALUMNI AND ASKING
                  FOR RE-RIDES
                </li>
              </div>
              <div className="list-style">
                <img src={arrowImg} alt="arrow" />
                <li>
                  COACH AND TEAM WILL BE RESPONSIBLE FOR PROVIDING HORSES TO
                  COVER ALUMNI RIDES
                </li>
              </div>
              <div className="list-style">
                <img src={arrowImg} alt="arrow" />
                <li>
                  COACH GUARANTEES THE ALUMNI IS QUALIFIED IN THEIR ABILITY TO
                  RIDE UNFAMILIAR HORSES IN A GROUP
                </li>
              </div>
            </ul>
          </ListDataWrapper>
          <p className="notice-para">
            RIDING INSTRUCTION IN REGULAR LESSONS
            <br />
            OR <br />
            RIDING THEIR OWN HORSES OFF PREMISES, BOTH ON OWNED AND NON-OWNED
            HORSES
          </p>
          <p className="para-content">
            YOUR SIGNATURE BELOW ACKNOWLEDGES THAT COACH, GUARDIAN, AND RIDER
            HAVE FULLY REVIEWED THE YEDA RULE BOOK{' '}
            {currentSeason === undefined ? '2023 - 2024' : currentSeason} AND
            UNDERSTAND THE CONDUCT, COMPETITION REQUIREMENTS, AND POSSIBLE
            SANCTIONS OUTLINED THEREIN. YOU ARE HEREBY GUARANTEEING THAT COACH,
            GUARDIAN, AND RIDER WILL ADHERE TO YEDA SOCIAL MEDIA POLICY AND
            RULES OF THE ORGANIZATION. YOUR SIGNATURE ADDITIONALLY ACKNOWLEDGES
            THAT THE RIDER IS A FULLTIME STUDENT IN GOOD ACADEMIC STANDING.
            COACH, GUARDIAN, AND RIDER ARE SUBJECT TO ALL RULES, FINES AND
            DISCIPLINARY ACTIONS FOR VIOLATIONS OF THE YEDA RULES.
          </p>
        </InfoWrapper>
        <ButtonWrapper className="bg-color-change">
          <BackNextButton
            classNameBack="btn-back"
            classNameNxt="btn-nxt"
            onClickNxt={() => handleEwdNewMemberSubmit(newMemberData)}
            onClickBack={onClickBackDivions}
            disableNext={valid}
            next="Submit"
            back="Cancel"
          />
        </ButtonWrapper>
      </OverflowWrapper>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  color: #9597a6;
  width: 100%;
  height: calc(100vh - 280px);
  position: relative;
  padding: 20px 0px 0px;
  .para-heading {
    font-family: 'Roboto';
    font-size: 14px;
    color: #ff9900;
  }
  .para-two {
    color: #ff9900;
    border-bottom: 1px solid #585858;
    padding: 0px 0px 10px;
    font-size: 11px;
    margin-bottom: 20px;
  }
  .para-three {
    margin-bottom: 20px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    label {
      font-size: 14px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    label {
      font-size: 14px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    label {
      font-size: 15px;
    }
  }
`;
const OverflowWrapper = styled.div`
  height: 100%;
  overflow: auto;
  padding-bottom: 100px;
  .small-span {
    font-size: 11px;
    color: #4f4f4f;
  }
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
  .photoCheckboxWrapper {
    display: flex;
    align-items: center;
  }
  @media (min-width: 100px) and (max-width: 575px) {
    .photoCheckboxWrapper {
      display: block;
    }
  }
`;
const RadioBtnWrapper = styled.div`
  padding: 30px 10px;
  .k-radio {
    background-color: transparent;
    border: 1px solid #ced4da;
  }
  .k-radio:checked,
  .k-radio.k-checked {
    border-color: 1px solid #0d6efd;
    color: white;
    background-color: #0d6efd;
  }
  .k-radio-list {
    flex-flow: row;
    justify-content: flex-start;
    gap: 50px;
    display: grid;
    grid-template-columns: repeat(2, 250px);
    grid-gap: 10px;
  }
  .k-radio-item,
  .k-radio-list-item {
    color: #9597a6;
    gap: 10px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .k-radio-list {
      display: block;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .k-radio-list {
      display: block;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .k-radio-list {
      display: block;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .k-radio-list {
      display: block;
    }
  }
`;
const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  ::placeholder {
    color: #8f8e8e !important;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    display: block;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    display: block;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    display: block;
  }
`;
const TextFieldWrapper = styled.div`
  width: 50%;
  margin-bottom: 20px;
  @media (min-width: 100px) and (max-width: 767px) {
    width: 100%;
  }
`;
const PhotoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  label {
    color: #4f4f4f;
    font-style: oblique;
  }

  ::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #585858;
  }

  p {
    margin: 0 10px 0px 0px;
    white-space: nowrap;
  }
  .photo {
    color: #4f4f4f;
  }
`;
const YesNoWrapper = styled.div`
  .k-radio {
    background-color: transparent;
    border: 1px solid #ced4da;
  }
  .k-radio:checked,
  .k-radio.k-checked {
    border-color: 1px solid #0d6efd;
    color: white;
    background-color: #0d6efd;
  }
  .k-radio-list {
    flex-flow: row;
    justify-content: flex-start;
    gap: 50px;
    display: grid;
    grid-template-columns: repeat(2, 80px);
    grid-gap: 10px;
  }
  .k-radio-item,
  .k-radio-list-item {
    color: #9597a6;
    gap: 10px;
  }
`;
const PhotoUploadWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  .photo-title {
    color: #a7a7a7;
    font-family: 'Roboto';
    font-size: 15px;
    text-align: center;
  }
`;
const InfoWrapper = styled.div`
  width: 100%;
  border: 1px solid #585858;
  border-radius: 10px;
  margin: 30px 0px 20px;
  padding: 10px 20px;
  background: #27292d;
  .para-content {
    font-family: 'Roboto';
    color: #a7a7a7;
    font-size: 14px;
  }
  .notice-para {
    text-align: center;
    border-top: 1px solid #585858;
    border-bottom: 1px solid #585858;
    margin: 10px 35px;
    padding: 10px 0px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .para-content {
      font-size: 10px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .para-content {
      font-size: 11px;
    }
  }
  @media (min-width: 576px) and (max-width: 1199px) {
    .para-content {
      font-size: 13px;
    }
  }

  @media (min-width: 1200px) and (max-width: 1719px) {
    .para-content {
      font-size: 14px;
    }
  }

  @media (min-width: 1720px) and (max-width: 4000px) {
    .para-content {
      font-size: 15px;
    }
  }
`;
const ListDataWrapper = styled.div`
  display: flex;
  align-items: baseline;
  li {
    padding-left: 10px;
    list-style-type: none;
    padding-bottom: 10px;
    font-family: 'Roboto';
    font-size: 11px;
    color: #a7a7a7;
  }
  img {
    width: 11px;
    height: 11px;
  }
  .list-style {
    display: flex;
    align-items: baseline;
  }
  ul {
    margin: 0px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    li {
      font-size: 10px;
    }
    ul {
      padding: 0px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    li {
      font-size: 11px;
    }
    ul {
      padding: 10px !important;
    }
  }
  @media (min-width: 576px) and (max-width: 1199px) {
    li {
      font-size: 13px;
    }
    ul {
      padding: 20px !important;
    }
  }

  @media (min-width: 1200px) and (max-width: 1719px) {
    li {
      font-size: 14px;
    }
    ul {
      padding: 30px !important;
    }
  }

  @media (min-width: 1720px) and (max-width: 4000px) {
    li {
      font-size: 15px;
    }
    ul {
      padding: 40px !important;
    }
  }
`;

const ButtonWrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: -54px;
    left: 0px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
  }
`;

export default EWDDivisionsNew;
