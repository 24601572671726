import { GridCellProps } from '@progress/kendo-react-grid';
import { SHToolTip } from 'app/components/SHToolTip';
import styled from 'styled-components';

type Props = {
  className?: string;
  e: GridCellProps;
  onClick?: (e) => void;
};

const TeamMainContactCell = ({ className, e, onClick }: Props) => {
  const data = e.dataItem;
  return (
    <td className={className ?? ''}>
      <SHToolTip position="top">
        <EmailWrapper title={data?.phone}>Ph#: {data?.phone}</EmailWrapper>
      </SHToolTip>
      <SHToolTip position="top">
        <EmailWrapper title={data?.email}>Email: {data?.email}</EmailWrapper>
      </SHToolTip>
    </td>
  );
};

export default TeamMainContactCell;
const EmailWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  min-width: 150px;
  max-width: 200px;
  color: #475467;
`;
