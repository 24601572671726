import styled from 'styled-components';
import { ImageUpload } from '../ImageUpload';
import { ReactNode } from 'react';
import AdminCustomButton from '../AdminCustomButton';

interface Props {
  className?: string;
  setImgSrc?: React.Dispatch<React.SetStateAction<string | null>>;
  imgSrc?: string | null;
  children?: ReactNode;
  bgColor?: string;
  borderColor?: string;
  titleColor?: string;
  onSelectFile?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  buttonLabel?: string;
  buttonType?:
    | 'primary'
    | 'secondary'
    | 'icon'
    | 'edit'
    | 'card'
    | 'nav-icon'
    | 'delete'
    | 'cancel'
    | 'save'
    | 'uploadFile';
  svgIcon?: React.ReactNode;
  onButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  uploadButtonLabel?: string;
  uploadButtonType?:
    | 'primary'
    | 'secondary'
    | 'icon'
    | 'edit'
    | 'card'
    | 'nav-icon'
    | 'delete'
    | 'cancel'
    | 'save'
    | 'uploadFile';
  uploadSvgIcon?: React.ReactNode;
  isImgErrorMessage?: boolean;
  isLoader?: boolean;
  isPastDataFlag?: boolean;
}

export const ProfileImageUploadCard = ({
  className,
  setImgSrc,
  imgSrc,
  children,
  bgColor,
  borderColor,
  onSelectFile,
  buttonLabel,
  buttonType,
  svgIcon,
  onButtonClick,
  uploadButtonLabel,
  uploadButtonType,
  uploadSvgIcon,
  isImgErrorMessage,
  isLoader,
  isPastDataFlag,
}: Props) => {
  return (
    <Wrapper
      className={`${className} ${children ? 'max-style' : 'default-style'} ${
        isLoader ? 'loader-style' : ''
      }`}
      bgColor={bgColor}
      borderColor={borderColor}
    >
      <ImageUpload
        imgSrc={imgSrc}
        setImgSrc={setImgSrc}
        onSelectFile={onSelectFile}
        uploadButtonLabel={uploadButtonLabel}
        uploadButtonType={uploadButtonType}
        uploadSvgIcon={uploadSvgIcon}
        isImgErrorMessage={isImgErrorMessage}
        isLoader={isLoader}
      />
      {children && (
        <SubWrapper className="child-wrapper">{children}</SubWrapper>
      )}
      {buttonLabel && !isPastDataFlag && (
        <AdminCustomButton
          buttonType={buttonType}
          svgIcon={svgIcon}
          onClick={onButtonClick}
        >
          {buttonLabel}
        </AdminCustomButton>
      )}
    </Wrapper>
  );
};
const Wrapper = styled.div<{
  bgColor?: string;
  borderColor?: string;
}>`
  background-color: ${props => props.bgColor || 'rgba(231, 236, 255, 1)'};
  display: flex;
  border-radius: 12px;
  border: 1px solid ${props => props.borderColor || 'rgba(231, 236, 255, 1)'};
  gap: 20px;
  font-family: 'Inter';

  &.max-style {
    width: 100%;
    padding: 20px 40px 20px 20px;
    position: relative;
    button {
      font-size: 14px;
      padding: 0px 12px;
      position: absolute;
      bottom: 20px;
      right: 25px;
      min-height: 32px;
      svg {
        margin-right: 5px;
      }
      @media (min-width: 100px) and (max-width: 767px) {
        left: 50%;
        transform: translateX(-50%);
        right: auto;
      }
    }
  }
  &.default-style {
    width: 100%;
    padding: 30px 70px;
    flex-direction: column;
    align-items: center;
    display: flex;
    max-height: unset;
    button {
      font-size: 16px;
      padding: 0px 15px;
      min-height: 35px;
      margin: 30px 0 0 0;
      svg {
        margin-right: 5px;
      }
    }
  }
  &.loader-style {
    .camera-icon {
      background-color: #f1f1f1;
    }
    background-color: transparent !important;
    border-color: transparent !important;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    flex-direction: column;
    gap: 10px;
    max-height: max-content;
    align-items: center;
    &.max-style {
      width: 100%;
      padding: 10px 10px 10px 10px;
    }
  }
  @media (min-width: 320px) and (max-width: 576px) {
    flex-direction: column;
    gap: 10px;
    max-height: max-content;
    align-items: center;
    &.max-style {
      width: 100%;
      padding: 10px 15px 70px 15px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    flex-direction: column;
    gap: 10px;
    max-height: max-content;
    align-items: center;
    &.max-style {
      width: 100%;
      padding: 10px 10px 70px 15px;
    }
  }
`;
const SubWrapper = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: calc(100% - 215px);
  @media (min-width: 100px) and (max-width: 319px) {
    align-items: center;
  }
  @media (min-width: 320px) and (max-width: 350px) {
    align-items: center;
  }
  @media (min-width: 351px) and (max-width: 576px) {
    padding: 0px 10px 0px 10px;
    align-items: center;
    text-align: justify;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding: 0px 10px 0px 10px;
    align-items: center;
    text-align: justify;
  }
`;
