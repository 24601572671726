import { GridCellProps } from '@progress/kendo-react-grid';

type Props = {
  classname?: string;
  e: GridCellProps;
  onRowClick?: () => void;
};

export const TotalPointCell = ({ classname, e, onRowClick }: Props) => {
  const data = e.dataItem.totalPoints;
  return (
    <td className={classname ?? ''}>
      <div>{data}</div>
    </td>
  );
};
