import { CardSubWrapper, CardWrapper } from 'admin/components/wrappers';
import styled from 'styled-components';
import { BackNumberCell } from './Cells/BackNumberCell';
import { RiderNameCell } from './Cells/RiderNameCell';
import { TeamNameCell } from './Cells/TeamNameCell';
import { CommonCell } from './Cells/CommonCell';
import { PositionCell } from './Cells/PositionCell';
import { MultipleDataGrid } from 'app/components/MultipleDataGrid/Index';
import { ElementaryPoints } from 'services/openapi';
type Props = {
  showElementaryHighPointList: ElementaryPoints | undefined;
};
export const ElementaryPage = ({ showElementaryHighPointList }: Props) => {
  const elementaryPearlRiderCells = [
    {
      field: 'name',
      title: 'Back#',
      cell: e => <BackNumberCell value={e.dataItem.riderBackNo} />,
      width: '50',
    },
    {
      field: 'teamName',
      title: 'Rider Name',
      cell: e => <RiderNameCell value={e.dataItem.riderName} />,
      width: '150',
    },
    {
      field: 'teamId',
      title: 'Team',
      cell: e => (
        <TeamNameCell
          value={e.dataItem.teamName}
          imageUrl={e.dataItem.teamImgUrl}
        />
      ),
      width: '200',
    },
    {
      field: '',
      title: 'Rail Hoursemanship',
      cell: e => <CommonCell value={e.dataItem.railHorsemanship} />,
      width: '110',
    },
  ];
  const rankCells = [
    {
      field: '',
      title: 'Pattern Hoursemanship',
      cell: e => <CommonCell value={e.dataItem.patternHorsemanship} />,
      width: '120',
    },
    {
      field: '',
      title: 'Sum',
      cell: e => <CommonCell value={e.dataItem.sum} />,
      width: '50',
    },
    {
      field: '',
      title: 'Rank',
      cell: e => (
        <CommonCell value={e.dataItem.sum === 0 ? '' : e.dataItem.rank} />
      ),
      width: '50',
    },
  ];
  const position = {
    field: '',
    title: 'Position',
    cell: e => <PositionCell e={e} />,
    width: '150',
  };

  return (
    <>
      <CardWrapper style={{ paddingTop: '15px', marginBottom: '80px' }}>
        <CardSubWrapper className="d-flex"></CardSubWrapper>
        <GridWrapper>
          <MultipleDataGrid
            data={showElementaryHighPointList!}
            pageSize={10}
            columns1={elementaryPearlRiderCells}
            columns2={rankCells}
            position={position}
            isPosition
            colomn1title={'Elementary Pearl Information'}
            colomn2title={'Rank'}
          />
        </GridWrapper>
      </CardWrapper>
    </>
  );
};
const GridWrapper = styled.div`
  tr[aria-rowindex='1'] {
    th[aria-colindex='1'] {
      background: #e7ecff;
    }
    th[aria-colindex='8'] {
      padding-bottom: 34px;
    }
    .k-column-title {
      font-size: 14px;
      font-weight: 600;
      line-height: 14.52px;
      color: #000000;
    }
  }
  .k-grid-header tr th {
    border-top: 1px solid #eaecf0;
  }
  .k-grid-container .k-grid-content tr td:nth-last-child(1) {
    padding: 0px 24px 0px 0px !important;
  }
  .k-grid-content tr {
    &:hover {
      td {
        background: #f0f0f0 !important;
      }
    }
  }
  .k-grid-content tr td {
    cursor: pointer;
  }
  .k-grid-content tr td.team-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }
`;
