import { defaultLookup } from 'admin/pages/RiderIndividual/components/RiderCreatePage/staticDatas';
export const showDefaultData = {
  id: null,
  showName: '',
  season: defaultLookup,
  hostedBy: '',
  showLocation: '',
  isNationalFinal: false,
  nationalInvite: defaultLookup,
  address: '',
  city: '',
  state: defaultLookup,
  zip: '',
  showStartDate: '',
  showEndDate: '',
};
export const showsBreadCrumpitems = [
  {
    id: '',
    text: 'Showyeda admin',
    disabled: true,
  },
  {
    id: '/admin/shows',
    text: 'Shows',
  },
  {
    id: '',
    text: 'Shows Info',
    disabled: true,
  },
];
