import React, { useState } from 'react';
import styled from 'styled-components';
import { CheckBox } from 'app/components/CheckBox';
import { BackNextButton } from 'app/components/BackNextButton';
import useApi from 'services/mavapi/useApi';
import Cookies from 'js-cookie';
import {
  ApiError,
  GeneralLookup,
  LandingPage,
  LookupService,
  RiderService,
  sif,
} from 'services/openapi';
import { useNavigate } from 'react-router-dom';
import LoaderWrapper from 'app/components/CircularLoader/LoaderWrapper';
import CircularLoader from 'app/components/CircularLoader';
import EwdSustainingEnrollentTable from '../EwdSustainingEnrollment/component/EwdSustainingTable';
import isApiError from 'utils/isApiError';
import useToast from 'hooks/Toast';
import { useDispatch } from 'react-redux';
import { useErrorHandlingSlice } from 'app/error/slice';

type Props = {};

const EwdNewEnrollment = (props: Props) => {
  const navigate = useNavigate();

  const currentUserId = Cookies.get('userId');
  const currentSifId = Cookies.get('sifID');
  const currentSifJoinedDate = new Date();
  const [showLoader, setShowLoader] = React.useState<boolean>(false);
  const { riderGetLandingPageRiderInfo, riderCreateSif } = RiderService;
  const { error: apiError, handleRequest } = useApi();
  const [, setError] = React.useState<ApiError | unknown>();
  const [sifDetails, setSifDetails] = React.useState<any>();
  function getSifDetails(id?: string) {
    handleRequest(riderGetLandingPageRiderInfo(id))
      .then(res => {
        setSifDetails(res);
        setShowLoader(false);
      })
      .catch(() => setError(apiError));
  }
  const [sifLookUps, setSifLookUps] = React.useState<GeneralLookup>();
  const [payerTypes, setPayerTypes] = React.useState<GeneralLookup>();
  const [isChecked, setIsChecked] = useState<boolean>();
  const dispatch = useDispatch();
  const { actions } = useErrorHandlingSlice();
  const { lookupGetAllLookups } = LookupService;
  function getAllLookupData(names?: string) {
    handleRequest(lookupGetAllLookups(names))
      .then(data => {
        if (data) {
          const sifTypeLookup = data.find(item => item.type === 'SIFTYPES');
          if (sifTypeLookup) {
            const defaultSifType = sifTypeLookup.values?.find(
              value => value.name === 'sif-ewd',
            );
            if (defaultSifType) {
              setSifLookUps(defaultSifType);
            }
          }
          let varPayerTypes = data?.filter(item => {
            if (item?.type === 'PAYER_TYPES') {
              return item.values;
            }
          });
          let payerTypeObj = varPayerTypes?.[0]?.values?.find(item => {
            if (item?.name === 'Rider') {
              return item ?? {};
            }
          });
          setPayerTypes(payerTypeObj);
        }
      })
      .catch(() => setError(apiError));
  }
  React.useEffect(() => {
    setShowLoader(true);
    getAllLookupData('SIFTYPES,PAYER_TYPES');
  }, []);
  const [interestedInFreeEarnings, setInterestedInFreeEarnings] =
    React.useState(false);
  const [donationProgram501c3, setDonationProgram501c3] = React.useState(false);
  const createSif = (requestBody?: sif) => {
    if (sifLookUps)
      handleRequest(
        riderCreateSif({
          id: currentSifId,
          riderId: currentUserId,
          interestedInFreeEarnings: interestedInFreeEarnings,
          donationProgram501c3: donationProgram501c3,
          joinedDate: currentSifJoinedDate.toISOString(),
          sifType: sifLookUps,
        }),
      )
        .then(res => {
          if (res && !isApiError(res)) {
            navigate(`/payment/${payerTypes?.id}/${currentUserId}/true/false`);
            console.log('is ewd  new enroll:', res);
            Cookies.set('SifCreated', true);
          } else {
            dispatch(
              actions.dispatchToast({
                errorTxt: res.description,
                show: true,
                errorType: 'error',
              }),
            );
          }
        })
        .catch(() => setError(apiError));
  };
  const handlePaymentCancel = () => {
    navigate(-1);
  };
  const handleCheckboxChange = e => {
    setIsChecked(e.target.value);
  };
  React.useEffect(() => {
    getSifDetails(currentUserId);
  }, []);
  return (
    <>
      {showLoader ? (
        <LoaderWrapper>
          <CircularLoader />
        </LoaderWrapper>
      ) : (
        <MainWrapper>
          {' '}
          <h1>Scholarship Incentive Fund (SIF) application form</h1>
          <p className="first-para">EWD application- New enrollment</p>
          <p className="second-para">
            There is a first-time registration fee of $100 to participate in the
            YEDA Scholarship Incentive. Each year after the re-enrollment
            sustaining cost is $25. Incentive funds will be paid to graduating
            seniors who actively enrolled in the fund, and who have a YEDA
            membership, based on the total points the member accumulated during
            years enrolled in the Scholarship Incentive Fund.{' '}
          </p>
          <SubWrapper>
            <p className="third-para">First time enrolment</p>
            <p className="fourth-para">
              If there’s any discrepancy in the below details, please contact
              Debbie Arnold (CFO), 419 – 566 – 6589, DArnold@showyeda.com
            </p>
          </SubWrapper>
          <EwdSustainingEnrollentTable sifDetails={sifDetails} />
          <CheckBox
            label={`Are you interested in learning more about how to earn free entries by being an SIF business or private sponsor?`}
            onChange={() =>
              setInterestedInFreeEarnings(!interestedInFreeEarnings)
            }
          />
          <CheckBox
            label={`Does your employer participate in a 501c3 donation matching program?`}
            onChange={() => setDonationProgram501c3(!donationProgram501c3)}
          />
          <CheckBox
            className="third-checkbox"
            label={`I agree that the above details are true to my knowledge`}
            onChange={handleCheckboxChange}
            checked={isChecked}
          />
          <p className="fifth-para">Membership amount: $100</p>
          <BackNextButton
            next="Proceed to payment"
            back="Cancel"
            className="btn"
            classNameBack="cancel-btn"
            classNameNxt="proceed-btn"
            onClickNxt={() => createSif()}
            onClickBack={handlePaymentCancel}
            disableNext={!isChecked}
          />
        </MainWrapper>
      )}
    </>
  );
};

const MainWrapper = styled.div`
  @media (min-width: 100px) and (max-width: 756px) {
    .k-button-solid-base {
      width: 100% !important;
    }
  }
  padding: 20px 40px;
  min-height: 100vh;
  background-color: #ffffff;
  width: 100%;
  .first-para {
    // padding-bottom: 20px;
    font-size: 24px;
  }
  .second-para {
    color: #cc5050;
    font-size: 14px;
  }
  .third-checkbox {
    label {
      font-weight: bold;
    }
  }
  .fifth-para {
    font-weight: bold;
  }
  .btn {
    flex-direction: row-reverse;
    display: flex;
    justify-content: start;
    gap: 40px;
  }
  .k-button-solid-base {
    border-radius: 25px;
    width: 22% !important;
    height: auto;
    font-weight: 600;
  }
  .proceed-btn {
    background-color: #2961f4;
    color: #ffffff;
  }
  .cancel-btn {
    background-color: #8d8d8d;
    color: #ffffff;
  }
`;
const SubWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  .third-para {
    font-weight: bold;
    font-size: 14px;
  }
  .fourth-para {
    color: #2961f4;
    font-size: 14px;
  }
`;

export default EwdNewEnrollment;
