import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DeleteIcon } from 'app/assets/icons';

interface DeletePopupProps {
  popupLabel?: string;
  popupSubLabel?: string;
  children?: ReactNode;
  titleChildren?: ReactNode;
  setVisible?: (visible: boolean) => void;
  visible?: boolean;
  className?: string;
  headerUnderline?: boolean;
  closeToggle?: () => void;
  align?: 'centre' | 'right';
  btnLabel1?: string;
  btnLabel2?: string;
  handlebtn1Click?: () => void;
  handlebtn2Click?: () => void;
  disabledbutton2?: boolean;
  deleteIcon?: boolean;
  footerChildren?: ReactNode;
}
export const CustomDeletePopup = ({
  className,
  popupLabel,
  popupSubLabel,
  headerUnderline = false,
  align,
  children,
  titleChildren,
  visible,
  setVisible,
  closeToggle,
  btnLabel1,
  btnLabel2,
  handlebtn1Click,
  handlebtn2Click,
  disabledbutton2 = false,
  deleteIcon,
  footerChildren,
}: DeletePopupProps) => {
  const toggleDialog = () => {
    closeToggle?.();
    setVisible?.(!visible);
  };
  return (
    <>
      {visible && (
        <StyledDialog
          title={
            <>
              {titleChildren}
              <PopupLabelWrapper className="popup-label-wrapper">
                {deleteIcon ? (
                  <ImageWrapper>
                    <DeleteIcon />
                    <ContentWrapper>
                      {popupLabel}
                      <div>{popupSubLabel}</div>
                    </ContentWrapper>
                  </ImageWrapper>
                ) : (
                  popupLabel
                )}
              </PopupLabelWrapper>
            </>
          }
          onClose={toggleDialog}
          className={className}
          align={align}
          headerUnderline={headerUnderline}
        >
          {children}

          <DialogActionsBar>
            <DeleteButtonWrapper>
              <button
                className="grouped-button canel-popup-button"
                onClick={handlebtn1Click}
              >
                {btnLabel1}
              </button>

              <button
                className="grouped-button delete-popup-button"
                onClick={handlebtn2Click}
              >
                {btnLabel2}
              </button>
            </DeleteButtonWrapper>
          </DialogActionsBar>
        </StyledDialog>
      )}
    </>
  );
};
const StyledDialog = styled(({ align, ...props }) => <Dialog {...props} />)`
  backdrop-filter: blur(5px);
  height: calc(100vh - 72px);
  top: 72px !important;
  justify-content: flex-start;
  .k-window-actions {
    margin: unset;
  }
  .k-window-titlebar {
    border: none;
  }
  &.height-full {
    height: 100%;
    top: 0 !important;
  }

  &.delete-update {
    .k-dialog {
      border-radius: 10px;
      .k-button-icon {
        color: #667085 !important;
      }
      .k-dialog-buttongroup {
        border-color: 'transparent';
      }
      .k-window-content {
        margin: 0;
      }
      .action-styling {
        justify-content: end;
        > div {
          min-width: 72px;
        }
        .apply-button {
          background: 'transparent';
          .k-button {
            color: rgb(255 255 255);
            background: #d92d20 !important;
          }
        }
        .grouped-button {
          .k-button {
            color: #3085fe;
            background: 'transparent';
            border-color: #d0d5dd;
          }
        }
        .k-button {
          width: 100%;
          border-radius: 5px;
          padding: 6px 12px;
        }
      }
    }
  }
  .k-window {
    background-color: #ffffff;
    border-color: #eaecf0;
    color: #101828;
  }
  .k-dialog {
    min-width: 35%;
    height: ${props =>
      props.align === 'centre' ? 'unset' : 'calc(100vh - 72px) !important'};
    border-radius: unset !important;
    position: absolute !important;
    right: ${props => (props.align === 'centre' ? 'unset' : '0')};
    top: ${props => (props.align === 'centre' ? '50%' : '0')};
    transform: ${props =>
      props.align === 'centre' ? 'translateY(-50%)' : 'none'};
    border-radius: ${props =>
      props.align === 'centre' ? '6px' : 'unset'} !important;
  }

  .k-overlay {
    opacity: 0.75 !important;
    background-color: #344054;
  }
  .k-window-titlebar {
    border-color: #eaecf0;
    align-items: baseline;
    background: white;
  }
  .k-icon {
    font-size: 20px !important;
  }
  .k-dialog-buttongroup {
    border-color: #eaecf0;
  }
  .k-button-icon {
    color: #000000 !important;
  }

  .k-window-content {
    ${props =>
      props.align === 'centre' &&
      `
    font-family: 'Inter';
    text-align: center;
    margin: 15px 10px;
    padding: 5px;
    font-size: 14px;
  `}
    .title-span {
      font-weight: ${props => (props.align === 'centre' ? '600' : '')};
    }
  }
`;
const DialogActionsBarWrapper = styled.div`
  &.action-styling {
    display: flex;
    padding: 12px 12px !important;
    align-items: center;
    justify-content: center;
    gap: 15px;
    .grouped-button {
      :nth-child(2) {
        .k-button {
          border-color: #bf2328;
          color: #bf2328;
          background: #ffffff;
        }
      }
    }
    .k-button {
      width: 175px;
      font-size: 14px;
    }
  }
`;

const PopupLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: 'Inter';
  font-weight: 600;
  @media (min-width: 100px) and (max-width: 319px) {
    font-size: 11px;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    font-size: 12px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 12px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 13px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 13px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    font-size: 14px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    font-size: 16px;
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    font-size: 17px;
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    font-size: 17px;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  @media (min-width: 100px) and (max-width: 319px) {
    font-size: 11px;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    font-size: 12px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 12px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    svg {
      width: 20px;
      height: 20px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    svg {
      width: 23px;
      height: 23px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    svg {
      width: 25px;
      height: 25px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    svg {
      width: 25px;
      height: 25px;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    svg {
      width: 30px;
      height: 30px;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    svg {
      width: 30px;
      height: 30px;
    }
  }
`;
const ContentWrapper = styled.div`
  > div {
    color: #475467;
    font-weight: 400;
    margin-top: 5px;
    font-size: 14px;
    @media (min-width: 100px) and (max-width: 319px) {
      font-size: 11px;
    }
    @media (min-width: 320px) and (max-width: 575px) {
      font-size: 12px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 12px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 13px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 13px;
    }
    @media (min-width: 1200px) and (max-width: 1365px) {
      font-size: 14px;
    }
    @media (min-width: 1366px) and (max-width: 1448px) {
      font-size: 16px;
    }
    @media (min-width: 1449px) and (max-width: 1719px) {
      font-size: 17px;
    }
    @media (min-width: 1720px) and (max-width: 4000px) {
      font-size: 17px;
    }
    .title-span {
      font-weight: 600;
    }
  }
`;
const DeleteButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 0 10px 10px 0;
  .grouped-button.canel-popup-button {
    background-color: grey;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  .grouped-button.delete-popup-button {
    background-color: rgb(255, 82, 82);
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
`;
