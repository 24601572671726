import * as React from 'react';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import styled from 'styled-components';
import { CheckBox } from 'app/components/CheckBox';
import { GeneralRider } from 'services/openapi';

type props = {
  riderTableData: GeneralRider[] | undefined;
  handleCheckboxClick: (riderId) => void;
  checkedMap: null;
};
const RiderTable = ({
  riderTableData,
  handleCheckboxClick,
  checkedMap,
}: // oldRiderTableData,
props) => {
  const checkBoxBlock = e => {
    const riderId = e?.dataItem?.id;
    return (
      <td>
        <StyledCheckBox
          onChange={() => handleCheckboxClick(e?.dataItem?.id)}
          checked={checkedMap === riderId}
        />
      </td>
    );
  };
  const firstNameCell = e => {
    return <td>{e?.dataItem?.firstName}</td>;
  };
  const lastNameCell = e => {
    return <td>{e?.dataItem?.lastName}</td>;
  };
  const teamCell = e => {
    return <td>{e?.dataItem?.team.name}</td>;
  };
  const graduationCell = e => {
    return <td>{e?.dataItem?.expectedGraduationYear}</td>;
  };
  const zipCell = e => {
    return <td>{e?.dataItem?.zip}</td>;
  };
  return (
    <MainWrapper>
      <StyledGrid className="StyledGrid" data={riderTableData!}>
        <GridColumn title="" width="60px" cell={e => checkBoxBlock(e)} />
        <GridColumn
          field="FirstName"
          title="First Name"
          width="110px"
          cell={e => firstNameCell(e)}
        />
        <GridColumn
          field="LastName"
          title="Last Name"
          width="110px"
          cell={e => lastNameCell(e)}
        />
        <GridColumn
          field="Team"
          title="Team"
          width="110px"
          cell={e => teamCell(e)}
        />
        <GridColumn
          field="GraduationYear"
          title="Graduation Year"
          width="110px"
          cell={e => graduationCell(e)}
        />
        <GridColumn
          field="Zip"
          title="Zip"
          width="110px"
          cell={e => zipCell(e)}
        />
      </StyledGrid>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  width: 100%;
`;
const StyledCheckBox = styled(CheckBox)`
  display: flex;
  justify-content: center;
  label {
    display: none;
  }
`;
const StyledGrid = styled(Grid)`
  border: 1px solid #5e5e5e;
  border-radius: 20px;
  background: transparent;
  .k-table {
    width: 100% !important;
    height: auto;
  }
  .k-column-title {
    font-size: 12px;
  }
  .k-grid-header {
    border: none !important;
    padding: 0px !important;
    border-radius: 20px 0 0 0;
    background: transparent;
    .k-grid-header-wrap {
      margin: 0px !important;
      border-radius: 20px 20px 0px 0px !important;
    }
    .k-grid-header-table {
      thead.k-table-thead {
        background: #373737 !important;
        color: #717171 !important;
        border: none !important;
        tr {
          th {
            border: none !important;
          }
        }
      }
    }
  }
  .k-grid-container {
    border-radius: 0px 0px 20px 20px !important;
    .k-grid-content {
      overflow: auto !important;
      .k-table.k-grid-table {
        .k-table-tbody {
          .k-table-row.k-master-row {
            background: #2d2d2e !important;
            &.k-table-alt-row.k-alt {
              background: #333333 !important;
            }
            td {
              border: none !important;
              color: #9597a6;
              font-size: 10px;
            }
          }
        }
      }
    }
  }
`;
export default RiderTable;
