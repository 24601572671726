import { GridLayout } from '@progress/kendo-react-layout';
import styled from 'styled-components';

const CommonPageWrapper = styled.div`
  overflow: auto;
  overflow-x: hidden;
  background-color: #ffffff;
  height: 100vh;
  width: 100%;
  padding: 20px 40px 0px 40px;
  ::-webkit-scrollbar {
    height: 3px;
    margin-top: 3px;
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #dce4fe;
    border-radius: 5px;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    width: 1px;
    background: #caced8;
    border-radius: 5px;
  }

  @media (min-height: 100px) and (max-height: 560px) {
    .sub-wrapper {
      height: 80vh;
    }
  }
`;
const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;
const CardWrapper = styled.div`
  width: 100%;
  height: max-content;
  border-radius: 6px;
  background: #ffffff;
`;
const CardSubWrapper = styled.div`
  width: 100%;
  padding: 5px 24px;
  &.d-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.end {
      justify-content: end;
      padding: 20px 24px;
    }
  }
`;
const CustomgridLayoutWrapper = styled(GridLayout)`
  &.custom-grid-wrapper {
    .multichecker-div {
      button span {
        margin-right: 20px;
        overflow-x: scroll;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }
      }
    }
  }
  .box {
    &:nth-of-type(odd) {
      padding: 0 10px 0 0;
    }
    &:nth-of-type(even) {
      padding: 0 0 0 10px;
    }
  }
  .error-msg {
    color: red;
    font-size: 12px;
    font-family: 'Inter';
  }
`;
export {
  CommonPageWrapper,
  HeadingWrapper,
  CardWrapper,
  CardSubWrapper,
  CustomgridLayoutWrapper,
};
