import { GridCellProps } from '@progress/kendo-react-grid';
import { SHToolTip } from 'app/components/SHToolTip';
import styled from 'styled-components';

type Props = {
  value: string;
  onClick?: (e) => void;
  e?: GridCellProps;
};

export const ReportValueCell = ({ value, onClick, e }: Props) => {
  return (
    <td style={e?.style} className={e?.className} onClick={onClick}>
      <SHToolTip position="top">
        <StyledDiv title={value}>{value}</StyledDiv>
      </SHToolTip>
    </td>
  );
};
const StyledDiv = styled.div`
  width: 100% !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
`;
