import {
  GridColumnProps,
  GridPageChangeEvent,
} from '@progress/kendo-react-grid';
import { CardSubWrapper, CardWrapper } from 'admin/components/wrappers';
import CustomDataGrid from 'app/components/CustomDataGrid/Index';
import styled from 'styled-components';

type Props = {
  data: any[] | undefined;
  columns: GridColumnProps[] | undefined;
  onPageChange?: ((event: GridPageChangeEvent) => void) | undefined;
  totalCount?: number | undefined;
  pageSize?: number | undefined;
  pageSkip?: number | undefined;
};

const RiderReportsGrid = ({
  data,
  columns,
  onPageChange,
  totalCount,
  pageSize,
  pageSkip,
}: Props) => {
  return (
    <>
      <CardWrapper style={{ paddingTop: '15px' }}>
        <CardSubWrapper className="d-flex"></CardSubWrapper>
        <GridWrapper>
          <CustomDataGrid
            showPagination={true}
            className="custom-data-grid"
            data={data}
            columns={columns}
            onPageChange={onPageChange}
            totalCount={totalCount}
            pageSize={pageSize}
            skip={pageSkip}
          />
        </GridWrapper>
      </CardWrapper>
    </>
  );
};

export default RiderReportsGrid;
const GridWrapper = styled.div`
  margin-bottom: 10px;
  .k-grid-content tr td.k-grid-content-sticky {
    cursor: unset;
    padding: 10px 8px 10px 8px !important;
  }
  .k-grid-header {
    border-top: 1px solid #eaecf0;
  }
  .k-grid-content tr {
    &:hover {
      td {
        background: #f0f0f0 !important;
      }
    }
  }
  .custom-data-grid {
    th {
      padding: 10px 4px 9px 4px !important;
      vertical-align: top;
    }
    .k-virtual-content {
      height: unset !important;
      min-height: unset !important;
    }
  }
`;
