import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import EditSaveButton from 'admin/components/EditSaveButton';
import { CommonPageWrapper, HeadingWrapper } from 'admin/components/wrappers';
import useSeasonApi from 'admin/hooks/useSeasonApi';
import { SeasonSettingsbreadCrumpitems } from 'admin/pages/SettingsPage/StaticData';
import AdminDropDown from 'app/components/AdminDropDown';
import BackButton from 'app/components/BackButton';
import CustomBreadCrumb from 'app/components/CustomBreadCrumb';
import { PageMainLabel } from 'app/components/SHLabel';
import { Wrapper } from 'app/components/SHToolTip';
import useCommonLookUpDatas from 'hooks/useCommonLookupData';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GeneralLookup, SeasonRequest } from 'services/openapi';
import SimpleReactValidator from 'simple-react-validator';
import styled from 'styled-components';
import { getLookupArray } from 'utils/common';
type Model = {
  AdminLiveId: GeneralLookup;
  PublicLiveId: GeneralLookup;
};
const defaultdata = {
  AdminLiveId: { id: '', name: '' },
  PublicLiveId: { id: '', name: '' },
};

const SeasonSettings = () => {
  const {
    currentSeasonInDropDown,
    updateSeasonInDropDown,
    serviceDataList,
    loading,
    setIsEdit,
    isEdit,
  } = useSeasonApi();
  const navigate = useNavigate();
  const [payload, setPayload] = useState(defaultdata);
  const [defaultPayload, setDefaultPayload] = useState(defaultdata);
  const [trigger, setTrigger] = React.useState(false);
  const simpleValidator = useRef(new SimpleReactValidator());
  const isValid = simpleValidator.current.allValid();

  const onCancelClick = () => {
    simpleValidator.current.hideMessages();
    simpleValidator.current.visibleFields = [];
    setTrigger(!trigger);
    setPayload(defaultPayload);
  };

  const onSaveClick = () => {
    const seasonPayload: SeasonRequest | undefined = {
      adminLiveId: payload.AdminLiveId.id,
      publicLiveId: payload.PublicLiveId.id,
    };
    if (isValid) {
      updateSeasonInDropDown(seasonPayload);
    } else {
      simpleValidator.current.showMessages();
      setTrigger(!trigger);
    }
  };

  useEffect(() => {
    getAllLookupData('SEASON');
    currentSeasonInDropDown();
  }, []);
  useEffect(() => {
    if (serviceDataList) {
      const initialPayload = {
        AdminLiveId: serviceDataList.adminLiveSeason ?? { id: '', name: '' },
        PublicLiveId: serviceDataList.liveSeason ?? { id: '', name: '' },
      };
      setPayload(initialPayload);
      setDefaultPayload(initialPayload);
    }
  }, [serviceDataList]);

  const handleDropdownChange = (value, dropdownType) => {
    if (dropdownType === 'adminSeason') {
      setPayload(prevState => ({
        ...prevState,
        AdminLiveId: value || '',
      }));
    } else if (dropdownType === 'publicSeason') {
      setPayload(prevState => ({
        ...prevState,
        PublicLiveId: value || '',
      }));
    }
  };
  const { getAllLookupData, lookUps } = useCommonLookUpDatas();

  return (
    <>
      <CommonPageWrapper>
        <HeadingWrapper>
          <PageMainLabel>Season Settings</PageMainLabel>
          <CustomBreadCrumb preData={SeasonSettingsbreadCrumpitems} />
        </HeadingWrapper>
        <BtnWrapper>
          <div>
            <BackButton
              onClick={() => {
                isEdit === false
                  ? setIsEdit(true)
                  : navigate('/admin/settings');
              }}
            />
          </div>
          <div className="btn-action">
            <EditSaveButton
              isEdit={isEdit}
              onCancelClick={onCancelClick}
              onSaveClick={onSaveClick}
              onEditClick={() => {
                setIsEdit(false);
              }}
            />
          </div>
        </BtnWrapper>

        <MainWrapper>
          <GridLayout
            gap={{ rows: 6, cols: 10 }}
            rows={[{ height: 'auto' }, { height: 'auto' }]}
            cols={[
              { width: 'calc(33.33% - 7px)' },
              { width: 'calc(33.33% - 7px)' },
              { width: 'calc(33.33% - 7px)' },
            ]}
          >
            <GridLayoutItem row={1} col={1} colSpan={1} className="first-item">
              <ContentWrapper>
                <GridLayoutItem>
                  <AdminDropDown
                    label="Admin Season"
                    placeholder=""
                    data={getLookupArray('SEASON', lookUps)}
                    onDataChange={data =>
                      handleDropdownChange(data, 'adminSeason')
                    }
                    defaultValue={payload?.AdminLiveId}
                    className="field-style"
                    validations="required"
                    validator={simpleValidator.current}
                    isLoader={loading}
                    disabled={isEdit}
                  />
                </GridLayoutItem>
              </ContentWrapper>
            </GridLayoutItem>
            <GridLayoutItem row={1} col={2} colSpan={1} className="first-item">
              <ContentWrapper>
                <GridLayoutItem>
                  <AdminDropDown
                    label="Public Season"
                    placeholder=""
                    data={getLookupArray('SEASON', lookUps)}
                    onDataChange={data =>
                      handleDropdownChange(data, 'publicSeason')
                    }
                    defaultValue={payload?.PublicLiveId}
                    className="field-style"
                    validations="required"
                    validator={simpleValidator.current}
                    isLoader={loading}
                    disabled={isEdit}
                  />
                </GridLayoutItem>
              </ContentWrapper>
            </GridLayoutItem>
            <GridLayoutItem row={1} col={3} colSpan={1} className="first-item">
              <ContentWrapper></ContentWrapper>
            </GridLayoutItem>
          </GridLayout>
        </MainWrapper>
      </CommonPageWrapper>
    </>
  );
};

export default SeasonSettings;
const BtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 15px 0;
  @media (min-width: 1480px) and (max-width: 1899px) {
    padding: 15px 20px 15px 0;
  }
  @media (min-width: 1899px) and (max-width: 4000px) {
    padding: 15px 35px 15px 0;
  }
  .btn-action {
    display: flex;
    align-items: center;
    gap: 15px;
  }
`;
const MainWrapper = styled(Wrapper)`
  .profile-img {
    display: none;
  }
  .border-div {
    display: none;
  }
`;
const ContentWrapper = styled.div``;
