import { GridCellProps } from '@progress/kendo-react-grid';
import { AdminProfileInfo } from 'app/components/AdminProfileInfo';

type Props = {
  className?: string;
  e: GridCellProps;
  onClick?: (e) => void;
};

const CoachProfileCell = ({ className, e, onClick }: Props) => {
  const data = e?.dataItem;
  return (
    <td className={className ?? ''}>
      <AdminProfileInfo
        src={data?.coach?.imageUrl ?? ''}
        name={data?.coach?.name}
        subLabel={data?.coach?.email}
      />
    </td>
  );
};

export default CoachProfileCell;
