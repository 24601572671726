import { GeneralLookup } from 'services/openapi';

//Rider Points Report Model and default values
type shows = {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  isNationalFinal: boolean;
};
type riders = {
  id: string;
  firstName: string;
  lastName: string;
  team: string;
  backNumber: string;
  grade: string;
  state: string;
  totalRides: number;
  totalTop6Scores: number;
  totalPoints: number;
  shows: {
    showId: string;
    point: string;
  }[];
};
export type RiderReportModel = {
  season?: string;
  category?: string;
  division?: string;
  class?: string;
  pager: {
    count: number;
    currentPage: number;
  };
  shows: shows[];
  riders: riders[];
};
export type defaultFilterModel = {
  season: GeneralLookup;
  category: GeneralLookup;
  division: GeneralLookup;
  class: GeneralLookup;
};

export const riderPointBreadCrumbItems = [
  {
    id: '',
    text: 'Showyeda admin',
    disabled: true,
  },
  {
    id: '',
    text: 'Rider Points Report',
    disabled: true,
  },
];
export const defaultFilterData = {
  season: { id: '', name: '' },
  category: { id: '', name: '' },
  division: { id: '', name: '' },
  class: { id: '', name: '' },
};
export const staticColumns = [
  { field: 'team', title: 'Team', width: '100px', className: 'new' },
  { field: 'firstName', title: 'First Name', width: '120px' },
  { field: 'lastName', title: 'Last Name', width: '120px' },
  { field: 'backNumber', title: 'Back Number', width: '120px' },
  { field: 'grade', title: 'Grade', width: '75px' },
  { field: 'state', title: 'State', width: '100px' },
  { field: 'totalRides', title: 'Total Rides To Date', width: '110px' },
  { field: 'totalTop6Scores', title: 'Total Top 6 Scores', width: '110px' },
  { field: 'totalPoints', title: 'Points Total', width: '100px' },
];
export const RiderReportDummyData: RiderReportModel = {
  season: '2023-2024',
  category: 'string',
  division: 'string',
  class: 'string',
  pager: { count: 0, currentPage: 0 },
  shows: [
    {
      id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      name: 'TEAM 2022 National Championship',
      startDate: '2024-07-17T10:07:22.936Z',
      endDate: '2024-07-17T10:07:22.936Z',
      isNationalFinal: true,
    },
    {
      id: '3fa85f64-5717-4562-b3fc-2c963f66afa7',
      name: 'INDIVIDUAL Championship 2022',
      startDate: '2024-07-17T10:07:22.936Z',
      endDate: '2024-07-17T10:07:22.936Z',
      isNationalFinal: false,
    },
    {
      id: '3fa85f64-5717-4562-b3fc-2c963f66afa8',
      name: 'STATE Invitational 2022',
      startDate: '2024-07-17T10:07:22.936Z',
      endDate: '2024-07-17T10:07:22.936Z',
      isNationalFinal: false,
    },
  ],
  riders: [
    {
      id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      firstName: 'string',
      lastName: 'string',
      team: 'string',
      backNumber: 'string',
      grade: 'string',
      state: 'string',
      totalRides: 0,
      totalTop6Scores: 0,
      totalPoints: 0,
      shows: [
        { showId: '3fa85f64-5717-4562-b3fc-2c963f66afa6', point: 'string' },
      ],
    },
    {
      id: '3fa85f64-5717-4562-b3fc-2c963f66afa7',
      firstName: 'string',
      lastName: 'string',
      team: 'string',
      backNumber: 'string',
      grade: 'string',
      state: 'string',
      totalRides: 0,
      totalTop6Scores: 0,
      totalPoints: 0,
      shows: [
        { showId: '3fa85f64-5717-4562-b3fc-2c963f66afa7', point: 'string1' },
      ],
    },
    {
      id: '3fa85f64-5717-4562-b3fc-2c963f66afa7',
      firstName: 'string',
      lastName: 'string',
      team: 'string',
      backNumber: 'string',
      grade: 'string',
      state: 'string',
      totalRides: 0,
      totalTop6Scores: 0,
      totalPoints: 0,
      shows: [
        { showId: '3fa85f64-5717-4562-b3fc-2c963f66afa8', point: 'string1' },
      ],
    },
  ],
};
// Team Roster Report Model and default values
export type defaultRosterFilterModel = {
  season: GeneralLookup;
  team: GeneralLookup;
};
export const teamRosterBreadCrumbItems = [
  {
    id: '',
    text: 'Showyeda admin',
    disabled: true,
  },
  {
    id: '',
    text: 'Team Roster',
    disabled: true,
  },
];
export const defaultRosterFilterData = {
  season: { id: '', name: '' },
  team: { id: '', name: '' },
};
export const staticTeamRosterColumns = [
  { field: 'class', title: 'Class', width: '120px' },
  { field: 'backNumber', title: 'Back Number', width: '120px' },
  { field: 'firstName', title: 'First Name', width: '120px' },
  { field: 'lastName', title: 'Last Name', width: '120px' },
  { field: 'address', title: 'Mailing Address', width: '130px' },
  { field: 'phone', title: 'Contact Number', width: '130px' },
  { field: 'email', title: 'Email', width: '120px' },
  { field: 'height', title: 'Height', width: '100px' },
  { field: 'weight', title: 'Weight', width: '100px' },
  { field: 'sif', title: 'SIF', width: '120px' },
  { field: 'top6Total', title: 'Top 6 Total', width: '130px' },
  { field: 'totalPoints', title: 'Total Points', width: '130px' },
  { field: 'totalRides', title: 'Total Rides', width: '130px' },
];
export const splitNumberLookup: Array<GeneralLookup> = [
  { id: '1', name: 'A' },
  { id: '2', name: 'B' },
  { id: '3', name: 'C' },
  { id: '4', name: 'D' },
  { id: '5', name: 'E' },
  { id: '6', name: 'F' },
  { id: '7', name: 'G' },
  { id: '8', name: 'H' },
  { id: '9', name: 'I' },
  { id: '10', name: 'J' },
  { id: '11', name: 'K' },
  { id: '12', name: 'L' },
  { id: '13', name: 'M' },
  { id: '14', name: 'N' },
  { id: '15', name: 'O' },
  { id: '16', name: 'P' },
  { id: '17', name: 'Q' },
  { id: '18', name: 'R' },
  { id: '19', name: 'S' },
  { id: '20', name: 'T' },
  { id: '21', name: 'U' },
  { id: '22', name: 'V' },
  { id: '23', name: 'W' },
  { id: '24', name: 'X' },
  { id: '25', name: 'Y' },
  { id: '26', name: 'Z' },
];
