/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GeneralLookup } from '../models/GeneralLookup';
import type { OldDashboardGeneralList } from '../models/OldDashboardGeneralList';
import type { OldDashboardTeamInfo } from '../models/OldDashboardTeamInfo';
import type { TeamRegistration } from '../models/TeamRegistration';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TeamService {

    /**
     * API to Regiter team
     * @param requestBody 
     * @returns string Created
     * @throws ApiError
     */
    public static teamAddNewTeam(
requestBody?: TeamRegistration,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/team/addTeam',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to Generate Team Code
     * @param teamName 
     * @returns string Success
     * @throws ApiError
     */
    public static teamGenerateTeamCode(
teamName?: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/team/generateTeamcode',
            query: {
                'TeamName': teamName,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to get Team with teamId
     * @param teamId 
     * @returns TeamRegistration Success
     * @throws ApiError
     */
    public static teamGetTeam(
teamId: string,
): CancelablePromise<TeamRegistration> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/team/team/{teamId}',
            path: {
                'teamId': teamId,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to get Team list from old dashboard
     * @param target 
     * @returns OldDashboardGeneralList Success
     * @throws ApiError
     */
    public static teamGetOldDashboardTeamList(
target: string = 'OTHERS',
): CancelablePromise<Array<OldDashboardGeneralList>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/team/oldDashboard/teamList',
            query: {
                'target': target,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to get Team with teamId
     * @param teamId 
     * @returns OldDashboardTeamInfo Success
     * @throws ApiError
     */
    public static teamGetTeamInfo(
teamId: number,
): CancelablePromise<OldDashboardTeamInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/team/team/oldDashboard/{teamId}',
            path: {
                'teamId': teamId,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to get Team Lookup with oldId
     * @param teamId 
     * @returns GeneralLookup Success
     * @throws ApiError
     */
    public static teamGetTeamLookupWithOldId(
teamId: number,
): CancelablePromise<GeneralLookup> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/team/team/lookup/{teamId}',
            path: {
                'teamId': teamId,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to upload Team Photo
     * @param teamId 
     * @param formData 
     * @returns any Created
     * @throws ApiError
     */
    public static teamUploadTeamPhoto(
teamId: string,
formData?: {
file?: Blob;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/team/{teamId}/photo',
            path: {
                'teamId': teamId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

}
