import styled from 'styled-components';
import '@fontsource/roboto';
import React from 'react';
import { CheckBox } from 'app/components/CheckBox';
import { BackNextButton } from 'app/components/BackNextButton';

type Props = {
  onClickBackAffiliations?:
    | React.MouseEventHandler<HTMLButtonElement>
    | undefined;
  onClickNxtAffiliations?:
    | React.MouseEventHandler<HTMLButtonElement>
    | undefined;
};

const TryitEWDAffiliations = ({
  onClickBackAffiliations,
  onClickNxtAffiliations,
}: Props) => {
  return (
    <MainWrapper>
      <OverflowWrapper>
        <p className="para-two">
          <i>Please Mark all professional affiliations</i>
        </p>
        <CheckboxWrapper>
          <div>
            <CheckBox label="Special Olympics" />
          </div>
          <div>
            <CheckBox label="NRHA" />
          </div>
          <div>
            <CheckBox label="NSBA" />
          </div>
          <div>
            <CheckBox label="APHA" />
          </div>
          <div>
            <CheckBox label="AQHA" />
          </div>
          <div>
            <CheckBox label="CHA" />
          </div>
          <div>
            <CheckBox label="4H" />
          </div>
          <div>
            <CheckBox label="PtHA" />
          </div>
          <div>
            {' '}
            <CheckBox label="Other" />
          </div>
        </CheckboxWrapper>

        <ButtonWrapper className="bg-color-change">
          <BackNextButton
            classNameBack="btn-back"
            classNameNxt="btn-nxt"
            onClickNxt={onClickNxtAffiliations}
            onClickBack={onClickBackAffiliations}
            next="Next"
            back="Cancel"
          />
        </ButtonWrapper>
      </OverflowWrapper>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  color: #9597a6;
  width: 100%;
  height: calc(100vh - 200px);
  position: relative;
  padding: 20px 0px 0px;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  .para-two {
    color: #ff9900;
    border-bottom: 1px solid #585858;
    padding: 0px 0px 10px;
    font-size: 11px;
    margin-bottom: 20px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    // padding-top: 20px;
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    // padding-top: 20px;
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    // padding-top: 20px;
    label {
      font-size: 14px;
    }
  }
`;
const OverflowWrapper = styled.div`
  height: 100%;
  overflow: auto;
`;
const CheckboxWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 150px);
  grid-gap: 10px;
`;

const ButtonWrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: 12px;
    left: -14px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
  }
`;

export default TryitEWDAffiliations;
