import React, { useEffect, useState } from 'react';
import { PersonalInfoForm } from './components/PersonalInfoForm';
import styled from 'styled-components';
import { AlumniDivisionForm } from './components/AlumniDivisionForm';
import { PersonalAffiliationsForm } from './components/PersonalAffiliationsForm';
import { useNavigate } from 'react-router-dom';
import {
  Alumni,
  AlumniService,
  GeneralLookup,
  GeneralLookupWrapper,
  LookupService,
  RiderService,
  TeamService,
} from 'services/openapi';
import useApi from 'services/mavapi/useApi';
import { RadioValueModel } from '../AlumniNewMemberResgistration';
import { useManagePageSlice } from 'app/pages/slice';
import { selectPageLoader } from 'app/pages/slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import LoaderWrapper from 'app/components/CircularLoader/LoaderWrapper';
import CircularLoader from 'app/components/CircularLoader';
import Cookies from 'js-cookie';
import { heightWeightModel } from 'app/pages/RiderRegistrationPage/components/NotReturningRider';
import TeamListPopUp from 'app/components/TeamListPopUp';
import { BackNextButton } from 'app/components/BackNextButton';
import CircularProgress from '@mui/joy/CircularProgress';
import Box from '@mui/joy/Box';
import { Stepper, StepperChangeEvent } from '@progress/kendo-react-layout';
import check from '../../assets/img/icon/check.png';
import { useRiderRegistrationStateSlice } from 'app/pages/RiderRegistrationPage/slice';
import { selectPhotoUpload } from 'app/pages/RiderRegistrationPage/slice/selectors';
import isApiError from 'utils/isApiError';
import { useErrorHandlingSlice } from 'app/error/slice';
import { useRiderRegistrationApi } from 'hooks/useRiderRegistrationApi';

type Props = {};
const items = [
  { label: 'Personal Information', number: 1 },
  { label: 'Alumni Divisions', number: 2 },
  { label: 'Professional Affiliations', number: 3 },
];
const defaultValue: Alumni = {
  isReturningRider: false,
  isTrial: true,
  backNumber: '',
  firstName: '',
  lastName: '',
  primaryPhone: '',
  email: '',
  team: {
    id: '',
    name: '',
    children: null,
  },
  street: '',
  city: '',
  state: {
    id: '',
    name: '',
    children: null,
  },
  zip: '',
  emergencyContactName: '',
  emergencyContactPhone: '',
  height: 0,
  weight: 0,
  divisionClass: {
    id: '',
    name: '',
    children: null,
  },
  haveNotCompetedInClassLopeCanter: false,
  haveNotEarnedTwentyFivePointsInAssocs: true,
  haveFinishedTopTwoAsAERInNationals: false,
  haveFinishedTopThreeAaAER: false,
  haveCompetedTwoSeasonsAsAER: false,
  professionalAffiliations: [],
  usePhotoForPromotion: false,
};
const defaultPhotoValue = {
  formData: {
    file: undefined,
  },
};

const AlumniTryItResgistration = (props: Props) => {
  const { getAllLookupByCategory, teamLookup } = useRiderRegistrationApi();
  const { error: apiError, handleRequest } = useApi();
  const { riderGetBackNumberList } = RiderService;
  const { actions } = useErrorHandlingSlice();
  const { lookupGetAllLookups } = LookupService;
  const { alumniCreateAlumni, alumniUploadTeamPhoto } = AlumniService;
  const { actions: manageAppActions } = useRiderRegistrationStateSlice();
  const [affiliations, setAffiliations] = useState<GeneralLookup[]>([]);
  const [lookUp, setLookUp] = useState<GeneralLookupWrapper[]>([]);
  const [formNumber, setFormNumber] = useState<number>(0);
  const [aluminiTryitRiderData, setAluminiTryitRiderData] =
    useState<Alumni>(defaultValue);
  const [childDivisonData, setChildDivisonData] = useState<GeneralLookup[]>();
  const [payerTypes, setPayerTypes] = useState<GeneralLookup>();
  const [radioGroupValues, setRadioGroupValues] = useState<RadioValueModel>({
    haveNotCompetedInClassLopeCanter: undefined,
    haveNotEarnedTwentyFivePointsInAssocs: undefined,
    haveFinishedTopTwoAsAERInNationals: undefined,
    haveFinishedTopThreeAaAER: undefined,
    haveCompetedTwoSeasonsAsAER: undefined,
    usePhotoForPromotion: undefined,
  });
  const [cancelPaymentCookie, setCancelPaymentCookie] = useState<boolean>();
  //for Loader
  const { actions: PageActions } = useManagePageSlice();
  const pageLoader = useSelector(selectPageLoader);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleNextForm = () => {
    if (isFrontPage) {
      setIsFrontPage(false);
    } else {
      setFormNumber(formNumber => formNumber + 1);
    }
  };
  const [isBackNumberCheck, setIsBackNumberCheck] = useState<boolean | null>(
    null,
  );
  const [heightWeight, setHeightWeight] = useState<heightWeightModel>({
    feet: undefined,
    inches: undefined,
    weight: undefined,
  });
  const [visible, setVisible] = React.useState<boolean>(false);
  const [isFrontPage, setIsFrontPage] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 767);
  const totalPages = 3;
  const determinateValue = ((formNumber + 1) / totalPages) * 100;
  const [photoUploadPayload, setPhotoUploadPayload] = useState<{
    formData?: {
      file?: Blob;
    };
  }>(defaultPhotoValue);
  const [fileAvailability, setFileAvailability] = React.useState<boolean>(true);
  const [uploadFile, setUploadFile] = useState<File | null>();
  const [backNumberData, setBackNumberData] = React.useState<
    string[] | undefined
  >([]);
  const photoUploadDataRedux = useSelector(selectPhotoUpload);
  const currentSeason = Cookies.get('isAdminLiveFiltered');
  const [isNotImage, setIsNotImage] = useState(false);

  function getBackNumberList() {
    handleRequest(riderGetBackNumberList())
      .then(data => {
        setBackNumberData(data);
      })
      .catch(() => {});
  }
  const handleSubmit = () => {
    handleRequest(alumniCreateAlumni(aluminiTryitRiderData))
      .then(res => {
        if (res && !isApiError(res)) {
          if (cancelPaymentCookie) {
            Cookies.remove('paymentCancel');
          }
          if (photoUploadPayload?.formData?.file !== undefined) {
            handlePhotoUpload(res.toString(), photoUploadPayload.formData);
          }
          const aluminiNewDataString = JSON.stringify(aluminiTryitRiderData);
          const radioValuesString = JSON.stringify(radioGroupValues);
          Cookies.set('payload', aluminiNewDataString);
          Cookies.set('payloadRadiovalues', radioValuesString);
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Try-it Alumini rider registered successful',
              show: true,
              errorType: 'success',
            }),
          );
          navigate(`/payment/${payerTypes?.id}/${res}/false/false`);
          dispatch(manageAppActions.setPhotoUpload(photoUploadPayload));
        } else {
          dispatch(
            actions.dispatchToast({
              errorTxt: res.description,
              show: true,
              errorType: 'error',
            }),
          );
          dispatch(PageActions.setPageLoader(false));
        }
      })
      .catch(error => {
        dispatch(
          actions.dispatchToast({
            errorTxt: error.description,
            show: true,
            errorType: 'error',
          }),
        );
        dispatch(PageActions.setPageLoader(false));
      });
  };
  const handlePhotoUpload = (
    riderId: string,
    formData?: {
      file?: Blob;
    },
  ) => {
    handleRequest(alumniUploadTeamPhoto(riderId, formData))
      .then(res => {})
      .catch(error => {});
  };
  const handleBackForm = () => {
    if (formNumber === 0 && isFrontPage === false) {
      setIsFrontPage(true);
    } else if (isFrontPage === true) {
      navigate('/login');
    } else {
      setFormNumber(formNumber =>
        formNumber === 0 ? formNumber : formNumber - 1,
      );
    }
  };
  const handleGetLookups = () => {
    dispatch(PageActions.setPageLoader(true));
    handleRequest(
      lookupGetAllLookups(
        'DIVISION,TEAM,CATEGORY,STATE,PROFESSIONALAFFILIATIONS,PAYER_TYPES',
      ),
    )
      .then(res => {
        setLookUp(res!);
        const categoryLookup = res?.find(obj => obj.type === 'CATEGORY');
        if (categoryLookup?.values) {
          let filteredcategory = categoryLookup?.values?.filter(item => {
            if (item.name === 'Alumni') {
              getAllLookupByCategory(item?.id ?? '');
              return { item, checked: false };
            }
          });

          setChildDivisonData(filteredcategory?.[0].children!);
        }
        let varAffiliations = res?.filter(item => {
          if (item?.type === 'PROFESSIONALAFFILIATIONS') {
            return item.values?.map(data => {
              return data.name;
            });
          }
        });
        let varPayerTypes = res?.filter(item => {
          if (item?.type === 'PAYER_TYPES') {
            return item.values;
          }
        });
        let payerTypeObj = varPayerTypes?.[0]?.values?.find(item => {
          if (item?.name === 'Rider') {
            return item ?? {};
          }
        });
        setPayerTypes(payerTypeObj);
        setAffiliations(varAffiliations?.[0].values!);
        dispatch(PageActions.setPageLoader(false));
      })
      .catch(error => {});
  };
  const handleInfoIcon = () => {
    setVisible(!visible);
  };
  const handleItemClick = (index: number) => {};
  const handleChangeStepper = (e: StepperChangeEvent) => {
    const index = Number(e.value);
    if (index >= 0 && index !== formNumber) {
      setFormNumber(index);
    }
  };
  useEffect(() => {
    const aluminiDataCookie = Cookies.get('payload');
    const paymentCancelStatus = Cookies.get('paymentCancel');
    const radioValuesCookieData = Cookies.get('payloadRadiovalues');
    setCancelPaymentCookie(paymentCancelStatus);
    if (aluminiDataCookie && radioValuesCookieData) {
      setIsFrontPage(false);
      setFormNumber(2);
      const updatedValue = JSON.parse(aluminiDataCookie);
      const radioValuesCookieDataParse = JSON.parse(radioValuesCookieData);
      setAluminiTryitRiderData(updatedValue);
      setRadioGroupValues(radioValuesCookieDataParse);
      setHeightWeight({
        feet: Math.floor(updatedValue?.height!),
        inches: Math.round(
          (updatedValue?.height! - Math.floor(updatedValue?.height!)) * 12,
        ),
        weight: updatedValue.weight,
      });
      setPhotoUploadPayload(photoUploadDataRedux ?? {});
      setUploadFile(photoUploadDataRedux?.formData?.file as File);
    }
    handleGetLookups();
    getBackNumberList();
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 767);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      {pageLoader ? (
        <LoaderWrapper>
          <CircularLoader />
        </LoaderWrapper>
      ) : (
        <MainWrapper id="AlumniNewMemberResgistration">
          {isFrontPage ? (
            <ContentWrapper>
              <p className="para">
                Kindly check the list of Teams which have renewed memberships
                for the year till now. If you do not find the team you wish to
                be a part of, check back later.
              </p>
              <h4 onClick={handleInfoIcon}>
                Team list{' '}
                {currentSeason === undefined ? '2023 - 2024' : currentSeason}
              </h4>
              {visible && <TeamListPopUp onClose={handleInfoIcon} />}
              <p className="para-one">
                A membership registration forms for Equestrian with Disabilities
                must be accompanied by a Diagnosis and Adaptive Equipment Form.
              </p>
              <p className="para-two">
                <i>
                  Payment of $20 fee and proof of insurance must be accompanied
                  with this form
                </i>
              </p>
              <BottomRightWrapper id="BottomRightWrapper">
                <ButtonWrapper>
                  <BackNextButton
                    classNameBack="btn-back"
                    classNameNxt="btn-nxt"
                    onClickNxt={handleNextForm}
                    onClickBack={handleBackForm}
                    next="Next"
                    back="Back"
                  />
                </ButtonWrapper>
              </BottomRightWrapper>
            </ContentWrapper>
          ) : (
            <>
              <>
                {' '}
                <p className="Team-Heading">New Member</p>
                <div className="Stepper-wrapper">
                  {isSmallScreen ? (
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 2,
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <CircularProgress
                        size="lg"
                        determinate
                        value={determinateValue}
                      >
                        {`${formNumber + 1} / ${totalPages}`}
                      </CircularProgress>
                      {items
                        .filter((_, index) => index === formNumber)
                        .map((item, index) => (
                          <h2 className="circular-stepper-heading" key={index}>
                            {item.label}
                          </h2>
                        ))}
                    </Box>
                  ) : (
                    <Stepper
                      value={formNumber}
                      onChange={handleChangeStepper}
                      items={items}
                      className="form-stepper"
                    >
                      {items.map((item, index) => (
                        <StepperStep
                          key={index}
                          className={`stepper-step ${
                            index < formNumber ? 'completed' : ''
                          }`}
                          onClick={() => handleItemClick(index)}
                        >
                          {index < formNumber ? (
                            <span className="tick-icon">&#10003;</span>
                          ) : (
                            item.number
                          )}
                        </StepperStep>
                      ))}
                    </Stepper>
                  )}
                </div>
              </>
              {formNumber === 0 ? (
                <PersonalInfoForm
                  handleNextForm={handleNextForm}
                  handleBackForm={handleBackForm}
                  aluminiTryitRiderData={aluminiTryitRiderData}
                  setAluminiTryitRiderData={setAluminiTryitRiderData}
                  lookUp={lookUp}
                  setIsBackNumberCheck={setIsBackNumberCheck}
                  isBackNumberCheck={isBackNumberCheck}
                  setHeightWeight={setHeightWeight}
                  heightWeight={heightWeight}
                  backNumberData={backNumberData}
                  teamLookup={teamLookup}
                />
              ) : formNumber === 1 ? (
                <AlumniDivisionForm
                  handleNextForm={handleNextForm}
                  handleBackForm={handleBackForm}
                  aluminiTryitRiderData={aluminiTryitRiderData}
                  setAluminiTryitRiderData={setAluminiTryitRiderData}
                  lookUp={lookUp}
                  childDivisonData={childDivisonData}
                  radioGroupValues={radioGroupValues}
                  setRadioGroupValues={setRadioGroupValues}
                />
              ) : formNumber === 2 ? (
                <PersonalAffiliationsForm
                  handleNextForm={handleSubmit}
                  handleBackForm={handleBackForm}
                  affiliations={affiliations}
                  setAffiliations={setAffiliations}
                  aluminiTryitRiderData={aluminiTryitRiderData}
                  setAluminiTryitRiderData={setAluminiTryitRiderData}
                  radioGroupValues={radioGroupValues}
                  setRadioGroupValues={setRadioGroupValues}
                  setPhotoUploadPayload={setPhotoUploadPayload}
                  photoUploadPayload={photoUploadPayload}
                  setFileAvailability={setFileAvailability}
                  fileAvailability={fileAvailability}
                  setUploadFile={setUploadFile}
                  uploadFile={uploadFile}
                  setIsNotImage={setIsNotImage}
                  isNotImage={isNotImage}
                />
              ) : (
                <></>
              )}
            </>
          )}
        </MainWrapper>
      )}
    </>
  );
};

export default AlumniTryItResgistration;
const MainWrapper = styled.div`
  height: 100%;
  .Team-Heading {
    padding-top: 20px;
    text-align: center;
    color: #9597a6;
  }
  //stepper-style
  .circular-stepper-heading {
    color: #8a8a8a;
  }
  .MuiCircularProgress-progress,
  .MuiCircularProgress-sizeLg {
    color: #8a8a8a;
    stroke: #2dce98;
  }
  .css-zitgxn-JoyCircularProgress-track {
    stroke: #434343;
  }
  .k-step-done {
    .k-step-indicator-text {
      position: relative;
      ::after {
        position: absolute;
        content: '';
        z-index: 15;
        background: #2dce98;
        background-image: url(${check});
        background-repeat: no-repeat;
        background-size: inherit;
        background-position: center;
        border-radius: 50px;
        left: -8px;
        height: 26px;
        width: 26px;
        top: -1px;
      }
    }
  }
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 30px;
  flex-direction: column;
  align-items: center;
  .k-stepper.form-stepper {
    pointer-events: none;
  }
  input {
    ::placeholder {
      font-size: 14px;
      text-transform: capitalize;
    }
  }
  .k-stepper .k-step-indicator::after {
    box-shadow: unset !important;
  }
  .k-stepper .k-step-indicator::after {
    border-style: unset !important;
  }
  .k-stepper .k-step-label .k-step-text {
    width: 200px;
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #8a8a8a;
    font-size: 12px;
    max-width: unset !important;
  }
  .k-progressbar {
    background: #5b5b5b;
  }
  .k-progressbar .k-selected {
    background: #2dce98;
  }
  .k-stepper .k-step-done .k-step-indicator {
    border-color: #2dce98;
    color: white;
    background-color: #2dce98;
  }
  .k-stepper .k-step-current .k-step-indicator {
    border-color: #2dce98;
    color: white;
    background-color: #2dce98;
  }
  .k-stepper .k-step-indicator {
    border-color: #5b5b5b;
    color: #c4c4c4;
    background-color: #5b5b5b;
    width: 25px;
    height: auto;
  }
  .k-stepper {
    padding-bottom: 20px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        padding: 0 10px;
        height: 55px;
        gap: 5px;
      }
      .circular-stepper-heading {
        font-size: 13px;
      }
      .MuiCircularProgress-root {
        --CircularProgress-size: 50px;
      }
    }
    .k-stepper .k-step-label .k-step-text {
      width: 55px;
      font-size: 10px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        height: 55px;
        padding: 0 15px;
      }
      .circular-stepper-heading {
        font-size: 18px;
      }
      .MuiCircularProgress-root {
        --CircularProgress-size: 55px;
      }
    }
    .k-stepper .k-step-label .k-step-text {
      width: 55px;
      font-size: 11px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        height: 55px;
        padding: 0 20px;
        gap: 25px;
      }
      .circular-stepper-heading {
        font-size: 22px;
      }
    }
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .form-MainWrapper {
      padding-top: 25px;
      .inner-BorderWrapper {
        padding: 20px 20px;
      }
      .TextFieldWrapper {
        width: 100%;
        &.BackNumberFieldWrapper {
          .BackNumberField-label {
            margin: 0px !important;
          }
        }
        &.height-weight-wrapper {
          .FlexFieldWrapper {
            flex-direction: column;
            align-items: flex-start;
          }
          .note-block {
            font-size: 11px !important;
            padding-top: 22px !important;
            align-items: baseline;
            width: 100%;
            height: 100%;
          }
        }
      }
      .BackFieldWrapper {
        width: 100%;
        .StyledTextField {
        }
      }
      .StyledTextField {
        margin: 0px 0px;
      }
      .SHdropdown {
        margin: 0px 0px !important;
      }
      #InfoLabelWrapper {
        padding: 0 0px 10px 0px !important;
        label {
          font-size: 11px !important;
        }
      }
      .TextFieldGrid {
        &.height-note-grid {
          padding: 0px 0px !important;
          flex-direction: column-reverse !important;
        }
      }
      .MainAddressWrapper {
        width: 100% !important;
      }
      .StyledHeightTextField {
        margin: 0px !important;
        &.StyledHeightTextField-first {
          margin: 0px !important;
        }
      }
      .StyledWeightTextField {
        margin: 0px !important;
      }
      #BottomRightWrapper {
        height: 50px;
      }
      .NoteFieldWrapper {
        margin: 0px;
      }
      .HeightFieldWrapper {
        width: 100%;
        gap: 10px;
        margin-bottom: 20px;
      }
      .horizontal-line {
        margin: 20px 0px 15px 0px;
      }
      .BackNumberWrapper {
        padding: 0px;
      }
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .form-MainWrapper {
      padding-top: 25px;
      .inner-BorderWrapper {
        padding: 20px 20px;
      }
      .TextFieldWrapper {
        width: 100%;
        &.BackNumberFieldWrapper {
          .BackNumberField-label {
            margin: 0px !important;
          }
        }
        &.height-weight-wrapper {
          .FlexFieldWrapper {
            flex-direction: column;
            align-items: flex-start;
          }
          .note-block {
            font-size: 11px !important;
            padding-top: 22px !important;
            align-items: baseline;
            width: 100%;
            height: 100%;
          }
        }
      }
      .StyledTextField {
        margin: 0px 0px;
      }
      .SHdropdown {
        margin: 0px 0px !important;
      }
      #InfoLabelWrapper {
        padding: 0 0px 10px 0px !important;
        label {
          font-size: 13px !important;
        }
      }
      .TextFieldGrid {
        padding: 10px 0px !important;
        &.height-note-grid {
          padding: 0px 0px !important;
          flex-direction: column-reverse !important;
        }
      }
      .MainAddressWrapper {
        margin: 0px !important;
        padding: 0px 0px 85px 0px !important;
      }
      .NoteFieldWrapper {
        margin: 0px !important;
        .note-block {
          font-size: 10px;
          letter-spacing: 0.2px;
        }
      }
      .StyledWeightTextField {
        width: 33.33%;
        input {
          width: 100%;
        }
      }
      .StyledHeightTextField {
        width: 33.33%;
        input {
          width: 100%;
        }
        &.StyledHeightTextField-first {
          margin: 0px !important;
        }
      }
      .horizontal-line {
        margin: 20px 0px 15px 0px;
      }

      #BottomRightWrapper {
        height: 50px;
      }
      .HeightFieldWrapper {
        width: 100%;
        gap: 10px;
        margin-bottom: 20px;
      }
      .BackNumberWrapper {
        padding: 0px;
      }
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .form-MainWrapper {
      padding-top: 25px;
      .inner-BorderWrapper {
        padding: 20px 20px;
      }

      #InfoLabelWrapper {
        label {
          font-size: 10px !important;
        }
      }
      .TextFieldWrapper {
        width: 100%;
        &.height-weight-wrapper {
          .note-block {
            font-size: 11px !important;
            padding-top: 22px !important;
          }
        }
      }
      .StyledTextField {
        margin: 0px 8px;
      }
      .note-block {
        font-size: 10px;
      }
      .NoteFieldWrapper {
        margin: 0 8px;
      }

      #BottomRightWrapper {
        height: 50px;
      }
      .StyledHeightTextField {
        margin: 0 8px !important;
      }
      .horizontal-line {
        margin: 20px 12px 15px 12px;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .form-MainWrapper {
      .inner-BorderWrapper {
        padding: 20px 30px;
      }
      .SHdropdown {
        margin: 0px 12px;
      }

      #InfoLabelWrapper {
        label {
          font-size: 10px !important;
        }
      }
      .TextFieldWrapper {
        width: 100%;
        &.height-weight-wrapper {
          .note-block {
            font-size: 11px !important;
            padding-top: 22px !important;
          }
        }
      }
      .StyledTextField {
        margin: 0px 12px;
      }
      .note-block {
        font-size: 10px;
      }
      .NoteFieldWrapper {
        margin: 0 8px;
      }

      #BottomRightWrapper {
        height: 50px;
      }
      .StyledHeightTextField {
        margin: 0 8px !important;
      }
      .HeightFieldWrapper {
        width: 75%;
        gap: 10px;
        input {
          width: 100%;
        }
      }
      .horizontal-line {
        margin: 20px 12px 15px 12px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .form-MainWrapper {
      .inner-BorderWrapper {
        padding: 20px 10px 30px 10px;
      }
      .StyledHeightTextField {
        width: 25%;
        input {
          width: 100%;
        }
      }
      .StyledWeightTextField {
        width: 25%;
        input {
          width: 100%;
        }
      }
      .NoteFieldWrapper {
        margin: 0px 12px;
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .form-MainWrapper {
      .inner-BorderWrapper {
        padding: 20px 10px 30px 10px;
      }
      .FlexFieldWrapper {
        padding: 0 12px;
        gap: 10px;
      }
      .HeightFieldWrapper {
        gap: 10px;
        width: 100%;
        .StyledHeightTextField {
          margin: 0px;
          width: 25%;
          input {
            width: 100%;
          }
        }
      }
      .StyledWeightTextField {
        width: 25%;
        margin: 0px !important;
        input {
          width: 100%;
        }
      }
      .NoteFieldWrapper {
        margin: 0px 12px;
      }
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .form-MainWrapper {
      .inner-BorderWrapper {
        padding: 20px 30px 30px 30px;
      }
      .FlexFieldWrapper {
        padding: 0 12px;
        gap: 10px;
      }
      .HeightFieldWrapper {
        gap: 10px;
        width: 100%;
        .StyledHeightTextField {
          margin: 0px;
          width: 25%;
          input {
            width: 100%;
          }
        }
      }
      .StyledWeightTextField {
        width: 25%;
        margin: 0px !important;
        input {
          width: 100%;
        }
      }
      .NoteFieldWrapper {
        margin: 0px 12px;
      }
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    .form-MainWrapper {
      .inner-BorderWrapper {
        padding: 20px 35px 40px 35px;
      }
      .FlexFieldWrapper {
        padding: 0 12px;
        gap: 10px;
      }
      .HeightFieldWrapper {
        gap: 10px;
        width: 100%;
        .StyledHeightTextField {
          margin: 0px;
          width: 25%;
          input {
            width: 100%;
          }
        }
      }
      .StyledWeightTextField {
        width: 25%;
        margin: 0px !important;
        input {
          width: 100%;
        }
      }
      .NoteFieldWrapper {
        margin: 0px 12px;
      }
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    .form-MainWrapper {
      .inner-BorderWrapper {
        padding: 20px 60px 50px 60px;
      }
      .FlexFieldWrapper {
        padding: 0 12px;
        gap: 10px;
      }
      .HeightFieldWrapper {
        gap: 10px;
        width: 100%;
        .StyledHeightTextField {
          margin: 0px;
          width: 25%;
          input {
            width: 100%;
          }
        }
      }
      .StyledWeightTextField {
        width: 25%;
        margin: 0px !important;
        input {
          width: 100%;
        }
      }
      .NoteFieldWrapper {
        margin: 0px 12px;
      }
    }
  }
`;
const ContentWrapper = styled.div`
  //   padding-top: 30px;
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  padding: 40px 20px 100px;
  overflow: auto;
  p {
    margin: 0px;
  }
  .para {
    color: #ff9900;
    padding: 30px 0px 10px;
  }
  .para-one {
    color: #9597a6;
    padding: 20px 0px 10px;
  }
  .para-two {
    color: #ff9900;
    border-bottom: 1px solid #585858;
    padding: 0px 0px 10px;
  }
  h4 {
    color: #306fdb;
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
  }
`;
const BottomRightWrapper = styled.div`
  z-index: 1;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 20px 20px;
  backdrop-filter: blur(10px);
  position: absolute;
  bottom: 0px;
  left: 0px;
  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    /* position: absolute;
    bottom: 40px;
    padding-top: 30px;
    background: linear-gradient(
      to bottom,
      rgb(48 48 48 / 79%) 0%,
      rgb(42 42 42) 100%
    ); */
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 40px;
    &.bg-color-change {
      bottom: 25px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 45px;
    &.bg-color-change {
      bottom: 30px;
      padding-top: 20px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 60px;
    &.bg-color-change {
      bottom: 20px;
      padding-top: 20px;
    }
  }
`;
const ButtonWrapper = styled.div``;
const StepperStep = styled.button`
  background-color: green !important;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;
  font-weight: bold;

  &.completed {
    background-color: green !important;
  }
`;
