import React, { useEffect } from 'react';
import styled from 'styled-components';
import SimpleReactValidator from 'simple-react-validator';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

type Props = {
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: boolean,
  ) => void;
  //   lookupData?: GeneralLookup[];
  //   onChange?: (event: RadioGroupChangeEvent, value: GeneralLookup) => void;
  value?: string | number | boolean | null | undefined | boolean;
  name?: string;
  validator?: SimpleReactValidator;
  validations?: string;
  className?: string;
  validationTitle?: string;
  label1?: string;
  label2?: string;
  radioButtonTheme?: 'light';
  disabled?: boolean;
};

export const CustomRadioButtonGroup = ({
  onChange,
  validations,
  validator,
  className,
  validationTitle,
  label1,
  label2,
  value,
  name,
  disabled = false,
}: Props) => {
  const simpleValidator = React.useRef(validator);
  const handleChange = e => {
    const value = e.target.value;
    if (value === 'true') {
      onChange?.(e, true);
    } else {
      onChange?.(e, false);
    }
  };
  return (
    <MainWrapper
      className={`RadioButtonGroupWrapper ${
        validator && !simpleValidator?.current?.fieldValid(validationTitle!)
          ? 'error'
          : 'default'
      } ${className}`}
    >
      <RadioGroup
        name={name}
        value={value === true ? 'true' : value === false ? 'false' : null}
        onChange={handleChange}
        className="radio-btn-grp"
        // aria-disabled={true}
      >
        <FormControlLabel
          value={true}
          control={<Radio className="" />}
          label={label1 ? label1 : 'Yes'}
        />
        <FormControlLabel
          value={false}
          control={<Radio />}
          label={label2 ? label2 : 'No'}
        />
      </RadioGroup>
      {simpleValidator?.current?.message(
        validationTitle ?? '',
        value,
        validations ?? '',
      )}
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  .radio-btn-grp {
    flex-direction: unset;
    gap: 50px;
    padding-left: 28px;
  }
  span {
    font-family: 'Roboto' !important;
    font-size: 12px !important;
    color: #9597a6 !important;
    font-style: normal;
  }
  .Mui-checked span {
    color: #0d6efd !important;
  }
  .MuiRadio-colorPrimary span {
    color: #707070;
  }
`;
