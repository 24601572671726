import styled from 'styled-components';
import { TextField } from 'app/components/TextField';
import '@fontsource/roboto';
import React from 'react';
import { useState } from 'react';
import { BackNextButton } from 'app/components/BackNextButton';
import { useNavigate } from 'react-router-dom';
import SHDatePicker from 'app/components/SHDatePicker';
import SHdropdown from 'app/components/SHdropdown';
import { ApiError, EWD, GeneralLookup, RiderService } from 'services/openapi';
import useApi from 'services/mavapi/useApi';
import SHYear from 'app/components/SHYear';
import { useInternationalization } from '@progress/kendo-react-intl';
import BackNumberTableComponent from 'app/components/BackNumberListComponent';
import SimpleReactValidator from 'simple-react-validator';
import Cookies from 'js-cookie';
import { heightWeightModel } from 'app/pages/RiderRegistrationPage/components/NotReturningRider';
import SHBackNumberDropdown from 'app/components/SHBackNumberDropdown';
import InfoIconButton from 'app/components/InfoIconButton';
import { isAtLeastSevenYear } from 'utils/common';

type Props = {
  onClickNxtPersonal?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  setTryItEwdData: React.Dispatch<React.SetStateAction<EWD>>;
  tryItEwdData: EWD;
  stateData?: GeneralLookup[];
  teamData?: GeneralLookup[];
  setIsBackNumberCheck: React.Dispatch<React.SetStateAction<boolean | null>>;
  setHeightWeight: React.Dispatch<React.SetStateAction<heightWeightModel>>;
  heightWeight: heightWeightModel;
  backNumberData: string[] | undefined;
  teamLookup?: GeneralLookup[];
};

const TryitEWDPersonalInfoFieldsNew = ({
  onClickNxtPersonal,
  setTryItEwdData,
  tryItEwdData,
  stateData,
  teamData,
  setIsBackNumberCheck,
  setHeightWeight,
  heightWeight,
  backNumberData,
  teamLookup,
}: Props) => {
  const intl = useInternationalization();
  const { error: apiError, handleRequest } = useApi();
  const [, setError] = React.useState<ApiError | unknown>();
  const { riderCheckBackNumberExist } = RiderService;
  const simpleValidator = React.useRef(
    new SimpleReactValidator({
      validators: {
        atLeastSevenYear: {
          message: 'The Date of Birth must be at least 7 year old',
          rule: val => isAtLeastSevenYear(val),
        },
      },
    }),
  );
  const [valid, setValid] = useState<boolean>(false);

  const navigate = useNavigate();
  const [visible, setVisible] = React.useState<boolean>(false);
  const handleBack = () => {
    navigate(-1);
    Cookies.remove('diaganosisPayload');
    Cookies.remove('paymentCancel');
    Cookies.remove('payload');
  };
  const handleInfoIcon = () => {
    setVisible(!visible);
  };

  const expectedGraduationChange = e => {
    setTryItEwdData({
      ...tryItEwdData,
      expectedGraduationYear: e,
    });
  };
  const handleDate = e => {
    let date = intl.formatDate(e.value, "yyyy-MM-dd'T'HH:mm:ss");
    setTryItEwdData({
      ...tryItEwdData,
      dateOfBirth: date,
    });
    simpleValidator.current.showMessageFor('Date of Birth');
  };
  const checkBackNumber = (backNumber?: string) => {
    if (backNumber?.length !== 0) {
      handleRequest(riderCheckBackNumberExist(backNumber))
        .then(res => {
          setIsBackNumberCheck(res!);
        })
        .catch(() => setError(apiError));
    }
  };
  const handleFeetChange = event => {
    const newFeet = parseInt(event.target.value);
    if (event.target.value && newFeet >= 0 && newFeet <= 9) {
      setHeightWeight(prevData => ({ ...prevData, feet: Math.floor(newFeet) }));
      const newHeightInch = newFeet + heightWeight.inches! / 12;
      setTryItEwdData({ ...tryItEwdData, height: newFeet });
    } else if (!event.target.value) {
      setHeightWeight(prevData => ({ ...prevData, feet: undefined }));
    }
  };

  const handleInchesChange = event => {
    const newInches = parseInt(event.target.value);
    if (event.target.value && newInches >= 0 && newInches <= 11) {
      setHeightWeight(prevData => ({
        ...prevData,
        inches: Math.floor(newInches),
      }));
      const newHeightInch = heightWeight.feet! + newInches / 12;
      setTryItEwdData({ ...tryItEwdData, height: newHeightInch });
    } else if (!event.target.value) {
      setHeightWeight(prevData => ({
        ...prevData,
        inches: undefined,
      }));
    }
  };
  const handleWeightChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 3 && /^[0-9]*$/.test(inputValue)) {
      setTryItEwdData({
        ...tryItEwdData,
        weight: inputValue,
      });
      setHeightWeight(prevData => ({
        ...prevData,
        weight: inputValue,
      }));
    }
  };
  // const handleBackNumberChange = e => {
  //   const inputValue = e.target.value;
  //   if (inputValue?.length !== 3 && typeof isBackNumberCheck === 'boolean') {
  //     setIsBackNumberCheck(null);
  //   }
  //   if (inputValue.length <= 2 && /^\d*$/.test(inputValue)) {
  //     setTryItEwdData({
  //       ...tryItEwdData,
  //       backNumber: inputValue,
  //     });
  //   }
  // };
  const handleBackNumberChange = name => {
    if (tryItEwdData) {
      setTryItEwdData?.({
        ...tryItEwdData,
        backNumber: name.value,
      });
    }
  };
  const handlePhChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
      setTryItEwdData({
        ...tryItEwdData,
        primaryPhone: inputValue,
      });
    }
  };
  const handleZipChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 9 && /^[a-zA-Z0-9]*$/.test(inputValue)) {
      setTryItEwdData({ ...tryItEwdData, zip: e.target.value });
    }
  };
  const handleTeamChange = data => {
    if (data) {
      setTryItEwdData?.(prevData => ({
        ...prevData,
        team: data,
      }));
    }
  };
  const handleEmergencyPhChange = e => {
    const inputValue = e.target.value;
    if (tryItEwdData) {
      if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
        setTryItEwdData({
          ...tryItEwdData,
          emergencyContactPhone: inputValue,
        });
      }
    }
  };
  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(!formValid);
  }, [tryItEwdData]);
  return (
    <MainWrapper>
      <OverflowWrapper>
        <TextFieldGrid>
          <TextFieldWrapper>
            <StyledTextField
              label="First Name"
              type="text"
              name="firstName"
              onChange={e =>
                setTryItEwdData({ ...tryItEwdData, firstName: e.target.value })
              }
              value={tryItEwdData.firstName}
              validations={'required|max:30'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledTextField
              label="Last Name"
              type="text"
              name="lastName"
              onChange={e =>
                setTryItEwdData({ ...tryItEwdData, lastName: e.target.value })
              }
              value={tryItEwdData.lastName}
              validations={'required|max:30'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>{' '}
          <TextFieldWrapper className="TextFieldWrapper BackFieldMainWrapper">
            <BackFieldWrapper className="BackFieldWrapper">
              <SHBackNumberDropdown
                onChange={e => handleBackNumberChange(e.value)}
                data={backNumberData}
                defaultValue={tryItEwdData?.backNumber!}
                label="Back Number (2 digits)"
                validations={'required|max:30'}
                validator={simpleValidator.current}
                isAbove={false}
                className="BackfieldNumber"
              />
              <InfoIconButton onClick={handleInfoIcon} className="info-icon" />
            </BackFieldWrapper>
            {visible && (
              <BackNumberTableComponent
                onClose={handleInfoIcon}
                backNumberData={backNumberData}
                isAbove={false}
              />
            )}
          </TextFieldWrapper>
          <DropdownWrapper>
            <SHdropdown
              label="Team Name"
              onDataChange={handleTeamChange}
              data={teamLookup}
              defaultValue={tryItEwdData?.team}
              validations={'required'}
              validator={simpleValidator.current}
              className="drop-down"
            />
          </DropdownWrapper>
          <TextFieldWrapper className="TextFieldWrapper">
            <SHYear
              className="drop-down graduation-drop-down"
              label="Expected Graduation Year"
              defaultValue={
                tryItEwdData?.expectedGraduationYear === 0
                  ? undefined
                  : tryItEwdData?.expectedGraduationYear
              }
              value={
                tryItEwdData?.expectedGraduationYear === 0
                  ? undefined
                  : tryItEwdData?.expectedGraduationYear
              }
              onChange={expectedGraduationChange}
              validations={'required'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper">
            <StyledSHDatePicker
              onChange={handleDate}
              placeholder=""
              value={
                tryItEwdData.dateOfBirth
                  ? new Date(String(tryItEwdData.dateOfBirth))
                  : undefined
              }
              name="dob"
              className="datepicker-style"
              dateLabel="Date of Birth"
              validations={'required|atLeastSevenYear'}
              validator={simpleValidator.current}
              ageLimit={true}
            />
          </TextFieldWrapper>
          <HeightInchWeightWrapper>
            <StyledHeightTextField
              label="Height(Ft)"
              type="number"
              name="height"
              placeholder="Feet"
              onChange={handleFeetChange}
              value={heightWeight.feet === undefined ? '' : heightWeight.feet}
              validations={'required'}
              validator={simpleValidator.current}
            />
            <StyledInchTextField
              onChange={handleInchesChange}
              value={
                heightWeight.inches === undefined ? '' : heightWeight.inches
              }
              type="number"
              name="inch"
              label="Inches"
              placeholder="Inches"
            />
            <StyledWeightTextField
              label="Weight(Lbs)"
              type="text"
              name="weight"
              placeholder="lbs"
              onChange={handleWeightChange}
              value={
                heightWeight.weight === undefined ? '' : heightWeight.weight
              }
              validations={'required'}
              validator={simpleValidator.current}
            />
          </HeightInchWeightWrapper>
          <TextFieldWrapper>
            <StyledTextField
              label="Cell/Primary Ph#"
              type="tel"
              name="ph"
              onChange={handlePhChange}
              value={tryItEwdData.primaryPhone}
              validations={'required|numeric|min:10|max:10'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledTextField
              label="Email"
              type="email"
              name="email"
              onChange={e =>
                setTryItEwdData({ ...tryItEwdData, email: e.target.value })
              }
              value={tryItEwdData.email}
              validations={'required|email'}
              validator={simpleValidator.current}
              maxLength={60}
            />
          </TextFieldWrapper>
        </TextFieldGrid>
        <HomeAddressWrapper className="horizontal-line">
          <p className="home-address">Home mailing address</p>
        </HomeAddressWrapper>
        <TextFieldGrid>
          <TextFieldWrapper>
            <StyledTextField
              label="Street Address"
              type="text"
              name="streetAddress"
              onChange={e =>
                setTryItEwdData({ ...tryItEwdData, street: e.target.value })
              }
              value={tryItEwdData.street}
              validations={'required'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledTextField
              label="City"
              type="text"
              name="city"
              onChange={e =>
                setTryItEwdData({ ...tryItEwdData, city: e.target.value })
              }
              value={tryItEwdData.city}
              validations={'required|max:40'}
              validator={simpleValidator.current}
              maxLength={40}
            />
          </TextFieldWrapper>
          <DropdownWrapper>
            <SHdropdown
              className="drop-down"
              label="State"
              data={stateData}
              onDataChange={data =>
                setTryItEwdData({
                  ...tryItEwdData,
                  state: data,
                })
              }
              defaultValue={tryItEwdData.state}
              validations={'required'}
              validator={simpleValidator.current}
            />
          </DropdownWrapper>
          <TextFieldWrapper>
            <StyledTextField
              label="Zip"
              type="text"
              name="zip"
              onChange={handleZipChange}
              value={tryItEwdData.zip}
              validations={'required|alpha_num|min:5|max:9'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledTextField
              label="Emergency Contact Name"
              type="text"
              name="emergencyContactName"
              onChange={e => {
                if (tryItEwdData) {
                  setTryItEwdData({
                    ...tryItEwdData,
                    emergencyContactName: e.target.value,
                  });
                }
              }}
              value={tryItEwdData?.emergencyContactName ?? ''}
              validations={'required|max:30'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledTextField
              label="Emergency Contact Number"
              type="tel"
              name="emergencyContactNumber"
              onChange={handleEmergencyPhChange}
              value={tryItEwdData?.emergencyContactPhone ?? ''}
              validations={'required|numeric|min:10|max:10'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
        </TextFieldGrid>
        <ButtonWrapper className="bg-color-change">
          <BackNextButton
            classNameBack="btn-back"
            classNameNxt="btn-nxt"
            onClickNxt={onClickNxtPersonal}
            onClickBack={handleBack}
            disableNext={valid}
            next="Next"
            back="Back"
          />
        </ButtonWrapper>
      </OverflowWrapper>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  color: #9597a6;
  width: 100%;
  height: calc(100vh - 245px);
  padding: 20px 0px 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    font-family: 'Roboto';
    font-size: 14px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    label {
      font-size: 14px;
    }
  }
`;
const OverflowWrapper = styled.div`
  height: 100%;
  overflow: auto;
  padding-bottom: 100px;
  width: 100%;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
`;

const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  ::placeholder {
    color: #8f8e8e !important;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    display: block;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    display: block;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    display: block;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    display: block;
  }
`;
const TextFieldWrapper = styled.div`
  flex: 0 0 calc(50% - 20px) !important;
  margin: 0px 0px 10px !important;
  .graduation-drop-down {
    margin: 0px 0px 10px !important;
    @media (min-width: 100px) and (max-width: 575px) {
      margin: 0px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      margin: 0px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      margin: 0px;
    }
  }

  :has(.datepicker-style) {
    margin: 0px 0px 10px !important;
  }
`;
const HomeAddressWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  width: 100%;

  ::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #585858;
  }

  p {
    margin: 0 10px 0px 0px;
    white-space: nowrap;
    color: #4f4f4f;
  }
`;
const StyledTextField = styled(TextField)`
  flex: 0 0 calc(50% - 20px);
  input {
    &.back-number {
      &.green-border {
        border: 1px solid green;
      }
      &.red-border {
        border: 1px solid red;
      }
    }
  }
  margin: 0px 0px 10px !important;
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const BackFieldWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .BackfieldNumber {
    width: 80%;
    margin: 0px !important;
  }
  .info-icon {
    margin: 24px 0px 0px 0px !important;
  }

  @media (min-width: 100px) and (max-width: 575px) {
    width: 100%;
    .info-icon {
      margin: 17px 0px 0px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    width: 100%;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 100%;
    .info-icon {
      margin: 17px 0px 0px !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 100%;
    .info-icon {
      margin: 20px 0px 0px !important;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .info-icon {
      margin: 18px 0px 0px !important;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .info-icon {
      margin: 21px 0px 0px !important;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .info-icon {
      margin: 23px 0px 0px !important;
    }
  }
`;
const StyledSHDatePicker = styled(SHDatePicker)`
  display: flex;
  align-items: end;
  width: 100% !important;
  height: 35px;

  input {
    width: 100%;
    height: 35px;
  }
  span {
    border-color: #434343;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 100%;
  }
`;
const HeightInchWeightWrapper = styled.div`
  display: flex;
  flex: 0 0 calc(50% - 20px);
  gap: 10px;
  span {
    font-size: 10px;
    display: flex;
    align-items: center;
    padding-right: 10px;
  }
`;
const StyledWeightTextField = styled(TextField)`
  margin: 0px 0px 10px !important;
  width: 33%;
  input {
    width: 100%;
    height: 35px;
  }
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const StyledInchTextField = styled(TextField)`
  margin: 0px 0px 10px !important;
  padding-right: 10px;
  width: 33%;
  input {
    width: 100%;
    height: 35px;
  }
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const StyledHeightTextField = styled(TextField)`
  margin: 0px 0px 10px !important;
  padding-right: 10px;
  width: 33%;
  input {
    width: 100%;
    height: 35px;
  }
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const DropdownWrapper = styled.div`
  flex: 0 0 calc(50% - 20px);
  p {
    margin: 0px;
    font-family: 'Roboto';
    font-size: 14px;
  }
  .SHdropdown {
    margin: 0px;
    width: 100% !important;
    height: 35px;
    color: #9597a6;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    padding-bottom: 25px;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    padding-bottom: 25px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-bottom: 25px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-bottom: 25px;
  }
`;
const ButtonWrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: -19px;
    left: -3px;
    z-index: 2;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
    @media (min-width: 1200px) and (max-width: 1365px) {
      height: 55px;
    }
    @media (min-width: 1366px) and (max-width: 1448px) {
      height: 70px;
    }
  }
`;
export default TryitEWDPersonalInfoFieldsNew;
