import { BackNextButton } from 'app/components/BackNextButton';
import { CheckBox } from 'app/components/CheckBox';
import SHDatePicker from 'app/components/SHDatePicker';
import SHdropdown, { DropDownModel } from 'app/components/SHdropdown';
import { TextField } from 'app/components/TextField';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

type Props = {
  onClickEquipmentCheckboxNext: () => void;
  onClickEquipmentCheckboxBack: () => void;
};
const saddle = [
  { id: 1, equipment: 'Hard/soft hand holds' },
  { id: 2, equipment: 'Knee rolls/blocks' },
  { id: 3, equipment: 'Padded saddle flaps' },
  { id: 4, equipment: 'Raised cantle' },
  { id: 5, equipment: 'Raised pommel' },
  { id: 6, equipment: 'Thigh rolls/blocks' },
  { id: 7, equipment: 'Saddle Blocks Wedges Cushions' },
  { id: 8, equipment: 'Seat Savers' },
];

const ridingAttire = [
  {
    id: 1,
    equipment:
      'No boots if using safety stirrups (Peacock, S-shaped irons or Devonshire stirrups)',
  },
  { id: 2, equipment: 'Modified riding boots' },
  { id: 3, equipment: 'Gaiters' },
  { id: 4, equipment: 'Half chaps' },
  { id: 5, equipment: 'Helmet' },
  { id: 6, equipment: 'Off set spurs' },
  { id: 7, equipment: 'Safety vest' },
];

const postureSupport = [
  { id: 1, equipment: 'L or R Arm Sling' },
  { id: 2, equipment: 'Neck Collar' },
  { id: 3, equipment: 'Ankle foot orthoses' },
  { id: 4, equipment: 'Prosthesis' },
  { id: 5, equipment: 'Wrist brace' },
  { id: 6, equipment: 'Back support' },
  { id: 7, equipment: 'Trunk support' },
  { id: 8, equipment: 'Gait belt' },
];

const stirrup = [
  { id: 1, equipment: 'Enclosed stirrups' },
  { id: 2, equipment: 'Rubber bands around foot and stirrup' },
  { id: 3, equipment: 'Strap from stirrup leather to girth/cinch' },
  { id: 4, equipment: 'Strap from stirrup to girth/cinch' },
  { id: 5, equipment: 'Laces to tie stirrups or leathers to girth or cinch' },
  { id: 6, equipment: 'Safety Stirrups' },
];

const bridleReins = [
  { id: 1, equipment: 'Bridging rein' },
  { id: 2, equipment: 'Connecting bar reins' },
  { id: 3, equipment: 'Dowel Reins' },
  { id: 4, equipment: 'Ladder reins' },
  { id: 5, equipment: 'Looped reins' },
  { id: 6, equipment: 'Rein guides (rein through ring on saddle)' },
  { id: 7, equipment: 'Side pulls' },
  { id: 8, equipment: 'Rein Handles' },
];

const otherAids = [
  { id: 1, equipment: 'Audio Communications (for hearing impaired)' },
  { id: 2, equipment: 'Bareback pads' },
  { id: 3, equipment: 'Commander using sign language' },
  { id: 4, equipment: 'One or two crops' },
  { id: 5, equipment: 'Strap attaching crops to hand' },
  { id: 6, equipment: 'Voice' },
  { id: 7, equipment: 'Other' },
];

const AdaptiveEquipmentsNew = ({
  onClickEquipmentCheckboxBack,
  onClickEquipmentCheckboxNext,
}: Props) => {
  const navigate = useNavigate();
  const [detailsData, setDetailsData] = useState({
    coachName: '',
    team: '',
    coachSignature: '',
  });
  const handleChange = event => {
    const { name, value } = event.target;
    console.log('changed', value);
    setDetailsData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <MainWrapper>
      <OverflowWrapper>
        <p className="first-para">
          A membership registration forms for Equestrian with Disabilities must
          be accompanied by a Diagnosis and Adaptive Equipment Form.
        </p>
        <p className="para-two">
          <i>
            Eligible Conditions: From the list below, please indicate each
            condition which applies to the applicant. Other conditions will be
            considered upon request (please list in space provided).
          </i>
        </p>
        <ContentWrapper>
          <CheckboxWrapper>
            <div className="horizontal-line">
              <span>Saddle:</span>
            </div>
            <div>
              {saddle.map(value => (
                <CheckBox key={value.id} label={value.equipment} />
              ))}
            </div>
          </CheckboxWrapper>
          <CheckboxWrapper>
            <div className="horizontal-line">
              <span>Stirrup:</span>
            </div>
            <div>
              {stirrup.map(value => (
                <CheckBox key={value.id} label={value.equipment} />
              ))}
            </div>
          </CheckboxWrapper>
          <CheckboxWrapper>
            <div className="horizontal-line">
              <span>Riding Attire:</span>
            </div>
            <div>
              {ridingAttire.map(value => (
                <CheckBox key={value.id} label={value.equipment} />
              ))}
            </div>
          </CheckboxWrapper>
          <CheckboxWrapper>
            <div className="horizontal-line">
              <span>Bridle Reins:</span>
            </div>
            <div>
              {bridleReins.map(value => (
                <CheckBox key={value.id} label={value.equipment} />
              ))}
            </div>
          </CheckboxWrapper>
          <CheckboxWrapper>
            <div className="horizontal-line">
              <span>Posture, Postural Supports & Orthoses:</span>
            </div>
            <div>
              {postureSupport.map(value => (
                <CheckBox key={value.id} label={value.equipment} />
              ))}
            </div>
          </CheckboxWrapper>
          <CheckboxWrapper>
            <div className="horizontal-line">
              <span>Other Aids:</span>
            </div>
            <div>
              {otherAids.map(value => (
                <CheckBox key={value.id} label={value.equipment} />
              ))}
            </div>
            <br />
            <CheckBox
              label="No Adaptive Equipment needed"
              className="last-checkbox"
            />
          </CheckboxWrapper>
        </ContentWrapper>
        <InfoWrapper>
          <p className="para-one">
            <i>Coach Statement:</i>
          </p>
          <p>
            In accordance with YEDA Rulebook, this applicant will be using the
            above designated equipment while competing in YEDA Equestrians with
            Disabilities competitions and has the ability to ride these special
            classes.
          </p>
        </InfoWrapper>
        <TextFieldGrid>
          <StyledTextField
            onChange={handleChange}
            value={detailsData.coachName}
            label="Name of Coach"
            type="text"
            name="coachName"
          />
          <StyledTextField
            onChange={handleChange}
            value={detailsData.team}
            label="Team"
            type="text"
            name="team"
          />
          {/* <StyledTextField
            onChange={handleChange}
            value={detailsData.coachSignature}
            label="Signature of Coach "
            type="text"
            name="coachSignature"
          />
          <StyledSHDatePicker
            onChange={changeDate}
            placeholder=""
            value={value}
            name="dob"
            className="datepicker-style"
            dateLabel="Date"
          /> */}
        </TextFieldGrid>
        <ButtonWrapper className="bg-color-change">
          <BackNextButton
            classNameBack="btn-back"
            classNameNxt="btn-nxt"
            onClickNxt={onClickEquipmentCheckboxNext}
            onClickBack={onClickEquipmentCheckboxBack}
            next="Submit"
            back="Cancel"
          />
        </ButtonWrapper>
      </OverflowWrapper>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  color: #9597a6;
  width: 100%;
  height: calc(100vh - 90px);
  position: relative;
  padding: 20px 0px 0px;

  label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  .para-two {
    color: #ff9900;
    border-bottom: 1px solid #585858;
    padding: 0px 0px 10px;
    font-size: 11px;
    margin-bottom: 20px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    // padding-top: 20px;
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    // padding-top: 20px;
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    // padding-top: 20px;
    label {
      font-size: 14px;
    }
  }
`;
const OverflowWrapper = styled.div`
  height: 100%;
  overflow: auto;
  padding-bottom: 100px;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
  .k-input-solid {
    width: 325px !important;
  }
`;
const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); // Adjust the number of columns here
  grid-gap: 10px;
  .horizontal-line {
    display: flex;
    align-items: baseline;
    margin: 20px 0 15px;
  }
  .horizontal-line span {
    margin: 0 10px 0 0;
    white-space: nowrap;
    color: #9597a6;
    font-size: 15px;
  }
  .horizontal-line::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #434343;
    height: 3px;
  }
`;
const CheckboxWrapper = styled.div`
  .last-checkbox {
    color: #ff9900;
  }
`;
const DropdownWrapper = styled.div`
  flex: 0 0 calc(50% - 20px);
  p {
    margin: 0px;
    font-family: 'Roboto';
    font-size: 14px;
  }
  .drop-down {
    margin: 0px;
    width: 100% !important;
    height: 35px;
    color: #9597a6;
  }
`;
const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const StyledTextField = styled(TextField)`
  flex: 0 0 calc(50% - 20px);
  margin: 0px 0px 10px !important;
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const StyledSHDatePicker = styled(SHDatePicker)`
  flex: 0 0 calc(50% - 20px);
  margin: 0px 0px 10px !important;
  &.k-input-solid {
    width: 100% !important;
  }
`;

const ButtonWrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: -10px;
    left: -4px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
  }
`;
const InfoWrapper = styled.div`
  width: 100%;
  border: 1px solid #585858;
  border-radius: 10px;
  margin: 30px 0px 20px;
  padding: 10px 20px;
  background: #27292d;
  .para-one {
    font-family: 'Roboto';
    color: #ff9900;
    border-bottom: 1px solid #585858;
  }
  .notice-para {
    text-align: center;
    border-top: 1px solid #585858;
    border-bottom: 1px solid #585858;
    margin: 10px 35px;
    padding: 10px 0px;
  }
`;
export default AdaptiveEquipmentsNew;
