import { GridCellProps } from '@progress/kendo-react-grid';
import styled from 'styled-components';

type Props = {
  className?: string;
  e: GridCellProps;
};

const ExtrasCell = ({ className, e }: Props) => {
  const data = e?.dataItem;
  return (
    <td className={className ?? ''}>
      <Wrapper>
      <div><span className='content'>Insurance:</span> <span className='content-value'>{data?.hasInsurance ? "YES" : "NO"}</span></div>
      <div><span className='content'>Accepting New Members: </span><span className='content-value'>{data?.isTeamAcceptingMembers ? "YES" : "NO"}</span></div>
      <div><span className='content'>Referral Program:</span> <span className='content-value'>{data?.isInterestedInReferralProgram  ? "YES" : "NO"}</span></div>
      </Wrapper>
    </td>
  );
};

export default ExtrasCell;
const Wrapper = styled.div`
  .content {
    color: black !important;
  }
  .content-value {
    color: #475467;
  }
`;
