import { isEmtyString, isNullOrZero } from 'utils/common';

export const defaultLookup = { id: '', name: '' };

export const defaultRiderValue = {
  riderId: null,
  firstName: '',
  lastName: '',
  backNumber: '',
  team: defaultLookup,
  category: defaultLookup,
  divisionClassOne: defaultLookup,
  divisionClassTwo: defaultLookup,
  height: null,
  weight: null,
  street: '',
  city: '',
  state: defaultLookup,
  zip: '',
  primaryPhone: '',
  email: '',
  school: {
    schoolName: '',
    email: '',
    street: '',
    city: '',
    state: defaultLookup,
    zip: '',
  },
  grade: defaultLookup,
  expectedGraduationYear: 0,
  sifJoinedDate: '',
  sifAmountPaid: null,
  sifPoint: null,
  parent: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    street: '',
    city: '',
    state: defaultLookup,
    zip: '',
  },
  riderStatus: {
    id: '',
    name: '',
  },
  adminCreatedDate: '',
  isTrial: false,
  isTransferredTeam: false,
  isAvailableBackNumber: false,
};

export const breadCrumpitems = [
  {
    id: '',
    text: 'Showyeda admin',
    disabled: true,
  },
  {
    id: '/admin/riders',
    text: 'Riders',
  },
  {
    id: '',
    text: 'Rider Info',
    disabled: true,
  },
];

export const tabRiderNamesArray = [
  { tab: 'Rider Information', id: 1 },
  { tab: 'School Details', id: 2 },
  { tab: 'Parent Details', id: 3 },
];
export const riderInfoTabValidation = riderData => {
  let riderInfoTabValid =
    isEmtyString(riderData.primaryPhone) ||
    isEmtyString(riderData.email) ||
    isEmtyString(riderData.sifJoinedDate) ||
    isEmtyString(riderData.street) ||
    isEmtyString(riderData.city) ||
    isEmtyString(riderData.state.id) ||
    isEmtyString(riderData.zip);
  return riderInfoTabValid;
};

export const schoolTabValidation = riderData => {
  let isSchoolTabValid =
    isEmtyString(riderData?.school?.schoolName) ||
    isEmtyString(riderData?.grade?.id) ||
    isNullOrZero(riderData.expectedGraduationYear) ||
    isEmtyString(riderData?.school?.street) ||
    isEmtyString(riderData?.school?.state?.id) ||
    isEmtyString(riderData?.school?.zip) ||
    isEmtyString(riderData?.school?.city);
  return isSchoolTabValid;
};

export const parentDetailsTabValidation = riderData => {
  let isParentDetailsTabValid =
    isEmtyString(riderData.parent?.firstName) ||
    isEmtyString(riderData?.parent?.phone) ||
    isEmtyString(riderData?.parent?.street) ||
    isEmtyString(riderData.parent?.city) ||
    isEmtyString(riderData.parent?.state?.id) ||
    isEmtyString(riderData.parent?.zip);
  return isParentDetailsTabValid;
};
