import { defaultLookup } from 'admin/pages/RiderIndividual/components/RiderCreatePage/staticDatas';

export const defaultCoachValue = {
  id: null,
  isAssistant: false,
  firstName: '',
  lastName: '',
  email: '',
  primaryPhone: '',
  street: '',
  city: '',
  state: defaultLookup,
  zip: '',
  emergencyContactName: '',
  emergencyContactPhone: '',
  isTrial: false,
  hasVerifiedCertificateOfInsurance: undefined,
  isTeamAcceptingMembers: undefined,
  isInterestedInReferralProgram: undefined,
  affiliations: [defaultLookup],
  status: defaultLookup,
  team: defaultLookup,
  season: defaultLookup,
};

export const booleanLookupData = [
  { id: 0, text: 'Yes' },
  { id: 1, text: 'No' },
];

export const breadCrumpitems = [
  {
    id: '',
    text: 'Showyeda admin',
    disabled: true,
  },
  {
    id: '/admin/coaches',
    text: 'Coaches',
  },
  {
    id: '',
    text: 'Coach Info',
    disabled: true,
  },
];
export const selectedObject = (isTrue: boolean | undefined) => {
  return isTrue === undefined
    ? undefined
    : isTrue
    ? booleanLookupData[0]
    : booleanLookupData[1];
};
