import { TeamPointSummary } from 'services/openapi';

export const updateData = (data: TeamPointSummary[]) => {
  return data?.map(team => {
    const classesObject = {};
    team?.shows?.forEach(clss => {
      classesObject[clss?.showId!] = clss?.point;
    });
    return {
      ...team,
      ...classesObject,
    };
  });
};
