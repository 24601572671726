import * as React from 'react';
import styled from 'styled-components/macro';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
export type LogoutProps = {
  show?: boolean;
  setLogoutVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleLogout: () => void;
};
export function CustomLogoutPopup({
  show,
  setLogoutVisible,
  handleLogout,
}: LogoutProps) {
  const toggleDialog = () => {
    setLogoutVisible(!show);
  };
  const clickLogout = () => {
    handleLogout();
  };

  return (
    <>
      {show && (
        <LogoutDialog>
          <LogoutMainWrapper>
            <LogoutWrapper>Are you sure you want to Logout?</LogoutWrapper>
          </LogoutMainWrapper>
          <LogoutActionsBar>
            <button className="btn-cancel" onClick={toggleDialog}>
              Cancel
            </button>
            <button className="btn-apply" onClick={clickLogout}>
              Confirm
            </button>
          </LogoutActionsBar>
        </LogoutDialog>
      )}
    </>
  );
}

const LogoutDialog = styled(Dialog)`
  .k-dialog {
    width: 100%;
    max-width: 500px;
    background: rgb(255, 255, 255);
    border-color: rgb(224, 224, 224);
    height: 185px;
    font-family: 'Inter';
    border-radius: 15px;
  }
  .k-window-actions {
    border: none;
    max-width: 300px;
    margin: 15px auto 10px auto;
    button {
      cursor: pointer;
      font-size: 14px;
      border-radius: 30px;
      padding: 10px 15px;
      box-shadow: none;
      outline: none;
      margin: 0 5px;
      @media (min-width: 320px) and (max-width: 991px) {
        font-size: 14px;
      }
      &.btn-cancel {
        background: rgb(228, 228, 228);
        border: 1px solid rgb(112, 112, 112);
        color: rgb(0, 0, 0);
      }
      &.btn-apply {
        background: rgb(48, 111, 219);
        border: 1px solid rgb(48, 111, 219);
        color: rgb(255, 255, 255);
      }
    }
  }
`;
const LogoutMainWrapper = styled.div`
  padding: 25px 0 15px 0;
`;
const LogoutWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 18px;
  @media (min-width: 320px) and (max-width: 991px) {
    font-size: 16px;
  }
`;
const LogoutActionsBar = styled(DialogActionsBar)`
  font-family: 'Inter';
`;
