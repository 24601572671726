import styled from 'styled-components';

type Props = {
  value?: any;
};

export const ScorePointCell = ({ value }: Props) => {
  return (
    <CustomCellWrapper className={`${value ? 'has-value' : ''}`}>
      <div className="label-wrapper">{value ? value : '--'}</div>
    </CustomCellWrapper>
  );
};
const CustomCellWrapper = styled.td`
  align-items: center;
  background-color: #f6f6f6 !important;
  .label-wrapper {
    display: flex;
    justify-content: center;
  }
  &.has-value {
    background-color: #ffffff !important;
    .label-wrapper {
      display: flex;
      justify-content: center;
      color: #0302ff;
      font-weight: 600;
    }
  }
`;
