import React from 'react';
import styled from 'styled-components';
import arrow from './assets/img/icon/arrow.png';
import Cookies from 'js-cookie';

type Props = {
  userType?: string;
};
const data = [
  {
    id: 1,
    text: 'COACH GUARANTEES THEY ARE RESPONSIBLE FOR ALUMNI AND ASKING FOR RE-RIDES',
  },
  {
    id: 2,
    text: 'COACH AND TEAM WILL BE RESPONSIBLE FOR PROVIDING HORSES TO COVER ALUMNI RIDES',
  },
  {
    id: 3,
    text: ' COACH GUARANTEES THE ALUMNI IS QUALIFIED IN THEIR ABILITY TO RIDE UNFAMILIAR HORSES IN A GROUP',
  },
];
export const AlumniNoticeInfo = ({ userType }: Props) => {
  const currentSeason = Cookies.get('isAdminLiveFiltered');
  return (
    <MainWrapper>
      <p className="para-one">
        <i>NOTICE</i>
      </p>
      <p className="para-two">
        YEDA DOES NOT ASSUME ANY RESPONSIBILITY FOR THE SAFETY OF PARTICIPANTS
        AND/OR ATTENDEES AT ALL EQUINE EVENTS. EQUINE EVENTS ARE INHERENTLY
        RISKY. “INHERENT RISK OF AN EQUINE ACTIVITY" MEANS A DANGER OR CONDITION
        THAT IS AN INTEGRAL PART OF AN EQUINE ACTIVITY, INCLUDING, BUT NOT
        LIMITED TO, ANY OF THE FOLLOWING:(A) THE PROPENSITY OF AN EQUINE TO
        BEHAVE IN WAYS THAT MAY RESULT IN INJURY, DEATH, OR LOSS TO PERSONS ON
        OR AROUND THE EQUINE; (B) THE UNPREDICTABILITY OF AN EQUINE'S REACTION
        TO SOUNDS, SUDDEN MOVEMENT, UNFAMILIAR OBJECTS, PERSONS, OR OTHER
        ANIMALS; (C) HAZARDS, INCLUDING, BUT NOT LIMITED TO, SURFACE OR
        SUBSURFACE CONDITIONS; (D) A COLLISION WITH ANOTHER EQUINE, ANOTHER
        ANIMAL, A PERSON, OR AN OBJECT; OR (E) THE POTENTIAL OF AN EQUINE
        ACTIVITY PARTICIPANT TO ACT IN A NEGLIGENT MANNER THAT MAY CONTRIBUTE TO
        INJURY, DEATH, OR LOSS TO THE PERSON OF THE PARTICIPANT OR TO OTHER
        PERSONS, INCLUDING, BUT NOT LIMITED TO, FAILING TO MAINTAIN CONTROL OVER
        AN EQUINE OR FAILING TO ACT WITHIN THE ABILITY OF THE PARTICIPANT. THE
        PARENT OR GUARDIAN, ON BEHALF OF THEIR PARTICIPATING MINOR, ASSUMES ALL
        RISK OF PERSONAL INJURY OR PROPERTY DAMAGE OCCURRING AS A RESULT OF THE
        PARTICIPATION AND DOES HEREBY RELEASE AND DISCHARGE YEDA AND SHOW
        MANAGEMENT, THEIR RESPECTIVE OFFICERS, DIRECTORS, REPRESENTATIVES, AND
        EMPLOYEES FROM ANY AND ALL LIABILITY, WHENEVER OR HOWEVER ARISING FROM
        SUCH PARTICIPATION.
      </p>
      <ul style={{ paddingLeft: '10px' }}>
        {data.map(value => (
          <MappedDataWrapper>
            <img src={arrow} />
            <li>{value.text}</li>
          </MappedDataWrapper>
        ))}
      </ul>
      <BottomTextWrapper className="BottomTextWrapper">
        <p className="para-two">RIDING INSTRUCTION IN REGULAR LESSONS</p>
        <p className="para-two"> OR </p>
        <p className="para-two">
          RIDING THEIR OWN HORSES OFF PREMISES, BOTH ON OWNED AND NON-OWNED
          HORSES
        </p>
      </BottomTextWrapper>
      <p className="para-two">
        YOUR SIGNATURE BELOW ACKNOWLEDGES THAT COACH, GUARDIAN, AND RIDER HAVE
        FULLY REVIEWED THE YEDA RULE BOOK{' '}
        {currentSeason === undefined ? '2023 - 2024' : currentSeason} AND
        UNDERSTAND THE CONDUCT, COMPETITION REQUIREMENTS, AND POSSIBLE SANCTIONS
        OUTLINED THEREIN. YOU ARE HEREBY GUARANTEEING THAT COACH, GUARDIAN, AND
        RIDER WILL ADHERE TO YEDA SOCIAL MEDIA POLICY AND RULES OF THE
        ORGANIZATION. YOUR SIGNATURE ADDITIONALLY ACKNOWLEDGES THAT THE RIDER IS
        A FULLTIME STUDENT IN GOOD ACADEMIC STANDING. COACH, GUARDIAN, AND RIDER
        ARE SUBJECT TO ALL RULES, FINES AND DISCIPLINARY ACTIONS FOR VIOLATIONS
        OF THE YEDA RULES.
      </p>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  border: 1px solid #585858;
  border-radius: 7px;
  margin: 0px 20px;
  padding: 20px;
  background: #27292d;
  p {
    font-family: 'Roboto';
  }
  .para-one {
    color: #ff9900;
    font-size: 16px;
    border-bottom: 1px solid #585858;
  }
  .para-two {
    padding-bottom: 10px;
  }
`;
const BottomTextWrapper = styled.div`
  text-align: center;
  p {
    margin: 0px !important;
    padding-bottom: 0px !important;
  }
`;
const MappedDataWrapper = styled.div`
  display: flex;
  align-items: baseline;
  li {
    padding-left: 10px;
    list-style-type: none;
    padding-bottom: 10px;
    font-family: 'Roboto';
    font-size: 14px;
  }
  img {
    width: 11px;
    height: 11px;
  }
`;
