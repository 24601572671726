import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import { ErrorHandlingState, NotificationType, showToastType } from './types';

export const initialState: ErrorHandlingState = {
  showToast: { errorTxt: '', show: false, errorType: 'none' },
};

const slice = createSlice({
  name: 'errorHandling',
  initialState,
  reducers: {
    dispatchToast(state, action: PayloadAction<showToastType>) {
      state.showToast = action.payload;
    },
  },
});
export const { actions: ErrorHandlingActions } = slice;

export const useErrorHandlingSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
