import React, { useState } from 'react';
import styled from 'styled-components';
import '@fontsource/oswald';
import '@fontsource/roboto';
import { ShowyedaLogo } from 'app/components/ShowyedaLogo';
import { Outlet } from 'react-router-dom';
import tryitImg from './assets/img/bg/tryit.png';
import horseBg from './assets/img/bg/Rider.png';
import Cookies from 'js-cookie';

type Props = {};

const TryitEWDMembershipEligibilityLayout = (props: Props) => {
  const currentSeason = Cookies.get('isAdminLiveFiltered');
  return (
    <MainWrapper>
      <LeftWrapper>
        <ShowyedaLogo />
        <p>
          A membership registration forms for Equestrian with Disabilities must
          be accompanied by a Diagnosis and Adaptive Equipment Form.
        </p>
        <BottomImgWrapper>
          <TryImg src={tryitImg} />
          <ImgTag src={horseBg} />
        </BottomImgWrapper>
      </LeftWrapper>
      <RightWrapper>
        <BorderWrapper>
          <TopWrapper>
            <h3>
              YEDA Equestrian with Disabilities (EWD) <br /> Diagnosis Form
              {currentSeason === undefined ? '2023 - 2024' : currentSeason}
            </h3>
          </TopWrapper>
          <Outlet />
        </BorderWrapper>
      </RightWrapper>
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`;
const LeftWrapper = styled.div`
  width: 36%;
  background: #1b1b1b;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 60px 35px 0px;
  p {
    color: #898989;
    font-family: 'Oswald';
    font-size: 14px;
    text-align: center;
  }
  span {
    color: #ffffff;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 20px 20px;
    p {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    padding: 40px 28px;
    p {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    padding: 60px 35px 0px;
    p {
      font-size: 14px;
    }
  }
`;
const BottomImgWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: grid;
  place-items: center;
`;
const ImgTag = styled.img`
  width: 60%;
  height: auto;
  @media (min-width: 1366px) and (max-width: 1448px) {
    width: 60%;
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    width: 60%;
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    width: 60%;
  }
`;
const TryImg = styled.img`
  width: 25%;
  position: absolute;
  z-index: 2;
  bottom: 60%;
  left: 15%;
`;
const RightWrapper = styled.div`
  width: 64%;
  height: 100%;
  background: #2a2a2a;
  padding: 30px 35px 20px;
  p {
    color: #9597a6;
    font-family: 'Roboto';
    font-size: 14px;
  }
  // display: flex;
  // flex-direction: column;
  // align-items: center;

  @media (min-width: 992px) and (max-width: 1199px) {
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
  }
`;
const BorderWrapper = styled.div`
  height: 100%;
  border: 1px solid #585858;
  border-radius: 20px;
  padding: 20px 60px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const TopWrapper = styled.div`
  background: #2a2a2a;
  padding: 0px 10px;
  position: absolute;
  top: -12px;
  // left: 235px;
  z-index: 2;
  h3 {
    font-weight: 500;
    color: #ffffff;
    margin: 0px !important;
    font-family: 'Roboto';
    text-align: center;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    // padding: 15px 40px 0px;
    h3 {
      font-size: 16px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    // padding: 20px 60px 0px;
    h3 {
      font-size: 17px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    // padding: 20px 80px 0px;
    h3 {
      font-size: 18px;
    }
  }
`;

export default TryitEWDMembershipEligibilityLayout;
