import { useCallback, useState } from 'react';
import { ApiError, OpenAPI } from '../openapi';
import Cookies from 'js-cookie';
// import { useErrorHandlingSlice } from 'app/error/slice';
import { useDispatch } from 'react-redux';
import { useErrorHandlingSlice } from 'app/error/slice';
// import { AuthenticationApIsService, Login, LoginUser } from 'services/openapi';

export function useApi() {
  const [error, setError] = useState<ApiError | unknown>(undefined);
  const [isLoading, setIsloading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const { actions } = useErrorHandlingSlice();
  // const { actions } = useErrorHandlingSlice();
  // const { authRefresh } = AuthenticationApIsService;

  OpenAPI.BASE =
    process.env.REACT_APP_API_ENDPOINT ??
    'https://yeda-api-dev.onesmarter.in/v1';
  OpenAPI.HEADERS = {
    Authorization: `Bearer ${Cookies.get('accessToken')}`,
    'Accept-Language': 'en-US',
  };
  const handleRequest = useCallback(async function <T>(request: Promise<T>) {
    setIsloading(true);
    try {
      const response = await request;
      setError(undefined);
      console.log('handleRequest success jink.....try', response);
      return response;
    } catch (err) {
      console.log(err, 'ApiError err');
      var apiError = err as ApiError;
      setError(err);
      var errorMessage = 'something went wrong';
      if (apiError) {
        console.log(
          'handleRequest.....catchapiError',
          JSON.stringify(apiError),
        );
        console.log('handleRequest.....catch', apiError.body);
        if (typeof apiError.body === 'string') {
          errorMessage = apiError.body;
        } else {
          console.log(apiError, 'apiError.body');
          errorMessage = apiError.body.title;
        }
        //Show toast for error
        if (apiError.body.description) {
          dispatch(
            actions.dispatchToast({
              errorTxt: apiError.body.description,
              show: true,
              errorType: 'error',
            }),
          );
        }

        return apiError.body;
      }

      // You might want to rethrow the error here if needed
      throw err;
    } finally {
      setIsloading(true);
    }
  }, []);

  function dismissError() {
    setError(undefined);
  }

  // const handleRefreshToken = useCallback((formData: Tokens) => {
  //   authRefresh(formData)
  //     .then(res => {
  //       if (res) {
  //         console.log('Token test', res);
  //         Cookies.set('accessToken', res?.accessToken);
  //         Cookies.set('refreshToken', res?.refreshToken);
  //         Cookies.set(
  //           'accessTokenExpiryMilliSeconds',
  //           res?.accessTokenExpiryMilliSeconds,
  //         );
  //         Cookies.set('accessTokenExpiry', res?.accessTokenExpiry);
  //         Cookies.set('name', res?.name);
  //         Cookies.set('imageUrl', res?.imageUrl);
  //         Cookies.set('userId', res?.id);
  //         Cookies.set('roles', res?.roles?.join('|'));
  //         console.log('Token test', 'Login success');
  //         Cookies.set('loginAlert', true);
  //       } else {
  //         console.log('Token test', 'Login Failed');
  //       }
  //     })
  //     .catch(error => {
  //       console.log('Token test', 'Login Failed');
  //     });
  // }, []);

  return { dismissError, error, isLoading, handleRequest };
}

export default useApi;
