import { Button } from '@progress/kendo-react-buttons';
import React from 'react';
import styled from 'styled-components';
export type ButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void; // to handle onClick functions
  children?: React.ReactNode; // make the component able to receive children elements
  buttonType?: 'primary' | 'secondary' | 'icon' | 'third'; // two styling options (you can create as many as you want)
  className?: string; // two styling options (you can create as many as you want)
  disabled?: boolean; // make the button disabled or not
  type?: 'submit' | 'button';
  variant?: 'contained' | 'outlined' | 'text';
  color?: 'success' | 'warning';
  disableNext?: boolean;
};

const SearchButton = ({
  onClick,
  children,
  variant,
  color,
  buttonType,
  disableNext,
}: ButtonProps) => {
  return (
    <Wrapper
      className={`button-wrapper ${buttonType} ${
        disableNext ? 'disableNext' : ''
      }`}
    >
      <Button className={`button `} onClick={onClick} disabled={disableNext}>
        {children}
      </Button>
    </Wrapper>
  );
};

export default SearchButton;
const Wrapper = styled.div`
  // padding: 2px 2px;
  &.button-wrapper {
    .button {
      font-size: 15px;
      font-weight: 400;
      text-transform: capitalize !important;
      -webkit-letter-spacing: 1px !important;
      -moz-letter-spacing: 1px !important;
      -ms-letter-spacing: 1px !important;
      letter-spacing: 1px !important;
      height: 35px;
      min-width: 100px;
      max-width: 150px;
      border-radius: 25px !important;
      box-shadow: none !important;
      padding: 6px 20px !important;
      color: #ffffff !important;
      background: #5b5a5a !important;
      border: 1px solid #8b8b8b;
      &.secondary {
        color: #909090 !important;
        border: 1px solid #606060 !important;
        background: transparent !important;

        &:hover {
          background: transparent !important;
        }
      }
      &.third {
        color: #909090 !important;
        border: 1px solid #606060 !important;
        background: #3b3b3b !important;

        &:hover {
          color: #ffffff !important;
          background: #306fdb !important;
          border: 1px solid #306fdb !important;
        }
      }

      &.primary {
        background: #306fdb !important;
        border: 1px solid #306fdb !important;
        color: #ffffff !important;
        &:hover {
          background: #306fdb !important;
        }
      }
    }
  }
  &.disableNext {
    button {
      border: 1px solid red !important;
      opacity: 1 !important;
    }

    /* color: #9e9ea0 !important; */
  }
`;
