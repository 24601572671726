import { CardSubWrapper, CardWrapper } from 'admin/components/wrappers';
import CustomDataGrid from 'app/components/CustomDataGrid/Index';
import React, { useState } from 'react';
import styled from 'styled-components';
import ShowRidereTeamCell from 'app/components/CustomDataGrid/Cells/ShowRidereTeamCell';
import ShowRiderCell from 'app/components/CustomDataGrid/Cells/ShowRiderCell';
import ShowRiderBackNumberCell from 'app/components/CustomDataGrid/Cells/ShowRiderBackNumberCell';
import ShowRiderStateCell from 'app/components/CustomDataGrid/Cells/ShowRiderStateCell';
import ShowRiderBMICell from 'app/components/CustomDataGrid/Cells/ShowRiderBMICell';
import ShowRiderGradeCell from 'app/components/CustomDataGrid/Cells/ShowRiderGradeCell';
import { RiderSplitClass, ShowRiderListWrapper } from 'services/openapi';
import { useParams } from 'react-router-dom';
import DeleteIconCell from 'app/components/CustomDataGrid/Cells/DeleteIconCell';
import { CustomDeletePopup } from 'app/components/CustomDeletePopup';
import ManageRiderClassSplitUpdateCell from 'app/components/CustomDataGrid/Cells/ManageRiderClassSplitUpdateCell';
type Props = {
  setPageSkip: React.Dispatch<React.SetStateAction<number | undefined>>;
  pageSkip: number | undefined;
  debounceShowSearch: string;
  fetchManageGetShowRiderList: (
    showId: string,
    teamFilter?: string[] | undefined,
    categoryId?: string | undefined,
    divisionId?: string | undefined,
    searchInput?: string | undefined,
    sortBy?: string | undefined,
    sortOrder?: number | undefined,
    pageNumber?: number,
    pageSize?: number,
  ) => void;
  showRiderDataList?: ShowRiderListWrapper | undefined;
  deleteShowRider: (
    riderId?: string | undefined,
    showId?: string | undefined,
    teamFilter?: string[] | undefined,
    categoryId?: string | undefined,
    divisionId?: string | undefined,
    searchInput?: string | undefined,
    sortBy?: string | undefined,
    sortOrder?: number | undefined,
    pageNumber?: number,
    pageSize?: number,
  ) => void;
  teamId: string[];
  onSaveClick: (requestBody: Array<RiderSplitClass>) => void;
};

const ShowManageRiderTable = ({
  setPageSkip,
  pageSkip,
  debounceShowSearch,
  fetchManageGetShowRiderList,
  showRiderDataList,
  deleteShowRider,
  teamId,
  onSaveClick,
}: Props) => {
  const { id: showId } = useParams();
  const [showDeletePopup, setShowDeletePopup] = React.useState<boolean>(false);
  const [getRiderId, setGetRiderId] = useState('');

  const onClassACheckboxChange = (e, event, className) => {};
  const handleDeleteRider = e => {
    setGetRiderId(e.dataItem.id);
    setShowDeletePopup(true);
  };
  const ShowRiderList = [
    {
      field: 'team',
      title: 'Team',
      cell: e => <ShowRidereTeamCell e={e} />,
      width: '300',
      locked: true,
    },
    {
      field: 'rider',
      title: 'Rider',
      cell: e => <ShowRiderCell e={e} />,
      width: '300',
    },

    {
      field: 'bmi',
      title: 'Height & Weight',
      cell: e => <ShowRiderBMICell e={e} />,
      width: '150',
    },
    {
      field: 'grade',
      title: 'Grade',
      cell: e => <ShowRiderGradeCell e={e} />,
      width: '150',
    },
    {
      field: 'backNumber',
      title: 'Back',
      cell: e => <ShowRiderBackNumberCell e={e} />,
      width: '150',
    },
    {
      field: 'state',
      title: 'State',
      cell: e => <ShowRiderStateCell e={e} />,
      width: '200',
    },
    {
      field: 'class',
      title: 'Class',
      cell: e => (
        <ManageRiderClassSplitUpdateCell
          e={e}
          onClassACheckboxChange={onClassACheckboxChange}
          onSaveClick={onSaveClick}
        />
      ),
      width: '550',
    },
    {
      field: 'action',
      title: 'Action',
      cell: e => (
        <DeleteIconCell e={e} deleteClick={() => handleDeleteRider(e)} />
      ),
      width: '80',
    },
  ];
  const handlePageChange = event => {
    setPageSkip(event.page.skip);
    fetchManageGetShowRiderList(
      showId ?? '',
      teamId,
      '',
      '',
      debounceShowSearch,
      '',
      0,
      event.page.skip,
      10,
    );
  };
  const handleDeleteRiderList = () => {
    setShowDeletePopup(false);
    deleteShowRider(
      getRiderId ?? '',
      showId,
      teamId,
      '',
      '',
      debounceShowSearch,
      '',
      0,
      pageSkip,
      10,
    );
  };

  return (
    <>
      <CardWrapper style={{ paddingTop: '15px' }}>
        <CardSubWrapper className="d-flex"></CardSubWrapper>
        <GridWrapper>
          <CustomDataGrid
            showPagination
            data={showRiderDataList?.riders ?? []}
            totalCount={showRiderDataList?.pageDetails?.count}
            columns={ShowRiderList}
            pageSize={10}
            onPageChange={event => {
              handlePageChange(event);
            }}
            skip={pageSkip}
          />
          <CustomDeletePopup
            deleteIcon={true}
            headerUnderline={false}
            popupSubLabel="Are you sure you want to delete this rider?"
            className="body-scroll-lock height-full delete-update"
            popupLabel="Delete rider"
            setVisible={setShowDeletePopup}
            visible={showDeletePopup}
            align="centre"
            btnLabel1="Cancel"
            btnLabel2="Delete"
            handlebtn2Click={handleDeleteRiderList}
            handlebtn1Click={() => {
              setShowDeletePopup(false);
            }}
          />
        </GridWrapper>
      </CardWrapper>
    </>
  );
};

export default ShowManageRiderTable;
const GridWrapper = styled.div`
  .k-grid-content tr {
    .k-grid-content-sticky {
      :hover {
      }
    }
  }
  .k-grid-container .k-grid-content {
    height: calc(100vh - 410px) !important;
  }
`;
