import { GridCellProps } from '@progress/kendo-react-grid';
import { AdminCheckBox } from 'app/components/AdminCheckbox';
import { AdminProfileInfo } from 'app/components/AdminProfileInfo';
import styled from 'styled-components';

type Props = {
  className?: string;
  e: GridCellProps;
  onClick?: (e) => void;
};

const SplitRiderCell = ({ className, e, onClick }: Props) => {
  const data = e.dataItem;
  const name = data.firstName + ' ' + data.lastName;
  return (
    <StyledTd className={className ?? ''}>
      <AdminProfileInfo src={data?.riderImageUrl} name={name} />
      <AdminCheckBox
        onChange={event => {}}
        value={data.scratch}
        label="Scratch"
        className="checkbox-style"
      />
    </StyledTd>
  );
};

export default SplitRiderCell;
const StyledTd = styled.td`
  .checkbox-style {
    label {
      padding-left: 10px;
    }
  }
`;
