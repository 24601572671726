import { GridCellProps } from '@progress/kendo-react-grid';
import { DetailArrowIcon, ThreeDotIcon } from 'app/assets/icons';
import DropdownComponent from 'app/components/CustomFilterPopup';
import styled from 'styled-components';

type Props = {
  className?: string;
  e?: GridCellProps;
  onMoreClick?: (item: any, e) => void;
  isAssigned?: boolean;
  onDataClick?: (data: any) => void;
  isRider?: boolean;
  isCoach?: boolean;
  isUser?: boolean;
  isCurrentSeason?: boolean;
  isPastDataFlag?: boolean;
};
const HorizontalKebabCell = ({
  className,
  e,
  onMoreClick,
  isAssigned,
  onDataClick,
  isRider = false,
  isCoach = false,
  isUser = false,
  isCurrentSeason = false,
  isPastDataFlag,
}: Props) => {
  const data = e?.dataItem;
  const mainData = [
    'Make active',
    'Make inactive',
    'Edit team info',
    'Delete team',
  ];

  const mainRiderData = [
    'Make active',
    'Make inactive',
    'Edit rider info',
    'Delete rider',
  ];
  const mainCoachData = [
    'Make active',
    'Make inactive',
    'Edit coach info',
    'Delete coach',
  ];
  const mainUserData = ['Edit user info', 'Delete user'];
  const mainCurrentSeasonData = [
    'Make active',
    'Make inactive',
    'Duplicate show',
    'Generate split',
    'Edit showinfo',
    'Delete show',
    'Show result',
  ];
  let dropdowndata;
  if (isCurrentSeason) {
    dropdowndata = mainCurrentSeasonData;
  } else if (isUser) {
    dropdowndata = mainUserData;
  } else if (isCoach) {
    dropdowndata = mainCoachData;
  } else if (isRider) {
    dropdowndata = mainRiderData;
  } else {
    dropdowndata = mainData;
  }
  const dropDowndata =
    data.status?.name === 'Active'
      ? dropdowndata.filter(item => item !== 'Make active')
      : data.status?.name === 'Inactive'
      ? dropdowndata.filter(item => item !== 'Make inactive')
      : dropdowndata;
  return (
    <StyledTd className={className ?? ''}>
      <Wrapper>
        {!isPastDataFlag && (
          <DropdownComponent
            placement={'bottom-end'}
            data={dropDowndata}
            onDataClick={e => onDataClick?.(data)}
            onItemClick={item => {
              onMoreClick?.(item, e);
            }}
            svgIcon={<ThreeDotIcon />}
            className="custom-dropdown"
          />
        )}
      </Wrapper>
    </StyledTd>
  );
};

export default HorizontalKebabCell;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  position: absolute;
  height: 0;
  svg {
    transform: rotate(90deg);
  }
  .custom-dropdown {
    right: 0;
    top: 30px;
  }
  .k-button:hover::before {
    opacity: 0;
  }
  .filter-button button {
    border: none !important;
    width: 10px;
    padding: 5px;
    background-color: transparent !important;
    :hover {
      background: transparent !important;
      background-color: transparent !important;
      border: unset;
    }
  }
`;
const StyledTd = styled.td``;
