import { CardSubWrapper, CardWrapper } from 'admin/components/wrappers';
import BackNoCell from 'app/components/CustomDataGrid/Cells/BackNoCell';
import DivisionCell from 'app/components/CustomDataGrid/Cells/DivisionCell';
import GradeCell from 'app/components/CustomDataGrid/Cells/GradeCell';
import RiderNameCell from 'app/components/CustomDataGrid/Cells/RiderName';
import RiderStatus from 'app/components/CustomDataGrid/Cells/RiderStatusCell/RiderStatus';
import SIFamountPaidCell from 'app/components/CustomDataGrid/Cells/SifAmountPaid';
import SIFjoinDateCell from 'app/components/CustomDataGrid/Cells/SifJoinDate';
import SifPointCell from 'app/components/CustomDataGrid/Cells/SifPointCell';
import TeamCell from 'app/components/CustomDataGrid/Cells/TeamCell';
import CustomDataGrid from 'app/components/CustomDataGrid/Index';
import { CustomDeletePopup } from 'app/components/CustomDeletePopup';
import React, { useState } from 'react';
import { ActiveRiderWrapper } from 'services/openapi';
import styled from 'styled-components';
import { GeneralLookup } from 'services/openapi';
import { useNavigateToRoute } from 'utils/common';
import { useLocation } from 'react-router-dom';
import { SortDescriptor } from '@progress/kendo-data-query';
import { GridSortChangeEvent } from '@progress/kendo-react-grid';
import { TableSkeletonLoader } from 'app/components/TableSkeletonLoader';
import HorizontalKebabCell from 'app/components/CustomDataGrid/Cells/HorizontalKebabCell';
import PaymentStatusCell from 'app/components/CustomDataGrid/Cells/PaymentStatusCell';

type Props = {
  activeRiderList: ActiveRiderWrapper | undefined;
  setPageSkip: React.Dispatch<React.SetStateAction<number | undefined>>;
  pageSkip: number | undefined;
  fetchActiveRiderList: (
    season?: string | undefined,
    teamFilter?: string[] | undefined,
    divisionFilter?: string[] | undefined,
    searchInput?: string | undefined,
    status?: string | undefined,
    sortBy?: string | undefined,
    sortOrder?: number | undefined,
    pageNumber?: number,
    pageSize?: number,
  ) => void;
  debounceRiderSearch: string;
  deleteRiderInfo: (
    id?: string | undefined,
    season?: string | undefined,
    teamFilter?: string[] | undefined,
    divisionFilter?: string[] | undefined,
    searchInput?: string | undefined,
    status?: string | undefined,
    sortBy?: string | undefined,
    sortOrder?: number | undefined,
    pageNumber?: number,
    pageSize?: number,
  ) => void;
  selectedItemId: string | null;
  fetchRiderActiveOrInactive: (
    id: string,
    season?: string | undefined,
    activeStatusId?: string | undefined,
    searchInput?: string | undefined,
    status?: string | undefined,
    sortBy?: string | undefined,
    sortOrder?: number | undefined,
    pageNumber?: number,
    pageSize?: number,
    item?: string,
    teamId?: string[],
    divisionId?: string[],
  ) => void;
  lookup: GeneralLookup[];
  teamId: string[];
  divisionId: string[];
  sortField: string | undefined;
  sortOrder: number | undefined;
  setSortField: React.Dispatch<React.SetStateAction<string>>;
  setSortOrder: React.Dispatch<React.SetStateAction<number>>;
  loading: boolean;
};

const RidersTable = ({
  activeRiderList,
  setPageSkip,
  pageSkip,
  fetchActiveRiderList,
  debounceRiderSearch,
  deleteRiderInfo,
  selectedItemId,
  fetchRiderActiveOrInactive,
  lookup,
  teamId,
  divisionId,
  sortField,
  sortOrder,
  setSortField,
  setSortOrder,
  loading,
}: Props) => {
  const [showDeletePopup, setShowDeletePopup] = React.useState<boolean>(false);
  const [riderInfoId, setRiderInfoID] = useState('');
  const handleRowClick = e => {};
  const redirect = useNavigateToRoute();
  const activeId = lookup.find(item => item.name === 'Active');
  const inActiveId = lookup.find(item => item.name === 'Inactive');
  const currentLocation = useLocation();
  const isPastDataFlag = currentLocation.pathname.includes('past-data');
  const initialSort: Array<SortDescriptor> = [{ field: 'name', dir: 'asc' }];
  const [sort, setSort] = React.useState(initialSort);
  const handleMoreClick = (item: string, e) => {
    const id = e.dataItem.id;
    if (item === 'Make active') {
      fetchRiderActiveOrInactive(
        id,
        e.dataItem.season?.id,
        activeId?.id,
        debounceRiderSearch,
        selectedItemId ?? '',
        sortField,
        sortOrder,
        pageSkip,
        10,
        item,
        teamId,
        divisionId,
      );
    } else if (item === 'Make inactive') {
      fetchRiderActiveOrInactive(
        id,
        e.dataItem.season?.id,
        inActiveId?.id,
        debounceRiderSearch,
        selectedItemId ?? '',
        sortField,
        sortOrder,
        pageSkip,
        10,
        item,
        teamId,
        divisionId,
      );
    } else if (item === 'Delete rider') {
      setRiderInfoID(e.dataItem.id);
      setShowDeletePopup(true);
    } else if (item === 'Edit rider info') {
      redirect(`/admin/riders/${'edit'}/${e.dataItem.id}`);
    }
  };
  const handleViewClick = e => {
    if (isPastDataFlag) {
      redirect(`/admin/past-data/riders/${`view`}/profile-info/${e?.id}`);
    } else {
      redirect(`/admin/riders/${`view`}/profile-info/${e?.id}`);
    }
  };

  const RiderColumn = [
    {
      field: 'ridername',
      title: 'Rider name',
      cell: e => <RiderNameCell e={e} onViewClick={e => handleViewClick(e)} />,
      width: '320',
      locked: true,
      sortable: true,
      className: 'rider-name-style',
      headerClassName: 'rider-header-style',
    },
    {
      field: 'backNumber',
      title: 'Back No:#',
      cell: e => <BackNoCell e={e} onClick={handleRowClick} />,
      width: '100',
      sortable: false,
    },

    {
      field: 'divisionOrClasses',
      title: 'Division/Classes',
      cell: e => <DivisionCell e={e} onClick={handleRowClick} />,
      width: '150',
      sortable: false,
    },
    {
      field: 'grade',
      title: 'Grade',
      cell: e => <GradeCell e={e} onClick={handleRowClick} />,
      width: '100',
      sortable: false,
    },
    {
      field: 'team',
      title: 'Team',
      cell: e => (
        <TeamCell e={e} onClick={handleRowClick} className="team-cell" />
      ),
      width: '200',
      sortable: true,
      headerClassName: 'team-header-style',
    },
    {
      field: 'sifJoinDate',
      title: 'SIF join Date',
      cell: e => <SIFjoinDateCell e={e} />,
      width: '150',
      sortable: true,
      headerClassName: 'sif-join-header-style',
    },
    {
      field: 'financialRespSignature',
      title: 'SIF Amt. Paid ',
      cell: e => <SIFamountPaidCell e={e} />,
      width: '100',
      sortable: false,
    },
    {
      field: 'financialRespSignature',
      title: 'SIF Points ',
      cell: e => <SifPointCell e={e} />,
      width: '100',
      sortable: false,
    },
    {
      field: 'isPaymentComplete',
      title: 'Payment Completed',
      cell: e => <PaymentStatusCell e={e} />,
      width: '150',
      sortable: false,
    },
    {
      field: 'teamStatus',
      title: 'Status',
      cell: e => <RiderStatus e={e} />,
      width: isPastDataFlag ? '100' : '150',
      sortable: false,
    },
    {
      field: 'action',
      title: 'Action',
      cell: e => (
        <HorizontalKebabCell
          isAssigned={true}
          e={e}
          onMoreClick={handleMoreClick}
          isRider={true}
          isPastDataFlag={isPastDataFlag}
        />
      ),
      width: '100',
      sortable: false,
    },
  ];

  const RiderPastDataColumn = RiderColumn.slice(0, -1);
  const handlePageChange = event => {
    setPageSkip(event.page.skip);
    fetchActiveRiderList(
      '',
      teamId,
      divisionId,
      debounceRiderSearch,
      selectedItemId ?? '',
      sortField,
      sortOrder,
      event.page.skip,
      10,
    );
  };

  const handleDeleteRiderInfo = () => {
    deleteRiderInfo(
      riderInfoId,
      '',
      teamId,
      divisionId,
      debounceRiderSearch,
      selectedItemId ?? '',
      sortField,
      sortOrder,
      pageSkip,
      10,
    );
    setShowDeletePopup(false);
  };
  const handleSortChange = e => {
    let sortOrderValue = e.dir === 'asc' ? 0 : 1;
    setSortOrder(sortOrderValue);
    setSortField(e.field);
    fetchActiveRiderList(
      '',
      teamId,
      divisionId,
      debounceRiderSearch,
      selectedItemId ?? '',
      e.field,
      sortOrderValue,
      pageSkip,
      10,
    );
  };
  return (
    <>
      {loading ? (
        <TableSkeletonLoader rowContent={8} width={100} headingWidth={80} />
      ) : (
        <CardWrapper style={{ paddingTop: '15px' }}>
          <CardSubWrapper className="d-flex"></CardSubWrapper>
          <GridWrapper>
            <CustomDataGrid
              showPagination
              data={activeRiderList?.riders ?? []}
              columns={isPastDataFlag ? RiderPastDataColumn : RiderColumn}
              pageSize={10}
              totalCount={activeRiderList?.pageDetails?.count}
              onPageChange={event => {
                handlePageChange(event);
              }}
              skip={pageSkip}
              sortable={{
                allowUnsort: false,
                mode: 'single',
              }}
              sort={sort}
              onSortChange={(e: GridSortChangeEvent) => {
                let sortvalue = e.sort[0];
                setSort(e.sort);
                handleSortChange(sortvalue);
              }}
            />
            <CustomDeletePopup
              deleteIcon={true}
              headerUnderline={false}
              popupSubLabel="Are you sure you want to delete this rider?"
              className="body-scroll-lock height-full delete-update"
              popupLabel="Delete rider"
              setVisible={setShowDeletePopup}
              visible={showDeletePopup}
              align="centre"
              btnLabel1="Cancel"
              btnLabel2="Delete"
              handlebtn2Click={handleDeleteRiderInfo}
              handlebtn1Click={() => {
                setShowDeletePopup(false);
              }}
            />
          </GridWrapper>
        </CardWrapper>
      )}
    </>
  );
};

export default RidersTable;

const GridWrapper = styled.div`
  .k-grid-content tr td.team-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }
  .k-grid-content tr .rider-name-style {
    .name-label,
    .sub-name-label {
      cursor: pointer !important;
    }
  }
  .k-table-thead {
    .k-link {
      cursor: default;
    }
    .sif-join-header-style,
    .team-header-style,
    .rider-header-style {
      cursor: pointer !important;
      .k-link {
        cursor: pointer !important;
      }
    }
  }
`;
