import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PersonalInfoFields from './components/PersonalInfoFields';
import ParentDetails from './components/ParentDetails';
import SchoolDetails from './components/SchoolDetails';
import EnrolmentData from './components/EnrolmentData';
import { Stepper, StepperChangeEvent } from '@progress/kendo-react-layout';
import {
  GeneralLookup,
  GeneralLookupWrapper,
  RegisterRider,
  RiderDivisionResponse,
  RiderService,
} from 'services/openapi';
import check from './components/Stepper/assets/img/icon/check.png';
import IndividualPlacementForm from './components/IndividualPlacementForm';
import PreviousEnrollmentReturning from './components/PreviousEnrolReturning';
import IndividualPlacementFormTwo from './components/IndividualPlacementFormTwo';
import Box from '@mui/joy/Box';
import CircularProgress from '@mui/joy/CircularProgress';
import useApi from 'services/mavapi/useApi';
import { heightWeightModel } from '../../../NotReturningRider';
import MembershipFormReturning from './components/MembershipFormReturning';
import { items, radioModel } from './staticData';
import { getLookupArray } from 'utils/common';
import { prevRadioModel } from '../../../staticData';

type Props = {
  handlePlacementSubmit: () => void;
  riderData: RegisterRider;
  setRiderData?: React.Dispatch<React.SetStateAction<RegisterRider>>;
  lookUps: GeneralLookupWrapper[];
  teamFilteredCategory: GeneralLookup[] | undefined;
  values: number;
  setValues: React.Dispatch<React.SetStateAction<number>>;
  previousData: {
    seasonList: GeneralLookup;
    category: GeneralLookup;
    division: GeneralLookup;
  };
  setRiderDivisionRes: React.Dispatch<
    React.SetStateAction<RiderDivisionResponse | undefined>
  >;
  setIsTermsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  isTermsChecked: boolean;
  setPlacementRadioValues: React.Dispatch<React.SetStateAction<radioModel>>;
  placementRadioValues: radioModel;
  previousEnrolRadioValues: prevRadioModel;
  setHeightWeight: React.Dispatch<
    React.SetStateAction<heightWeightModel | undefined>
  >;
  heightWeight: heightWeightModel | undefined;
  setPhotoUploadPayload: React.Dispatch<
    React.SetStateAction<{
      formData?:
        | {
            file?: Blob | undefined;
          }
        | undefined;
    }>
  >;
  photoUploadPayload: {
    formData?:
      | {
          file?: Blob | undefined;
        }
      | undefined;
  };
  setFileAvailability: React.Dispatch<React.SetStateAction<boolean>>;
  fileAvailability: boolean;
  setUploadFile: React.Dispatch<React.SetStateAction<File | null | undefined>>;
  uploadFile: File | null | undefined;
  backNumberData: string[] | undefined;
  setTeamFilteredCategory: React.Dispatch<
    React.SetStateAction<GeneralLookup[] | undefined>
  >;
  riderDivisionRes: RiderDivisionResponse | undefined;
  seasonDivison: (string[] | undefined)[] | undefined;
  teamLookup?: GeneralLookup[];
  setTeamLookup?: React.Dispatch<
    React.SetStateAction<GeneralLookup[] | undefined>
  >;
  getAllLookupByCategory: (categoryId: string) => void;
};

const RiderInformation = ({
  handlePlacementSubmit,
  riderData,
  setRiderData,
  lookUps,
  teamFilteredCategory,
  setValues,
  values,
  previousData,
  setRiderDivisionRes,
  setIsTermsChecked,
  isTermsChecked,
  setPlacementRadioValues,
  placementRadioValues,
  previousEnrolRadioValues,
  setHeightWeight,
  heightWeight,
  setPhotoUploadPayload,
  photoUploadPayload,
  setFileAvailability,
  fileAvailability,
  setUploadFile,
  uploadFile,
  backNumberData,
  setTeamFilteredCategory,
  riderDivisionRes,
  seasonDivison,
  teamLookup,
  getAllLookupByCategory,
}: Props) => {
  const { handleRequest } = useApi();
  const { riderGetRiderDivision } = RiderService;
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 968);
  const [previousEnrolRadioValue, setPreviousEnrolRadioValue] =
    useState<prevRadioModel>({
      hasStayedInSameDivisionForTwoYears: null,
      achievedTopTwoInIndividualNationals: null,
      achievedTopThreeInDivision: null,
      divisionCompliesWithSectionThirty: null,
    });
  const [isNotImage, setIsNotImage] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 968);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    setPreviousEnrolRadioValue({
      hasStayedInSameDivisionForTwoYears:
        riderData?.hasStayedInSameDivisionForTwoYears,
      achievedTopTwoInIndividualNationals:
        riderData?.achievedTopTwoInIndividualNationals,
      achievedTopThreeInDivision: riderData?.achievedTopThreeInDivision,
      divisionCompliesWithSectionThirty:
        riderData?.divisionCompliesWithSectionThirty,
    });
  }, [riderData]);

  const totalPages = 8;
  const determinateValue = ((values + 1) / totalPages) * 100;

  const handleChangeStepper = (e: StepperChangeEvent) => {
    const index = Number(e.value);
    if (index >= 0 && index !== values) {
      setValues(index);
    }
  };
  const handlePreviousEnrolNext = () => {
    setValues(5);
    getRiderEnrollmentDivision();
  };
  const getRiderEnrollmentDivision = () => {
    const riderEnrollmentdivison = {
      currentGrade: riderData.currentGrade,
      hasStayedInSameDivisionForTwoYears:
        riderData.hasStayedInSameDivisionForTwoYears,
      achievedTopTwoInIndividualNationals:
        riderData.achievedTopTwoInIndividualNationals,
      achievedTopThreeInDivision: riderData.achievedTopThreeInDivision,
      divisionCompliesWithSectionThirty:
        riderData.divisionCompliesWithSectionThirty,
      pwhQuestionOne: riderData.placementData.pwhQuestionOne,
      owhQuestionOne: riderData.placementData.owhQuestionOne,
      ewhQuestionOne: riderData.placementData.ewhQuestionOne,
      rwhQuestionOne: riderData.placementData.rwhQuestionOne,
      swhQuestionOne: riderData.placementData.swhQuestionOne,
      swhQuestionTwo: riderData.placementData.swhQuestionTwo,
      dwhQuestionOne: riderData.placementData.dwhQuestionOne,
      drQuestionOne: riderData.placementData.drQuestionOne,
      drQuestionTwo: riderData.placementData.drQuestionTwo,
    };
    const payload = { ...riderEnrollmentdivison, ...previousData };
    handleRequest(
      riderGetRiderDivision(payload)
        .then(res => {
          if (res?.category?.id) {
            getAllLookupByCategory(res?.category?.id);
          }
          setRiderDivisionRes(res);
        })
        .catch(error => {}),
    );
  };

  return (
    <MainWrapper className="page-content-wrapper">
      <p className="Team-Heading">Returning Rider</p>
      <div className="Stepper-wrapper">
        {isSmallScreen ? (
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <CircularProgress size="lg" determinate value={determinateValue}>
              {`${values + 1} / ${totalPages}`}
            </CircularProgress>
            {items
              .filter((_, index) => index === values)
              .map((item, index) => (
                <h2 className="circular-stepper-heading" key={index}>
                  {item.label}
                </h2>
              ))}
          </Box>
        ) : (
          <Stepper
            value={values}
            onChange={handleChangeStepper}
            items={items}
            className="form-stepper"
          >
            {items.map((item, index) => (
              <StepperStep
                key={index}
                className={`stepper-step ${index < values ? 'completed' : ''}`}
              >
                {index < values ? (
                  <span className="tick-icon">&#10003;</span>
                ) : (
                  item.number
                )}
              </StepperStep>
            ))}
          </Stepper>
        )}
      </div>
      <>
        {values === 0 && (
          <PersonalInfoFields
            riderData={riderData}
            setRiderData={setRiderData}
            onClickNxtPersonal={() => {
              setValues(1);
            }}
            setHeightWeight={setHeightWeight}
            heightWeight={heightWeight}
            backNumberData={backNumberData}
            lookUps={lookUps}
          />
        )}
        {values === 1 && (
          <ParentDetails
            riderData={riderData}
            setRiderData={setRiderData}
            onClickBackParent={() => {
              setValues(0);
            }}
            onClickNxtParent={() => {
              setValues(2);
            }}
            stateData={getLookupArray('STATE', lookUps)}
          />
        )}
        {values === 2 && (
          <SchoolDetails
            riderData={riderData}
            setRiderData={setRiderData}
            onClickBackSchool={() => {
              setValues(1);
            }}
            onClickNxtSchool={() => {
              setValues(3);
            }}
            stateData={getLookupArray('STATE', lookUps)}
          />
        )}
        {values === 3 && (
          <IndividualPlacementForm
            onClickNxtPlacementOne={() => {
              setValues(4);
            }}
            onClickBackPlacementOne={() => {
              setValues(2);
            }}
            riderData={riderData}
            setRiderData={setRiderData}
            placementRadioValues={placementRadioValues}
            setPlacementRadioValues={setPlacementRadioValues}
            previousEnrolRadioValues={previousEnrolRadioValue}
            setPreviousEnrolRadioValues={setPreviousEnrolRadioValue}
          />
        )}
        {values === 4 && (
          <PreviousEnrollmentReturning
            onClickBackPreviousBack={() => {
              setValues(3);
            }}
            onClickPreviousNxt={handlePreviousEnrolNext}
            previousData={previousData}
            previousEnrolRadioValues={previousEnrolRadioValues}
          />
        )}
        {values === 5 && (
          <EnrolmentData
            riderData={riderData}
            setRiderData={setRiderData}
            onClickBackEnrolment={() => {
              setValues(4);
            }}
            onClickNxtEnrolment={() => {
              setValues(6);
            }}
            teamFilteredCategory={teamFilteredCategory}
            setIsTermsChecked={setIsTermsChecked}
            lookUps={lookUps}
            setTeamFilteredCategory={setTeamFilteredCategory}
            riderDivisionRes={riderDivisionRes}
            seasonDivison={seasonDivison}
            teamLookup={teamLookup}
          />
        )}
        {values === 6 && (
          <MembershipFormReturning
            onClickMembershipNext={() => {
              setValues(7);
            }}
            onClickMembershipBack={() => {
              setValues(5);
            }}
            riderData={riderData}
            setRiderData={setRiderData}
            setIsTermsChecked={setIsTermsChecked}
            isTermsChecked={isTermsChecked}
            placementRadioValues={placementRadioValues}
            setPlacementRadioValues={setPlacementRadioValues}
            setPhotoUploadPayload={setPhotoUploadPayload}
            photoUploadPayload={photoUploadPayload}
            setFileAvailability={setFileAvailability}
            fileAvailability={fileAvailability}
            setUploadFile={setUploadFile}
            uploadFile={uploadFile}
            setIsNotImage={setIsNotImage}
            isNotImage={isNotImage}
          />
        )}
        {values === 7 && (
          <IndividualPlacementFormTwo
            riderData={riderData}
            setRiderData={setRiderData}
            handlePlacementSubmit={handlePlacementSubmit}
            onClickPlacementTwoBack={() => {
              setValues(6);
            }}
          />
        )}
      </>
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  .Team-Heading {
    color: #9597a6;
    text-align: center;
  }
  .circular-stepper-heading {
    color: #8a8a8a;
  }
  .MuiCircularProgress-progress,
  .MuiCircularProgress-sizeLg {
    color: #8a8a8a;
    stroke: #2dce98;
  }
  .css-zitgxn-JoyCircularProgress-track {
    stroke: #434343;
  }
  .k-step-done {
    .k-step-indicator-text {
      position: relative;
      ::after {
        position: absolute;
        content: '';
        z-index: 15;
        background: #2dce98;
        background-image: url(${check});
        background-repeat: no-repeat;
        background-size: inherit;
        background-position: center;
        border-radius: 50px;
        left: -8px;
        height: 26px;
        width: 26px;
        top: -1px;
      }
    }
  }
  width: 100%;
  height: 100%;
  input {
    ::placeholder {
      font-size: 14px;
      text-transform: capitalize;
    }
  }
  .k-stepper.form-stepper {
    pointer-events: none;
    .k-step-label .k-step-text {
      white-space: normal;
      text-align: center;
      word-break: break-word;
      width: 50%;
    }
  }
  .k-stepper .k-step-indicator::after {
    box-shadow: unset !important;
  }
  .k-stepper .k-step-indicator::after {
    border-style: unset !important;
  }
  .k-stepper .k-step-label .k-step-text {
    width: 200px;
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #8a8a8a;
    font-size: 12px;
    max-width: unset !important;
  }
  .k-progressbar {
    background: #5b5b5b;
  }
  .k-progressbar .k-selected {
    background: #2dce98;
  }
  .k-stepper .k-step-done .k-step-indicator {
    border-color: #2dce98;
    color: white;
    background-color: #2dce98;
  }
  .k-stepper .k-step-current .k-step-indicator {
    border-color: #2dce98;
    color: white;
    background-color: #2dce98;
  }
  .k-stepper .k-step-indicator {
    border-color: #5b5b5b;
    color: #c4c4c4;
    background-color: #5b5b5b;
    width: 25px;
    height: auto;
  }
`;
const StepperStep = styled.button`
  background-color: green !important;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;
  font-weight: bold;

  &.completed {
    background-color: green !important;
  }
`;
export default RiderInformation;
