import { GridCellProps } from '@progress/kendo-react-grid';
import { SHToolTip } from 'app/components/SHToolTip';
import styled from 'styled-components';

type Props = {
  className?: string;
  e: GridCellProps;
};

const CoachphoneCell = ({ className, e }: Props) => {
  const data = e.dataItem;
  return (
    <Wrapper className={className ?? ''}>
      <SHToolTip position="top">
        <EmailWrapper title={data?.primaryPhone}>
          {data?.primaryPhone}
        </EmailWrapper>
      </SHToolTip>
    </Wrapper>
  );
};

export default CoachphoneCell;
const Wrapper = styled.td``;
const EmailWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  color: #475467;
`;
