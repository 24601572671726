import { useErrorHandlingSlice } from 'app/error/slice';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useApi from 'services/mavapi/useApi';
import {
  ActiveRiderWrapper,
  AdminRiderRegistration,
  ManageRidersService,
  ManageTeamService,
  RiderInfo,
} from 'services/openapi';
import { useNavigateToRoute } from 'utils/common';

const useRiderApi = () => {
  const { action } = useParams();
  const redirect = useNavigateToRoute();
  const [loading, setLoading] = useState(false);
  const [riderDataLoading, setRiderDataLoading] = useState(false);
  const [, setError] = useState<unknown | null>();
  const { error: apiError, handleRequest } = useApi();
  const { manageTeamGetRiderInfo, manageTeamUploadRiderImage } =
    ManageTeamService;
  const {
    manageRidersGetActiveRiders,
    manageRidersDeleteRiderInfo,
    manageRidersUpdateRiderStatus,
    manageRidersUpdateOrCreateRider,
    manageRidersRiderBackNumberTakenOrNot,
  } = ManageRidersService;
  const dispatch = useDispatch();
  const { actions } = useErrorHandlingSlice();
  const [riderInfo, setRiderInfo] = useState<RiderInfo>();
  const [activeRiderList, setActiveRiderList] = useState<ActiveRiderWrapper>();
  const [isBacknumberTaken, setIsBacknumberTaken] = useState<boolean | null>(
    null,
  );
  const fetchRiderInfo = (id: string) => {
    setRiderDataLoading(true);
    handleRequest(manageTeamGetRiderInfo(id))
      .then(res => {
        if (res) {
          setRiderInfo(res);
        }
        setRiderDataLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setRiderDataLoading(false);
      });
  };
  const fetchActiveRiderList = (
    season?: string,
    teamFilter?: Array<string>,
    divisionFilter?: Array<string>,
    searchInput?: string,
    status?: string,
    sortBy?: string,
    sortOrder?: number,
    pageNumber: number = 0,
    pageSize: number = 10,
  ) => {
    setLoading(true);
    let pageNumCall = pageNumber ? pageNumber / 10 + 1 : 1;
    handleRequest(
      manageRidersGetActiveRiders(
        season,
        teamFilter,
        divisionFilter,
        searchInput,
        status,
        sortBy,
        sortOrder,
        pageNumCall,
        pageSize,
      ),
    )
      .then(res => {
        if (res) {
          setActiveRiderList(res);
        }
        setLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };
  const deleteRiderInfo = (
    id?: string,
    season?: string,
    teamFilter?: Array<string>,
    divisionFilter?: Array<string>,
    searchInput?: string,
    status?: string,
    sortBy?: string,
    sortOrder?: number,
    pageNumber: number = 0,
    pageSize: number = 10,
  ) => {
    setLoading(true);
    handleRequest(manageRidersDeleteRiderInfo(id ?? ''))
      .then(res => {
        if (res) {
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Rider deleted successfully',
              show: true,
              errorType: 'success',
            }),
          );
          fetchActiveRiderList(
            season,
            teamFilter,
            divisionFilter,
            searchInput,
            status,
            sortBy,
            sortOrder,
            pageNumber,
            pageSize,
          );
        }
        setLoading(false);
      })
      .catch(apiError => {
        setError(apiError);
        setLoading(false);
      });
  };
  const fetchRiderActiveOrInactive = (
    id: string,
    season?: string,
    statusId?: string,
    searchInput?: string,
    status?: string,
    sortBy?: string,
    sortOrder?: number,
    pageNumber: number = 0,
    pageSize: number = 10,
    item?: string,
    teamId?: string[],
    divisionId?: string[],
  ) => {
    setLoading(true);
    handleRequest(manageRidersUpdateRiderStatus(id, statusId))
      .then(res => {
        if (res) {
          fetchActiveRiderList(
            season,
            teamId,
            divisionId,
            searchInput,
            status,
            sortBy,
            sortOrder,
            pageNumber,
            pageSize,
          );
          if (item === 'Make active') {
            dispatch(
              actions.dispatchToast({
                errorTxt: 'Successfully Active',
                show: true,
                errorType: 'success',
              }),
            );
          } else {
            dispatch(
              actions.dispatchToast({
                errorTxt: 'Successfully Inactive',
                show: true,
                errorType: 'success',
              }),
            );
          }
        }
        setLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };

  const updateOrCreateRiders = (
    requestBody?: AdminRiderRegistration,
    imageFile?: Blob,
  ) => {
    setLoading(true);
    handleRequest(manageRidersUpdateOrCreateRider(requestBody))
      .then(res => {
        if (res === 'Email is already in use') {
          dispatch(
            actions.dispatchToast({
              errorTxt: res,
              show: true,
              errorType: 'error',
            }),
          );
          setLoading(false);
        } else if (res === 'Rider BackNumber Already Exsit') {
          dispatch(
            actions.dispatchToast({
              errorTxt: res,
              show: true,
              errorType: 'error',
            }),
          );
          setLoading(false);
        } else if (imageFile) {
          let formData = {
            file: imageFile,
          };
          updateOrCreateRiderImage(
            requestBody?.riderId === null ? res?.riderId : requestBody?.riderId,
            formData,
          );
        } else {
          if (action === 'edit') {
            redirect(
              `/admin/riders/${`view`}/profile-info/${requestBody?.riderId!}`,
            );
            dispatch(
              actions.dispatchToast({
                errorTxt: 'Rider Updated Successfully',
                show: true,
                errorType: 'success',
              }),
            );
          }
          if (action === 'create') {
            redirect(`/admin/riders/${`view`}/profile-info/${res.riderId!}`);
            dispatch(
              actions.dispatchToast({
                errorTxt: 'Rider Added Successfully',
                show: true,
                errorType: 'success',
              }),
            );
          }
          setLoading(false);
        }
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };

  const updateOrCreateRiderImage = (
    id: string,
    formData?: {
      file?: Blob;
    },
  ) => {
    setLoading(true);
    handleRequest(manageTeamUploadRiderImage(id, formData))
      .then(res => {
        redirect(`/admin/riders/view/profile-info/${id}`);
        if (action === 'edit') {
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Updated Rider Image',
              show: true,
              errorType: 'success',
            }),
          );
        }
        if (action === 'create') {
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Added Rider Image',
              show: true,
              errorType: 'success',
            }),
          );
        }
        setLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };
  const checkBacknumberTaken = (
    backNumber?: string,
    isTryIt?: boolean,
    riderId?: string,
  ) => {
    handleRequest(
      manageRidersRiderBackNumberTakenOrNot(backNumber, isTryIt, riderId),
    )
      .then(res => {
        setIsBacknumberTaken(res);
      })
      .catch(() => {
        setError(apiError);
      });
  };

  return {
    loading,
    fetchRiderInfo,
    riderInfo,
    deleteRiderInfo,
    fetchActiveRiderList,
    activeRiderList,
    updateOrCreateRiders,
    fetchRiderActiveOrInactive,
    updateOrCreateRiderImage,
    riderDataLoading,
    checkBacknumberTaken,
    isBacknumberTaken,
    setIsBacknumberTaken,
  };
};
export default useRiderApi;
