import React, { useState } from 'react';
import styled from 'styled-components';
import '@fontsource/oswald';
import horseBg from './assets/img/bg/riderNew.png';
import '@fontsource/roboto';
import { ShowyedaLogo } from 'app/components/ShowyedaLogo';
import { Outlet } from 'react-router-dom';

type Props = {};

const RiderRegistrationLayout = (props: Props) => {
  return (
    <MainWrapper>
      <LeftWrapper className="LeftWrapper">
        <ShowyedaLogo />
        {/* <BottomImgWrapper>
          <ImgTag src={horseBg} />
        </BottomImgWrapper> */}
      </LeftWrapper>
      <RightWrapper className="RightWrapper">
        <BorderWrapper className="BorderWrapper">
          <TopWrapper className="TopWrapper">
            <h3 className="toplabel-header">YEDA Rider Registration Form</h3>
          </TopWrapper>
          <Outlet />
        </BorderWrapper>
      </RightWrapper>
    </MainWrapper>
  );
};

const LeftWrapper = styled.div`
  width: 36%;
  /* background: #1b1b1b; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  position: relative;
  background: url(${horseBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  padding: 10px 35px;
  p {
    color: #898989;
    font-family: 'Oswald';
    font-size: 16px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 10px 20px;
    p {
      font-size: 14px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    padding: 10px 28px;
    p {
      font-size: 15px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    padding: 10px 35px;
    p {
      font-size: 16px;
    }
  }
`;
const BottomImgWrapper = styled.div`
  width: 60%;
  height: auto;
  @media (min-width: 1366px) and (max-width: 1448px) {
    width: 60%;
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    width: 60%;
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    width: 60%;
  }
`;
const ImgTag = styled.img`
  width: 100%;
  height: auto;
`;
const RightWrapper = styled.div`
  width: 64%;
  height: 100%;
  background: #2a2a2a;
  padding: 30px 35px 20px;
  p {
    color: #9597a6;
    font-family: 'Roboto';
    font-size: 14px;
  }
  align-items: center;

  @media (min-width: 100px) and (max-width: 319px) {
    padding: 30px 15px 20px;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    padding: 30px 15px 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding: 30px 15px 20px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding: 30px 15px 20px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 30px 15px 20px;
  }
`;
const BorderWrapper = styled.div`
  height: 100%;
  border: 1px solid #585858;
  border-radius: 20px;
  padding: 20px 20px 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
  position: relative;
  @media (min-width: 100px) and (max-width: 319px) {
    padding: 20px 20px 0px 20px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    padding: 20px 20px 0px 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding: 20px 20px 0px 20px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding: 20px 20px 0px 20px;
  }
`;

const TopWrapper = styled.div`
  background: #2a2a2a;
  padding: 0px 10px;
  position: absolute;
  top: -12px;
  // left: 270px;
  h3 {
    font-weight: 500;
    color: #ffffff;
    margin: 0px !important;
    font-family: 'Roboto';
    text-align: center;
  }

  @media (min-width: 100px) and (max-width: 319px) {
    h3 {
      font-size: 12px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    h3 {
      font-size: 13px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    h3 {
      font-size: 14px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    h3 {
      font-size: 15px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    // padding: 15px 40px 0px;
    h3 {
      font-size: 16px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    // padding: 20px 60px 0px;
    h3 {
      font-size: 17px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    // padding: 20px 80px 0px;
    h3 {
      font-size: 18px;
    }
  }
`;
const MainWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  @media (min-width: 100px) and (max-width: 319px) {
    .TopWrapper {
      .toplabel-header {
        font-size: 12px;
      }
    }
    .LeftWrapper {
      display: none;
    }
    .RightWrapper {
      width: 100%;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .TopWrapper {
      .toplabel-header {
        font-size: 13px;
      }
    }
    .LeftWrapper {
      display: none;
    }
    .RightWrapper {
      width: 100%;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .TopWrapper {
      top: -10px;
      .toplabel-header {
        font-size: 14px;
      }
    }
    .LeftWrapper {
      display: none;
    }
    .RightWrapper {
      width: 100%;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .TopWrapper {
      .toplabel-header {
        font-size: 18px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .TopWrapper {
      .toplabel-header {
        font-size: 19px;
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .TopWrapper {
      .toplabel-header {
        font-size: 20px;
      }
    }
  }
  @media (min-width: 1366px) and (max-width: 1449px) {
    .TopWrapper {
      .toplabel-header {
        font-size: 21px;
      }
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    .TopWrapper {
      .toplabel-header {
        font-size: 23px;
      }
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    .TopWrapper {
      .toplabel-header {
        font-size: 25px;
      }
    }
  }
`;

export default RiderRegistrationLayout;
