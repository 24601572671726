import { useState } from 'react';
import useApi from 'services/mavapi/useApi';
import { GroupList, GroupListWrapper, ReportService } from 'services/openapi';

const useShowRiderReportGroupApi = () => {
  const {
    reportGetShowRiderReportGroupList,
    reportUpdateShowRiderReportGroups,
  } = ReportService;

  const [, setError] = useState<unknown | null>();
  const { error: apiError, handleRequest } = useApi();

  // States
  const [loading, setLoading] = useState(false);
  const [riderReportGroupList, setRiderReportGroupList] =
    useState<GroupListWrapper>();

  const getShowRiderReportGroupList = (showId: string, seasonId: string) => {
    setLoading(true);
    handleRequest(reportGetShowRiderReportGroupList(showId, seasonId))
      .then(res => {
        setRiderReportGroupList(res);
        setLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };
  const updateShowRiderReportGroupsList = (
    showId: string,
    requestBody: Array<GroupList>,
  ) => {
    setLoading(true);
    handleRequest(reportUpdateShowRiderReportGroups(showId, requestBody))
      .then(res => {
        // setRiderReportGroupList(res);
        setLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };
  return {
    loading,
    getShowRiderReportGroupList,
    riderReportGroupList,
    setRiderReportGroupList,
    updateShowRiderReportGroupsList,
  };
};

export default useShowRiderReportGroupApi;
