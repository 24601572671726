import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import '@fontsource/oswald';
import horseBg from './assets/img/bg/ewdReturning.jpg';
import '@fontsource/roboto';
import { ShowyedaLogo } from 'app/components/ShowyedaLogo';
import { Outlet } from 'react-router-dom';
import Cookies from 'js-cookie';
import { selectFormValue } from 'app/pages/EWDMembershipFormPage/slice/selectors';
import { useSelector } from 'react-redux';

type Props = {};

const EWDMembershipFormLayout = (props: Props) => {
  const formValue = useSelector(selectFormValue);
  const currentSeason = Cookies.get('isAdminLiveFiltered');
  return (
    <MainWrapper>
      <LeftWrapper className="LeftWrapper">
        <ShowyedaLogo />
        <div className="left-header-block"></div>
      </LeftWrapper>
      <RightWrapper className="RightWrapper">
        <BorderWrapper className="BorderWrapper">
          <TopWrapper className="TopWrapper">
            <h3 className="toplabel-header">
              YEDA Equestrian with Disabilities (EWD) <br />
              {formValue && formValue >= 4
                ? 'Diagnosis Form '
                : 'Membership Form '}
              {currentSeason === undefined ? '2023 - 2024' : currentSeason}
            </h3>
          </TopWrapper>
          <Outlet />
        </BorderWrapper>
      </RightWrapper>
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  @media (min-width: 100px) and (max-width: 319px) {
    .TopWrapper {
      .toplabel-header {
        font-size: 12px;
      }
    }
    .LeftWrapper {
      display: none;
    }
    .RightWrapper {
      width: 100%;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .TopWrapper {
      .toplabel-header {
        font-size: 13px;
      }
    }
    .LeftWrapper {
      display: none;
    }
    .RightWrapper {
      width: 100%;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .TopWrapper {
      top: -10px;
      .toplabel-header {
        font-size: 14px;
      }
    }
    .LeftWrapper {
      display: none;
    }
    .RightWrapper {
      width: 100%;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .TopWrapper {
      .toplabel-header {
        font-size: 18px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .TopWrapper {
      .toplabel-header {
        font-size: 19px;
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .TopWrapper {
      .toplabel-header {
        font-size: 20px;
      }
    }
  }
  @media (min-width: 1366px) and (max-width: 1439px) {
    .TopWrapper {
      .toplabel-header {
        font-size: 21px;
      }
    }
  }
`;
const LeftWrapper = styled.div`
  width: 36%;
  height: 100%;
  /* background: #1b1b1b; */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: url(${horseBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  .left-header-block {
    background: black;
    opacity: 0.7;
    padding: 0 20px;
    position: absolute;
    top: 30%;
  }
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; */
  /* padding: 60px 35px 0px; */
  p {
    color: #898989;
    font-family: 'Oswald';
    font-size: 14px;
    text-align: center;
    padding-top: 40px;
  }
  span {
    color: #ffffff;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    p {
      font-size: 11px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    p {
      font-size: 13px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 20px 20px;
    p {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    padding: 40px 28px;
    p {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    padding: 60px 35px 0px;
    p {
      font-size: 14px;
    }
  }
`;
const BottomImgWrapper = styled.div`
  width: 100%;
  height: auto;
  justify-content: center;
  display: flex;

  @media (min-width: 1366px) and (max-width: 1448px) {
    width: 60%;
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    width: 60%;
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    width: 60%;
  }
`;
const ImgTag = styled.img`
  width: 100%;
  max-width: 530px;
  min-height: calc(100vh - 367px);
  max-height: 500px;
  object-fit: contain;
  object-position: center;
  @media (min-width: 576px) and (max-width: 767px) {
    max-height: calc(100vh - 338px);
  }
  @media (min-width: 768px) and (max-width: 991px) {
    max-height: calc(100vh - 338px);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 280px;
    max-height: calc(100vh - 266px);
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    width: 290px;
    max-height: calc(100vh - 274px);
  }
  @media (min-width: 1366px) and (max-width: 1439px) {
    width: 320px;
    height: calc(100vh - 304px);
  }
  @media (min-width: 1440px) {
    height: calc(100vh - 304px);
  }
`;
const RightWrapper = styled.div`
  width: 64%;
  height: 100%;
  background: #2a2a2a;
  padding: 30px 35px 20px;
  p {
    color: #9597a6;
    font-family: 'Roboto';
    font-size: 14px;
  }
  // display: flex;
  // flex-direction: column;
  // align-items: center;

  @media (min-width: 100px) and (max-width: 319px) {
    padding: 30px 15px 20px;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    padding: 30px 15px 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding: 30px 15px 20px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding: 30px 15px 20px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 30px 15px 20px;
  }
`;
const BorderWrapper = styled.div`
  height: 100%;
  border: 1px solid #585858;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px 20px 0px 20px;
  /* max-width: 850px; */
  margin: 0 auto;
  @media (min-width: 100px) and (max-width: 319px) {
    padding: 20px 20px 0px 20px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    padding: 20px 20px 0px 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding: 20px 20px 0px 20px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding: 20px 20px 0px 20px;
  }
`;

const TopWrapper = styled.div`
  background: #2a2a2a;
  padding: 0px 10px;
  position: absolute;
  top: -12px;
  z-index: 2;
  h3 {
    font-weight: 500;
    color: #ffffff;
    margin: 0px !important;
    font-family: 'Roboto';
    text-align: center;
  }

  @media (min-width: 100px) and (max-width: 319px) {
    h3 {
      font-size: 12px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    h3 {
      font-size: 13px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    h3 {
      font-size: 14px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    h3 {
      font-size: 15px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    // padding: 15px 40px 0px;
    h3 {
      font-size: 16px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    // padding: 20px 60px 0px;
    h3 {
      font-size: 17px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    // padding: 20px 80px 0px;
    h3 {
      font-size: 18px;
    }
  }
`;

export default EWDMembershipFormLayout;
