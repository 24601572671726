import React from 'react';
import styled from 'styled-components';
import { TextField } from 'app/components/TextField';
import '@fontsource/roboto';
import SHdropdown from 'app/components/SHdropdown';
import { GeneralLookup, NonCompeting } from 'services/openapi';
import SimpleReactValidator from 'simple-react-validator';
import { CheckBox } from 'app/components/CheckBox';
import TermsandConditons from 'app/components/TermsandConditions';
import CustomDragAndDropFiles from 'app/components/CustomDragAndDropFiles';
import { useRiderRegistrationStateSlice } from 'app/pages/RiderRegistrationPage/slice';
import { useDispatch } from 'react-redux';

type Props = {
  labelName?: string;
  setNonCompetingpayload?: React.Dispatch<React.SetStateAction<NonCompeting>>;
  nonCompetingpayload?: NonCompeting;
  stateData?: GeneralLookup[];
  setValid: React.Dispatch<React.SetStateAction<boolean>>;
  valid: boolean;
  setIsTermsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  isTermsChecked: boolean;
  setPhotoUploadPayload?: React.Dispatch<
    React.SetStateAction<{
      formData?:
        | {
            file?: Blob | undefined;
          }
        | undefined;
    }>
  >;
  photoUploadPayload?: {
    formData?:
      | {
          file?: Blob | undefined;
        }
      | undefined;
  };
  setFileAvailability?: React.Dispatch<React.SetStateAction<boolean>>;
  fileAvailability?: boolean;
  setUploadFile?: React.Dispatch<React.SetStateAction<File | null | undefined>>;
  uploadFile?: File | null | undefined;
};

export const NonCompetingFormFields = ({
  labelName,
  setNonCompetingpayload,
  stateData,
  nonCompetingpayload,
  setValid,
  valid,
  setIsTermsChecked,
  isTermsChecked,
  setPhotoUploadPayload,
  photoUploadPayload,
  setFileAvailability,
  fileAvailability,
  setUploadFile,
  uploadFile,
}: Props) => {
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const dispatch = useDispatch();
  const { actions: manageAppActions } = useRiderRegistrationStateSlice();
  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(!formValid);
  }, [nonCompetingpayload]);
  const handleChange = event => {
    const { name, value } = event.target;
    console.log('changed', value);
    setNonCompetingpayload!(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleCheckboxChange = e => {
    setIsTermsChecked(!isTermsChecked);
  };
  const handleZipChange = e => {
    const inputValue = e.target.value;
    if (nonCompetingpayload) {
      if (inputValue.length <= 9 && /^[a-zA-Z0-9]*$/.test(inputValue)) {
        setNonCompetingpayload?.({
          ...nonCompetingpayload,
          zip: inputValue,
        });
      }
    }
  };
  const handlePhChange = e => {
    const inputValue = e.target.value;
    if (nonCompetingpayload) {
      if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
        setNonCompetingpayload?.({
          ...nonCompetingpayload,
          primaryPhone: inputValue,
        });
      }
    }
  };
  const handleEmergencyPhChange = e => {
    const inputValue = e.target.value;
    if (nonCompetingpayload) {
      if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
        setNonCompetingpayload?.({
          ...nonCompetingpayload,
          emergencyContactPhone: inputValue,
        });
      }
    }
  };
  const handleFileUpload = e => {
    var file = e.target.files[0];
    dispatch(manageAppActions.setPhotoUpload(file));
    console.log(file, 'file');

    setUploadFile?.(file);
    setPhotoUploadPayload?.({
      ...photoUploadPayload,
      formData: { file: file },
    });
  };
  const handleRemoveFile = e => {
    setUploadFile?.(undefined);
    setPhotoUploadPayload?.({
      ...photoUploadPayload,
      formData: { file: undefined },
    });
  };
  const handleFileOnCancelClick = e => {
    setUploadFile?.(undefined);
    setPhotoUploadPayload?.({
      ...photoUploadPayload,
      formData: { file: undefined },
    });
  };
  React.useEffect(() => {
    if (uploadFile === undefined) {
      setFileAvailability?.(true);
    } else {
      setFileAvailability?.(false);
    }
  }, [uploadFile]);
  return (
    <MainWrapper className="MainWrapper-NonCompetingFormFields">
      <TextFieldGrid className="TextFieldGrid">
        <TextFieldWrapper className="TextFieldWrapper">
          <StyledTextField
            className="StyledTextField"
            onChange={handleChange}
            value={nonCompetingpayload?.firstName}
            label="First Name"
            type="text"
            name="firstName"
            validations={'required|max:30'}
            validator={simpleValidator.current}
          />
        </TextFieldWrapper>
        <TextFieldWrapper className="TextFieldWrapper">
          <StyledTextField
            className="StyledTextField"
            onChange={handleChange}
            value={nonCompetingpayload?.lastName}
            label="Last Name"
            type="text"
            name="lastName"
            validations={'required|max:30'}
            validator={simpleValidator.current}
          />
        </TextFieldWrapper>
        <TextFieldWrapper className="TextFieldWrapper">
          <StyledTextField
            className="StyledTextField"
            onChange={handleChange}
            value={nonCompetingpayload?.street}
            label="Address"
            type="text"
            name="street"
            validations={'required'}
            validator={simpleValidator.current}
          />
        </TextFieldWrapper>
        <TextFieldWrapper className="TextFieldWrapper">
          <StyledTextField
            className="StyledTextField"
            onChange={handleChange}
            value={nonCompetingpayload?.city}
            label="City"
            type="text"
            name="city"
            validations={'required|max:40'}
            validator={simpleValidator.current}
            maxLength={40}
          />
        </TextFieldWrapper>
        <TextFieldWrapper className="TextFieldWrapper">
          <SHdropdown
            className="drop-down"
            label="State"
            onDataChange={e => {
              setNonCompetingpayload!({
                ...nonCompetingpayload!,
                state: e,
              });
            }}
            data={stateData}
            defaultValue={nonCompetingpayload?.state}
            validations={'required'}
            validator={simpleValidator.current}
          />
        </TextFieldWrapper>
        <TextFieldWrapper className="TextFieldWrapper">
          <StyledTextField
            className="StyledTextField"
            onChange={handleZipChange}
            value={nonCompetingpayload?.zip}
            label="Zip"
            type="text"
            name="zip"
            validations={'required|alpha_num|min:5|max:9'}
            validator={simpleValidator.current}
          />
        </TextFieldWrapper>
        <TextFieldWrapper className="TextFieldWrapper">
          <StyledTextField
            className="StyledTextField"
            onChange={handlePhChange}
            value={nonCompetingpayload?.primaryPhone}
            label="Phone Number"
            type="tel"
            name="primaryPhone"
            validations={'required|numeric|min:10|max:10'}
            validator={simpleValidator.current}
          />
        </TextFieldWrapper>
        <TextFieldWrapper className="TextFieldWrapper">
          <StyledTextField
            className="StyledTextField"
            onChange={handleChange}
            value={nonCompetingpayload?.email}
            label="Email:"
            type="email"
            name="email"
            validations={'required|email'}
            validator={simpleValidator.current}
            maxLength={60}
          />
        </TextFieldWrapper>
        <TextFieldWrapper className="TextFieldWrapper">
          <StyledTextField
            className="StyledTextField"
            onChange={handleChange}
            value={nonCompetingpayload?.emergencyContactName}
            label="Emergency Contact"
            type="text"
            name="emergencyContactName"
            validations={'required'}
            validator={simpleValidator.current}
          />
        </TextFieldWrapper>
        <TextFieldWrapper className="TextFieldWrapper">
          <StyledTextField
            className="StyledTextField"
            onChange={handleEmergencyPhChange}
            value={nonCompetingpayload?.emergencyContactPhone}
            label="PH:"
            type="tel"
            name="emergencyContactPhone"
            validations={'required|numeric|min:10|max:10'}
            validator={simpleValidator.current}
          />
        </TextFieldWrapper>
      </TextFieldGrid>
      <PhotoUploadWrapper>
        <h3 className="photo-title"> Upload your current photo below. </h3>
        <CustomDragAndDropFiles
          className="dragNdrop"
          onCancel={e => handleFileOnCancelClick(e)}
          onChange={e => handleFileUpload(e)}
          uploadBtnDisabled={!fileAvailability}
          onRemoveFile={handleRemoveFile}
          chooseFileBtnDisabled={!fileAvailability}
          uploadFile={uploadFile}
        />
      </PhotoUploadWrapper>
      <TermsWrapper className="TermsWrapper">
        <TermsandConditons />
        <CheckBox onChange={handleCheckboxChange} value={isTermsChecked} />
      </TermsWrapper>
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  color: #9597a6;
  width: 100%;
  height: calc(100vh - 255px);
  padding: 20px 0px 100px 0px;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    label {
      font-size: 14px;
    }
  }
`;
const TermsWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 30px 12px 0 12px;
  p {
    color: #898989;
    font-family: 'Roboto';
    font-size: 12px;
  }
  span {
    color: #ff9900;
    text-decoration: underline;
  }
`;
const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;
const PhotoUploadWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  .photo-title {
    color: #a7a7a7;
    font-family: 'Roboto';
    font-size: 15px;
    text-align: center;
  }
  .dragNdrop {
    width: 100%;
  }
`;
const TextFieldWrapper = styled.div`
  width: 50% !important;
  margin-bottom: 20px;
  &.styled-TextFieldWrapper {
    display: grid;
    align-items: end;
  }
  @media (min-width: 100px) and (max-width: 767.98px) {
    width: 100% !important;
  }
`;
const StyledTextField = styled(TextField)`
  &.styled-field {
    margin-bottom: 0px !important;
  }
`;
