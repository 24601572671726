import React from 'react';
import styled from 'styled-components';
import { Button } from '@progress/kendo-react-buttons';
import { LeftArrowIcon } from 'app/assets/icons';
import { useNavigate } from 'react-router-dom';

export type ButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  className?: string;
  isNavHistory?: boolean;
};

const BackButton = React.forwardRef<Button, ButtonProps>(
  ({ className, disabled, onClick, isNavHistory = false }: ButtonProps) => {
    const navigate = useNavigate();
    const handleBack = e => {
      if (isNavHistory) {
        if (window.history.length > 1) {
          navigate(-1);
        } else {
          navigate('/');
        }
      } else {
        onClick?.(e);
      }
    };
    return (
      <WrapperDiv className={className}>
        <Button disabled={disabled} onClick={e => handleBack(e)}>
          <LeftArrowIcon />
          <span className="text">Back</span>
        </Button>
      </WrapperDiv>
    );
  },
);

export default BackButton;
const WrapperDiv = styled.div`
  button {
    height: 17px;
    font-size: 14px;
    font-weight: 500;
    box-shadow: none;
    border: unset;
    background: unset;
    font-family: 'Inter';
  }
  .k-button-solid-base:hover {
    background: unset;
    border-color: unset;
  }
  .k-button-solid-base:focus,
  .k-button-solid-base.k-focus {
    box-shadow: unset;
  }
  .text {
    margin-left: 5px;
  }
`;
