import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import '@fontsource/roboto';
import { InfoLabel } from 'app/components/SHLabel';
import SHdropdown, { DropDownModel } from 'app/components/SHdropdown';
import { TextField } from 'app/components/TextField';
import { BackNextButton } from 'app/components/BackNextButton';
import { Grid, GridCellProps, GridColumn } from '@progress/kendo-react-grid';
import { CheckBox } from 'app/components/CheckBox';
import SearchButton from 'app/components/SearchButton';
import CircularLoader from 'app/components/CircularLoader';
import {
  GeneralLookup,
  GeneralLookupWrapper,
  GeneralRider,
  RiderService,
} from 'services/openapi';
import useApi from 'services/mavapi/useApi';
import SimpleReactValidator from 'simple-react-validator';
import { periviousValue } from 'app/pages/RiderRegistrationPage/components/staticData';
import { Button } from '@progress/kendo-react-buttons';
import { useNavigate } from 'react-router-dom';

type Props = {
  handleNextForm?: (event) => void;
  handleBackForm?: (event) => void;
  backNumber?: string;
  setBackNumber: React.Dispatch<React.SetStateAction<string>>;
  lookUp?: GeneralLookupWrapper[];
  riderTableData?: GeneralRider[] | undefined;
  setRidertableData: React.Dispatch<
    React.SetStateAction<GeneralRider[] | undefined>
  >;
  isChecked: boolean;
  handleCheckboxClick: (riderId: string) => void;
  checkedMap: null;

  categoryDefaultDatas: GeneralLookup[] | undefined;
  seasonId: string | undefined;
  setSeasonValue: React.Dispatch<
    React.SetStateAction<GeneralLookup | undefined>
  >;
  seasonValue: GeneralLookup | undefined;
  filteredSeasonLookupData: GeneralLookup[];
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  setCheckedMap: React.Dispatch<React.SetStateAction<null>>;
};

const RetriveReturningMemberDataForm = ({
  categoryDefaultDatas,
  seasonId,
  setSeasonValue,
  seasonValue,
  handleNextForm,
  handleBackForm,
  backNumber,
  setBackNumber,
  lookUp,
  riderTableData,
  setRidertableData,
  handleCheckboxClick,
  isChecked,
  checkedMap,
  filteredSeasonLookupData,
  setIsChecked,
  setCheckedMap,
}: Props) => {
  const { error: apiError, handleRequest } = useApi();
  const searchedBy: DropDownModel[] = [
    // { id: 1, text: 'Team/Category/Division' },
    { id: 2, text: 'Back Number' },
    { id: 3, text: 'Name' },
  ];
  const navigate = useNavigate();
  const { riderSearchRiders } = RiderService;
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [showTable, setShowTable] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false);
  const [validation, setValidation] = useState<boolean>(false);
  const [lookUpShow, setLookUpShow] = useState(false);
  const [backNumberShow, setBackNumberShow] = useState(true);
  const [nameShow, setNameShow] = useState(false);
  const [defaultDropDownValue, setDefaultDropDownValue] =
    useState<DropDownModel>({ id: 2, text: 'Back Number' });
  const [nameValue, setNameChange] = useState('');
  const [isName, setIsName] = useState(false);
  const [isTeam, setIsTeam] = useState(true);
  const [isCategory, setIsCategoty] = useState(true);
  const [isDivision, setIsDivision] = useState(true);
  const [isBackNumberCheck, setIsBackNumberCheck] = useState<boolean | null>(
    null,
  );
  const selectSreachedby = e => {
    setIsChecked(true);
    setDefaultDropDownValue(e);
    setIsTeam(false);
    setIsCategoty(false);
    setIsDivision(false);
    setShowTable(false);
    setTeamValue(periviousValue);
    setCategoryValue(periviousValue);
    setDivisionChange(periviousValue);
    setBackNumber('');
    setNameChange('');
    if (e.id === 1) {
      setLookUpShow(true);
      setBackNumberShow(false);
      setNameShow(false);
      setValid(false);
      setIsName(false);
    } else if (e.id === 2) {
      setBackNumberShow(true);
      setLookUpShow(false);
      setNameShow(false);
      setValid(true);
      setIsName(false);
    } else {
      setNameShow(true);
      setLookUpShow(false);
      setBackNumberShow(false);
      setValid(false);
      setIsName(true);
    }
  };

  const [teamValue, setTeamValue] = useState<GeneralLookup>();
  const [categoryValue, setCategoryValue] = useState<GeneralLookup>();
  const [categoryDefaultValue, setCategoryDefaultValue] =
    useState<GeneralLookup>();
  const [divisionDatas, setDivisionDatas] = useState<GeneralLookup[]>();
  const [divisionValue, setDivisionChange] = useState<GeneralLookup>();
  const [categoryDatas, setCategoryDatas] = useState<GeneralLookup[]>();
  const seasonChange = e => {
    setSeasonValue(e);
    setCategoryDatas(e.children);
    setTeamValue(periviousValue);
    setCategoryValue(periviousValue);
    setDivisionChange(periviousValue);
    setBackNumber('');
    setNameChange('');
  };
  const teamChangeData = e => {
    setTeamValue(e);
    setIsTeam(false);
    setIsCategoty(false);
    setIsDivision(false);
  };
  const onDivisionChange = e => {
    setDivisionChange(e);
    setIsTeam(false);
    setIsCategoty(false);
    setIsDivision(false);
  };
  const onCategoryChange = e => {
    setCategoryValue(e);
    setCategoryDefaultValue(e);
    setDivisionDatas(e.children);
    setIsTeam(false);
    setIsCategoty(false);
    setIsDivision(false);
  };
  const handleSearchClick = (
    seasonId: string,
    backNumber: string,
    riderName: string,
    categoryId: string,
    divisionId: string,
    teamId: string,
  ) => {
    setShowLoader(true);
    handleRequest(
      riderSearchRiders(
        seasonId,
        backNumber,
        riderName,
        categoryId,
        divisionId,
        teamId,
      )
        .then(res => {
          if (res) {
            setRidertableData(res);
          }
          setShowLoader(false);
          setShowTable(true);
          setCheckedMap(null);
          setIsChecked(true);
        })
        .catch(error => {}),
    );
  };

  const firstNameCell = e => {
    return <td>{e.dataItem?.firstName}</td>;
  };
  const lastNameCell = e => {
    return <td>{e.dataItem?.lastName}</td>;
  };
  const teamCell = e => {
    return <td>{e.dataItem?.team?.name}</td>;
  };
  const graduationCell = e => {
    return <td>{e.dataItem?.expectedGraduationYear}</td>;
  };
  const zipCell = e => {
    return <td>{e.dataItem?.zip}</td>;
  };

  const checkBoxCell = (data: GridCellProps) => {
    const riderId = data?.dataItem?.id;
    return (
      <td>
        <StyledCheckBox
          onChange={() => handleCheckboxClick(riderId)}
          checked={checkedMap === riderId}
        />
      </td>
    );
  };
  const handleBackNumberChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 4 && /^\d*$/.test(inputValue)) {
      setBackNumber(inputValue);
      if (inputValue === '') {
        setValid(true);
      } else {
        setValid(false);
      }
    }
    if (inputValue.length >= 3) {
      setIsBackNumberCheck(false);
    } else {
      setIsBackNumberCheck(true);
    }
  };
  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValidation(!formValid);
  }, [backNumber]);
  return (
    <MainWrapper className="form-MainWrapper" id="PersonalInfoFormMainWrapper">
      {showLoader ? (
        <CircularLoader showText={true} />
      ) : (
        <>
          <SubMainWrapper className="SubMainWrapper">
            <BorderWrapper className="inner-BorderWrapper">
              <InfoLabelWrapper id="InfoLabelWrapper">
                <p className="Team-Heading">Returning Member</p>
                <div>
                  <InfoLabel>
                    Payment of $20 fee and proof of insurance must be
                    accompanied with this form
                  </InfoLabel>
                </div>
              </InfoLabelWrapper>
              <TextFieldGrid className="TextFieldGrid">
                <TextFieldWrapper>
                  <SHdropdown
                    className="drop-down"
                    label="Season"
                    data={filteredSeasonLookupData}
                    defaultValue={
                      seasonValue
                        ? seasonValue
                        : lookUp
                            ?.find(obj => {
                              return obj.type === 'SEASON';
                            })
                            ?.values?.find(item => item.isAdminLive === true) ??
                          undefined
                    }
                    onDataChange={event => {
                      seasonChange(event);
                    }}
                  />
                </TextFieldWrapper>
                <TextFieldWrapper>
                  <SHdropdown
                    className="drop-down"
                    label="Searched by"
                    genericData={searchedBy}
                    genericDefaultValue={defaultDropDownValue}
                    onGeneralDataChange={event => {
                      selectSreachedby(event);
                    }}
                  />
                </TextFieldWrapper>
                {lookUpShow && (
                  <>
                    {' '}
                    <TextFieldWrapper>
                      <SHdropdown
                        className="drop-down"
                        label="Team"
                        data={
                          lookUp?.find(obj => {
                            return obj.type === 'TEAM';
                          })?.values ?? []
                        }
                        onDataChange={event => {
                          teamChangeData(event);
                        }}
                        defaultValue={teamValue}
                      />
                    </TextFieldWrapper>
                    <TextFieldWrapper>
                      <SHdropdown
                        className="drop-down"
                        label="Category"
                        data={
                          categoryDatas ? categoryDatas : categoryDefaultDatas
                        }
                        onDataChange={event => {
                          onCategoryChange(event);
                        }}
                        defaultValue={categoryValue}
                      />
                    </TextFieldWrapper>
                    <TextFieldWrapper>
                      <SHdropdown
                        className="drop-down"
                        label="Division"
                        data={divisionDatas}
                        onDataChange={event => {
                          onDivisionChange(event);
                        }}
                        defaultValue={divisionValue}
                      />
                    </TextFieldWrapper>
                  </>
                )}
                {backNumberShow && (
                  <>
                    <TextFieldWrapper>
                      <StyledTextField
                        label="Back Number(3 or 4 digits)"
                        type="text"
                        name="backNumber"
                        value={backNumber}
                        onChange={handleBackNumberChange}
                        validations={'required|min:3|max:4'}
                        validator={simpleValidator.current}
                      />
                    </TextFieldWrapper>
                  </>
                )}
                {nameShow && (
                  <>
                    <TextFieldWrapper>
                      <StyledTextField
                        label="Name"
                        type="text"
                        name="name"
                        value={nameValue}
                        onChange={e => {
                          setNameChange(e.target.value);
                          setIsName(e.target.value.trim() === '');
                        }}
                        validations={'required|max:40'}
                        validator={simpleValidator.current}
                      />
                    </TextFieldWrapper>
                  </>
                )}
              </TextFieldGrid>
              <MainAddressWrapper>
                <SearchButtonWrapper>
                  <Button
                    className="main-btn-back"
                    onClick={() => {
                      navigate('/rider-registration-page');
                    }}
                  >
                    Back
                  </Button>
                  <SearchButton
                    onClick={() =>
                      handleSearchClick(
                        seasonValue?.id! ? seasonValue?.id! : seasonId!,
                        backNumber!,
                        nameValue,
                        categoryValue?.id!
                          ? categoryValue?.id!
                          : categoryDefaultValue?.id!,
                        divisionValue?.id!,
                        teamValue?.id!,
                      )
                    }
                    disableNext={
                      (isBackNumberCheck !== null
                        ? isBackNumberCheck
                        : false) || isName
                    }
                  >
                    Search
                  </SearchButton>
                </SearchButtonWrapper>
                {showTable ? (
                  <>
                    <StyledGrid
                      data={riderTableData?.length ? riderTableData : []}
                      className="StyledGrid"
                    >
                      <GridColumn
                        field="id"
                        title=""
                        width="60px"
                        cell={e => checkBoxCell(e)}
                      />
                      <GridColumn
                        field="firstName"
                        title="First Name"
                        width="110px"
                        cell={e => firstNameCell(e)}
                      />
                      <GridColumn
                        field="lastName"
                        title="Last Name"
                        width="110px"
                        cell={e => lastNameCell(e)}
                      />
                      <GridColumn
                        field="team.name"
                        title="Team"
                        width="110px"
                        cell={e => teamCell(e)}
                      />
                      <GridColumn
                        field="GraduationYear"
                        title="Graduation Year"
                        width="110px"
                        cell={e => graduationCell(e)}
                      />
                      <GridColumn
                        field="Zip"
                        title="Zip"
                        width="110px"
                        cell={e => zipCell(e)}
                      />
                    </StyledGrid>
                    <InfoWrapper>
                      <p className="para-heading">
                        <i>PLEASE NOTE:</i>
                      </p>
                      <p className="para-content">
                        You are able to edit and update information in Returning
                        Member Registration.
                      </p>
                    </InfoWrapper>
                  </>
                ) : (
                  ''
                )}
              </MainAddressWrapper>
            </BorderWrapper>
          </SubMainWrapper>
          {showTable ? (
            <BottomRightWrapper id="BottomRightWrapper">
              <ButtonWrapper>
                <BackNextButton
                  classNameBack="btn-back"
                  classNameNxt="btn-nxt"
                  onClickBack={handleBackForm}
                  onClickNxt={handleNextForm}
                  next="Next"
                  back="Cancel"
                  disableNext={isChecked}
                />
              </ButtonWrapper>
            </BottomRightWrapper>
          ) : (
            ''
          )}
        </>
      )}
    </MainWrapper>
  );
};

export default RetriveReturningMemberDataForm;

const MainWrapper = styled.div`
  color: #9597a6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  /* padding-top: 50px; */
  position: relative;
  .Team-Heading {
    margin: 0px;
    padding: 0px 0px 20px !important;
    text-align: center;
    color: #9597a6;
  }
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
  .first-para,
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    /* padding-top: 40px; */
    .first-para,
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    /* padding-top: 45px; */
    .first-para,
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    /* padding-top: 50px; */
    .first-para,
    label {
      font-size: 14px;
    }
  }
`;
const SubMainWrapper = styled.div`
  height: calc(100vh - 150px);
  width: 100%;
`;
const BorderWrapper = styled.div`
  height: 100%;
  overflow: auto;
  /* border: 1px solid #585858; */
  border-radius: 20px;
  padding: 20px 60px 0px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
  position: relative;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: #201e1e !important;
    border-radius: 10px;
  }
  @media (min-width: 100px) and (max-width: 575px) {
    padding: 10px 5px 0px 5px;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    padding: 10px 10px 0px;
  }
`;
const InfoLabelWrapper = styled.div`
  padding: 0 20px 10px 20px;
  text-align: start;
  width: 100%;
  div {
    border-bottom: 1px solid #434343;
    padding: 6px 0;
  }
`;

const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 35px 0 0;

  /* gap: 15px; */
  @media (min-width: 992px) and (max-width: 1199px) {
    /* gap: 5px; */
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    /* gap: 10px; */
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    /* gap: 15px; */
  }
`;

const TextFieldWrapper = styled.div`
  /* flex: 0 0 50%;
  margin-bottom: 20px;*/
  width: 50% !important;
  margin-bottom: 20px;
  &.styled-TextFieldWrapper {
    display: grid;
    align-items: end;
  }

  /* padding: 0px 20px; */
  @media (min-width: 100px) and (max-width: 767.98px) {
    width: 100% !important;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 50% !important;
  }
`;

const StyledTextField = styled(TextField)`
  flex: 0 0 50%;
  &.styled-field {
    margin-bottom: 0px !important;
  }
`;

const BackFieldWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  ${StyledTextField} {
    width: 50%;
  }
  .button-wrapper.third,
  .info-icon {
    margin: 0 0 0 10px !important;
  }
`;
const SearchButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 30px;
  .main-btn-back {
    background-color: #2a2a2a;
    border: 1px solid #606060;
    color: #909090;
    height: 35px;
    &.light {
      background-color: #ededed;
    }
    &.dark {
      background-color: #2a2a2a;
    }
  }
  button {
    width: 100px;
    height: 30px;
    margin: 0px 10px;
    border-radius: 20px;
    background: #5b5a5a;
    border: 1px solid #8b8b8b;
  }
`;
const StyledGrid = styled(Grid)`
  border: 1px solid #5e5e5e;
  border-radius: 20px;
  background: transparent;
  .k-grid-content {
    ::-webkit-scrollbar {
      width: 2px;
      margin-left: 3px;
      height: 10px;
    }
    ::-webkit-scrollbar-track {
      background: transparent !important;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      width: 2px;
      background: #201e1e !important;
      border-radius: 10px;
    }
  }
  .k-table {
    width: 100% !important;
  }
  .k-column-title {
    font-size: 12px;
  }
  .k-grid-header {
    border: none !important;
    padding: 0px !important;
    border-radius: 20px 0 0 0;
    background: transparent;
    .k-grid-header-wrap {
      margin: 0px !important;
      border-radius: 20px 20px 0px 0px !important;
    }
    .k-grid-header-table {
      thead.k-table-thead {
        background: #373737 !important;
        color: #717171 !important;
        border: none !important;
        tr {
          th {
            border: none !important;
          }
        }
      }
    }
  }
  .k-grid-container {
    border-radius: 0px 0px 20px 20px !important;
    .k-grid-content {
      overflow: auto !important;
      .k-table.k-grid-table {
        .k-table-tbody {
          .k-table-row.k-master-row {
            background: #2d2d2e !important;
            &.k-table-alt-row.k-alt {
              background: #333333 !important;
            }
            td {
              border: none !important;
              color: #9597a6;
              font-size: 10px;
            }
          }
        }
      }
    }
  }

  //*****************************/
  /* table,
  tr {
    color: #9597a6;
    border-color: unset;
  }
  .k-grid tr {
    border-color: unset;
    background: #2d2d2e;
  }
  th {
    background: #373737;
    color: #717171;
    border-color: unset;
  }
  th {
    border-color: transparent;
  }
  .k-grid .k-alt {
    background: #373737;
    border-color: unset;
  }
  .k-grid {
    border-color: unset;
    color: unset;
    background-color: unset;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  .k-table,
  .k-data-table {
    border-color: transparent !important;
  }
  .k-grid td,
  .k-grid .k-grid-content,
  .k-grid .k-grid-header-locked,
  .k-grid .k-grid-content-locked {
    border-color: transparent !important;
  }
  .k-grid-header {
    border-color: #585858 !important;
    border-radius: 15px;
  }
  .k-table-thead,
  .k-table-tbody,
  .k-table-tfoot,
  .k-table-row,
  .k-table-alt-row {
    border-color: unset;
    background-color: #2d2d2e;
  }
  .table {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  .k-grid-header-wrap {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }
  .k-grid-footer-wrap,
  colgroup {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  .k-grid-content,
  .k-grid-content-locked {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  .k-grid-content {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  } */
`;
const StyledCheckBox = styled(CheckBox)`
  display: flex;
  justify-content: center;
  label {
    display: none;
  }
`;
const InfoWrapper = styled.div`
  width: 100%;
  border: 1px solid #585858;
  border-radius: 10px;
  margin: 30px 0px 20px;
  padding: 2px 20px;
  background: #27292d;
  .para-heading {
    color: #ff9900;
    border-bottom: 1px solid #5d5c5c;
    font-size: 14px;
  }
  .para-content {
    font-family: 'Roboto';
    color: #a7a7a7;
    font-size: 12px;
    padding: 10px 0px;
  }

  @media (min-width: 100px) and (max-width: 319px) {
    .para-content {
      font-size: 10px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .para-content {
      font-size: 10px;
    }
  }
  @media (min-width: 576px) and (max-width: 1199px) {
    .para-content {
      font-size: 11px;
    }
  }

  @media (min-width: 1200px) and (max-width: 1719px) {
    .para-content {
      font-size: 12px;
    }
  }

  @media (min-width: 1720px) and (max-width: 4000px) {
    .para-content {
      font-size: 13px;
    }
  }
`;
const MainAddressWrapper = styled.div`
  margin-bottom: 50px;
  padding: 0 0 50px 0;
  width: 100%;
  .horizontal-line {
    display: flex;
    align-items: baseline;
    margin: 20px 20px 15px 20px;
  }
  .horizontal-line span {
    margin: 0 10px 0 0;
    white-space: nowrap;
    color: #4f4f4f;
    font-size: 15px;
  }
  .horizontal-line::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #434343;
    height: 3px;
  }
`;
const BottomRightWrapper = styled.div`
  z-index: 1;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 20px 20px;
  backdrop-filter: blur(10px);
  position: absolute;
  bottom: 0;
  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    /* position: absolute;
    bottom: 40px;
    padding-top: 30px;
    background: linear-gradient(
      to bottom,
      rgb(48 48 48 / 79%) 0%,
      rgb(42 42 42) 100%
    ); */
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 40px;
    &.bg-color-change {
      bottom: 25px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 45px;
    &.bg-color-change {
      bottom: 30px;
      padding-top: 20px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 60px;
    &.bg-color-change {
      bottom: 20px;
      padding-top: 20px;
    }
  }
`;
const ButtonWrapper = styled.div``;
