import { GridCellProps } from '@progress/kendo-react-grid';
import { SHToolTip } from 'app/components/SHToolTip';
import styled from 'styled-components';

type Props = {
  className?: string;
  e: GridCellProps;
  onClick?: (e) => void;
};

const TeamContactAddressCell = ({ className, e, onClick }: Props) => {
  const data = e?.dataItem;
  return (
    <td className={className ?? ''}>
      <SHToolTip position="top">
        <ContactWrapper title={data?.contactAddress}>
          {data?.contactAddress}
        </ContactWrapper>
      </SHToolTip>
    </td>
  );
};

export default TeamContactAddressCell;

const ContactWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.4;
  min-height: 40px;
`;
