import React from 'react';
import styled from 'styled-components';
import {
  Dialog,
  DialogCloseEvent,
  DialogProps,
} from '@progress/kendo-react-dialogs';
import { RadioGroupChangeEvent } from '@progress/kendo-react-inputs';
import checkIcon from './assets/img/icon/check-icon.png';
import { useNavigate } from 'react-router-dom';
import {
  CloverMakePayment,
  CoachRegistration,
  GeneralLookup,
} from 'services/openapi';
import Cookies from 'js-cookie';
import { useManagePageSlice } from 'app/pages/slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectPageLoader } from 'app/pages/slice/selectors';
import CircularLoader from '../CircularLoader';
import LoaderWrapper from '../CircularLoader/LoaderWrapper';

type Props = {
  show?: boolean;
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: (event: DialogCloseEvent) => void;
  onChange?: (event: RadioGroupChangeEvent) => void;
  payer?: GeneralLookup;
  isCoach?: CoachRegistration;
};

const PaymentSuccessMessage = ({
  onClose,
  show,
  setShow,
  payer,
  isCoach,
}: Props) => {
  const { actions: PageActions } = useManagePageSlice();
  const pageLoader = useSelector(selectPageLoader);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toggleDialog = () => {
    setShow?.(false);
    dispatch(PageActions.setPageLoader(true));
    const cloverFooter = document.querySelector('.clover-footer');
    const cloverScriptWrapper = document.querySelector('scriptWrapper');

    if (cloverScriptWrapper) {
      document.body.removeChild(cloverScriptWrapper);
    }

    if (cloverFooter) {
      document.body.removeChild(cloverFooter);
    }
    setTimeout(() => {
      navigate('/login');
      // window.location.reload();
    }, 2000);
  };
  return (
    <>
      {pageLoader ? (
        <LoaderWrapper>
          <CircularLoader />
        </LoaderWrapper>
      ) : (
        <>
          {show ? (
            <StyledDialog onClose={toggleDialog}>
              <CloseIcon className="CloseIcon" onClick={toggleDialog}>
                <span className="k-icon k-i-close"></span>
              </CloseIcon>
              <ContentWrapper className="ContentWrapper">
                <TitleWrapper className="TitleWrapper">
                  <div>
                    <img src={checkIcon} alt="check-icon" />
                  </div>
                  <label>Registration Successful</label>
                </TitleWrapper>
                <MessageWrapper>
                  <h3 className="payment-heading">
                    Thank you for registering with us.{' '}
                    {payer ? (
                      <span>Your payment has been successful. </span>
                    ) : (
                      ''
                    )}
                  </h3>
                  {payer?.name === 'Rider' && (
                    <p className="payment-text">
                      Your login credentials will be sent to your registered
                      email. Kindly login using the same and know more about the
                      Scholarship Incentive Fund (SIF)
                    </p>
                  )}

                  {(payer?.name === 'Team' || payer?.name === 'Associate') && (
                    <p className="payment-text">
                      Your login credentials will be sent to your registered
                      email. Kindly login using the same.
                    </p>
                  )}
                  {isCoach?.isAssistant === false &&
                    isCoach?.isTrial === false && (
                      <p className="payment-text">
                        Your login credentials will be sent to your registered
                        email. Kindly login using the same to know more & Pay
                        for the Liability Insurance.
                      </p>
                    )}
                  {payer?.name === 'Coach' && (
                    <p className="payment-text">
                      Your login credentials will be sent to your registered
                      email. Kindly login using the same to know more.
                    </p>
                  )}
                </MessageWrapper>
              </ContentWrapper>
            </StyledDialog>
          ) : (
            ''
          )}
        </>
      )}
    </>
  );
};
const StyledDialog = styled(Dialog)<DialogProps>`
  backdrop-filter: blur(8px);
  .payment-heading {
    color: #ff9900;
    margin: 0px;
    font-size: 15px;
    padding-top: 15px;
    padding-bottom: 40px;
  }
  .payment-text {
    color: #a7a7a7;
    margin: 0px;
    font-size: 14px;
  }
  .k-overlay {
  }
  .k-window.k-dialog {
    border: none !important;
    max-height: 60% !important;
    min-height: 40% !important;
    background: transparent !important;
    width: 50%;
  }
  .k-dialog-content {
    background: transparent !important;
    border-radius: 13px !important;
    position: relative;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .k-window.k-dialog {
      width: 95%;
      height: 50%;
    }
    .TitleWrapper {
      label {
        font-size: 12px !important;
      }
    }
    .payment-heading {
      font-size: 14px;
      padding-bottom: 20px;
    }
    .payment-text {
      font-size: 12px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .k-window.k-dialog {
      width: 90%;
      height: 50%;
    }
    .TitleWrapper {
      label {
        font-size: 14px !important;
      }
    }
    .payment-heading {
      font-size: 15px;
      padding-bottom: 30px;
    }
    .payment-text {
      font-size: 13px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .k-window.k-dialog {
      width: 90%;
    }
    .TitleWrapper {
      label {
        font-size: 15px !important;
      }
    }
    .payment-heading {
      font-size: 15px;
    }
    .payment-text {
      font-size: 13px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .k-window.k-dialog {
      width: 70%;
    }
    .TitleWrapper {
      label {
        font-size: 15px !important;
      }
    }
    .payment-heading {
      font-size: 15px;
    }
    .payment-text {
      font-size: 13px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .k-window.k-dialog {
      width: 70%;
    }
    .TitleWrapper {
      label {
        font-size: 16px !important;
      }
    }
    .payment-heading {
      font-size: 16px;
    }
    .payment-text {
      font-size: 14px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .k-window.k-dialog {
      width: 70%;
    }
    .TitleWrapper {
      label {
        font-size: 17px !important;
      }
    }
    .payment-heading {
      font-size: 17px;
    }
    .payment-text {
      font-size: 15px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .k-window.k-dialog {
      width: 65%;
    }
    .TitleWrapper {
      label {
        font-size: 18px !important;
      }
    }
    .payment-heading {
      font-size: 18px;
    }
    .payment-text {
      font-size: 16px;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    .k-window.k-dialog {
      width: 55%;
    }
    .TitleWrapper {
      label {
        font-size: 19px !important;
      }
    }
    .payment-heading {
      font-size: 19px;
    }
    .payment-text {
      font-size: 17px;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    .k-window.k-dialog {
      width: 60%;
    }
    .TitleWrapper {
      label {
        font-size: 22px !important;
      }
    }
    .payment-heading {
      font-size: 20px;
    }
    .payment-text {
      font-size: 18px;
    }
  }
`;
const TitleWrapper = styled.div`
  width: 100%;
  text-align: start;
  margin-bottom: 8px;
  padding: 10px 0;
  border-bottom: 1px solid #5d5c5c;
  display: flex;
  align-items: center;
  gap: 10px;
  label {
    color: #a7a7a7;
    font-size: 19px;
  }
  img {
  }
`;
const MessageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  height: 100%;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: #201e1e !important;
    border-radius: 10px;
  }
`;
const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #27292d !important;
  border-radius: 25px;
  padding: 15px 40px 25px;
  @media (min-width: 100px) and (max-width: 575) {
    padding: 15px 15px;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    padding: 15px 25px;
  }
  @media (min-width: 992px) and (max-width: 1365px) {
    padding: 15px 30px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    padding: 15px 40px 35px;
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    padding: 20px 50px 40px;
  }
`;

const CloseIcon = styled.div`
  color: #5a5a5a;
  position: absolute;
  right: 0px;
  top: 3px;
  background: #262626;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
  border: 1px solid #5a5a5a;
  cursor: pointer;
`;
export default PaymentSuccessMessage;
