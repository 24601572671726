import { CommonPageWrapper, HeadingWrapper } from 'admin/components/wrappers';
import AdminCustomButton from 'app/components/AdminCustomButton';
import { AdminSearch } from 'app/components/AdminSearchField';
import CustomBreadCrumb from 'app/components/CustomBreadCrumb';
import { PageMainLabel } from 'app/components/SHLabel';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDebounce } from 'hooks/useDebounce';
import UserTable from './UserTable';
import useUserApi from 'admin/hooks/useUserApi';
import { useNavigateToRoute } from 'utils/common';
const UserPage = () => {
  const breadCrumpitems = [
    {
      id: '',
      text: 'Showyeda admin',
      disabled: true,
    },
    {
      id: '',
      text: 'Users',
      disabled: true,
    },
  ];
  const { fetchManageGetUser, userProfileData, loading, deleteUser } =
    useUserApi();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [active, setActive] = useState(true);
  const redirect = useNavigateToRoute();
  const [pageSkip, setPageSkip] = useState<number | undefined>(0);
  const debounceUserSearch = useDebounce(searchTerm);
  const [sortField, setSortField] = useState<string>('name');
  const [sortOrder, setSortOrder] = useState<number>(0);
  const handleSearch = e => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    if (debounceUserSearch.length > 0) {
      fetchManageGetUser(debounceUserSearch, sortField, sortOrder, 0, 10);
    } else {
      fetchManageGetUser(
        debounceUserSearch,
        sortField,
        sortOrder,
        pageSkip,
        10,
      );
    }
    if (pageSkip !== 0) {
      if (debounceUserSearch?.length! >= 0) {
        setPageSkip(0);
      }
    }
  }, [debounceUserSearch]);
  return (
    <>
      <CommonPageWrapper>
        <HeadingWrapper>
          <PageMainLabel>Users</PageMainLabel>
          <CustomBreadCrumb preData={breadCrumpitems} />
        </HeadingWrapper>
        <HeadingWrapper>
          <ButtonWrapper>
            <AdminCustomButton
              buttonType="primary"
              isActive={false}
              className="fill-button"
              onClick={() => {
                setActive(!active);
                redirect(`/admin/users/create`);
              }}
            >
              Add New User
            </AdminCustomButton>
          </ButtonWrapper>
          <div style={{ width: '283px', paddingTop: '30px' }}>
            <AdminSearch
              placeholder="Search by name"
              onSearchTextEntering={handleSearch}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          </div>
        </HeadingWrapper>
        {/* User Table */}
        <UserTable
          setPageSkip={setPageSkip}
          pageSkip={pageSkip}
          debounceUserSearch={debounceUserSearch}
          fetchManageGetUser={fetchManageGetUser}
          userProfileData={userProfileData}
          deleteUser={deleteUser}
          sortField={sortField}
          sortOrder={sortOrder}
          setSortField={setSortField}
          setSortOrder={setSortOrder}
          loading={loading}
        />
      </CommonPageWrapper>
    </>
  );
};
export default UserPage;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding: 30px 0 0;
`;
