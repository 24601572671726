import { AdminProfileInfo } from 'app/components/AdminProfileInfo';

type Props = {
  className?: string;
  value: any;
  imageUrl: string;
};

export const TeamNameCell = ({ className, value, imageUrl }: Props) => {
  return (
    <td className={className ?? ''}>
      <AdminProfileInfo src={imageUrl} name={value} />
    </td>
  );
};
