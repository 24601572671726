import { CommonPageWrapper, HeadingWrapper } from 'admin/components/wrappers';
import AdminCustomButton from 'app/components/AdminCustomButton';
import { AdminSearch } from 'app/components/AdminSearchField';
import CustomBreadCrumb from 'app/components/CustomBreadCrumb';
import { BlurredLabel, PageMainLabel } from 'app/components/SHLabel';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TeamsTable from './TeamsTable';
import { useNavigateToRoute } from 'utils/common';
import useTeamApi from 'admin/hooks/useTeamApi';
import { useDebounce } from 'hooks/useDebounce';
import useCommonLookUpDatas from 'hooks/useCommonLookupData';
import { getLookupArray } from 'utils/common';
import { useLocation } from 'react-router-dom';
import AdminDropDown from 'app/components/AdminDropDown';
import { GeneralLookup } from 'services/openapi';

export interface breadCrumbDataModel {
  id: string;
  text?: string;
}
const TeamsPage = () => {
  const currentLocation = useLocation();
  const isPastDataFlag = currentLocation.pathname.includes('past-data');

  const breadCrumbItems = [
    {
      id: '',
      text: 'Showyeda admin',
      disabled: true,
    },
    {
      id: '',
      text: isPastDataFlag ? 'Past-Data' : 'Teams',
      disabled: true,
    },
  ];

  if (isPastDataFlag) {
    breadCrumbItems.push({
      id: '',
      text: 'Teams',
      disabled: true,
    });
  }
  const redirect = useNavigateToRoute();
  const {
    fetchManageGetTeams,
    teamProfileData,
    loading,
    deleteTeam,
    teamActiveOrInactive,
  } = useTeamApi();
  const { getAllLookupData, lookUps } = useCommonLookUpDatas();
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [pageSkip, setPageSkip] = useState<number | undefined>(0);
  const debounceTeamSearch = useDebounce(searchTerm);
  const [breadcrumbPreData, setBreadcrumbPreData] =
    React.useState<breadCrumbDataModel[]>(breadCrumbItems);
  const [seasonPayload, setSeasonPayload] = React.useState<GeneralLookup>({
    id: '',
    name: '',
  });
  const [sortField, setSortField] = useState<string>('teamName');
  const [sortOrder, setSortOrder] = useState<number>(0);
  // const [isOrder, setIsOrder] = useState(false);
  const handleItemIdClick = (itemId: string) => {
    setSelectedItemId(itemId === selectedItemId ? null : itemId);
  };
  const handleSearch = e => {
    setSearchTerm(e.target.value);
  };
  const handleNewTeam = () => {
    redirect(`/admin/teams/add/profile-info`);
  };

  const handleDropdownChange = (data: GeneralLookup) => {
    setSeasonPayload(data);
    fetchManageGetTeams(
      debounceTeamSearch,
      selectedItemId ?? '',
      data?.id,
      sortField,
      sortOrder,
      0,
      10,
    );
  };
  useEffect(() => {
    if (debounceTeamSearch.length > 0) {
      fetchManageGetTeams(
        debounceTeamSearch,
        selectedItemId ?? '',
        '',
        sortField,
        sortOrder,
        0,
        10,
      );
    } else if (
      (debounceTeamSearch.length === 0 && selectedItemId) ||
      selectedItemId === null
    ) {
      fetchManageGetTeams(
        debounceTeamSearch,
        selectedItemId ?? '',
        '',
        sortField,
        sortOrder,
        0,
        10,
      );
    } else {
      fetchManageGetTeams(
        debounceTeamSearch,
        selectedItemId ?? '',
        '',
        sortField,
        sortOrder,
        pageSkip,
        10,
      );
    }

    if (pageSkip !== 0) {
      if (debounceTeamSearch?.length! >= 0 || selectedItemId) {
        setPageSkip(0);
      }
    }
  }, [debounceTeamSearch, selectedItemId]);
  useEffect(() => {
    getAllLookupData('ACTIVESTATUS,SEASON');
  }, []);
  return (
    <>
      <CommonPageWrapper>
        <HeadingWrapper>
          <PageMainLabel>Teams</PageMainLabel>
          <CustomBreadCrumb
            preData={breadcrumbPreData}
            setPreData={setBreadcrumbPreData}
          />
        </HeadingWrapper>
        <HeadingWrapper>
          <ButtonWrapper>
            {!isPastDataFlag && (
              <AdminCustomButton
                buttonType="primary"
                isActive={false}
                className="fill-button"
                onClick={handleNewTeam}
              >
                New Team
              </AdminCustomButton>
            )}
            <BlurredLabel style={{ whiteSpace: 'nowrap' }}>
              Popular filter
            </BlurredLabel>
            {getLookupArray('ACTIVESTATUS', lookUps).map(item => (
              <AdminCustomButton
                key={item.id}
                buttonType="primary"
                isActive={false}
                className={
                  selectedItemId?.includes(item?.id)
                    ? 'active-inactive'
                    : 'Inactive-button'
                }
                onClick={() => {
                  handleItemIdClick(item?.id);
                }}
              >
                {item?.name}
              </AdminCustomButton>
            ))}
            {isPastDataFlag && (
              <CustomDropDown
                label=""
                placeholder="Select season"
                onDataChange={data => handleDropdownChange(data)}
                defaultValue={seasonPayload}
                data={getLookupArray('SEASON', lookUps)}
                className="field-style"
                popupStyles="medium-dropdown"
              />
            )}
          </ButtonWrapper>
          <div style={{ width: '283px', paddingTop: '30px' }}>
            <AdminSearch
              placeholder="Search for a team"
              onSearchTextEntering={handleSearch}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          </div>
        </HeadingWrapper>
        {/* Teams Table */}
        <TeamsTable
          teamProfileData={teamProfileData}
          setPageSkip={setPageSkip}
          pageSkip={pageSkip}
          fetchManageGetTeams={fetchManageGetTeams}
          debounceTeamSearch={debounceTeamSearch}
          teamActiveOrInactive={teamActiveOrInactive}
          deleteTeam={deleteTeam}
          selectedItemId={selectedItemId}
          lookup={getLookupArray('ACTIVESTATUS', lookUps)}
          sortField={sortField}
          sortOrder={sortOrder}
          setSortField={setSortField}
          setSortOrder={setSortOrder}
          loading={loading}
        />
      </CommonPageWrapper>
    </>
  );
};

export default TeamsPage;
const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding: 30px 0 0;
  .field-style {
    display: flex;
  }
  .k-dropdownlist {
    max-height: 31px;
  }
`;
const CustomDropDown = styled(AdminDropDown)`
  width: 160px;
  .k-dropdownlist {
    max-height: 31px;
    border-radius: 8px !important;
  }
  .k-input-inner {
    padding: 0 5px !important;
  }
  .k-input-value-text {
    margin: 2px 4px 2px 4px;
    font-size: 12px !important;
    letter-spacing: 0px;
    color: #1e1e1e;
    text-align: left;
  }
`;
