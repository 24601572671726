import { CommonPageWrapper } from 'admin/components/wrappers';
import CustomBreadCrumb from 'app/components/CustomBreadCrumb';
import styled from 'styled-components';
import { PageMainLabel } from 'app/components/SHLabel';
import { breadCrumpitems } from './staticDatas';
import { AdminSearch } from 'app/components/AdminSearchField';
import { useEffect, useState } from 'react';
import { useDebounce } from 'hooks/useDebounce';
import { TableSkeletonLoader } from 'app/components/TableSkeletonLoader';
import SponsersTable from './SponsersTable';
import useSponserApi from 'admin/hooks/useSponserApi';
import { SponsorList, SponsorWrapper } from 'services/openapi';

type Props = {};

const SponsersPage = (props: Props) => {
  const { fetchSponsersList, sponsersDataList } = useSponserApi();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debounceRiderSearch = useDebounce(searchTerm);
  const [pageSkip, setPageSkip] = useState<number | undefined>(0);
  const [chosenId, setChosenId] = useState<string>('');
  const [sponserDataArray, setSponserDataArray] = useState<SponsorList[]>();
  const [sponserDataArrayClone, setSponserDataArrayClone] =
    useState<SponsorWrapper>();
  const [sortField, setSortField] = useState<string>('sponserName');
  const [sortOrder, setSortOrder] = useState<number>(0);
  const handleSearch = e => {
    onListCancelclick(e);
    setSearchTerm(e.target.value);
  };
  const onListCancelclick = e => {
    setChosenId('');
    setSponserDataArray(_ => {
      const tmp = !sponserDataArrayClone?.sponsors
        ? undefined
        : JSON.parse(JSON.stringify(sponserDataArrayClone?.sponsors));
      return tmp;
    });
  };
  useEffect(() => {
    if (debounceRiderSearch.length >= 0) {
      fetchSponsersList(debounceRiderSearch, sortField, sortOrder, 0, 10);
    } else {
      fetchSponsersList(
        debounceRiderSearch,
        sortField,
        sortOrder,
        pageSkip,
        10,
      );
    }
    if (pageSkip !== 0) {
      if (debounceRiderSearch?.length >= 0) {
        setPageSkip(0);
      }
    }
  }, [debounceRiderSearch]);
  const refreshFunction = page => {
    fetchSponsersList(debounceRiderSearch, sortField, sortOrder, page, 10);
  };
  return (
    <CommonPageWrapper>
      <HeadingWrapper>
        <PageMainLabel>Sponsors</PageMainLabel>
        <CustomBreadCrumb preData={breadCrumpitems} />
      </HeadingWrapper>
      <SearchWrapper>
        <div style={{ width: '283px', paddingTop: '10px' }}>
          <AdminSearch
            placeholder="Search by sponsors"
            onSearchTextEntering={handleSearch}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </div>
      </SearchWrapper>
      {/* Sponsers Table */}
      {!sponsersDataList ? (
        <TableSkeletonLoader rowContent={8} width={100} headingWidth={80} />
      ) : (
        <SponsersTable
          refreshFunction={refreshFunction}
          setPageSkip={setPageSkip}
          pageSkip={pageSkip}
          sponsersDataList={sponsersDataList}
          onListCancelclick={onListCancelclick}
          setChosenId={setChosenId}
          chosenId={chosenId}
          setSponserDataArray={setSponserDataArray}
          sponserDataArray={sponserDataArray}
          setSponserDataArrayClone={setSponserDataArrayClone}
          setSortField={setSortField}
          setSortOrder={setSortOrder}
          fetchSponsersList={fetchSponsersList}
          sortField={sortField}
          sortOrder={sortOrder}
          debounceRiderSearch={debounceRiderSearch}
        />
      )}
    </CommonPageWrapper>
  );
};

export default SponsersPage;
const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const SearchWrapper = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  align-items: center;
  padding: 15px 0 0 0;

  .btn-action {
    display: flex;
    align-items: center;
    gap: 15px;
  }
`;
