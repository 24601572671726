import React from 'react';
import styled from 'styled-components';

type Props = {};

const Loader = (props: Props) => {
  return (
    <MainWrapper>
      <div className="circle-loader"></div>
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .circle-loader {
    border: 7px solid #f3f3f3;
    border-top: 7px solid #3498db;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 3s linear infinite;
    margin: auto;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  // p {
  //   position: absolute;
  //   top: 270px;
  //   left: 325px;
  // }
`;
export default Loader;
