import React from 'react';
import styled from 'styled-components';
import SimpleReactValidator from 'simple-react-validator';
import {
  TextArea,
  TextAreaChangeEvent,
  TextAreaFocusEvent,
} from '@progress/kendo-react-inputs';

type Props = {
  onChange?: (event: TextAreaChangeEvent) => void;
  value?: string | number;
  placeholder?: string;
  label?: string;
  style?: any;
  name?: string;
  className?: string;
  disabled?: boolean;
  inputClassName?: string;
  validator?: SimpleReactValidator;
  validations?: string;
  maxLength?: number | undefined;
  readOnly?: boolean | undefined;
  rows?: number;
  autoSize?: boolean;
  autoFocus?: boolean;
  onFocus?: ((event: TextAreaFocusEvent) => void) | undefined;
  onClick?: React.MouseEventHandler<HTMLTextAreaElement> | undefined;
  onBlurCapture?: React.FocusEventHandler<HTMLTextAreaElement> | undefined;
};

export const SHTextArea = ({
  onChange,
  value,
  placeholder,
  label,
  style,
  name,
  className,
  disabled,
  inputClassName,
  validator,
  validations,
  maxLength,
  readOnly,
  rows,
  autoSize,
  autoFocus,
  onFocus,
  onClick,
  onBlurCapture,
}: Props) => {
  const simpleValidator = React.useRef(validator);
  const handleBlur = () => {
    simpleValidator?.current?.showMessageFor(label ? label : 'field');
  };
  return (
    <TextAreaWrapper
      className={`${className} ${
        validator && !simpleValidator?.current?.fieldValid(label!)
          ? 'error'
          : 'default'
      }`}
    >
      <div className="label">{label}</div>
      <TextArea
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        style={style}
        name={name}
        disabled={disabled}
        className={inputClassName}
        onBlur={handleBlur}
        maxLength={maxLength}
        readOnly={readOnly}
        rows={rows ? rows : 3}
        autoSize={autoSize}
        autoFocus={autoFocus}
        onFocus={onFocus}
        onClick={onClick}
        onBlurCapture={onBlurCapture}
      />
      {simpleValidator?.current?.message(
        label ? label : 'field',
        value,
        validations ?? '',
      )}
    </TextAreaWrapper>
  );
};
const TextAreaWrapper = styled.div`
  margin: 0px 12px;
  .srv-validation-message {
    color: #f44336;
    font-size: 12px;
    display: inline-block;
  }
  &.error {
    textarea {
      border-bottom: 1px solid #f44336 !important;
    }
  }
  &.default {
    textarea {
      border: transparent !important;
    }
  }
  .k-input-solid:focus-within {
    border-color: transparent;
    color: unset;
    background-color: unset;
    box-shadow: unset;
  }
  .k-textarea {
    border-color: transparent;
    background-color: transparent;
  }
  textarea {
    background: #434343;
    border-color: transparent;
    min-height: 35px;
    width: 100%;
    padding: 0 10px;
    color: #ffffff;
    border-radius: 6px;
    text-size-adjust: 100%;
    ::placeholder {
      color: #9597a6;
      padding-left: 10px;
      font-weight: 500;
      font-size: 12px;
    }
    :focus-visible {
      outline: unset !important;
    }
  }
  /* Chrome, Safari, Edge, Opera */
  textarea::-webkit-outer-spin-button,
  textarea::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active {
    -webkit-text-fill-color: #ffffff !important;
    background-clip: text !important;
    box-shadow: inset 0 0 0 50px #434343 !important;
    border-radius: 6px;
    border: none !important;
  }

  /* Firefox */
  textarea[type='number'] {
    -moz-appearance: textfield;
  }
  .label {
    font-family: 'Roboto';
  }
  //Responsive
  @media (min-width: 100px) and (max-width: 319px) {
    .label {
      font-size: 11px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .label {
      font-size: 12px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .label {
      font-size: 12px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .label {
      font-size: 13px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .label {
      font-size: 13px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .label {
      font-size: 14px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .label {
      font-size: 16px;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    .label {
      font-size: 17px;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    .label {
      font-size: 17px;
    }
  }
  //Component Responsive
  @media (min-width: 100px) and (max-width: 319px) {
    textarea {
      height: 30px;
      font-size: 12px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    textarea {
      height: 30px;
      font-size: 12px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    textarea {
      font-size: 12px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    textarea {
      font-size: 13px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    textarea {
      font-size: 13px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    textarea {
      font-size: 14px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    textarea {
      font-size: 16px;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    textarea {
      font-size: 17px;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    textarea {
      font-size: 17px;
    }
  }
`;
