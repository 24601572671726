import { GridCellProps } from '@progress/kendo-react-grid';
import { AdminProfileInfo } from 'app/components/AdminProfileInfo';
import StatusChip from 'app/components/StatusChip';

type Props = {
  className?: string;
  e: GridCellProps;
  onClick?: (e) => void;
};

const CoachProfileCell = ({ className, e, onClick }: Props) => {
  const data = e.dataItem.profile;
  return (
    <td className={className ?? ''}>
      {data.id ? (
        <AdminProfileInfo
          src={data.imageUrl ?? ''}
          name={data.name}
          subLabel={data.email}
        />
      ) : (
        <>
          <StatusChip
            className=""
            StatusType={'not-assigned'}
            text={'Not Assigned'}
          />
        </>
      )}
    </td>
  );
};

export default CoachProfileCell;
