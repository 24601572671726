/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ElementaryPoints } from '../models/ElementaryPoints';
import type { GeneralLookup } from '../models/GeneralLookup';
import type { GeneratedSplit } from '../models/GeneratedSplit';
import type { HighSchoolTeam } from '../models/HighSchoolTeam';
import type { LeaderPoint } from '../models/LeaderPoint';
import type { RiderInfo } from '../models/RiderInfo';
import type { RiderPoint } from '../models/RiderPoint';
import type { RiderPointListWrapper } from '../models/RiderPointListWrapper';
import type { RiderSplitClass } from '../models/RiderSplitClass';
import type { ScoreBoard } from '../models/ScoreBoard';
import type { ShowRegistration } from '../models/ShowRegistration';
import type { ShowRider } from '../models/ShowRider';
import type { ShowRiderListWrapper } from '../models/ShowRiderListWrapper';
import type { ShowWrapper } from '../models/ShowWrapper';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ManageShowService {

    /**
     * API to update show Status
     * @param id 
     * @param activeStatusId 
     * @returns string Success
     * @throws ApiError
     */
    public static manageShowShowStatus(
id: string,
activeStatusId?: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/show/{id}/status',
            path: {
                'id': id,
            },
            query: {
                'activeStatusId': activeStatusId,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to get show info
     * @param id 
     * @returns ShowRegistration Success
     * @throws ApiError
     */
    public static manageShowGetShowInfo(
id: string,
): CancelablePromise<ShowRegistration> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/show/shows/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param season 
     * @param location 
     * @param status 
     * @param searchInput 
     * @param sortBy 
     * @param sortOrder 
     * @param pageNumber 
     * @param pageSize 
     * @returns ShowWrapper Success
     * @throws ApiError
     */
    public static manageShowGetShows(
season?: string,
location?: Array<string>,
status?: string,
searchInput?: string,
sortBy?: string,
sortOrder?: number,
pageNumber: number = 1,
pageSize: number = 10,
): CancelablePromise<ShowWrapper> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/show/shows',
            query: {
                'season': season,
                'location': location,
                'status': status,
                'searchInput': searchInput,
                'sortBy': sortBy,
                'sortOrder': sortOrder,
                'pageNumber': pageNumber,
                'pageSize': pageSize,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * API to delete shows
     * @param id 
     * @returns string Success
     * @throws ApiError
     */
    public static manageShowDeleteShow(
id: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/admin/show/show/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to Create Show
     * @param requestBody 
     * @returns ShowRegistration Created
     * @throws ApiError
     */
    public static manageShowCreateShow(
requestBody?: ShowRegistration,
): CancelablePromise<ShowRegistration> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/show/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to Update Show
     * @param requestBody 
     * @returns ShowRegistration Created
     * @throws ApiError
     */
    public static manageShowUpdateShow(
requestBody?: ShowRegistration,
): CancelablePromise<ShowRegistration> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/show/update',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to list all riders
     * @param teamId 
     * @param categoryId 
     * @param divisionId 
     * @returns GeneralLookup Success
     * @throws ApiError
     */
    public static manageShowGetAllRiders(
teamId?: string,
categoryId?: string,
divisionId?: string,
): CancelablePromise<Array<GeneralLookup>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/show/riders',
            query: {
                'teamId': teamId,
                'categoryId': categoryId,
                'divisionId': divisionId,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to list all riders added to the show
     * @param showId 
     * @param teamFilter 
     * @param categoryId 
     * @param divisionId 
     * @param searchInput 
     * @param sortBy 
     * @param sortOrder 
     * @param pageNumber 
     * @param pageSize 
     * @returns ShowRiderListWrapper Success
     * @throws ApiError
     */
    public static manageShowGetShowRiderList(
showId: string,
teamFilter?: Array<string>,
categoryId?: string,
divisionId?: string,
searchInput?: string,
sortBy?: string,
sortOrder?: number,
pageNumber: number = 1,
pageSize: number = 10,
): CancelablePromise<ShowRiderListWrapper> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/show/{showId}/riders',
            path: {
                'showId': showId,
            },
            query: {
                'teamFilter': teamFilter,
                'categoryId': categoryId,
                'divisionId': divisionId,
                'searchInput': searchInput,
                'sortBy': sortBy,
                'sortOrder': sortOrder,
                'pageNumber': pageNumber,
                'pageSize': pageSize,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to upload show image
     * @param id 
     * @param formData 
     * @returns any Created
     * @throws ApiError
     */
    public static manageShowUploadShowImage(
id: string,
formData?: {
file?: Blob;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/show/{id}/image',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to remove rider from show
     * @param riderId 
     * @param showId 
     * @returns string Success
     * @throws ApiError
     */
    public static manageShowDeleteRider(
riderId: string,
showId?: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/admin/show/rider/{riderId}',
            path: {
                'riderId': riderId,
            },
            query: {
                'showId': showId,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to Add rider points
     * @param requestBody 
     * @returns string Created
     * @throws ApiError
     */
    public static manageShowAddRiderPoints(
requestBody: RiderPoint,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/show/rider/addPoint',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to get rider elmentaryHighPoint
     * @param showId 
     * @param divisionId 
     * @param classId 
     * @param categoryId 
     * @returns ElementaryPoints Success
     * @throws ApiError
     */
    public static manageShowGetRiderElmentaryHighPoint(
showId: string,
divisionId?: string,
classId?: string,
categoryId?: string,
): CancelablePromise<Array<ElementaryPoints>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/show/rider/elmentaryHighPoint',
            query: {
                'showId': showId,
                'divisionId': divisionId,
                'classId': classId,
                'categoryId': categoryId,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to get show rider points
     * @param showId 
     * @param divisionId 
     * @param categoryId 
     * @param classFilter 
     * @param pageNumber 
     * @param pageSize 
     * @returns RiderPointListWrapper Success
     * @throws ApiError
     */
    public static manageShowGetShowRiderPoints(
showId: string,
divisionId?: string,
categoryId?: string,
classFilter?: Array<string>,
pageNumber: number = 1,
pageSize: number = 10,
): CancelablePromise<RiderPointListWrapper> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/show/shows/getpoints/{showId}',
            path: {
                'showId': showId,
            },
            query: {
                'divisionId': divisionId,
                'categoryId': categoryId,
                'classFilter': classFilter,
                'pageNumber': pageNumber,
                'pageSize': pageSize,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to get Senior junior high points
     * @param showId 
     * @returns HighSchoolTeam Success
     * @throws ApiError
     */
    public static manageShowGetSeniorJuniorHighPoints(
showId: string,
): CancelablePromise<HighSchoolTeam> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/show/{showId}/seniorJuniorHighPoints',
            path: {
                'showId': showId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * API to get show Score board
     * @param showId 
     * @returns ScoreBoard Success
     * @throws ApiError
     */
    public static manageShowGetShowScoreBoard(
showId: string,
): CancelablePromise<ScoreBoard> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/show/{showId}/scoreBoard',
            path: {
                'showId': showId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * API to get leader point
     * @param showId 
     * @returns LeaderPoint Success
     * @throws ApiError
     */
    public static manageShowGetLeaderPoint(
showId: string,
): CancelablePromise<LeaderPoint> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/show/{showId}/leaderPoint',
            path: {
                'showId': showId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * API to Add rider to Show
     * @param requestBody 
     * @returns string Created
     * @throws ApiError
     */
    public static manageShowAddRiderToShow(
requestBody?: ShowRider,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/show/rider/add',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to get Class lookup
     * @param riderId 
     * @returns GeneralLookup Success
     * @throws ApiError
     */
    public static manageShowGetRiderClasses(
riderId: string,
): CancelablePromise<Array<GeneralLookup>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/show/rider/{riderId}/class',
            path: {
                'riderId': riderId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * Api to get rider info on adding rider to show
     * @param id 
     * @returns RiderInfo Success
     * @throws ApiError
     */
    public static manageShowGetShowRiderInfo(
id: string,
): CancelablePromise<RiderInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/show/rider/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Api to update split number
     * @param requestBody 
     * @returns number Success
     * @throws ApiError
     */
    public static manageShowUpdateRiderSplit(
requestBody?: Array<RiderSplitClass>,
): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/show/rider/split',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to GenerateSplit
     * @param showId 
     * @returns GeneratedSplit Success
     * @throws ApiError
     */
    public static manageShowGenerateSplit(
showId: string,
): CancelablePromise<Array<GeneratedSplit>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/show/{showId}/split',
            path: {
                'showId': showId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

}
