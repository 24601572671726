export const breadCrumpitemsUser = [
  {
    id: '',
    text: 'Showyeda admin',
    disabled: true,
  },
  {
    id: '/admin/users',
    text: 'Users',
  },
  {
    id: '',
    text: 'Profile Info',
    disabled: true,
  },
];

export const userDefaultData = {
  id: null,
  firstName: '',
  lastName: '',
  userName: '',
  email: '',
  primaryPhone: '',
  photo: '',
  street: '',
  city: '',
  state: {
    id: '',
    name: '',
  },
  zip: '',
  teamId: null,
  userTypes: [],
};

export const userTypeCustomData = [
  { id: 0, text: 'Data Entry User' },
  { id: 1, text: 'Super Admin' },
  { id: 2, text: 'Team User' },
];
