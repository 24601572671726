import React from 'react';
import styled from 'styled-components';
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridColumnProps,
  GridPageChangeEvent,
  GridRowClickEvent,
  GridSortChangeEvent,
  GridSortSettings,
} from '@progress/kendo-react-grid';
import CustomPagination from './pagination';
import NoDataFound from '../NoDataFound';
import { SortDescriptor } from '@progress/kendo-data-query';
import sortIcon from '../../../admin/assets/icon/sort.png';
import { ScrollMode } from '@progress/kendo-react-grid/dist/npm/ScrollMode';

export type CustomCellRender = React.ComponentType<GridCellProps>;

type Props = {
  data?: Array<any>;
  columns?: Array<GridColumnProps>;
  className?: string;
  noDataLabel?: string;
  showPagination?: boolean;
  loader?: boolean;
  pageSize?: number;
  skip?: number;
  totalCount?: number;
  onRowClick?: (event: GridRowClickEvent) => void;
  onPageChange?: (event: GridPageChangeEvent) => void;
  onSortChange?: (event: GridSortChangeEvent) => void;
  sortable?: GridSortSettings;
  sort?: SortDescriptor[] | undefined;
  scrollable?: ScrollMode | undefined;
};

const CustomDataGrid = ({
  data,
  columns,
  className,
  noDataLabel,
  showPagination,
  pageSize,
  skip,
  totalCount,
  loader,
  onRowClick,
  onPageChange,
  onSortChange,
  sortable,
  sort,
  scrollable,
}: Props) => {
  const [gridOverflow, setgridOverflow] = React.useState<boolean>(false);
  const handleUpdateGridScroll = () => {
    let divGrid = document.querySelector('.k-grid-table') as HTMLElement;
    let divContent = document.querySelector('.k-grid-content') as HTMLElement;
    let gridContainer = divGrid?.getBoundingClientRect();
    let contentContainer = divContent?.getBoundingClientRect();
    if (contentContainer && gridContainer) {
      if (gridContainer?.height > contentContainer?.height) {
        setgridOverflow(true);
      } else {
        setgridOverflow(false);
      }
    }
  };

  React.useEffect(() => {
    // Call handleResize initially
    handleUpdateGridScroll();

    // Attach event listener
    window.addEventListener('resize', handleUpdateGridScroll);

    // Detach event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleUpdateGridScroll);
    };
  }, [data]);

  return (
    <CustomGridWrapper>
      {data?.length ? (
        <CustomGrid
          onRowClick={onRowClick}
          className={`${className ?? 'CustomGrid'} ${
            showPagination ? 'showPagination' : ''
          } ${gridOverflow ? 'is-scrollable' : ''}`}
          data={data ?? []}
          total={totalCount}
          skip={skip}
          take={pageSize}
          reorderable={false}
          onPageChange={onPageChange}
          onSortChange={onSortChange}
          sortable={sortable}
          sort={sort}
          pager={e => (
            <>
              {showPagination ? (
                <CustomPagination props={e} totalCount={totalCount} />
              ) : (
                ''
              )}
            </>
          )}
          pageable={{
            info: false,
            previousNext: true,
          }}
          scrollable={scrollable}
        >
          {columns?.map((column, index) => {
            return <GridColumn key={index} {...column} />;
          })}
        </CustomGrid>
      ) : (
        !data?.length && !loader && <NoDataFound noDataLabel={noDataLabel} />
      )}
    </CustomGridWrapper>
  );
};

export default CustomDataGrid;

const CustomGrid = styled(Grid)`
  font-family: 'Inter';
  background: #ffffff;
  border: none;
  &.is-scrollable {
    .k-grid-header {
      padding-right: 0px !important;
    }
  }

  &.hide-border-bottom {
    .k-grid-container {
      tr {
        &:nth-last-child(1) {
          td {
            border-bottom: 0px;
          }
        }
      }
    }
  }

  &.showPagination {
    .k-grid-container {
      .k-grid-content {
        min-height: auto;
        border: 1px solid #eaecf0;
      }
    }
  }
  .k-sorted {
    background-color: transparent !important;
  }
  .k-grid-header {
    .k-grid-header-wrap {
      .k-table {
        width: 100% !important;
      }
    }
    .k-table-thead {
    }
    padding: 0 0 0 0;
    border: 1px solid #eaecf0;
    border-bottom: unset;
    .k-grid-header-wrap {
      margin-right: 0px;
      border-width: 0 0 0 0;
    }
    tr {
      th {
        padding: 13px 8px 13px 8px;
        border-width: 0 0 0px 0px;
        &:nth-last-child(1) {
          padding: 13px 24px 13px 0px;
        }
        .k-cell-inner {
          .k-sort-icon {
            .k-sort-order {
              display: none;
            }
            .class-asc {
              transform: rotate(0deg);
            }
            .class-des {
              transform: rotate(180deg);
            }
          }
          .k-link {
            font-weight: 600;
            font-size: 13px;
            color: #475467;
            font-family: 'Inter';
          }
        }
      }
    }
  }
  .k-grid-header {
    table {
      tr th {
        &.k-grid-header-sticky {
          /* border-left: 1px solid #eaecf0 !important; */
          border-right: 1px solid #eaecf0 !important;
        }
        :first-child {
          /* border-left: 1px solid #eaecf0 !important; */
        }
        :last-child {
          /* border-right: 1px solid #eaecf0 !important; */
        }
      }
    }
  }
  /* Grid Alternate Color Design */
  .k-table-thead .k-table-row {
    background: #fafafa !important; //#e6f3fe
  }
  .k-grid-header-sticky {
    background: #fafafa !important;
  }
  .k-grid .k-table-row {
    background: white !important;
  }
  .k-table-alt-row {
    background: #f8f9fa !important;
  }
  .k-table-row .k-grid-content-sticky {
    background: white !important;
  }
  .k-table-alt-row .k-grid-content-sticky {
    background: #f8f9fa !important;
  }
  /*End of Grid Alternate Color Design */

  .k-grid-content tr td {
    &.k-grid-content-sticky {
      cursor: pointer;
      /* border-left: 1px solid #eaecf0 !important; */
      border-right: 1px solid #eaecf0 !important;
    }
    :first-child {
      /* border-left: 1px solid #eaecf0 !important; */
    }
    :last-child {
      /* border-right: 1px solid #eaecf0 !important; */
    }

    border-bottom: unset !important;
    background-color: unset !important;
  }
  .k-grid-content tr {
    .k-grid-content-sticky {
      :hover {
        background: #d8eafa !important;
      }
    }
    :hover {
      td {
        background: #eaecf0 !important;
      }
    }
  }
  .k-grid-container {
    ::-webkit-scrollbar {
      height: 5px;
      margin-top: 3px;
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      background: #dce4fe;
      border-radius: 5px;
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      width: 1px;
      background: #caced8;
      border-radius: 5px;
    }
    overflow: auto;
    .k-grid-content {
      overflow-y: unset !important;
      overflow: auto;
      height: calc(100vh - 245px);
      min-height: 270px;
      border: 1px solid #eaecf0;
      ::-webkit-scrollbar {
        height: 5px;
        margin-top: 3px;
        width: 5px;
      }

      ::-webkit-scrollbar-track {
        background: #dce4fe;
        border-radius: 5px;
        width: 5px;
      }

      ::-webkit-scrollbar-thumb {
        width: 1px;
        background: #caced8;
        border-radius: 5px;
      }
      tr {
        td {
          min-width: 25px;
          height: auto;
          overflow: visible;
          text-overflow: ellipsis;
          text-wrap: nowrap;
          &:nth-last-child(1) {
            padding: 0px 24px 0px 0px;
          }

          color: #475467;
          border-color: #e0e0e0;
          padding: 6px 8px 6px 8px !important;
          font-family: 'Inter';
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          background: #ffffff;
          border-width: 0 0 0 0;
          border-bottom: 1px solid #eaecf0;
          overflow: hidden;
        }
      }
      .k-grid-table-wrap {
        .k-grid-table {
          border-bottom: 1px solid #eaecf0;
        }
        .k-table {
          width: 100% !important;
        }
      }
    }
  }
`;

const CustomGridWrapper = styled.div`
  position: relative;
  @media (min-width: 100px) and (max-width: 784px) {
    overflow: auto;
    .k-grid {
      width: 550px;
    }
  }
`;
