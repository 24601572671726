import { ShowyedaLogo } from 'app/components/ShowyedaLogo';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import headerBg from './assets/img/bg/header.png';
import dashboardImg from './assets/img/icon/dash.png';
import sifImg from './assets/img/icon/awesome-trophy.png';
import { ProfileDropdown } from '../RiderLandingPage/components/PopupLogout';
import Cookies from 'js-cookie';
import PasswordResetEmail from 'app/components/PasswordResetEmail';
import { CoachRegistration, CoachService } from 'services/openapi';
import useApi from 'services/mavapi/useApi';

type Props = {};

const CoachLandingPage = (props: Props) => {
  const { error: apiError, handleRequest } = useApi();
  const { coachGetCoach } = CoachService;
  const [popupVisible, setPopupVisible] = React.useState<boolean>(false);
  const [passwordChangePage, setPasswordChangePage] = useState<number>(1);
  const currentName = Cookies.get('userName');
  const [getFirstLetter, setGetFirstLetter] = useState<string>();
  const [coachLoginResponse, setCoachLoginResponse] =
    useState<CoachRegistration>();

  const getName = () => {
    if (currentName) {
      const firstLetter = currentName.charAt(0);
      setGetFirstLetter(firstLetter);
    }
  };

  const navigate = useNavigate();
  const location = useLocation();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleDashboard = () => {
    navigate('/rider-landing-page/coach-dashboard');
    setIsHovered(false);
  };
  const handleSif = () => {
    navigate('/rider-landing-page/coach-insurance-application');
    setIsHovered(false);
  };
  const handleChangePassword = e => {
    setPopupVisible(true);
    setPasswordChangePage(3);
  };
  const getCoachdetails = (coachId: string) => {
    handleRequest(coachGetCoach(coachId))
      .then(res => {
        if (res) {
          setCoachLoginResponse(res);
        }
      })
      .catch(error => {});
  };
  useEffect(() => {
    getName();
    const coachUserId = Cookies.get('userId');
    console.log(coachUserId, 'coachUserId');
    getCoachdetails?.(coachUserId);
  }, []);

  const [showProfileDropDown, setShowProfileDropDown] = React.useState(false);
  const handleClick = () => {
    setShowProfileDropDown(!showProfileDropDown);
  };
  const menuRef = React.useRef(null);
  const imgRef = React.useRef(null);
  window.addEventListener('click', e => {
    if (e.target !== menuRef.current && e.target !== imgRef.current) {
      setShowProfileDropDown(false);
    }
  });
  // console.log('coachLoginResponseParsed', coachLoginResponseParsed);
  return (
    <MainWrapper>
      <NavWrapper
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={isHovered ? 'hoveredNav' : 'nav'}
      >
        <DashboardWrapper>
          {isHovered ? (
            <div
              onClick={handleDashboard}
              className={`dashboard-icon-hover ${
                location?.pathname?.includes('dashboard')
                  ? 'dashboard-hover-icon-clicked'
                  : ''
              }`}
            >
              <div>
                <img src={dashboardImg} />
              </div>
              <p>Dashboard</p>
            </div>
          ) : (
            <div
              onClick={handleDashboard}
              className={`dashboard-icon ${
                location?.pathname?.includes('dashboard')
                  ? 'dashboard-icon-clicked'
                  : ''
              }`}
            >
              <img src={dashboardImg} />
            </div>
          )}
        </DashboardWrapper>
        {coachLoginResponse?.hasSubmittedCertificateOfInsurance === false &&
        coachLoginResponse?.hasPaidForInsurance === false ? (
          <InsuranceWrapper>
            {isHovered ? (
              <div
                onClick={handleSif}
                className={`sif-icon-hover ${
                  location?.pathname?.includes('insurance')
                    ? 'sif-hover-icon-clicked'
                    : ''
                }`}
              >
                <div>
                  <img src={sifImg} />
                </div>
                <p>Liability Insurance</p>
              </div>
            ) : (
              <div
                onClick={handleSif}
                className={`sif-icon ${
                  location?.pathname?.includes('insurance')
                    ? 'sif-icon-clicked'
                    : ''
                }`}
              >
                <img src={sifImg} />
              </div>
            )}
          </InsuranceWrapper>
        ) : (
          ''
        )}
      </NavWrapper>
      <HeaderContentWrapper>
        <HeaderWrapper>
          <div className="header">
            <ShowyedaLogo className="logo" />
            <div className="logout-btn">
              <button onClick={handleClick} ref={imgRef}>
                {getFirstLetter}
              </button>
            </div>
          </div>
        </HeaderWrapper>
        {showProfileDropDown && (
          <ProfileDropdown
            setShowProfileDropDown={setShowProfileDropDown}
            menuRef={menuRef}
            onChangePassword={handleChangePassword}
            isCoachLogin={true}
          />
        )}
        <Outlet />
      </HeaderContentWrapper>
      <PasswordResetEmail
        show={popupVisible}
        setShow={setPopupVisible}
        setPage={setPasswordChangePage}
        page={passwordChangePage}
      />
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  background-color: #ffffff;
  display: flex;
  width: 100%;
  height: 100vh;
  position: relative;
  padding-left: 6%;
  z-index: 2;
  @media (min-width: 100px) and (max-width: 319px) {
    padding-left: 12%;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    padding-left: 10%;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-left: 9%;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-left: 10%;
  }
`;
const DashboardWrapper = styled.div``;
const InsuranceWrapper = styled.div``;
const NavWrapper = styled.div`
  height: 100vh;
  background: #f2f3f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0px;
  padding: 40px 10px;
  z-index: 2;
  width: 6%;
  gap: 20px;
  transition: width 0.3s;
  &.hoveredNav {
    align-items: start !important;
    width: 20%;
  }
  .dashboard-icon {
    padding: 10px 10px;
    filter: brightness(0.5);
    color: #ffffff;

    p {
      color: #8d8d8d;
      font-size: 30px;
      font-weight: bold;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
  .dashboard-icon-clicked {
    border: 1px solid blue;
    border-radius: 100%;
    background: #2961f4;
    filter: unset;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .dashboard-icon-hover {
    display: flex;
    align-items: center;
    gap: 10px;
    filter: brightness(0.5);
    cursor: pointer;
    p {
      color: #ffffff;
      font-size: 20px;
      font-weight: bold;
      margin: 0px;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
  .dashboard-hover-icon-clicked {
    border-radius: 25px;
    background: #2961f4;
    filter: unset;
    display: flex;
    align-items: center;
    background: #2961f4;
    gap: 10px;
    width: 100%;
    padding: 7px 30px 7px 10px;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .sif-icon {
    padding: 10px 10px;
    filter: brightness(0.5);
    color: #ffffff;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .sif-icon-clicked {
    border: 1px solid blue;
    border-radius: 100%;
    background: #2961f4;
    filter: unset;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .sif-icon-hover {
    display: flex;
    align-items: center;
    gap: 10px;
    filter: brightness(0.5);
    cursor: pointer;
    p {
      color: #ffffff;
      font-size: 20px;
      font-weight: bold;
      margin: 0px;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
  .sif-hover-icon-clicked {
    display: flex;
    align-items: center;
    gap: 10px;
    filter: unset;
    border: 1px solid blue;
    border-radius: 25px;
    background: #2961f4;
    width: 100%;
    padding: 7px 70px 7px 10px;
    img {
      width: 20px;
      height: 20px;
    }
  }
  @media (min-width: 100px) and (max-width: 319px) {
    width: 18%;
    .dashboard-hover-icon-clicked,
    .sif-hover-icon-clicked {
      padding: 2px 7px;
    }
    &.hoveredNav {
      width: 30%;
    }
    .sif-icon-hover p,
    .dashboard-icon-hover p {
      font-size: 13px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    /* width: 18% !important;
    .dashboard-icon {
      padding: 5px 5px !important;
      img {
        width: 12px !important;
        height: 12px !important;
      }
    }
    .sif-icon {
      padding: 5px 5px !important;
      img {
        width: 12px !important;
        height: 12px !important;
      }
    } */
    width: 12%;
    .dashboard-hover-icon-clicked,
    .sif-hover-icon-clicked {
      padding: 2px 7px;
    }
    &.hoveredNav {
      width: 33%;
    }
    .sif-icon-hover p,
    .dashboard-icon-hover p {
      font-size: 14px;
    }
  }
  @media (min-width: 576px) and (max-width: 991px) {
    width: 11%;
    .dashboard-hover-icon-clicked,
    .sif-hover-icon-clicked {
      padding: 7px 15px;
    }
    .sif-icon-hover p,
    .dashboard-icon-hover p {
      font-size: 16px;
    }
  }
  @media (min-width: 992px) and (max-width: 1365px) {
    width: 7%;
    .dashboard-hover-icon-clicked,
    .sif-hover-icon-clicked {
      padding: 2px 15px;
    }
    .sif-icon-hover p,
    .dashboard-icon-hover p {
      font-size: 17px;
    }
  }
`;
const HeaderContentWrapper = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
  ::-webkit-scrollbar {
    width: 1px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
`;
const HeaderWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  .logout-btn {
    display: flex;
    justify-content: end;
    padding: 20px;
    button {
      cursor: pointer;
      font-size: 20px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      border: none;
      box-shadow: none;
    }
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: end;
    /* background-image: url(${headerBg}); */
    background-color: #ffffff;
    height: 70px;
    width: 100%;
  }
  .logo {
    position: absolute;
    top: 5px;
    left: 20px;
    padding: 0px !important;
    img {
      width: 80px;
      width: auto;
    }
  }
  @media (min-width: 100px) and (max-width: 319px) {
  }
  @media (min-width: 320px) and (max-width: 575px) {
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .logout-btn {
      button {
        width: 35px;
        height: 35px;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .logout-btn {
      button {
        width: 35px;
        height: 35px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .logout-btn {
      button {
        width: 40px;
        height: 40px;
      }
      padding-right: 25px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .logout-btn {
      button {
        width: 45px;
        height: 45px;
      }
      padding-right: 25px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .logout-btn {
      button {
        width: 45px;
        height: 45px;
      }
      padding-right: 30px;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    .logout-btn {
      button {
        width: 50px;
        height: 50px;
      }
      padding-right: 35px;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    .logout-btn {
      button {
        width: 55px;
        height: 55px;
      }
      padding-right: 40px;
    }
  }
`;
export default CoachLandingPage;
