import * as React from 'react';
import styled, { css } from 'styled-components';
import {
  DatePicker,
  DatePickerChangeEvent,
} from '@progress/kendo-react-dateinputs';
import { DateFormatOptions } from '@progress/kendo-react-intl';
import SimpleReactValidator from 'simple-react-validator';
import CustomCalendar from './customCalendar';
import CalendarIcon from './Img/calender.svg';
import { FormSkeletonLoader } from '../FormSkeletonLoader';

export type DatePickerProps = {
  onChange?: (event: DatePickerChangeEvent) => void; // to handle onClick functions
  children?: React.ReactNode; // make the component able to receive children elements
  disabled?: boolean; // make the button disabled or not
  defaultValue?: Date;
  title?: string;
  dateFormat?: string | DateFormatOptions;
  customLabel?: 'primary';
  inputPaddingBlock?: 'primary';
  value?: Date | null;
  placeholder?: string;
  mindate?: Date;
  maxdate?: Date;
  validator?: SimpleReactValidator;
  validations?: string;
  fieldType?: 'type1' | 'type2' | 'type3';
  permissionId?: string;
  disableValidation?: boolean; // To Disable Validation Dynamically in loop
  className?: string;
  requestFormType?: boolean;
  hideStar?: boolean;
  isLoader?: boolean;
};
interface WrapperProps extends DatePickerProps {
  background?: 'primary' | 'secondary' | 'tertiary';
  border?: 'primary' | 'secondary' | 'none' | 'error';
}
export const AdminDatePicker = ({
  defaultValue,
  title,
  disabled,
  dateFormat,
  onChange,
  customLabel,
  inputPaddingBlock,
  maxdate,
  value,
  mindate,
  placeholder,
  validations,
  validator,
  fieldType,
  className,
  hideStar,
  requestFormType = false,
  isLoader,
}: DatePickerProps) => {
  const simpleValidator = React.useRef(validator);
  const dateValue = defaultValue ? defaultValue : undefined;
  const dateFormatRequired = dateFormat ? dateFormat : 'dd/MM/yyyy';
  const datePickerRef = React.useRef<HTMLDivElement>(null);
  const [render, setRender] = React.useState(true);
  const [pickerValue, setPickerValue] = React.useState<Date | null>();

  let background: 'primary' | 'secondary' | 'tertiary';
  let border: 'primary' | 'secondary' | 'none' | 'error';
  if (fieldType === 'type2') {
    if (disabled) {
      background = 'secondary';
      border = 'primary';
    } else {
      background = 'secondary';
      border = 'primary';
    }
  } else if (fieldType === 'type3') {
    background = 'tertiary';
    border = 'secondary';
  } else {
    if (disabled) {
      background = 'primary';
      border = 'primary';
    } else {
      background = 'primary';
      border = 'primary';
    }
  }
  const handleBlur = e => {
    if (e && value === undefined) {
      setPickerValue(new Date());
    } else {
      simpleValidator?.current?.showMessageFor(title ? title : 'field');
      setRender(!render);
    }
  };
  const handleClick = e => {
    const wrapperElement = e.target.closest('.custom-calendar-wrapper');
    if (wrapperElement.length !== 0) {
      handleBlur(e);
    }
  };
  React.useEffect(() => {
    setPickerValue(value ? value : dateValue ? dateValue : null);
  }, [value, dateValue, pickerValue]);

  return (
    <Wrapper
      background={background}
      border={!value ? 'error' : border}
      customLabel={customLabel}
      className={`custom-calendar-wrapper ${className}`}
    >
      {!isLoader ? (
        <>
          <DateWrapper
            className={`${requestFormType && 'requestForm'} cardViewStyle`}
          >
            <InputTextStyle tabIndex={0}>
              <LabelWrapper
                className={`${
                  requestFormType && 'requestForm'
                } date-picker-label`}
              >
                <div className="date-picker-title">
                  {title}
                  <span className="red-star">
                    {validations && !hideStar ? '*' : ''}
                  </span>
                </div>
              </LabelWrapper>
              <StyleDatePicker
                ref={datePickerRef}
                className={`datepicker-class  ${
                  value ? 'active' : 'non-active'
                } ${requestFormType && 'requestForm'} ${
                  disabled ? 'disabled' : ''
                }`}
                calendar={CustomCalendar}
                inputPaddingBlock={inputPaddingBlock}
                value={pickerValue}
                disabled={disabled}
                format={dateFormatRequired}
                weekNumber={true}
                onChange={onChange}
                onBlur={e => handleClick(e)}
                background={background}
                border={border}
                placeholder={placeholder}
                max={maxdate}
                min={mindate}
              ></StyleDatePicker>
            </InputTextStyle>
          </DateWrapper>
          {simpleValidator?.current?.message(
            title ? title : 'field',
            value?.toString(),
            validations ?? '',
          )}
        </>
      ) : (
        <FormSkeletonLoader textHeight={44} labelHeight={20} isLabel />
      )}
    </Wrapper>
  );
};
const inputBlock = {
  primary: css`
    margin: 10px 4px 0px 0px;
  `,
};
const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  min-height: 70px;
  &.requestForm {
    min-height: 44px;
  }
  .srv-validation-message {
    color: rgb(244 67 54);
    font-size: 12px;
    display: inline-block;
    font-family: 'Inter';
  }
  .datepicker-class {
    border: 1px solid #d0d5dd;
  }
  .red-star {
    color: red;
    font-weight: bold;
  }

  :has(input:disabled) {
    .red-star {
      display: none !important;
    }
  }
  .date-picker-title {
    font-size: 14px;
    font-family: 'Inter';
    color: #a3a3a3;
    line-height: 19.6px;
    font-weight: 500;
  }
  .non-active {
    .k-input-inner {
      color: #a3a3a3 !important;
      font-size: 15px;
      font-weight: 500;
      font-family: 'Inter';
      line-height: 18.15px;
    }
  }

  .k-input-solid {
    padding-left: 10px;
    align-items: center;
    height: 44px;
    background-color: #ffffff;
  }

  &.requestForm {
    margin: 0;
  }
`;
const StyleDatePicker = styled(DatePicker)<WrapperProps>`
  &.requestForm {
    input {
      font-weight: 500;
      font-size: 15px;
    }
  }
  width: 100%;
  border: none;
  font-size: 15px !important;
  border-radius: 6px;
  font-family: 'Inter';
  left: 0px;
  &:focus,
  &:focus-within {
    outline-style: none;
    box-shadow: none;
  }
  .k-button-solid-base ::before {
    content: '';
    width: 22px;
    height: 22px;
    background-image: url(${CalendarIcon});
    background-size: 16px;
    background-size: contain;
    position: absolute;
    top: 5px;
  }
  .k-svg-icon > svg {
    opacity: 0;
  }
  .k-button {
    position: relative;
  }
  .k-button-md.k-icon-button > .k-button-icon {
    width: 30px;
    height: 30px;
    z-index: 9;
  }
  .k-dateinput {
    .k-input-inner {
      padding: 0;
      font-family: 'Inter';
      font-size: 15px;
      font-weight: 500;
      line-height: 18.15px;
      ${props =>
        props.inputPaddingBlock && inputBlock[props.inputPaddingBlock]};
      color: #101828;
    }
  }
  .k-button-solid-base {
    margin: 0px 0px 0px 4px;
    background-color: #ffffff;
    border-inline-start-width: 0px;
  }
  &.requestForm {
    .k-button-solid-base {
      background-color: #ffffff;
    }
    background-color: #ffffff;
  }
  .k-i-calendar::before {
    content: '';
    background-image: url(${CalendarIcon});
    width: 22px;
    height: 25px;
  }
  &.disabled {
    .k-button-icon {
      display: none;
    }
    border: none;
    box-shadow: 0px 1px 6px 0px #f0eeee;
  }
  .k-svg-icon > svg {
    color: #0d6efd;
  }
  .k-disabled {
    input {
      color: #101828 !important;
    }
  }
  //Responsive
  @media (min-width: 100px) and (max-width: 319px) {
    .date-picker-title {
      font-size: 10px;
    }
    .k-dateinput {
      .k-input-inner {
        font-size: 11px;
      }
    }
    .non-active {
      .k-input-inner {
        font-size: 11px;
      }
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .date-picker-title {
      font-size: 11px;
    }
    .k-dateinput {
      .k-input-inner {
        font-size: 12px;
      }
    }
    .non-active {
      .k-input-inner {
        font-size: 12px;
      }
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .date-picker-title {
      font-size: 12px;
    }
    .k-dateinput {
      .k-input-inner {
        font-size: 13px;
      }
    }
    .non-active {
      .k-input-inner {
        font-size: 13px;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    .date-picker-title {
      font-size: 13px;
    }
    .k-dateinput {
      .k-input-inner {
        font-size: 14px;
      }
    }
    .non-active {
      .k-input-inner {
        font-size: 14px;
      }
    }
  }
`;
const InputTextStyle = styled.span`
  .k-disabled {
    opacity: 1;
    color: rgb(28 28 28);
  }
  .date-picker-label {
    label {
      font-weight: 500;
    }
    &.requestForm {
      margin-bottom: 0;
    }
  }
`;
const LabelWrapper = styled.div`
  label {
    color: #344054 !important;
  }
  margin-bottom: 5px;
`;
const DateWrapper = styled.div`
  .cardViewStyle {
    width: 100%;
    border: none;
    background: #ffffff;
  }
  &.requestForm {
    margin-bottom: 0;
  }
`;
