import { Alumni } from 'services/openapi';

export const defaultAlumniValue: Alumni = {
  isReturningRider: false,
  isTrial: false,
  backNumber: '',
  firstName: '',
  lastName: '',
  primaryPhone: '',
  email: '',
  street: '',
  city: '',
  state: { id: '', name: '', children: null },
  team: { id: '', name: '', children: null },
  zip: '',
  emergencyContactName: '',
  emergencyContactPhone: '',
  height: 0,
  weight: 0,
  divisionClass: { id: '', name: '', children: null },
  haveNotCompetedInClassLopeCanter: null,
  haveNotEarnedTwentyFivePointsInAssocs: null,
  haveFinishedTopTwoAsAERInNationals: null,
  haveFinishedTopThreeAaAER: null,
  haveCompetedTwoSeasonsAsAER: null,
  professionalAffiliations: [],
  usePhotoForPromotion: false,
};

export const defaultPhotoValue = {
  formData: {
    file: undefined,
  },
};
