/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CalculateRiderPlacement } from '../models/CalculateRiderPlacement';
import type { GeneralRider } from '../models/GeneralRider';
import type { LandingPage } from '../models/LandingPage';
import type { NonCompeting } from '../models/NonCompeting';
import type { RegisterRider } from '../models/RegisterRider';
import type { RiderDivisionResponse } from '../models/RiderDivisionResponse';
import type { sif } from '../models/sif';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RiderService {

    /**
     * Add new rider
     * @param requestBody 
     * @returns string Created
     * @throws ApiError
     */
    public static riderAddNewRider(
requestBody?: RegisterRider,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/rider',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to search riders
     * @param seasonId 
     * @param backNumber 
     * @param name 
     * @param categoryId 
     * @param divisionId 
     * @param teamId 
     * @returns GeneralRider Success
     * @throws ApiError
     */
    public static riderSearchRiders(
seasonId?: string,
backNumber?: string,
name?: string,
categoryId?: string,
divisionId?: string,
teamId?: string,
): CancelablePromise<Array<GeneralRider>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/rider/riders',
            query: {
                'seasonId': seasonId,
                'backNumber': backNumber,
                'name': name,
                'categoryId': categoryId,
                'divisionId': divisionId,
                'teamId': teamId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * API to get rider
     * @param riderId 
     * @param seasonId 
     * @returns RegisterRider Success
     * @throws ApiError
     */
    public static riderGetRider(
riderId?: string,
seasonId?: string,
): CancelablePromise<RegisterRider> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/rider/rider',
            query: {
                'riderId': riderId,
                'seasonId': seasonId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * API to Check back number
     * @param backNumber 
     * @returns boolean Success
     * @throws ApiError
     */
    public static riderCheckBackNumber(
backNumber?: string,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/rider/backNumber',
            query: {
                'backNumber': backNumber,
            },
        });
    }

    /**
     * API to Register Non Competing
     * @param requestBody 
     * @returns string Created
     * @throws ApiError
     */
    public static riderCreateNonCompeting(
requestBody?: NonCompeting,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/rider/nonCompeting',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to get Rider List
     * @returns GeneralRider Success
     * @throws ApiError
     */
    public static riderGetRiderList(): CancelablePromise<Array<GeneralRider>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/rider/RiderList',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * API to get Non-Competing List
     * @returns GeneralRider Success
     * @throws ApiError
     */
    public static riderGetNonCompetingList(): CancelablePromise<Array<GeneralRider>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/rider/NonCompetingList',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * API to get Back Number List
     * @returns string Success
     * @throws ApiError
     */
    public static riderGetBackNumberList(): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/rider/BackNumberList',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * API to create sif
     * @param requestBody 
     * @returns string Created
     * @throws ApiError
     */
    public static riderCreateSif(
requestBody?: sif,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/rider/SIF',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to get Landing Page
     * @param id 
     * @returns LandingPage Success
     * @throws ApiError
     */
    public static riderGetLandingPage(
id?: string,
): CancelablePromise<LandingPage> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/rider/landingPage',
            query: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to get sif with Rider ID and Season ID
     * @param riderId 
     * @param seasonId 
     * @returns sif Success
     * @throws ApiError
     */
    public static riderGetSif(
riderId: string,
seasonId: string,
): CancelablePromise<sif> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/rider/SIF/Rider/{riderId}/Season/{seasonId}',
            path: {
                'riderId': riderId,
                'seasonId': seasonId,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to get Landing page rider info
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static riderGetLandingPageRiderInfo(
id?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/rider/landingPageRiderInfo',
            query: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to get division and category from grade
 * and previous season info
     * @param requestBody 
     * @returns RiderDivisionResponse Success
     * @throws ApiError
     */
    public static riderGetRiderDivision(
requestBody?: CalculateRiderPlacement,
): CancelablePromise<RiderDivisionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/rider/getRiderDivision',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to Check back number
     * @param backNumber 
     * @returns boolean Success
     * @throws ApiError
     */
    public static riderCheckBackNumberExist(
backNumber?: string,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/rider/oldDashboard/backNumberCheck',
            query: {
                'backNumber': backNumber,
            },
        });
    }

    /**
     * API to upload Rider Photo
     * @param riderId 
     * @param formData 
     * @returns any Created
     * @throws ApiError
     */
    public static riderUploadRiderPhoto(
riderId: string,
formData?: {
file?: Blob;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/rider/{riderId}/photo',
            path: {
                'riderId': riderId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

}
