import React from 'react';
import styled from 'styled-components';
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridPageChangeEvent,
  GridRowClickEvent,
} from '@progress/kendo-react-grid';
import CustomPagination from './pagination';
import NoDataFound from '../NoDataFound';

export type CustomCellRender = React.ComponentType<GridCellProps>;

type Props = {
  data?: any;
  className?: string;
  noDataLabel?: string;
  showPagination?: boolean;
  loader?: boolean;
  pageSize?: number;
  skip?: number;
  totalCount?: number;
  onRowClick?: (event: GridRowClickEvent) => void;
  onPageChange?: (event: GridPageChangeEvent) => void;
  columns1?: {
    field: string;
    title: string;
    cell: (e: any) => JSX.Element;
    width: string;
  }[];
  columns2?: {
    field: string;
    title: string;
    cell: (e: any) => JSX.Element;
    width: string;
  }[];
  position?: {
    field: string;
    title: string;
    cell: (e: any) => JSX.Element;
    width: string;
  };
  isPosition?: boolean;
  colomn1title?: string;
  colomn2title?: string;
};

export const MultipleDataGrid = ({
  data,
  className,
  noDataLabel,
  showPagination,
  pageSize,
  skip,
  totalCount,
  loader,
  onRowClick,
  onPageChange,
  columns1,
  columns2,
  position,
  isPosition = false,
  colomn1title,
  colomn2title,
}: Props) => {
  const [gridOverflow, setgridOverflow] = React.useState<boolean>(false);

  const handleUpdateGridScroll = () => {
    let divGrid = document.querySelector('.k-grid-table') as HTMLElement;
    let divContent = document.querySelector('.k-grid-content') as HTMLElement;
    let gridContainer = divGrid?.getBoundingClientRect();
    let contentContainer = divContent?.getBoundingClientRect();
    if (contentContainer && gridContainer) {
      if (gridContainer?.height > contentContainer?.height) {
        setgridOverflow(true);
      } else {
        setgridOverflow(false);
      }
    }
  };

  React.useEffect(() => {
    // Call handleResize initially
    handleUpdateGridScroll();

    // Attach event listener
    window.addEventListener('resize', handleUpdateGridScroll);

    // Detach event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleUpdateGridScroll);
    };
  }, [data]);

  return (
    <CustomGridWrapper>
      {data?.length ? (
        <CustomGrid
          onRowClick={onRowClick}
          className={`${className ?? 'CustomGrid'} ${
            showPagination ? 'showPagination' : ''
          } ${gridOverflow ? 'is-scrollable' : ''}`}
          data={data ?? []}
          total={totalCount}
          skip={skip}
          take={pageSize}
          onPageChange={onPageChange}
          pager={e => (
            <>
              {showPagination ? (
                <CustomPagination props={e} totalCount={totalCount} />
              ) : (
                ''
              )}
            </>
          )}
          pageable={{
            info: false,
            previousNext: true,
          }}
        >
          <GridColumn title={colomn1title}>
            {columns1?.map((column, index) => {
              return <GridColumn key={index} {...column} />;
            })}
          </GridColumn>
          <GridColumn title={colomn2title}>
            {columns2?.map((column, index) => {
              return <GridColumn key={index} {...column} />;
            })}
          </GridColumn>
          {isPosition && <GridColumn {...position} />}
        </CustomGrid>
      ) : (
        !data?.length && !loader && <NoDataFound noDataLabel={noDataLabel} />
      )}
    </CustomGridWrapper>
  );
};

const CustomGrid = styled(Grid)`
  font-family: 'Inter';
  background: #ffffff;
  border: none;
  table {
    border-collapse: collapse;
  }
  tr[aria-rowindex='2'] {
    border-left: 1px solid #eaecf0;
  }
  tr {
    th {
      &:nth-last-child(1) {
        border-right: 0px;
      }
    }
  }
  .k-table-row[aria-rowindex='1'] {
    th[aria-colindex='1'] {
      border-left: 1px solid #eaecf0;
      border-top: 1px solid #eaecf0;
    }
    th[aria-colindex='5'] {
      border-left: 1px solid #eaecf0;
      border-top: 1px solid #eaecf0;
    }
    .k-header {
      .k-cell-inner {
        .k-link {
          justify-content: center;
        }
      }
    }
  }
  tr[aria-rowindex='2'] {
    th {
      border: 1px solid #eaecf0;
      border-width: 1px 1px 1px 1px;
    }
  }

  &.hide-border-bottom {
    .k-grid-container {
      tr {
        &:nth-last-child(1) {
          td {
            border-bottom: 0px;
          }
        }
      }
    }
  }

  &.showPagination {
    .k-grid-container {
      .k-grid-content {
        min-height: auto;
      }
    }
  }

  .k-grid-header {
    .k-grid-header-wrap {
      .k-table {
        width: 100% !important;
      }
    }
    padding: 0 0 0 0;
    .k-grid-header-wrap {
      margin-right: 0px;
    }
    tr {
      th {
        padding: 13px 8px 13px 8px;
        &:nth-last-child(1) {
          align-items: center;
          border-right: none;
        }
        .k-cell-inner {
          .k-link {
            font-weight: 600;
            font-size: 12px;
            color: #475467;
            font-family: 'Inter';
          }
        }
      }
    }
  }

  .k-grid-container {
    overflow: auto;
    .k-grid-content {
      overflow: auto;
      height: calc(100vh - 255px);
      min-height: 270px;
      ::-webkit-scrollbar {
        height: 5px;
        margin-top: 3px;
        width: 5px;
      }

      ::-webkit-scrollbar-track {
        background: #dce4fe;
        border-radius: 5px;
        width: 5px;
      }

      ::-webkit-scrollbar-thumb {
        width: 1px;
        background: #caced8;
        border-radius: 5px;
      }
      tr {
        td {
          min-width: 25px;
          height: auto;
          overflow: hidden;
          text-overflow: ellipsis;
          text-wrap: nowrap;
          border-width: 0;
          &:nth-last-child(1) {
            padding: 0px 24px 0px 0px;
          }

          color: #475467;
          border-color: #e0e0e0;
          padding: 12px 8px 12px 8px !important;
          font-family: 'Inter';
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
        }
      }
      .k-grid-table-wrap {
        .k-table {
          width: 100% !important;
        }
      }
    }
  }
`;

const CustomGridWrapper = styled.div`
  position: relative;
  @media (min-width: 100px) and (max-width: 784px) {
    overflow: auto;
    .k-grid {
      width: 550px;
    }
  }
`;
