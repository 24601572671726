import { useErrorHandlingSlice } from 'app/error/slice';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useApi from 'services/mavapi/useApi';
import {
  ManageSponsorService,
  Sponsor,
  SponsorWrapper,
} from 'services/openapi';

const useSponserApi = () => {
  const [loading, setLoading] = useState(false);
  const { manageSponsorGetSponsors, manageSponsorEditSponser } =
    ManageSponsorService;
  const [, setError] = useState<unknown | null>();
  const { error: apiError, handleRequest } = useApi();
  const { actions } = useErrorHandlingSlice();
  const dispatch = useDispatch();
  const [sponsersDataList, setSponsersDataList] = useState<SponsorWrapper>();
  const fetchSponsersList = (
    searchInput?: string,
    sortBy?: string,
    sortOrder?: number,
    pageNumber: number = 0,
    pageSize: number = 10,
  ) => {
    setLoading(true);
    let pageNumCall = pageNumber ? pageNumber / 10 + 1 : 1;
    handleRequest(
      manageSponsorGetSponsors(
        searchInput,
        sortBy,
        sortOrder,
        pageNumCall,
        pageSize,
      ),
    )
      .then(res => {
        if (res) {
          setSponsersDataList(res);
        }
        setLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };
  const updateSponsor = (
    requestBody: Sponsor,
    setChosenId,
    onSave,
    refreshFunction,
    pageSkip,
  ) => {
    setLoading(true);
    handleRequest(manageSponsorEditSponser(requestBody))
      .then(res => {
        refreshFunction(pageSkip);
        setLoading(false);
        dispatch(
          actions.dispatchToast({
            errorTxt: 'Sponsor Updated Successfully',
            show: true,
            errorType: 'success',
          }),
        );
        setChosenId('');
        onSave(false);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };
  return {
    fetchSponsersList,
    sponsersDataList,
    loading,
    updateSponsor,
  };
};
export default useSponserApi;
