import React, { useState } from 'react';
import styled from 'styled-components';
import SifCard from './component/Card';
import {
  Card,
  CardTitle,
  CardBody,
  CardActions,
} from '@progress/kendo-react-layout';
import listDot from './assets/img/icon/list-dot.png';
import '@fontsource/roboto';
import dot from './assets/img/icon/ellipse.png';
import SHButton from 'app/components/SHButton';
import { useNavigate } from 'react-router-dom';
import {
  ApiError,
  LandingPage,
  RegisterRider,
  RiderService,
  sif,
} from 'services/openapi';
import useApi from 'services/mavapi/useApi';
import Cookies from 'js-cookie';

const data = [
  {
    id: 1,
    text: 'Only points accumulated while an active member enrolled in the Incentive Program will count toward scholarship distribution.',
  },
  {
    id: 2,
    text: 'The rider must be a YEDA member their Senior year of High School to receive a distribution',
  },
  {
    id: 3,
    text: 'Incentive Program points do not accrue until receipt of application and payment has been processed.',
  },
];

type Props = {};

const SIF = (props: Props) => {
  const currentUserId = Cookies.get('userId');
  const { riderGetLandingPage } = RiderService;
  const { error: apiError, handleRequest } = useApi();
  const [, setError] = React.useState<ApiError | unknown>();
  const [sifDetails, setSifDetails] = React.useState<LandingPage>();
  const [enrolledDate, setEnrolledDate] = useState(false);

  function getSifDetails(id?: string) {
    handleRequest(riderGetLandingPage(id!))
      .then(res => {
        if (res) {
          Cookies.set('sifID', res?.id);
          Cookies.set('joinedDate', res?.sifJoinedDate);
          setSifDetails(res);
        }
      })
      .catch(() => setError(apiError));
  }
  React.useEffect(() => {
    getSifDetails(currentUserId);
    const joinedDateCookie = Cookies.get('hasSIFForSeason');
    setEnrolledDate(Boolean(joinedDateCookie));
  }, []);
  console.log(sifDetails, enrolledDate, 'sifDetails');
  const navigate = useNavigate();
  const handleEnroll = () => {
    if (sifDetails) {
      const userRole = Cookies.get('userRole');
      const joinedDate = new Date(sifDetails?.sifJoinedDate!);
      if (sifDetails.hasSIFForSeason === false) {
        if (userRole === 'EWD') {
          navigate('/rider-landing-page/sif/ewd-new-enrollment');
        } else if (userRole === 'RIDER') {
          navigate('/rider-landing-page/sif/rider-new-enrollment');
        }
      } else if (
        sifDetails.hasSIFForSeason === true &&
        sifDetails?.sifJoinedDate !== null
      ) {
        const oneYearAgo = new Date();
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
        if (userRole === 'EWD' && joinedDate < oneYearAgo) {
          navigate('/rider-landing-page/sif/ewd-sustaining-enrollment');
        } else if (userRole === 'RIDER' && joinedDate < oneYearAgo) {
          navigate('/rider-landing-page/sif/rider-sustaining-enrollment');
        }
      }
    }
  };
  const joinedDate = new Date(sifDetails?.sifJoinedDate!);
  const currentYear = new Date().getFullYear();
  const isCurrentYear = joinedDate.getFullYear() === currentYear;
  return (
    <MainWrapper>
      <ListCardWrapper>
        <LeftWrapper>
          <div className="text">
            <h1>Scholarship Incentive Fund (SIF)</h1>
            <p>
              YEDA is thrilled to continue the YEDA Scholarship Incentive Fund
              Program (SIF). Unlike other scholarship programs, YEDA recognizes
              an educational needs and desires far broader than those offered by
              traditional Secondary Education Programs.
            </p>
            <h3>How to enrol? </h3>
          </div>
          <div className="left-card">
            <CardWrapper>
              <div className="single-card">
                <SifCard
                  contentOne="Register as a YEDA member. "
                  contentTwo="Voila! You’re already a member"
                  titleType="hidden"
                />
                <span>01</span>
              </div>
              <div className="single-card">
                <SifCard
                  contentOne="Complete the YEDA 
          Scholarship Incentive Fund Application "
                  heading="*"
                  titleType="keepSpace"
                />
                <span>02</span>
              </div>
              <div className="single-card">
                <SifCard
                  contentOne="Register as a YEDA member. "
                  contentTwo="Voila! You’re already a member"
                  titleType="show"
                  heading="Payment"
                />
                <span>03</span>
              </div>
            </CardWrapper>
            <div className="hr-line">
              <img src={dot} alt="" className="first-dot" />
              <img src={dot} alt="" className="second-dot" />
              <img src={dot} alt="" className="third-dot" />
              <img src={dot} alt="" className="fourth-dot" />
            </div>
            {enrolledDate ? (
              <SHButton disabled={true}>Enrolled</SHButton>
            ) : (
              <SHButton onClick={handleEnroll}>Enrol Now</SHButton>
            )}
          </div>
        </LeftWrapper>
        <RightWrapper>
          <Card className="card-style">
            <StyledCardBody>
              <div>
                {data.map(value => (
                  <div>
                    <div>
                      <img src={listDot} />
                    </div>
                    <p>{value.text}</p>
                  </div>
                ))}
              </div>
              <p className="address-para">
                For inqueries, contact <br />
                Terri Rafeld <br />
                419-564-5401, trafeld@showyeda.com
              </p>
            </StyledCardBody>
          </Card>
        </RightWrapper>
      </ListCardWrapper>
      <ContentWrapper>
        <p>
          The YEDA Scholarship Incentive Fund was designed to provide a vehicle
          for competing members to grow their continuing education dollars,
          based on their own individual performance. Dollars collected from
          members are placed in the designated YEDA Scholarship Incentive Fund
          account and coupled with additional scholarship contributions. Funds
          will be awarded to graduating Seniors based on the cumulative number
          of points earned during the years in which the member was enrolled in
          the Incentive Fund. YEDA competitors may enroll in the YEDA
          Scholarship Incentive Fund by paying a first-time application fee of
          $150. Each year after the re-enrollment sustaining cost is $75. For
          EWD Members, first time enrollment is $100 and sustaining fee is $25 a
          year.{' '}
        </p>
        <h3>Rules for Scholarship Incentive Fund (SIF)</h3>
        <p>
          The purpose of the YEDA Scholarship Incentive Fund (SIF is to provide
          scholarship support to graduating seniors based on their competition
          performance over the period in which they were enrolled in the Fund.
          Points earned throughout the student/riders eligibility in YEDA will
          have a dollar value to be applied toward school or post education of
          choice.
        </p>
        <h5>How Do I Enrol? </h5>
        <p>
          Use the SIF enrollment form. The initial enrollment fee is one hundred
          and fifty dollars ($150 ) Each year after the re-enrollment sustaining
          cost is $75. The more points a student rider earns, the larger the
          portion of the funds he/she has the potential of earning. For EWD
          Members, first time enrollment is $100 and sustaining fee is $25 a
          year. A student rider may enroll in the program at any time however,
          scholarship points will not begin to accrue until after enrollment
          forms and payment is received or postmarked. For the remainder of the
          member’s competition eligibility, an annual sustaining fee of
          seventy-five dollars ($75) will be due prior to the rider’s points
          beginning to accrue for that competition season. The amount of the
          sustaining fee will be reviewed by the YEDA Scholarship Incentive
          Committee on a five (5) year schedule.
        </p>
        <h5>How Are Funds Created? </h5>
        <p>
          Enrollment and sustaining fees collected will remain with the
          graduating/future graduating class from which they were collected. In
          addition, a pot containing moneys collected for current year back
          numbers, current year YEDA show 50/50 raffles, current year SIF
          sponsorships and any other fund raising deemed appropriate will be
          split with 60% going to current year graduating class and 40% to be
          held back for the following year graduating class.
        </p>
        <h5>How Do I Earn SIF Dollars? </h5>
        <p>
          Points for SIF only build the years the rider is enrolled in SIF and
          only after the enrollment form and payment is received/post marked.
          Upon the rider’s graduation year and/or last year of YEDA eligibility,
          all points earned while a member will be tallied and compared to the
          other SIF members in the graduating class. The total SIF dollars
          allocated to current years graduating class SIF enrollees will be
          proportionate to the number of points earned while a SIF member.
          Please note that the rider must be a YEDA member and has paid the
          sustaining fee to the SIF by the time the funds are disbursed to be
          eligible for any SIF monies.{' '}
        </p>
        <h5>How do I cash out my SIF dollars? </h5>
        <p>
          When an enrolled student/rider is in their graduating year or final
          year of YEDA eligibility, they will automatically receive a check from
          SIF. The check may be paid to the student’s college, university, post
          educational institution of choice or directly to the student/rider{' '}
        </p>
        <h5>What Contingencies Does SIF Provide? </h5>
        <p>
          If a student/rider is unable to continue to compete due to illness,
          injury or change of location prior to finishing their final year; a
          rider may retain enrollment in SIF by purchasing an YEDA
          Non-Competitive Membership and the SIF sustaining fee. All points
          earned while an enrollee of SIF will be paid as stated under the “How
          do I Earn SIF Dollars” heading.{' '}
        </p>
        <h5>How Do I Track SIF? </h5>
        <p>
          On the YEDA Website there is a points spreadsheet. The location is
          under Scholarships, then Enrolled SIF Members and Points.
          http://showyeda.com/scholarship/sif-points/ This page has been
          organized by graduating class and then by team.{' '}
        </p>
        <h5>What If SIF Is Replaced or Canceled? </h5>
        <p>
          In the unlikely event, the SIF program is replaced or canceled between
          competition seasons, refunds will occur in the following manner:{' '}
        </p>
        <ul>
          <li>
            Students enrolled the season prior to SIF cancelation will be
            reimbursed their enrollment and yearly sustaining fees collected to
            date.
          </li>
          <li>
            Any and all funds remaining in the SIF 60/40 pot will be distributed
            to previous season’s enrollees. Those dollars will be prorated based
            on the number of points earned while a SIF member.
          </li>
          <li>
            Any funds shortfall for the enrollment and sustaining fees will be
            paid from the YEDA general fund.
          </li>
        </ul>
        <h5>
          In the unlikely event, the SIF program is replaced or canceled
          mid-season:{' '}
        </h5>
        <ul>
          <li>
            Students enrolled in SIF will be reimbursed their enrollment and
            yearly sustaining fees collected to date.
          </li>
          <li>
            Any and all funds remaining in the SIF 60/40 pot will be distributed
            to the SIF members of the graduating class as outlined under the
            heading “How Do I Earn SIF Dollars?”.
          </li>
          <li>
            {' '}
            Any funds shortfall for the enrollment and sustaining fees will be
            paid from the YEDA general fund.
          </li>
        </ul>
      </ContentWrapper>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  padding: 20px 40px;
  min-height: 100vh;
  width: 100%;
  background-color: #ffffff;
`;

const ListCardWrapper = styled.div`
  @media (min-width: 100px) and (max-width: 768px) {
    display: block;
  }
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  gap: 3%;
  .top-wrapper {
  }

  .button-wrapper .button {
    margin-top: 20px;
    background: #2961f4 !important;
    border-radius: 16px !important;
    width: 100%;
    height: auto;
    font-size: 18px;
    color: #ffffff;
    font-weight: 600;
  }
`;
const LeftWrapper = styled.div`
  @media (min-width: 100px) and (max-width: 768px) {
    width: 100%;
  }
  width: 65%;
  height: 100%;
  .text {
    width: 100%;
    height: 50%;
  }
  .left-card {
    width: 100%;
    height: 50%;
  }
  .hr-line {
    margin-top: 20px;
    border-top: 1px dashed #d9d9d9;
    position: relative;
    width: 100%;
    .first-dot {
      position: absolute;
      left: 0px;
      top: -8px;
    }
    .second-dot {
      position: absolute;
      left: 32%;
      top: -8px;
    }
    .third-dot {
      position: absolute;
      right: 31%;
      top: -8px;
    }
    .fourth-dot {
      position: absolute;
      right: 0%;
      top: -8px;
    }
  }
`;
const CardWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;
  .single-card {
    position: relative;
    width: 33%;
    span {
      color: #cfdbfc;
      font-size: 50px;
      font-weight: 700;
      position: absolute;
      top: -35px;
      right: -14px;
    }
  }
`;
const RightWrapper = styled.div`
  @media (min-width: 100px) and (max-width: 768px) {
    width: 100%;
    padding: 12px 0px;
  }
  width: 35%;
  height: 100%;
  .card-style {
    border-radius: 15px;
    width: 100%;
    height: 80%;
    border: 1px solid #d9f0ff;
    background: #ffe991;
  }
`;
const StyledCardBody = styled(CardBody)`
  padding: 45px 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  > div {
    border-bottom: 2px dotted #ffffff;
    > div {
      display: flex;
      gap: 10px;
    }
  }
  p {
    color: #848484;
    font-family: 'Roboto';
    font-weight: 600;
  }
  .address-para {
    padding-top: 10px;
    text-align: center;
    color: #cc5050;
    font-weight: 600;
  }
`;
const ContentWrapper = styled.div`
  h3 {
    padding-top: 30px;
  }
`;

export default SIF;
