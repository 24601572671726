import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import { managePageState } from './types';

export const initialState: managePageState = {
  pageLoader: false,
  isAccessToken: '',
};

const slice = createSlice({
  name: 'managePage',
  initialState,
  reducers: {
    setPageLoader(state, action: PayloadAction<boolean>) {
      state.pageLoader = action.payload;
    },
    setIsAccessToken(state, action: PayloadAction<string | undefined>) {
      state.isAccessToken = action.payload;
    },
  },
});

export const { actions: managePageActions } = slice;

export const useManagePageSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
