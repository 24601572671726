import React from 'react';
import styled from 'styled-components';
import Icon from './assets/img/icon.png';
import SHButton from '../SHButton';

type Props = {
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onCancel?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onUpload?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onRemoveFile?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  uploadBtnDisabled?: boolean;
  chooseFileBtnDisabled?: boolean;
  uploadFile?: File | null | undefined;
  accept?: string | undefined;
  onDragChange?: React.DragEventHandler<HTMLDivElement> | undefined;
  isNotImageValidation?: boolean;
};

const CustomDragAndDropFiles = ({
  onUpload,
  onCancel,
  onChange,
  onRemoveFile,
  className,
  uploadBtnDisabled,
  chooseFileBtnDisabled,
  uploadFile,
  accept,
  onDragChange,
  isNotImageValidation,
}: Props) => {
  // drag state
  const [dragActive, setDragActive] = React.useState(false);
  //Files
  const [files, setfiles] = React.useState<File | null>(null);
  // ref
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };
  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (!onDragChange) {
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        setfiles(e.dataTransfer.files ? e.dataTransfer.files[0] : null);
      }
    }
    onDragChange?.(e);
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (!onChange) {
      if (e.target.files && e.target.files[0]) {
        setfiles(e.target.files && e.target.files[0]);
      }
    }
    onChange?.(e);
  };

  const onButtonClick = () => {
    inputRef?.current?.click();
  };
  const handleCancelClick = e => {
    inputRef.current!.value = '';
    setfiles(null);
    onCancel?.(e);
  };
  const handleUploadClick = e => {
    inputRef?.current?.click();
  };
  const handleFileCancel = e => {
    inputRef.current!.value = '';
    setfiles(null);
    onRemoveFile?.(e);
  };
  return (
    <DragAndDropWrapper className={className}>
      <div id="form-file-upload" onDragEnter={handleDrag} onDrop={handleDrop}>
        <input
          ref={inputRef}
          type="file"
          id="input-file-upload"
          multiple={false}
          onChange={handleChange}
          accept={accept}
        />
        <label
          id="label-file-upload"
          className={dragActive ? 'drag-active' : ''}
          onClick={e => e.preventDefault()}
        >
          <InnerWrapper>
            <SubWrapper>
              <img src={Icon} alt="" className="upload-logo" />
              <div>
                <p>
                  Drag & drop your{' '}
                  {accept === '.jpg, .jpeg, .png' ? 'Image' : 'files'} here or
                  <button
                    className="upload-button"
                    onClick={onButtonClick}
                    disabled={chooseFileBtnDisabled}
                  >
                    choose the{' '}
                    {accept === '.jpg, .jpeg, .png' ? 'Image' : 'file'}
                  </button>
                </p>
              </div>
            </SubWrapper>
            <BottomWrapper className="BottomWrapper">
              {uploadFile || files ? (
                <SelectedFileBlock className="SelectedFileBlock">
                  <SelectedExtensionNameWrapper className="SelectedExtensionNameWrapper">
                    <span className="extension-block">
                      {uploadFile?.name.split('.').pop() ??
                        files?.name?.split('.').pop()}
                    </span>
                    <span className="filename-block">
                      {uploadFile?.name ?? files?.name}
                    </span>
                  </SelectedExtensionNameWrapper>
                  <span
                    className="k-icon k-i-close fileCancel"
                    onClick={handleFileCancel}
                  ></span>
                </SelectedFileBlock>
              ) : (
                ''
              )}
              <ButtonWrapper>
                <SHButton buttonType={'secondary'} onClick={handleCancelClick}>
                  Cancel
                </SHButton>
                <SHButton
                  className="uploadBtn"
                  buttonType={'primary'}
                  onClick={handleUploadClick}
                  disabled={uploadBtnDisabled}
                >
                  Upload
                </SHButton>
              </ButtonWrapper>
            </BottomWrapper>
            {isNotImageValidation ? (
              <span className="srv-validation-message">
                Please upload a valid image file.
              </span>
            ) : (
              ''
            )}
          </InnerWrapper>
        </label>
        {dragActive && (
          <div
            id="drag-file-element"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </div>
    </DragAndDropWrapper>
  );
};

export default CustomDragAndDropFiles;
const DragAndDropWrapper = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  .k-dropzone-inner {
    border: none;
    color: #9597a6;
    background-color: transparent;
  }
  #form-file-upload {
    height: 16rem;
    width: 28rem;
    max-width: 100%;
    text-align: center;
    position: relative;
  }

  #input-file-upload {
    display: none;
  }

  #label-file-upload {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    border: 1px dashed rgb(103 98 96);
    background-color: #27292d;
  }

  #label-file-upload.drag-active {
    background-color: #ffffff;
  }

  .upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 15px;
    border: none;
    font-family: 'arimo';
    background-color: transparent;
    color: #ff9900;
    text-decoration: underline;
    margin: 0 5px;
    :where([aria-disabled='true' i], [disabled]) {
      cursor: not-allowed !important;
    }
  }

  #drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
`;
const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 0 20px;
  .srv-validation-message {
    color: #f44336;
    font-size: 12px;
    display: inline-block;
    padding-bottom: 10px;
  }
`;
const SubWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid #707070;
  width: 100%;
  margin: 0 20px;
  position: relative;
  padding: 30px 0 0 0;
`;
const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px 0;
  width: 100%;
  .button-wrapper {
    margin: 0px 5px;
  }
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0 0 0;
`;
const SelectedExtensionNameWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const SelectedFileBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  padding: 6px 30px;
  background: #2c2f34;
  border-radius: 10px;
  span.fileCancel {
    cursor: pointer;
    filter: invert();
  }
  span.extension-block {
    padding: 10px 10px;
    background: #616365;
    color: #8b8b8b;
    text-transform: uppercase;
    border-radius: 10px;
  }
  span.filename-block {
    padding: 0 0 0 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #ffffff;
    max-width: 253px;
    @media (min-width: 100px) and (max-width: 319px) {
      max-width: 80px;
    }
    @media (min-width: 320px) and (max-width: 575px) {
      max-width: 80px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      max-width: 83px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      max-width: 206px;
    }
  }
`;
