import { useErrorHandlingSlice } from 'app/error/slice';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useApi from 'services/mavapi/useApi';
import { CommonService, CurrentSeason, SeasonRequest } from 'services/openapi';

const useSeasonApi = () => {
  const [loading, setLoading] = useState(false);
  const { commonGetCurrentSeason, commonUpdateSeason } = CommonService;
  const [, setError] = useState<unknown | null>();
  const { error: apiError, handleRequest } = useApi();
  const [serviceDataList, setServiceDataList] = useState<CurrentSeason>();
  const { actions } = useErrorHandlingSlice();
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState<boolean>(true);
  const currentSeasonInDropDown = () => {
    setLoading(true);
    handleRequest(commonGetCurrentSeason())
      .then(res => {
        if (res) {
          setServiceDataList(res);
        }
        setLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };

  const updateSeasonInDropDown = (requestBody?: SeasonRequest | undefined) => {
    setLoading(true);
    handleRequest(commonUpdateSeason(requestBody))
      .then(res => {
        if (res) {
          setIsEdit(true);
          currentSeasonInDropDown();
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Season Updated Successfully',
              show: true,
              errorType: 'success',
            }),
          );
        }
        setLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };
  return {
    currentSeasonInDropDown,
    updateSeasonInDropDown,
    serviceDataList,
    loading,
    setIsEdit,
    isEdit,
  };
};
export default useSeasonApi;
