/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CloverMakePayment } from '../models/CloverMakePayment';
import type { GetPayableAmountDetails } from '../models/GetPayableAmountDetails';
import type { PayableAmountDetails } from '../models/PayableAmountDetails';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PaymentService {

    /**
     * API to make clover payment
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static paymentMakeCloverPayment(
requestBody?: CloverMakePayment,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payment/clover',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to get payable amount
     * @param requestBody 
     * @returns PayableAmountDetails Success
     * @throws ApiError
     */
    public static paymentGetPayableAmount(
requestBody?: GetPayableAmountDetails,
): CancelablePromise<PayableAmountDetails> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payment/getPayableAmount',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

}
