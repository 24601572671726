import { BackNextButton } from 'app/components/BackNextButton';
import Loader from 'app/components/Loader';
import { TextField } from 'app/components/TextField';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import SHdropdown, { DropDownModel } from 'app/components/SHdropdown';
import TryitEWDRiderInformation from './components/TryitEWDRiderInformation';
import TryitEWDRiderTable from './components/TryitEWDRiderTable';

type Props = {};
const dummyData: DropDownModel[] = [
  { id: 1, text: 'Option 1' },
  { id: 2, text: 'Option 2' },
  { id: 3, text: 'Option 3' },
  { id: 4, text: 'Option 4' },
  { id: 5, text: 'Option 5' },
];

const TryitEWDReturningMember = (props: Props) => {
  const navigate = useNavigate();
  const [detailsData, setDetailsData] = useState({
    backNumber: '',
  });
  const [showLoader, setShowLoader] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const handleChange = event => {
    const { name, value } = event.target;
    console.log('changed', value);
    setDetailsData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleClick = () => {
    setShowLoader(true);
    setTimeout(() => {
      setShowLoader(false); // Set showLoader to false after 5 seconds
      setShowTable(true);
    }, 2600);
  };
  const handleNxtBack = () => {
    setShowTable(false);
  };
  const handleNxt = () => {
    navigate('/tryit-ewd-membership-form/returning-member/details');
  };
  const handlePersonalNxtBack = () => {
    setShowInfo(!showInfo);
    navigate(-1);
  };
  return (
    <MainWrapper>
      {showInfo ? (
        <TryitEWDRiderInformation onClickBackPersonal={handlePersonalNxtBack} />
      ) : (
        <>
          {showLoader ? (
            <LoaderWrapper>
              <Loader />
              <p>Please wait while we retrieve your information</p>
            </LoaderWrapper>
          ) : (
            <>
              <p className="para-one">
                A membership registration forms for Equestrian with Disabilities
                must be accompanied by a Diagnosis and Adaptive Equipment Form.
              </p>
              <p className="para-two">
                <i>
                  Payment of $80 fee must be accompanied with this form ($10 of
                  the $80 will go towards the SIF as the YEDA Back # fee)
                </i>
              </p>
              <OverflowWrapper>
                <TextFieldGrid>
                  <StyledTextField
                    onChange={handleChange}
                    value={detailsData.backNumber}
                    label="Back Number"
                    type="text"
                    name="backNumber"
                  />
                  <StyledTextField
                    onChange={handleChange}
                    value=""
                    label=""
                    type="text"
                    style={{ visibility: 'hidden' }}
                  />
                  <DropdownWrapper>
                    <SHdropdown className="drop-down" label="Class" />
                  </DropdownWrapper>
                  <DropdownWrapper>
                    <SHdropdown className="drop-down" label="Team" />
                  </DropdownWrapper>
                </TextFieldGrid>
                <SearchButtonWrapper>
                  <button onClick={handleClick}>Search</button>
                </SearchButtonWrapper>
                {showTable ? (
                  <TableButtonWrapper>
                    <TryitEWDRiderTable />
                    <ButtonWrapper className="bg-color-change">
                      <BackNextButton
                        classNameBack="btn-back"
                        classNameNxt="btn-nxt"
                        onClickNxt={handleNxt}
                        onClickBack={handleNxtBack}
                        back="Cancel"
                        next="Next"
                      />
                    </ButtonWrapper>
                  </TableButtonWrapper>
                ) : (
                  ''
                )}
              </OverflowWrapper>
            </>
          )}
        </>
      )}
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 145px);
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 20px 0px;
  p {
    margin: 0px;
  }
  .para-one {
    color: #9597a6;
    padding: 20px 0px 10px;
  }
  .para-two {
    color: #ff9900;
    border-bottom: 1px solid #585858;
    padding: 0px 0px 10px;
  }
  label {
    color: #9597a6;
    font-family: 'Roboto';
    font-size: 14px;
  }
`;
const LoaderWrapper = styled.div`
  position: relative;
  // top: 245px;
  // left: 260px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    position: absolute;
    top: 60%;
  }
`;
const OverflowWrapper = styled.div`
  overflow: auto;
  height: 100%;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
`;
const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 30px;
  gap: 10px;

  @media (min-width: 992px) and (max-width: 1199px) {
    gap: 6px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    gap: 8px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    gap: 10px;
  }
`;
const StyledTextField = styled(TextField)`
  flex: 0 0 calc(50% - 20px);
  margin: 0px 0px 20px !important;
`;

const DropdownWrapper = styled.div`
  p {
    margin: 0px;
    font-family: 'Roboto';
    font-size: 14px;
  }
  .drop-down {
    margin: 0px;
    width: 326px !important;
    height: 35px;
    color: #9597a6;
  }
`;

const TableButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // overflow: auto;
  // border-radius: 10px;
`;
const SearchButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;
  button {
    width: 100px;
    height: 30px;
    margin: 0px 10px;
    border-radius: 20px;
    background: #5b5a5a;
    border: 1px solid #8b8b8b;
  }
`;
const ButtonWrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: -70px;
    left: 0px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
  }
`;

export default TryitEWDReturningMember;
