import { CommonPageWrapper, HeadingWrapper } from 'admin/components/wrappers';
import CustomBreadCrumb from 'app/components/CustomBreadCrumb';
import { PageMainLabel } from 'app/components/SHLabel';
import BackButton from 'app/components/BackButton';
import styled from 'styled-components';
import AdminCustomButton from 'app/components/AdminCustomButton';
import { CoachInfoFormBlock } from './components/coachInfoForm';
import useCoachApi from 'admin/hooks/useCoachApi';
import { useParams } from 'react-router-dom';
import React from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { Coach } from 'services/openapi';
import { breadCrumpitems, defaultCoachValue } from './staticDatas';
import { CommonLoader } from 'app/components/CommonLoader';
import useCommonLookUpDatas from 'hooks/useCommonLookupData';
import { useNavigateToRoute } from 'utils/common';

export const CoachCreateEditPage = () => {
  const redirect = useNavigateToRoute();
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const { action, id: coachId } = useParams();
  const {
    updateOrCreateCoach,
    getCoachProfileViewData,
    coachViewData,
    loading,
    coachDataLoading,
  } = useCoachApi();
  const {
    getAllLookupData,
    lookUps,
    loading: lookUpLoading,
  } = useCommonLookUpDatas();
  const [imgSrc, setImgSrc] = React.useState<string | null>(null);
  const [imgSrcInsurance, setImgSrcInsurance] = React.useState<string | null>(
    null,
  );
  const [imageFile, setImageFile] = React.useState<Blob>();
  const [imageFileInsurence, setImageFileInsurence] = React.useState<Blob>();
  const [coachPayload, setCoachPayload] =
    React.useState<Coach>(defaultCoachValue);
  const [coachPayloadDefault, setCoachPayloadDefault] =
    React.useState<Coach>(defaultCoachValue);
  const [valid, setValid] = React.useState<boolean>(false);
  const [triggor, setTriggor] = React.useState(false);
  const [isAfiliationSelected, setIsAfiliationSelected] = React.useState(false);
  const [showInsuranceFileError, setShowInsuranceFileError] =
    React.useState(false);
  const handleSaveClick = () => {
    let isFormValid = simpleValidator.current.allValid();
    let fileValidation: boolean =
      imgSrcInsurance !== null
        ? true
        : coachPayload.hasVerifiedCertificateOfInsurance && !imageFileInsurence
        ? false
        : true;

    if (
      (valid || isFormValid) &&
      coachPayload?.affiliations?.[0].id !== '' &&
      fileValidation
    ) {
      updateOrCreateCoach(coachPayload, imageFile, imageFileInsurence);
    } else {
      if (coachPayload?.affiliations?.[0].id === '') {
        setIsAfiliationSelected(true);
      }
      if (!fileValidation) {
        setShowInsuranceFileError(true);
      }
      simpleValidator.current.showMessages();
      setTriggor(!triggor);
    }
  };
  const handleCancelClick = () => {
    if (action === 'create') {
      setCoachPayload(defaultCoachValue);
      setImageFileInsurence(undefined);
      setImgSrcInsurance(null);
      setImgSrc(null);
    } else if (action === 'update') {
      setCoachPayload(coachPayloadDefault);
      setImgSrc(coachViewData?.photo!);
      setImgSrcInsurance(coachViewData?.insuranceCertificate!);
    }
    setIsAfiliationSelected(false);
    setShowInsuranceFileError(false);
    setTriggor(!triggor);
    simpleValidator.current.hideMessages();
    simpleValidator.current.visibleFields = [];
  };

  const handleBackClick = () => {
    if (action === 'update') {
      redirect(`/admin/coaches/view/${coachId}`);
    } else {
      redirect(`/admin/coaches`);
    }
  };
  React.useEffect(() => {
    if (action === 'update' && coachViewData) {
      const updatedObject = {
        ...coachViewData,
        isInterestedInReferralProgram:
          coachViewData.isInterestedInReferalProgram,
        isInterestedInReferalProgram: undefined,
        hasVerifiedCertificateOfInsurance: coachViewData.hasInsurance,
        hasInsurance: undefined,
      };
      setCoachPayload(updatedObject as Coach);
      setCoachPayloadDefault(updatedObject as Coach);
      setImgSrc(coachViewData.photo!);
      setImgSrcInsurance(coachViewData.insuranceCertificate!);
    }
  }, [coachViewData]);
  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(formValid);
  }, [coachPayload]);
  React.useEffect(() => {
    getCoachProfileViewData(coachId ?? '');
  }, [coachId]);
  return (
    <>
      <CommonPageWrapper>
        {loading && <CommonLoader loaderType="main" />}
        <HeadingWrapper>
          <PageMainLabel>Coach Information</PageMainLabel>
          <CustomBreadCrumb preData={breadCrumpitems} />
        </HeadingWrapper>
        <ContentWrapper>
          <BtnWrapper>
            <div>
              <BackButton onClick={handleBackClick} />
            </div>
            <div className="btn-action">
              <AdminCustomButton
                buttonType="cancel"
                onClick={handleCancelClick}
              >
                Cancel
              </AdminCustomButton>
              <AdminCustomButton buttonType="save" onClick={handleSaveClick}>
                Save
              </AdminCustomButton>
            </div>
          </BtnWrapper>
          <CoachInfoFormBlock
            action={action}
            simpleValidator={simpleValidator}
            coachPayload={coachPayload}
            setCoachPayload={setCoachPayload}
            isAfiliationSelected={isAfiliationSelected}
            setImgSrc={setImgSrc}
            imgSrc={imgSrc}
            setImageFile={setImageFile}
            setImageFileInsurence={setImageFileInsurence}
            imageFileInsurence={imageFileInsurence}
            imgSrcInsurance={imgSrcInsurance}
            setImgSrcInsurance={setImgSrcInsurance}
            setShowInsuranceFileError={setShowInsuranceFileError}
            showInsuranceFileError={showInsuranceFileError}
            setIsAfiliationSelected={setIsAfiliationSelected}
            coachDataLoading={coachDataLoading || lookUpLoading}
            getAllLookupData={getAllLookupData}
            lookUps={lookUps}
          />
        </ContentWrapper>
      </CommonPageWrapper>
    </>
  );
};
const ContentWrapper = styled.div`
  width: 100%;
  padding: 15px 30px 15px 0;
  @media (min-width: 100px) and (max-width: 1200px) {
    padding: 0px;
  }
`;
const BtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #eaecf0;
  .btn-action {
    display: flex;
    align-items: center;
    gap: 15px;
  }
`;
