import styled from 'styled-components/macro';
import '@fontsource/roboto';
import '@fontsource/arimo';

const CellPositionLabel = styled.label`
  font-family: 'Inter' !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  color: #475467;
`;

const CoachTableMainLabel = styled.label`
  font-family: 'Inter' !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #1e1e1e;
`;
export { CellPositionLabel, CoachTableMainLabel };
