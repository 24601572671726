import { RegisterRider } from 'services/openapi';

const nextYear = new Date().getFullYear() + 1;
export const defaultRiderData: RegisterRider = {
  id: '',
  isReturningRider: true,
  backNumber: '',
  firstName: '',
  lastName: '',
  season: {
    id: '',
    name: '',
    children: null,
  },
  currentGrade: {
    id: '',
    name: '',
    children: null,
  },
  expectedGraduationYear: nextYear,
  dateOfBirth: '',
  height: 0,
  weight: 0,
  primaryPhone: '',
  email: '',
  street: '',
  city: '',
  zip: '',
  hasStayedInSameDivisionForTwoYears: false,
  achievedTopTwoInIndividualNationals: false,
  achievedTopThreeInDivision: false,
  divisionCompliesWithSectionThirty: false,
  state: {
    id: '',
    name: '',
    children: null,
  },
  parent: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    street: '',
    city: '',
    state: {
      id: '',
      name: '',
      children: null,
    },
    zip: '',
  },
  school: {
    schoolName: '',
    email: '',
    street: '',
    city: '',
    state: {
      id: '',
      name: '',
      children: null,
    },
    zip: '',
  },
  team: {
    id: '',
    name: '',
    children: null,
  },
  category: {
    id: '',
    name: '',
    children: null,
  },
  divisionClassOne: {
    id: '',
    name: '',
    children: null,
  },
  divisionClassTwo: undefined,
  isTrial: false,
  usePhotoForPromotion: false,
  riderIdOld: null,
  riderAssociations: [],
  placementData: {
    pwhQuestionOne: false,
    owhQuestionOne: false,
    ewhQuestionOne: false,
    rwhQuestionOne: false,
    swhQuestionOne: false,
    swhQuestionTwo: false,
    dwhQuestionOne: false,
    drQuestionOne: false,
    drQuestionTwo: false,
  },
};

export type radioModel = {
  pwhQuestionOne: boolean | null;
  owhQuestionOne: boolean | null;
  ewhQuestionOne: boolean | null;
  rwhQuestionOne: boolean | null;
  swhQuestionOne: boolean | null;
  swhQuestionTwo: boolean | null;
  dwhQuestionOne: boolean | null;
  drQuestionOne: boolean | null;
  drQuestionTwo: boolean | null;
  usePhotoForPromotion?: boolean | null;
};

export const items = [
  { label: 'Personal Information', number: '1' },
  { label: 'Parent', number: '2' },
  { label: 'School', number: '3' },
  { label: 'Placement Form', number: '4' },
  { label: 'Previous Enrollment', number: '5' },
  { label: 'Enrolment Data', number: '6' },
  { label: 'Membership Form', number: '7' },
  { label: 'Competition Records', number: '8' },
];

export const teamValueDefault = {
  id: '',
  name: '',
  code: '',
  children: null,
  otherName: '',
  isAdminLive: null,
};
