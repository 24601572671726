import { GridCellProps } from '@progress/kendo-react-grid';
import StatusChip from 'app/components/StatusChip';

type Props = {
  className?: string;
  e: GridCellProps;
  onClick?: (e) => void;
};

const RiderStatus = ({ className, e, onClick }: Props) => {
  const status = e.dataItem.status; // Access status directly as a string
  return status?.name ? (
    <td className={className ?? ''}>
      {status?.name === 'Active' ? ( // Check if status is 'Activee'
        <StatusChip className="" StatusType={'active'} text={status?.name} />
      ) : (
        <StatusChip
          className=""
          StatusType={'inactive-team'}
          text={status?.name}
        />
      )}
    </td>
  ) : (
    <td></td>
  );
};

export default RiderStatus;
