import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { t } from 'i18next';
interface LoaderProps {
  rowContent?: number;
  isIndividualLoader?: boolean;
  width?: number;
  height?: number;
  headingWidth?: number;
}
export const TableSkeletonLoader = ({
  rowContent,
  isIndividualLoader,
  width,
  height,
  headingWidth,
}: LoaderProps) => {
  const tableContentRows = rowContent ? rowContent : 4;
  return (
    <>
      <Wrapper>
        <TableWrapper>
          {isIndividualLoader ? (
            <div>
              <Skeleton width={50} height={height} />
              <Skeleton width={'100%'} height={'1px'} />
            </div>
          ) : (
            <div className="heading">
              <Skeleton width={headingWidth} height={height} />
              <Skeleton width={headingWidth} height={height} />
              <Skeleton width={headingWidth} height={height} />
              <Skeleton width={headingWidth} height={height} />
              <Skeleton width={headingWidth} height={height} />
              <Skeleton width={headingWidth} height={height} />
            </div>
          )}
          {Array.from({ length: tableContentRows }).map((_, index) => (
            <>
              <div className="table-content" key={index}>
                <Skeleton width={width} height={height} />
                <Skeleton width={width} height={height} />
                <Skeleton width={width} height={height} />
                <Skeleton width={width} height={height} />
                <Skeleton width={width} height={height} />
                <Skeleton width={width} height={height} />
              </div>
              <div className="under-line">
                <Skeleton width={'100%'} height={'1px'} />
              </div>
            </>
          ))}
        </TableWrapper>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  background-color: rgb(255 255 255);
  padding: 10px;
  border-radius: 10px;
  height: auto;
`;

export const TableWrapper = styled.div`
  @media (max-width: 1200px) {
    .table-content :last-child {
      display: none !important;
    }

    .heading :last-child {
      display: none !important;
    }
  }
  .react-loading-skeleton {
    --base-color: #b7bdbe2b;
    --highlight-color: #f5f5f58f;
  }
  display: grid;
  width: 100%;
  min-width: 100%;
  height: 100%;
  margin: 10px 0 10px 0;
  background: rgb(255 255 255);
  background-color: rgb(255 255 255);
  color: rgb(28 28 28);
  margin-top: 15px;
  position: relative;
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    width: 100%;
    margin-bottom: 25px;
  }
  .table-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    width: 100%;
    margin-top: 10px;
  }
`;
