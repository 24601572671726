import { CardSubWrapper, CardWrapper } from 'admin/components/wrappers';
import CustomDataGrid from 'app/components/CustomDataGrid/Index';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import ClassCell from 'app/components/CustomDataGrid/Cells/ClassCell';
import SponserCell from 'app/components/CustomDataGrid/Cells/SponserCell';
import EditButtonCell from 'app/components/CustomDataGrid/Cells/EditButtonCell';
import { Sponsor, SponsorList, SponsorWrapper } from 'services/openapi';
import useSponserApi from 'admin/hooks/useSponserApi';
import { GridSortChangeEvent } from '@progress/kendo-react-grid';
import { SortDescriptor } from '@progress/kendo-data-query';

type Props = {
  setPageSkip: React.Dispatch<React.SetStateAction<number | undefined>>;
  pageSkip: number | undefined;
  sponsersDataList: SponsorWrapper | undefined;
  refreshFunction: (page: number) => void;
  onListCancelclick: (e: any) => void;
  setChosenId: Dispatch<SetStateAction<string>>;
  chosenId: string;
  setSponserDataArray: Dispatch<SetStateAction<SponsorList[] | undefined>>;
  sponserDataArray: SponsorList[] | undefined;
  setSponserDataArrayClone: Dispatch<
    SetStateAction<SponsorWrapper | undefined>
  >;
  sortField: string | undefined;
  sortOrder: number | undefined;
  setSortField: React.Dispatch<React.SetStateAction<string>>;
  setSortOrder: React.Dispatch<React.SetStateAction<number>>;
  debounceRiderSearch: string;
  fetchSponsersList: (
    searchInput?: string,
    sortBy?: string,
    sortOrder?: number,
    pageNumber?: number,
    pageSize?: number,
  ) => void;
};

const SponsersTable = ({
  setPageSkip,
  pageSkip,
  sponsersDataList,
  refreshFunction,
  onListCancelclick,
  setChosenId,
  chosenId,
  setSponserDataArray,
  sponserDataArray,
  setSponserDataArrayClone,
  sortField,
  sortOrder,
  setSortField,
  setSortOrder,
  fetchSponsersList,
  debounceRiderSearch,
}: Props) => {
  const { updateSponsor } = useSponserApi();
  const initialSort: Array<SortDescriptor> = [
    { field: 'sponserName', dir: 'asc' },
  ];
  const [sort, setSort] = React.useState(initialSort);
  const [sponserName, setSponserName] = useState<string>('');
  const [sponsorPayload, setSponsorPayload] = React.useState<Sponsor>();
  const [onSave, setOnSave] = React.useState<boolean>(false);
  const handleEditClick = e => {
    setChosenId(e.dataItem.sponsorId);
  };
  const onSaveClick = () => {
    if (sponsorPayload)
      updateSponsor(
        sponsorPayload,
        setChosenId,
        onSave,
        refreshFunction,
        pageSkip,
      );
  };

  const handleSponsorName = (e, event) => {
    setOnSave(true);
    let mappedArray = sponserDataArray?.map(item => {
      if (item.sponsorId === chosenId) {
        item.sponsorName = event.target.value;
      }
      return item;
    });
    if (mappedArray) {
      setSponserDataArray(mappedArray);
      let filteredSponsor = sponserDataArray?.filter(
        item => item.sponsorId === e.dataItem.sponsorId,
      )[0];
      setSponsorPayload({
        id: filteredSponsor?.sponsorId,
        name: filteredSponsor?.sponsorName,
        divisionRider: filteredSponsor?.class,
      });
    }
    setSponserDataArray(mappedArray);
  };
  const SponsersColumn = [
    {
      field: 'class',
      title: 'Class',
      cell: e => <ClassCell e={e} />,
      width: '300',
      sortable: false,
    },
    {
      field: 'name',
      title: 'Sponsors',
      cell: e => (
        <SponserCell
          e={e}
          setSponserName={setSponserName}
          sponserName={sponserName}
          setSponserDataArray={setSponserDataArray}
          sponserDataArray={sponserDataArray}
          chosenId={chosenId}
          handleSponsorName={handleSponsorName}
        />
      ),
      width: '300',
      sortable: true,
    },
    {
      field: '',
      title: '',
      cell: e => (
        <EditButtonCell
          sponsorPayload={sponsorPayload}
          e={e}
          onClick={() => handleEditClick(e)}
          chosenId={chosenId}
          onCancelClick={onListCancelclick}
          onSaveClick={onSaveClick}
          onSave={onSave}
        />
      ),
      width: '150',
      sortable: false,
    },
  ];
  const handlePageChange = event => {
    setPageSkip(event.page.skip);
    fetchSponsersList(
      debounceRiderSearch,
      sortField,
      sortOrder,
      event.page.skip,
      10,
    );
  };

  const handleSortChange = e => {
    let sortOrderValue = e.dir === 'asc' ? 0 : 1;
    setSortOrder(sortOrderValue);
    setSortField(e.field);
    fetchSponsersList(
      debounceRiderSearch,
      e.field,
      sortOrderValue,
      pageSkip,
      10,
    );
  };

  useEffect(() => {
    setSponserDataArrayClone(_ => {
      const tmp = !sponsersDataList
        ? undefined
        : JSON.parse(JSON.stringify(sponsersDataList));
      return tmp;
    });
    setSponserDataArray(sponsersDataList?.sponsors ?? []);
  }, [sponsersDataList]);

  return (
    <>
      <CardWrapper style={{ paddingTop: '15px' }}>
        <CardSubWrapper className="d-flex"></CardSubWrapper>
        <GridWrapper>
          <CustomDataGrid
            showPagination
            data={sponserDataArray ?? []}
            columns={SponsersColumn}
            pageSize={10}
            totalCount={sponsersDataList?.pageDetails?.count}
            onPageChange={event => {
              handlePageChange(event);
            }}
            skip={pageSkip}
            sortable={{
              allowUnsort: false,
              mode: 'single',
            }}
            sort={sort}
            onSortChange={(e: GridSortChangeEvent) => {
              let sortvalue = e.sort[0];
              setSort(e.sort);
              handleSortChange(sortvalue);
            }}
          />
        </GridWrapper>
      </CardWrapper>
    </>
  );
};

export default SponsersTable;

const GridWrapper = styled.div`
  .k-grid-content tr {
    &:hover {
      td {
        background: #f0f0f0 !important;
      }
    }
  }
  .k-grid-content tr td {
    cursor: pointer;
    .label {
      display: none;
    }
    input {
      font-family: 'Inter';
      font-weight: 500;
      font-size: 12px;
      line-height: 14.52px;
      max-width: 100%;
    }
    div {
      font-family: 'Inter';
      font-weight: 500;
      font-size: 12px;
      line-height: 14.52px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .custom-cell-style {
      width: fit-content;
      max-width: 100%;
      cursor: pointer;
    }
  }
  .k-grid-content tr td.team-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }
  input:disabled {
    opacity: 1;
    cursor: pointer;
    padding: 0px !important;
    border-width: 0px;
    border-color: transparent !important;
    background-color: transparent !important;
    max-width: unset;
    width: fit-content;
  }
`;
