import { CommonPageWrapper, HeadingWrapper } from 'admin/components/wrappers';
import CustomBreadCrumb from 'app/components/CustomBreadCrumb';
import { PageMainLabel } from 'app/components/SHLabel';
import BackButton from 'app/components/BackButton';
import styled from 'styled-components';
import AdminCustomButton from 'app/components/AdminCustomButton';
import SimpleReactValidator from 'simple-react-validator';
import React, { useEffect, useRef, useState } from 'react';
import { UserInfoFormBlock } from './UserInfoForm';
import { GeneralLookup, User, UserTypes } from 'services/openapi';
import useUserApi from 'admin/hooks/useUserApi';
import { useParams } from 'react-router-dom';
import { DropDownModel } from 'app/components/SHdropdown';
import { CommonLoader } from 'app/components/CommonLoader';
import { getLookupArray, useNavigateToRoute } from 'utils/common';
import {
  breadCrumpitemsUser,
  userDefaultData,
  userTypeCustomData,
} from '../../staticDefaultData';
import useCommonLookUpDatas from 'hooks/useCommonLookupData';

export const UserCreateEditPage = () => {
  const redirect = useNavigateToRoute();
  const simpleValidator = useRef(new SimpleReactValidator());
  const { action, id: userId } = useParams();
  const {
    createUser,
    setImageUploadBlobPayload,
    imageUploadBlobPayload,
    EditUser,
    userViewData,
    getUserProfileViewData,
    loading,
    userViewLoading,
  } = useUserApi();
  const [userPayload, setUserPayload] = useState<User>(userDefaultData);
  const [userPayloadDefault, setUserPayloadDefault] =
    useState<User>(userDefaultData);
  const [teamDefault, setTeamDefault] = useState<GeneralLookup>();
  const [teamDefaultSaved, setTeamDefaultSaved] = useState<GeneralLookup>();
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [triggor, setTriggor] = useState(false);
  const [triggorTwo, setTriggorTwo] = useState(false);
  const [userPreDataList, setUserPreDataList] = useState<DropDownModel[]>([]);
  const [, setForceUpdate] = useState(false);
  const {
    getAllLookupData,
    lookUps,
    loading: lookUpLoading,
  } = useCommonLookUpDatas();

  const userTypeEditData = userViewData?.userTypes?.split(',');
  const filteredUserTypeCustomData =
    userTypeEditData?.length === 0
      ? []
      : userTypeCustomData?.filter(item => {
          const trimmedText = item.text.replace(/\s+/g, '');
          return userTypeEditData?.some(
            editItem => editItem.replace(/\s+/g, '') === trimmedText,
          );
        });
  const handleSaveClick = () => {
    const isValid = simpleValidator.current.allValid();
    const isTeamUser = userTypeCustomData.find(
      user => user.text === 'Team User',
    );
    const showMessagesForFields = (fields: string[]): boolean => {
      let messagesShown = false;
      fields.forEach(field => {
        simpleValidator.current.showMessageFor(field);
        if (!simpleValidator.current.fieldValid(field)) {
          messagesShown = true;
        }
      });
      // Force re-render to show error messages
      setForceUpdate(prevState => !prevState);
      return messagesShown;
    };
    const messagesShown = showMessagesForFields([
      'Address',
      'City',
      'Phone Number',
      'State',
      'User Email',
      'User First Name',
      'User Last Name',
      'User Name',
      'User Type',
      'Zip',
    ]);
    if (isTeamUser && isValid) {
      if (action === 'create') {
        createUser(userPayload, (imageUploadBlobPayload as Blob) ?? undefined);
      } else if (action === 'update') {
        EditUser(userPayload, (imageUploadBlobPayload as Blob) ?? undefined);
      }
    } else if (!messagesShown) {
      if (action === 'create') {
        createUser(userPayload, (imageUploadBlobPayload as Blob) ?? undefined);
      } else if (action === 'update') {
        EditUser(userPayload, (imageUploadBlobPayload as Blob) ?? undefined);
      }
    } else {
      simpleValidator.current.showMessages();
    }
    setTriggor(!triggor);
  };
  const handleCancelClick = () => {
    if (action === 'create') {
      setUserPayload(userDefaultData);
      setImgSrc(null);
      setImageUploadBlobPayload(null);
      setTeamDefault(undefined);
      setUserPreDataList([]);
    } else if (action === 'update' && userId) {
      setImageUploadBlobPayload(null);
      setUserPreDataList(filteredUserTypeCustomData);
      setImgSrc(userViewData?.photo!);
      setUserPayload(userPayloadDefault);
      setTeamDefault(teamDefaultSaved);
    }
    setTriggorTwo(!triggorTwo);
    simpleValidator.current.hideMessages();
    simpleValidator.current.visibleFields = [];
  };

  const handleBackClick = () => {
    if (action === 'update') {
      redirect(`/admin/users/view/${userId}`);
    } else {
      redirect(`/admin/users`);
    }
  };

  React.useEffect(() => {
    if (action === 'update' && userId && userViewData) {
      setUserPayload({
        ...userViewData,
        userTypes: userTypeEditData as UserTypes[],
      });
      setUserPayloadDefault(userViewData as User);
      setUserPreDataList(filteredUserTypeCustomData);
      setImgSrc(userViewData.photo ?? '');
    }
  }, [userViewData]);
  React.useEffect(() => {
    if (action === 'update') {
      getUserProfileViewData(userId ?? '');
    }
  }, [userId, action]);
  React.useEffect(() => {
    getAllLookupData('STATE,SEASON,ACTIVESTATUS,PROFESSIONALAFFILIATIONS,TEAM');
  }, []);
  React.useEffect(() => {
    const isTeamUser = filteredUserTypeCustomData.some(item => {
      return item.text.toLowerCase() === 'team user';
    });
    if (userViewData && lookUps) {
      const teamList = getLookupArray('TEAM', lookUps);
      const teamSelected = teamList?.find(
        obj => obj.id === userViewData?.team?.id,
      );
      if (isTeamUser) {
        setTeamDefault(teamSelected);
        setTeamDefaultSaved(teamSelected);
      }
    }
  }, [lookUps]);
  return (
    <>
      <CommonPageWrapper>
        {loading && <CommonLoader loaderType="main" />}
        <HeadingWrapper>
          <PageMainLabel>User Information</PageMainLabel>
          <CustomBreadCrumb preData={breadCrumpitemsUser} />
        </HeadingWrapper>
        <ContentWrapper>
          <BtnWrapper>
            <div>
              <BackButton onClick={handleBackClick} />
            </div>
            <div className="btn-action">
              <AdminCustomButton
                buttonType="cancel"
                onClick={handleCancelClick}
              >
                Cancel
              </AdminCustomButton>
              <AdminCustomButton buttonType="save" onClick={handleSaveClick}>
                Save
              </AdminCustomButton>
            </div>
          </BtnWrapper>
          <UserInfoFormBlock
            simpleValidator={simpleValidator}
            setUserPayload={setUserPayload}
            userPayload={userPayload}
            setImageUploadBlobPayload={setImageUploadBlobPayload}
            setTeamDefault={setTeamDefault}
            teamDefault={teamDefault}
            setImgSrc={setImgSrc}
            imgSrc={imgSrc}
            setUserPreDataList={setUserPreDataList}
            userPreDataList={userPreDataList}
            loading={userViewLoading || lookUpLoading}
            lookUps={lookUps}
          />
        </ContentWrapper>
      </CommonPageWrapper>
    </>
  );
};
const ContentWrapper = styled.div`
  width: 100%;
  padding: 15px 30px 15px 0;
`;
const BtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 15px 5px;
  border-bottom: 1px solid #eaecf0;
  .btn-action {
    display: flex;
    align-items: center;
    gap: 15px;
  }
`;
