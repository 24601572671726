import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface LoaderProps {
  isProfileImageLoader?: boolean;
  isEditImageLoader?: boolean;
  isHeaderLoader?: boolean;
  isTextLoader?: boolean;
  imageWidth?: number;
  imageHeight?: number;
  headerWidth?: number;
  headerHeight?: number;
  editWidth?: number;
  editHeight?: number;
  textWidth?: number;
  textHeight?: number;
  lineContent?: number;
}

export const CardSkeletonLoader = ({
  isProfileImageLoader,
  isEditImageLoader,
  isHeaderLoader,
  isTextLoader,
  imageWidth,
  imageHeight,
  editWidth,
  editHeight,
  headerWidth,
  headerHeight,
  textWidth,
  textHeight,
  lineContent,
}: LoaderProps) => {
  const lineContentRows = lineContent ? lineContent : 3;
  return (
    <CardWrapper>
      {isProfileImageLoader && (
        <ImageWrapper>
          <Skeleton
            width={imageWidth ? imageWidth : 130}
            height={imageHeight ? imageHeight : 130}
            className="profile-img"
          />
          {isEditImageLoader && (
            <Skeleton
              width={editWidth ? editWidth : 34.37}
              height={editHeight ? editHeight : 34.37}
              className="camera-icon"
            />
          )}
        </ImageWrapper>
      )}
      <TextWrapper>
        {isHeaderLoader && (
          <Skeleton
            width={headerWidth ? headerWidth : '10%'}
            height={headerHeight ? headerHeight : 25}
            className="heading"
          />
        )}
        {isTextLoader &&
          Array.from({ length: lineContentRows }).map((_, index) => (
            <Skeleton
              width={textWidth ? textWidth : '100%'}
              height={textHeight ? textHeight : 20}
              className="para-header"
            />
          ))}
      </TextWrapper>
    </CardWrapper>
  );
};

const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: top;
  .react-loading-skeleton {
    --base-color: #b7bdbe2b;
    --highlight-color: #f5f5f58f;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background-size: cover;
  min-width: 130px;
  .camera-icon {
    position: absolute;
    right: 0px;
    bottom: 0px;
    z-index: 2;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .profile-img {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
`;
const TextWrapper = styled.div`
  flex: 1;
  padding-left: 40px;
  padding-top: 20px;
  .heading {
    width: 100%;
    margin-bottom: 10px;
  }
  .para-header {
    width: 100%;
  }
`;
