import { SHToolTip } from 'app/components/SHToolTip';
import styled from 'styled-components';

type Props = {
  value: string;
};

export const CommonCell = ({ value }: Props) => {
  return (
    <StyledTd style={{ alignItems: 'center' }}>
      <SHToolTip position="top">
        <div title={value}>{value}</div>
      </SHToolTip>
    </StyledTd>
  );
};
const StyledTd = styled.td`
  div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
