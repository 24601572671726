import { Tooltip } from '@progress/kendo-react-tooltip';
import styled from 'styled-components';

type Props = {
  children?: React.ReactNode;
  title?: string;
  position?: 'auto' | 'right' | 'left' | 'top' | 'bottom';
  className?: string;
  style?: React.CSSProperties | undefined;
  anchorElement?: string;
};

export const SHToolTip = ({
  children,
  position,
  title,
  style,
  className,
  anchorElement,
}: Props) => {
  return (
    <TooltipWrapper
      position={position ? position : 'top'}
      className={className}
    >
      <Wrapper title={title} style={style} className="tooltip-style">
        {children}
      </Wrapper>
    </TooltipWrapper>
  );
};
const TooltipWrapper = styled(Tooltip)`
  & > div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .k-tooltip-content {
    font-family: 'inter' !important;
    font-size: 13px !important;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    line-height: 1.4;
    margin: 5px 10px;
    max-width: 500px;
  }
`;

export const Wrapper = styled.div``;
