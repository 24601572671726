export const breadCrumpitems = [
  {
    id: '',
    text: 'Showyeda admin',
    disabled: true,
  },
  {
    id: '/admin/settings',
    text: 'settings',
  },
];

export const SeasonSettingsbreadCrumpitems = [
  {
    id: '',
    text: 'Showyeda admin',
    disabled: true,
  },
  {
    id: '/admin/settings',
    text: 'settings',
  },
  {
    id: '',
    text: 'Season Settings',
    disabled: true,
  },
];