import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import '@fontsource/roboto';
import { InfoLabel } from 'app/components/SHLabel';
import SHdropdown from 'app/components/SHdropdown';
import { TextField } from 'app/components/TextField';
import { BackNextButton } from 'app/components/BackNextButton';
import { CoachRegistration, GeneralLookup } from 'services/openapi';
import SimpleReactValidator from 'simple-react-validator';
import useApi from 'services/mavapi/useApi';
import Cookies from 'js-cookie';
import { digitRemovedValue } from 'utils/common';

type Props = {
  handleNextForm?: (event) => void;
  handleBackForm?: (event) => void;
  coachRegisterPayload: CoachRegistration;
  setCoachRegisterPayload: React.Dispatch<
    React.SetStateAction<CoachRegistration>
  >;
  teamData?: GeneralLookup[];
  stateData?: GeneralLookup[];
  coachDetails: GeneralLookup[];
  isReturningValue: boolean | null;
  handleGetReturningCoachDetails: (coachId: string) => void;
  setCoachDefault: React.Dispatch<React.SetStateAction<GeneralLookup>>;
  coachDefault: GeneralLookup;
};

export const PersonalInfoForm = ({
  handleNextForm,
  handleBackForm,
  coachRegisterPayload,
  setCoachRegisterPayload,
  teamData,
  stateData,
  coachDetails,
  isReturningValue,
  handleGetReturningCoachDetails,
  setCoachDefault,
  coachDefault,
}: Props) => {
  const { error: apiError, handleRequest } = useApi();
  const [valid, setValid] = useState<boolean>(false);
  //Simple validator Ref
  const simpleValidator = React.useRef(new SimpleReactValidator());

  useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(formValid);
  }, [coachRegisterPayload]);

  const handleAssistantCoachChange = data => {
    if (data) {
      setCoachDefault(data);
      const coachRes = JSON.stringify(data);
      Cookies.set('coachDropdownData', coachRes);

      setCoachRegisterPayload(prevData => ({
        ...prevData,
        coachIdOld: data.id,
      }));
      handleGetReturningCoachDetails(data.id);
    }
  };
  const handleZipChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 9 && /^[a-zA-Z0-9]*$/.test(inputValue)) {
      setCoachRegisterPayload({
        ...coachRegisterPayload,
        zip: inputValue,
      });
    }
  };
  const handlePhChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
      setCoachRegisterPayload({
        ...coachRegisterPayload,
        primaryPhone: inputValue,
      });
    }
  };
  const handleEmergencyPhChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
      setCoachRegisterPayload({
        ...coachRegisterPayload,
        emergencyContactPhone: inputValue,
      });
    }
  };
  const handleTeamChange = data => {
    if (coachRegisterPayload) {
      setCoachRegisterPayload({
        ...coachRegisterPayload,
        team: data,
      });
    }
    setCoachRegisterPayload({
      ...coachRegisterPayload,
      team: data,
    });
  };
  return (
    <MainWrapper id="PersonalInfoFormMainWrapper">
      <p className="Team-Heading">
        {isReturningValue ? 'Returning Member' : 'New Member'}
      </p>
      <SubMainWrapper className="SubMainWrapper">
        <BorderWrapper className="inner-BorderWrapper">
          <InfoLabelWrapper id="InfoLabelWrapper">
            <div>
              <InfoLabel>
                Payment of $60 fee must be accompanied with this form
              </InfoLabel>
            </div>
          </InfoLabelWrapper>
          <>
            <TextFieldGrid>
              {isReturningValue ? (
                <>
                  <TextFieldWrapper>
                    <SHdropdown
                      data={coachDetails}
                      onDataChange={handleAssistantCoachChange}
                      defaultValue={coachDefault}
                      className="drop-down"
                      label="Coach Name"
                      validations={'required'}
                      validator={simpleValidator.current}
                    />
                  </TextFieldWrapper>
                  <TextFieldWrapper>
                    <StyledTextField
                      onChange={e =>
                        setCoachRegisterPayload({
                          ...coachRegisterPayload,
                          firstName: e.target.value,
                        })
                      }
                      value={coachRegisterPayload?.firstName!}
                      label="First Name"
                      type="text"
                      name="firstName"
                      validations={'required|max:30'}
                      validator={simpleValidator.current}
                    />
                  </TextFieldWrapper>
                  <TextFieldWrapper>
                    <StyledTextField
                      onChange={e =>
                        setCoachRegisterPayload({
                          ...coachRegisterPayload,
                          lastName: e.target.value,
                        })
                      }
                      value={coachRegisterPayload?.lastName!}
                      label="Last Name"
                      type="text"
                      name="lastName"
                      validations={'required|max:30'}
                      validator={simpleValidator.current}
                    />
                  </TextFieldWrapper>
                </>
              ) : (
                <>
                  <TextFieldWrapper>
                    <StyledTextField
                      onChange={e =>
                        setCoachRegisterPayload({
                          ...coachRegisterPayload,
                          firstName: e.target.value,
                        })
                      }
                      value={coachRegisterPayload?.firstName}
                      label="First Name"
                      type="text"
                      name="firstName"
                      validations={'required|max:30'}
                      validator={simpleValidator.current}
                    />
                  </TextFieldWrapper>
                  <TextFieldWrapper>
                    <StyledTextField
                      onChange={e =>
                        setCoachRegisterPayload({
                          ...coachRegisterPayload!,
                          lastName: e?.target?.value!,
                        })
                      }
                      value={coachRegisterPayload?.lastName}
                      label="Last Name"
                      type="text"
                      name="lastName"
                      validations={'required|max:30'}
                      validator={simpleValidator.current}
                    />
                  </TextFieldWrapper>
                </>
              )}
              <TextFieldWrapper>
                <SHdropdown
                  onDataChange={handleTeamChange}
                  data={teamData}
                  label="Team"
                  defaultValue={coachRegisterPayload.team}
                  validations={'required'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
            </TextFieldGrid>
            <MainAddressWrapper>
              <div className="horizontal-line">
                <span>Coach Address</span>
              </div>
              <TextFieldGrid>
                <TextFieldWrapper>
                  <StyledTextField
                    onChange={e =>
                      setCoachRegisterPayload({
                        ...coachRegisterPayload,
                        street: e.target.value,
                      })
                    }
                    value={coachRegisterPayload?.street!}
                    label=" Address"
                    type="text"
                    name="Address"
                    validations={'required'}
                    validator={simpleValidator.current}
                  />
                </TextFieldWrapper>
                <TextFieldWrapper>
                  <StyledTextField
                    onChange={e =>
                      setCoachRegisterPayload({
                        ...coachRegisterPayload,
                        city: e.target.value,
                      })
                    }
                    value={coachRegisterPayload?.city!}
                    label=" City"
                    type="text"
                    name=" City"
                    validations={'required'}
                    validator={simpleValidator.current}
                  />
                </TextFieldWrapper>
                <TextFieldWrapper>
                  <SHdropdown
                    defaultValue={coachRegisterPayload.state}
                    data={stateData}
                    onDataChange={data => {
                      setCoachRegisterPayload({
                        ...coachRegisterPayload,
                        state: data,
                      });
                    }}
                    label="State"
                    validations={'required'}
                    validator={simpleValidator.current}
                  />
                </TextFieldWrapper>
                <TextFieldWrapper>
                  <StyledTextField
                    onChange={handleZipChange}
                    value={coachRegisterPayload?.zip?.replaceAll(
                      /[^\w\d]/g,
                      '',
                    )}
                    label="Zip"
                    type="text"
                    name="Zip"
                    validations={'required|alpha_num|min:5|max:9'}
                    validator={simpleValidator.current}
                  />
                </TextFieldWrapper>
                <TextFieldWrapper>
                  <StyledTextField
                    onChange={handlePhChange}
                    value={coachRegisterPayload?.primaryPhone?.replaceAll(
                      /[^\w\d]/g,
                      '',
                    )}
                    label="Phone Number:"
                    type="tel"
                    name="phoneNumber"
                    validations={'required|numeric|min:10|max:10'}
                    validator={simpleValidator.current}
                  />
                </TextFieldWrapper>
                <TextFieldWrapper>
                  <StyledTextField
                    onChange={e =>
                      setCoachRegisterPayload({
                        ...coachRegisterPayload,
                        email: e.target.value,
                      })
                    }
                    value={coachRegisterPayload?.email!}
                    label="Email:"
                    type="email"
                    name="email"
                    validations={'required|email'}
                    validator={simpleValidator.current}
                    maxLength={60}
                  />
                </TextFieldWrapper>
                <TextFieldWrapper>
                  <StyledTextField
                    onChange={e =>
                      setCoachRegisterPayload({
                        ...coachRegisterPayload,
                        emergencyContactName: digitRemovedValue(e.target.value),
                      })
                    }
                    value={coachRegisterPayload?.emergencyContactName!}
                    label="Emergency Contact"
                    type="text"
                    name="Emergency Contact"
                    validations={'required|min:10|max:60'}
                    validator={simpleValidator.current}
                  />
                </TextFieldWrapper>
                <TextFieldWrapper>
                  <StyledTextField
                    onChange={handleEmergencyPhChange}
                    value={coachRegisterPayload?.emergencyContactPhone?.replaceAll(
                      /[^\w\d]/g,
                      '',
                    )}
                    label="PH:"
                    type="tel"
                    name="Ph"
                    validations={'required|numeric|min:10|max:10'}
                    validator={simpleValidator.current}
                  />
                </TextFieldWrapper>
              </TextFieldGrid>
            </MainAddressWrapper>
          </>
        </BorderWrapper>
      </SubMainWrapper>
      <BottomRightWrapper id="BottomRightWrapper">
        <ButtonWrapper>
          <BackNextButton
            classNameBack="btn-back"
            classNameNxt="btn-nxt"
            onClickNxt={handleNextForm}
            onClickBack={handleBackForm}
            next="Next"
            back="Cancel"
            disableNext={!valid ? true : false}
          />
        </ButtonWrapper>
      </BottomRightWrapper>
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  color: #9597a6;
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
  height: calc(100vh - 175px);
  padding-top: 50px;
  .Team-Heading {
    margin: 0px;
    padding: 0px !important;
    text-align: center;
    color: #9597a6;
  }
  position: relative;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
  .first-para,
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-top: 40px;
    .first-para,
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    padding-top: 45px;
    .first-para,
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    padding-top: 50px;
    .first-para,
    label {
      font-size: 14px;
    }
  }
`;
const SubMainWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
const BorderWrapper = styled.div`
  height: 100%;
  overflow: auto;
  height: calc(100vh - 262px);
  border-radius: 20px;
  padding: 20px 60px 0px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
  position: relative;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: #201e1e !important;
    border-radius: 10px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    padding: 20px 20px 0px 20px;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    padding: 20px 20px 0px 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding: 20px 20px 0px 20px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding: 20px 20px 0px 20px;
  }
`;
const InfoLabelWrapper = styled.div`
  padding: 0 20px 10px 12px;
  text-align: start;
  width: 100%;
  div {
    border-bottom: 1px solid #434343;
    padding: 6px 0;
  }
`;

const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 35px 0;
`;
const TextFieldWrapper = styled.div`
  width: 50% !important;
  margin-bottom: 20px;
  &.styled-TextFieldWrapper {
    display: grid;
    align-items: end;
  }

  @media (min-width: 100px) and (max-width: 767.98px) {
    width: 100% !important;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 50% !important;
  }
`;

const StyledTextField = styled(TextField)`
  flex: 0 0 50%;
  &.styled-field {
    margin-bottom: 0px !important;
  }
`;

const MainAddressWrapper = styled.div`
  margin-bottom: 60px;
  .horizontal-line {
    display: flex;
    align-items: baseline;
    margin: 20px 20px 15px 12px;
  }
  .horizontal-line span {
    margin: 0 10px 0 0;
    white-space: nowrap;
    color: #4f4f4f;
    font-size: 15px;
  }
  .horizontal-line::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #434343;
    height: 3px;
  }
`;
const BottomRightWrapper = styled.div`
  z-index: 1;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 20px 20px;
  backdrop-filter: blur(10px);
  position: absolute;
  bottom: 2px;
  &.bg-color {
    background: rgb(42 42 42) 100%;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    height: 40px;
    &.bg-color-change {
      bottom: 25px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 45px;
    &.bg-color-change {
      bottom: 30px;
      padding-top: 20px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 60px;
    &.bg-color-change {
      bottom: 20px;
      padding-top: 20px;
    }
  }
`;
const ButtonWrapper = styled.div``;
