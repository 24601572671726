import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import arrowImg from './assets/img/icon/arrow.png';
import { BackNextButton } from 'app/components/BackNextButton';
import RadioButtonGroup from 'app/components/RadioButtonGroup';
import { RegisterRider } from 'services/openapi';
import { prevRadioModel, radioModel } from '../..';
import { CustomRadioButtonGroup } from 'app/components/CustomRadioButtonGroup';

type Props = {
  onClickNxtPlacementOne?:
    | React.MouseEventHandler<HTMLButtonElement>
    | undefined;
  onClickBackPlacementOne?:
    | React.MouseEventHandler<HTMLButtonElement>
    | undefined;
  riderData: RegisterRider;
  setRiderData: React.Dispatch<React.SetStateAction<RegisterRider>>;
  setPlacementRadioValues: React.Dispatch<React.SetStateAction<radioModel>>;
  placementRadioValues: radioModel;
  setPreviousEnrolRadioValues: React.Dispatch<
    React.SetStateAction<prevRadioModel>
  >;
  previousEnrolRadioValues: prevRadioModel;
};

const IndividualPlacementFormNewRider = ({
  onClickNxtPlacementOne,
  onClickBackPlacementOne,
  riderData,
  setRiderData,
  setPlacementRadioValues,
  placementRadioValues,
  previousEnrolRadioValues,
  setPreviousEnrolRadioValues,
}: Props) => {
  const handleowhQuestionOneSelection = (e, value) => {
    const updateFunction = prevData => {
      if (value) {
        return {
          ...prevData,
          pwhQuestionOne: value,
          owhQuestionOne: false,
          ewhQuestionOne: false,
          rwhQuestionOne: false,
          swhQuestionOne: false,
          swhQuestionTwo: false,
          dwhQuestionOne: false,
          drQuestionOne: false,
          drQuestionTwo: false,
        };
      } else {
        return {
          ...prevData,
          pwhQuestionOne: value,
        };
      }
    };

    setPlacementRadioValues(updateFunction);
    setRiderData(prevData => ({
      ...prevData,
      placementData: updateFunction(prevData.placementData),
    }));
  };

  const handleowhQuestionTwoSelection = (e, value) => {
    const updateFunction = prevData => {
      if (value) {
        return {
          ...prevData,
          pwhQuestionOne: false,
          owhQuestionOne: value,
          ewhQuestionOne: false,
          rwhQuestionOne: false,
          swhQuestionOne: false,
          swhQuestionTwo: false,
          dwhQuestionOne: false,
          drQuestionOne: false,
          drQuestionTwo: false,
        };
      } else {
        return {
          ...prevData,
          owhQuestionOne: value,
        };
      }
    };

    setPlacementRadioValues(updateFunction);
    setRiderData(prevData => ({
      ...prevData,
      placementData: updateFunction(prevData.placementData),
    }));
  };

  const handleowhQuestionThreeSelection = (e, value) => {
    const updateFunction = prevData => {
      if (value) {
        return {
          ...prevData,
          pwhQuestionOne: false,
          owhQuestionOne: false,
          ewhQuestionOne: value,
          rwhQuestionOne: false,
          swhQuestionOne: false,
          swhQuestionTwo: false,
          dwhQuestionOne: false,
          drQuestionOne: false,
          drQuestionTwo: false,
        };
      } else {
        return {
          ...prevData,
          ewhQuestionOne: value,
        };
      }
    };

    setPlacementRadioValues(updateFunction);
    setRiderData(prevData => ({
      ...prevData,
      placementData: updateFunction(prevData.placementData),
    }));
  };

  const handlerwhQuestionOneSelection = (e, value) => {
    const updateFunction = prevData => {
      if (value) {
        return {
          ...prevData,
          pwhQuestionOne: false,
          owhQuestionOne: false,
          ewhQuestionOne: false,
          rwhQuestionOne: value,
          swhQuestionOne: false,
          swhQuestionTwo: false,
          dwhQuestionOne: false,
          drQuestionOne: false,
          drQuestionTwo: false,
        };
      } else {
        return {
          ...prevData,
          rwhQuestionOne: value,
        };
      }
    };

    setPlacementRadioValues(updateFunction);
    setRiderData(prevData => ({
      ...prevData,
      placementData: updateFunction(prevData.placementData),
    }));
  };

  const handleowhQuestionFourSelection = (e, value) => {
    const updateFunction = prevData => {
      if (value) {
        return {
          ...prevData,
          pwhQuestionOne: false,
          owhQuestionOne: false,
          ewhQuestionOne: false,
          rwhQuestionOne: false,
          swhQuestionOne: value,
          // swhQuestionTwo: false,
          dwhQuestionOne: false,
          drQuestionOne: false,
          drQuestionTwo: false,
        };
      } else {
        return {
          ...prevData,
          swhQuestionOne: value,
        };
      }
    };

    setPlacementRadioValues(updateFunction);
    setRiderData(prevData => ({
      ...prevData,
      placementData: updateFunction(prevData.placementData),
    }));
  };

  const handleowhQuestionFiveSelection = (e, value) => {
    const updateFunction = prevData => {
      if (value) {
        return {
          ...prevData,
          pwhQuestionOne: false,
          owhQuestionOne: false,
          ewhQuestionOne: false,
          rwhQuestionOne: false,
          // swhQuestionOne: false,
          swhQuestionTwo: value,
          dwhQuestionOne: false,
          drQuestionOne: false,
          drQuestionTwo: false,
        };
      } else {
        return {
          ...prevData,
          swhQuestionTwo: value,
        };
      }
    };

    setPlacementRadioValues(updateFunction);
    setRiderData(prevData => ({
      ...prevData,
      placementData: updateFunction(prevData.placementData),
    }));
  };

  const handleowhQuestionSixSelection = (e, value) => {
    const updateFunction = prevData => {
      if (value) {
        return {
          ...prevData,
          pwhQuestionOne: false,
          owhQuestionOne: false,
          ewhQuestionOne: false,
          rwhQuestionOne: false,
          swhQuestionOne: false,
          swhQuestionTwo: false,
          dwhQuestionOne: value,
          drQuestionOne: false,
          drQuestionTwo: false,
        };
      } else {
        return {
          ...prevData,
          dwhQuestionOne: value,
        };
      }
    };

    setPlacementRadioValues(updateFunction);
    setRiderData(prevData => ({
      ...prevData,
      placementData: updateFunction(prevData.placementData),
    }));
  };

  const handleowhQuestionSevenSelection = (e, value) => {
    const updateFunction = prevData => {
      if (value) {
        return {
          ...prevData,
          pwhQuestionOne: false,
          owhQuestionOne: false,
          ewhQuestionOne: false,
          rwhQuestionOne: false,
          swhQuestionOne: false,
          swhQuestionTwo: false,
          dwhQuestionOne: false,
          drQuestionOne: value,
          // drQuestionTwo: false,
        };
      } else {
        return {
          ...prevData,
          drQuestionOne: value,
        };
      }
    };

    setPlacementRadioValues(updateFunction);
    setRiderData(prevData => ({
      ...prevData,
      placementData: updateFunction(prevData.placementData),
    }));
  };

  const handleowhQuestionEightSelection = (e, value) => {
    const updateFunction = prevData => {
      if (value) {
        return {
          ...prevData,
          pwhQuestionOne: false,
          owhQuestionOne: false,
          ewhQuestionOne: false,
          rwhQuestionOne: false,
          swhQuestionOne: false,
          swhQuestionTwo: false,
          dwhQuestionOne: false,
          // drQuestionOne: false,
          drQuestionTwo: value,
        };
      } else {
        return {
          ...prevData,
          drQuestionTwo: value,
        };
      }
    };

    setPlacementRadioValues(updateFunction);
    setRiderData(prevData => ({
      ...prevData,
      placementData: updateFunction(prevData.placementData),
    }));
  };
  //next Radio button value section
  // const handleTopThreeSelection = e => {
  //   setPreviousEnrolRadioValues(prevData => ({
  //     ...prevData,
  //     achievedTopThreeInDivision: e.value,
  //   }));
  //   setRiderData(prevData => ({
  //     ...prevData,
  //     achievedTopThreeInDivision: e.value,
  //   }));
  // };
  // const handleOneOrTwoNatioalSelection = e => {
  //   setPreviousEnrolRadioValues(prevData => ({
  //     ...prevData,
  //     achievedTopTwoInIndividualNationals: e.value,
  //   }));
  //   setRiderData(prevData => ({
  //     ...prevData,
  //     achievedTopTwoInIndividualNationals: e.value,
  //   }));
  // };
  // const handleThirtySectionSelection = e => {
  //   setPreviousEnrolRadioValues(prevData => ({
  //     ...prevData,
  //     divisionCompliesWithSectionThirty: e.value,
  //   }));
  //   setRiderData(prevData => ({
  //     ...prevData,
  //     divisionCompliesWithSectionThirty: e.value,
  //   }));
  // };
  // const handleStayedTwoYearsSelection = e => {
  //   setPreviousEnrolRadioValues(prevData => ({
  //     ...prevData,
  //     hasStayedInSameDivisionForTwoYears: e.value,
  //   }));
  //   setRiderData(prevData => ({
  //     ...prevData,
  //     hasStayedInSameDivisionForTwoYears: e.value,
  //   }));
  // };

  return (
    <MainWrapper className="form-MainWrapper">
      <OverflowWrapper className="OverflowWrapper">
        <p className="overflow-header-para">
          In order to compete in YEDA shows, an individual member showing YEDA
          must submit the completed form below. Your coach must review for
          accurate assessment. Coaches are responsible for accurately evaluating
          a rider in the appropriate division.
        </p>
        <InfoWrapper className="InfoWrapper">
          <p className="para-one">
            <i>NOTE:</i>
          </p>
          <ListDataWrapper className="ListDataWrapper">
            <ul>
              <div className="list-style">
                <img src={arrowImg} alt="List-arrow" />
                <li>
                  Because of the yes/no format of this form, the questions in
                  each section are not intended to always match the class
                  description of the same section. Rather, a “yes” answer in one
                  section is, in many cases, an affirmation of the rider being
                  over-qualified for the class description in the next lower
                  class.
                </li>
              </div>
              <div className="list-style">
                <img src={arrowImg} alt="arrow" />
                <li>
                  For those students coming to YEDA from another team riding
                  national organization that places their riders in divisions
                  based on experience those students must compete in the
                  equivalent division equal or greater than that division they
                  have completed.
                </li>
              </div>
            </ul>
          </ListDataWrapper>
        </InfoWrapper>
        <ButtonWrapper>
          <div className="horizontal-line">
            <p className="content-title">
              Class 1 & 2: Pearl Western Horsemanship
            </p>
          </div>
          <p className="content-para">
            Pearl riders are student riders of any skill level that walk jog and
            who are riders currently enrolled in 4th of 5th grade for the school
            year of competition. Pearl riders have two years of eligibility
            starting with their initial completion of the YEDA Individual
            Membership Form.
          </p>
          <div>
            <p className="radiobtn-title">
              ARE YOU ENTERING THE 4TH OR 5TH GRADE FOR THIS COMPETITION YEAR?
            </p>
            <RadioButtonWrapper>
              {/* <RadioButtonGroup
                onChange={handleowhQuestionOneSelection}
                value={placementRadioValues?.pwhQuestionOne}
                name="pwhQuestionOne"
              /> */}
              <CustomRadioButtonGroup
                onChange={handleowhQuestionOneSelection}
                value={placementRadioValues?.pwhQuestionOne}
                name="pwhQuestionOne"
              />
            </RadioButtonWrapper>
          </div>
        </ButtonWrapper>
        <ButtonWrapper>
          <div className="horizontal-line">
            <p className="content-title">
              Classes 3, 4, 11 & 12: Opal Western Horsemanship
            </p>
          </div>
          <p className="content-para">
            Opal riders are Walk-jog students who are beginning riders who have
            not competed in mounted competition which require them to canter or
            lope.
          </p>
          <div>
            <p className="radiobtn-title">
              IS THIS STATEMENT TRUE? I HAVE NEVER COMPETED IN A CLASS THAT
              REQUIRES ME TO LOPE OR CANTER
            </p>
            <RadioButtonWrapper>
              <CustomRadioButtonGroup
                onChange={handleowhQuestionTwoSelection}
                value={placementRadioValues?.owhQuestionOne}
              />
            </RadioButtonWrapper>
          </div>
        </ButtonWrapper>
        <ButtonWrapper>
          <div className="horizontal-line">
            <p className="content-title">
              Classes 5, 6, 13 & 14: Emerald Western Horsemanship
            </p>
          </div>
          <p className="content-para">
            Emerald riders are students that have competed in twelve (12) or
            fewer shows that require them to lope or canter.
          </p>
          <div>
            <p className="radiobtn-title">
              HAVE YOU COMPETED IN 12 OR LESS SHOWS WHICH REQUIRE YOU TO LOPE OR
              CANTER?
            </p>
            <RadioButtonWrapper>
              <CustomRadioButtonGroup
                onChange={handleowhQuestionThreeSelection}
                value={placementRadioValues?.ewhQuestionOne}
              />
            </RadioButtonWrapper>
          </div>
        </ButtonWrapper>
        <ButtonWrapper>
          <div className="horizontal-line">
            <p className="content-title">
              Classes 7, 8, 15, & 16: Ruby Western Horsemanship
            </p>
          </div>
          <p className="content-para">
            Ruby riders have competed in more than twelve (12) shows that
            require a rider to lope or canter. Ruby riders may not have earned
            more than 25 national points from any association including novice
            classes. This excludes roping, games, halter, showmanship 10 & under
            classes, and/or non-loping classes.
          </p>
          <div>
            <p className="radiobtn-title">
              IS THIS STATEMENT TRUE? I HAVE NOT EARNED 25 NATIONAL POINTS FROM
              ANY NATIONAL ASSOCIATION INCLUDING NOVICE BUT EXCLUDING ROPING,
              GAMES, HALTER, SHOWMANSHIP 10 & UNDER CLASSES, AND/OR NON-LOPING
              CLASSES.
            </p>
            <RadioButtonWrapper>
              <CustomRadioButtonGroup
                onChange={handlerwhQuestionOneSelection}
                value={placementRadioValues?.rwhQuestionOne}
              />
            </RadioButtonWrapper>
          </div>
        </ButtonWrapper>
        <ButtonWrapper>
          <div className="horizontal-line">
            <p className="content-title">
              Classes 9, 10, 17, & 18: Sapphire Westem Horsemanship/Ranch Riding
            </p>
          </div>
          <p className="content-para">
            Sapphire riders are those who have eamed 26 or more national points
            from any national association including novice but excluding roping.
            games, hater, showmanship 10 & under classes, and/or non-loping
            classes. Sapphire riders are also riders who have completed at a
            national fun show including but not limited to World Championships.
            National Championships. Novice Championships. Congress, and Derby
          </p>
          <div>
            <p className="radiobtn-title">
              HAVE YOU EARNED 26 OR MORE NATIONAL POINTS FROM ANY NATIONAL
              ASSOCIATION INCLUDING NOVICE BUT EXCLUDING ROPING, GAMES, HALTER
              SHOWMANSHIP 10 & UNDER CLASSES, AND/OR NON-LOPING CLASSES
            </p>
            <RadioButtonWrapper>
              <CustomRadioButtonGroup
                onChange={handleowhQuestionFourSelection}
                value={placementRadioValues?.swhQuestionOne}
              />
            </RadioButtonWrapper>
          </div>
          <div>
            <p className="radiobtn-title">
              HAVE YOU COMPETED IN A NATIONAL RUN SHOW INCLUDING BUT NOT LIMITED
              TO WORLD CHAMPIONSHIPS, NATIONAL CHAMPIONSHIPS, NOVICE
              CHAMPIONSHIPS, CONGRESS, AND DERBY?
            </p>
            <RadioButtonWrapper>
              <CustomRadioButtonGroup
                onChange={handleowhQuestionFiveSelection}
                value={placementRadioValues?.swhQuestionTwo}
              />
            </RadioButtonWrapper>
          </div>
        </ButtonWrapper>
        <ButtonWrapper>
          <div className="horizontal-line">
            <p className="content-title">
              Class 19: Diamond Western Horsemanship
            </p>
          </div>
          <p className="content-para">
            <span>
              Diamond riders are those who have finished in Top Fifteen (15) at
              a{' '}
            </span>{' '}
            national run show including but not limited to World Championships,
            National Championships, Novice Championships, Congress, and Derby.
            Including novice but excluding roping, games, halter, showmanshi 10
            & under classes, and/or non-loping classes.
          </p>
          <div>
            <p className="radiobtn-title">
              HAVE YOU EVER FINISHED IN THE TOP FIFTEEN (15) AT A NATIONAL RUN
              SHOW INCLUDING BUT NOT LIMITED TO WORLD CHAMPIONSHIPS, NATIONAL
              CHAMPIONSHIPS, NOVICE CHAMPIONSHIPS, CONGRESS, AND DERBY INCLUDING
              NOVICE BUT EXCLUDING ROPING, GAMES, HALTER, SHOWMANSHIP 10 & UNDER
              CLASSES, AND/OR NON-LOPING CLASSES?
            </p>
            <RadioButtonWrapper>
              <CustomRadioButtonGroup
                onChange={handleowhQuestionSixSelection}
                value={placementRadioValues?.dwhQuestionOne}
              />
            </RadioButtonWrapper>
          </div>
        </ButtonWrapper>
        <ButtonWrapper>
          <div className="horizontal-line">
            <p className="content-title">Class 20: Diamond Reining</p>
          </div>
          <p className="radiobtn-title">
            ARE YOU ELIGIBLE FOR CLASS 19: DIAMOND HORSEMANSHIP?
          </p>
          <RadioButtonWrapper>
            <CustomRadioButtonGroup
              onChange={handleowhQuestionSevenSelection}
              value={placementRadioValues?.drQuestionOne}
            />
          </RadioButtonWrapper>
          <div>
            <p className="radiobtn-title">
              HAVE YOU HAD AT LEAST 6 MONTH OF PROFESSIONAL REINING TRAINING
              WITHIN THE PAST YEAR?
            </p>
            <RadioButtonWrapper>
              <CustomRadioButtonGroup
                onChange={handleowhQuestionEightSelection}
                value={placementRadioValues?.drQuestionTwo}
              />
            </RadioButtonWrapper>
          </div>
        </ButtonWrapper>
        <InfoWrapper className="InfoWrapper">
          <div className="ListDataWrapper para-content">
            <p>
              A Student must be enrolled in school full-time at the time of any
              YEDA Competition.
            </p>
            <p>
              For those students coming to YEDA from another team riding
              national organization that places their riders in divisions based
              on experience those students must compete in the equivalent
              division equal or greater than that division they have completed.
            </p>
            <p>Coach’s Assessment of YEDA Divisions :</p>
            <p className="sub-label">
              <span className="note-label">NOTE : </span> If either the coach or
              rider believes that this form places the rider into a class
              unfairly based on the riders abilities and experiences and that
              this rider should be placed into a lower division other than the
              one indicated by the form, the coach should send an email
              describing in detail the rider's circumstances to Debbie Arnold --
              Darnold@showyeda.com. The email will then be forwarded on to the
              National Steward for review and a final placement decision.
              Additional information may be required from the National Steward.
              A $25 fee must accompany the formal request. Reference Rule 4.08
              and 23.05
            </p>
          </div>
        </InfoWrapper>
        {/* <ButtonWrapper className="prev-radio-btns">
          <div>
            <p className="radiobtn-title">
              Did you finish in the top 3 in your division in 2022 - 2023 show
              season?
            </p>
            <RadioButtonWrapper>
              <RadioButtonGroup
                onChange={handleTopThreeSelection}
                value={previousEnrolRadioValues.achievedTopThreeInDivision}
              />
            </RadioButtonWrapper>
          </div>
          <div>
            {' '}
            <p className="radiobtn-title">
              Did you finish 1st or 2nd in any 2023 national individual
              championship class?
            </p>
            <RadioButtonWrapper>
              <RadioButtonGroup
                onChange={handleOneOrTwoNatioalSelection}
                value={
                  previousEnrolRadioValues.achievedTopTwoInIndividualNationals
                }
              />
            </RadioButtonWrapper>
          </div>
          <FormWrapper>
            <p className="para-one">Information</p>
            <p className="para-two">
              Answering “YES” to EITHER of the above 2 questions will
              automatically move you up to the next division. When answering the
              below placement questions any new points or titles earned prior to
              the start of the new YEDA season must be disclosed. If moving from
              Junior High to Senior High a lateral move is considered the next
              division.
            </p>
          </FormWrapper>
          <div>
            {' '}
            <p className="radiobtn-title">
              Does the division you have selected comply with section 30:
              individual point procedures & requirements in the cument rulebook
              as summarized below? If no, then the rider shall move up to the
              net division
            </p>
            <RadioButtonWrapper>
              <RadioButtonGroup
                onChange={handleThirtySectionSelection}
                value={
                  previousEnrolRadioValues.divisionCompliesWithSectionThirty
                }
              />
            </RadioButtonWrapper>
          </div>
          <div>
            <p className="radiobtn-title">
              Have you competed in the same division for two seasons?
            </p>
            <RadioButtonWrapper>
              <RadioButtonGroup
                onChange={handleStayedTwoYearsSelection}
                value={
                  previousEnrolRadioValues.hasStayedInSameDivisionForTwoYears
                }
              />
            </RadioButtonWrapper>
          </div>
          <FormWrapper>
            <p className="para-one">Information</p>
            <p className="para-two">
              Summary of section 30 individual point procedures & requirements
              in the rulebook: riders have 2 years of eligibility in each
              division within the category and must move up to the higher
              division once they have used all their years of eligibility
              regardless of points earned. See rulebook for more details. If you
              have competed in "recognized shows you may be asked to submit a
              printed copy of your competition/points records along with this
              form. For yeda purposes, recognized shows includes AQHA, NRHA,
              NSBA, APHA, PIHA, ApHC, IBHA, ABRA & POA, USEF, and any other
              association that keeps national records.
            </p>
          </FormWrapper>
        </ButtonWrapper> */}
        <NxtButtonWrapper className="bg-color-change">
          <BackNextButton
            classNameBack="btn-back"
            classNameNxt="btn-nxt"
            onClickNxt={onClickNxtPlacementOne}
            onClickBack={onClickBackPlacementOne}
            disableNext={
              placementRadioValues.drQuestionOne === null ||
              placementRadioValues.drQuestionTwo === null ||
              placementRadioValues.ewhQuestionOne === null ||
              placementRadioValues.owhQuestionOne === null ||
              placementRadioValues.pwhQuestionOne === null ||
              placementRadioValues.rwhQuestionOne === null ||
              placementRadioValues.swhQuestionOne === null ||
              placementRadioValues.swhQuestionTwo === null
            }
            next="Next"
            back="Back"
          />
        </NxtButtonWrapper>
      </OverflowWrapper>
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  height: calc(100vh - 190px);
  position: relative;
  width: 100%;
  padding-top: 25px;
`;
const OverflowWrapper = styled.div`
  height: 100%;
  overflow: auto;
  padding-bottom: 100px;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 5px;
    background: transparent !important;
    border-radius: 10px;
  }
  .overflow-header-para {
    color: #a7a7a7;
    font-size: 12px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .form-MainWrapper {
      padding-top: 10px;
      .inner-BorderWrapper {
        padding: 20px 20px;
      }
      .OverflowWrapper {
        padding: 20px 12px 100px 12px;
        .overflow-header-para {
          color: #a7a7a7;
          font-size: 12px;
        }
        .horizontal-line {
          ::after {
            display: none;
          }
          .content-title {
            font-size: 11px;
          }
        }
        .content-para,
        .radiobtn-title {
          font-size: 11px;
        }
        .InfoWrapper {
          padding: 10px 10px;
          margin: 15px 0px;
          .ListDataWrapper {
            ul {
              padding: 0px;
            }
          }
        }
        .over-flow-header {
          font-size: 11px;
        }
      }
      .TextFieldWrapper {
        width: 100%;
        &.BackNumberFieldWrapper {
          .BackNumberField-label {
            margin: 0px !important;
          }
        }
        &.height-weight-wrapper {
          .FlexFieldWrapper {
            flex-direction: column;
            align-items: flex-start;
          }
          .note-block {
            font-size: 11px !important;
            padding-top: 22px !important;
            align-items: baseline;
            width: 100%;
            height: 100%;
          }
        }
      }

      #BottomRightWrapper {
        height: 50px;
      }
      .StyledFormSecondaryLabel {
        font-size: 10px !important;
        letter-spacing: 0.2px;
      }
      .QuestionWrapper {
        .FormSecondaryLabel {
          font-size: 10px !important;
          letter-spacing: 0.2px;
        }
      }
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    /* &.page-content-wrapper {
      padding: 20px 20px;
    } */
    .form-MainWrapper {
      padding-top: 10px;
      .inner-BorderWrapper {
        padding: 20px 20px;
      }
      .OverflowWrapper {
        padding: 20px 12px 100px 12px;
        .overflow-header-para {
          font-size: 12px;
          color: #a7a7a7;
        }
        .horizontal-line {
          ::after {
            display: none;
          }
          .content-title {
            font-size: 12px;
          }
        }
        .content-para,
        .radiobtn-title {
          font-size: 11px;
        }
        .InfoWrapper {
          padding: 10px 10px;
          margin: 15px 0px;
          .ListDataWrapper {
            ul {
              padding: 0px;
            }
          }
        }
        .over-flow-header {
          font-size: 12px;
        }
      }
      .TextFieldWrapper {
        width: 100%;
        &.BackNumberFieldWrapper {
          .BackNumberField-label {
            margin: 0px !important;
          }
        }
        &.height-weight-wrapper {
          .FlexFieldWrapper {
            flex-direction: column;
            align-items: flex-start;
          }
          .note-block {
            font-size: 11px !important;
            padding-top: 22px !important;
            align-items: baseline;
            width: 100%;
            height: 100%;
          }
        }
      }
      .BackFieldWrapper {
        width: 100%;
        padding: 0px;
        .BackNumberWrapper {
          padding: 0px;
        }
        .StyledTextField {
          margin-left: 0px;
        }
      }

      .StyledTextField {
        margin: 0px 0px;
      }
      .SHdropdown {
        margin: 0px 0px !important;
      }
      #InfoLabelWrapper {
        padding: 0 0px 10px 0px !important;
        label {
          font-size: 13px !important;
        }
      }
      .TextFieldGrid {
        padding: 10px 0px !important;
        &.height-note-grid {
          padding: 0px 0px !important;
          flex-direction: column-reverse !important;
        }
      }
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .form-MainWrapper {
      .inner-BorderWrapper {
        padding: 20px 20px;
      }

      #InfoLabelWrapper {
        label {
          font-size: 10px !important;
        }
      }
      .TextFieldWrapper {
        width: 100%;
        &.height-weight-wrapper {
          .note-block {
            font-size: 8px !important;
            padding-top: 22px !important;
          }
        }
      }
      .StyledTextField {
        margin: 0px 8px;
      }
      .note-block {
        font-size: 10px;
      }
      #BottomRightWrapper {
        height: 50px;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }
  @media (min-width: 992px) and (max-width: 1199px) {
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
  }
`;
const InfoWrapper = styled.div`
  width: 100%;
  color: #9597a6;
  border: 1px solid #585858;
  border-radius: 10px;
  margin: 30px 0px 20px;
  padding: 10px 20px;
  background: #27292d;
  p {
    font-family: 'Roboto';
  }
  .para-one {
    color: #ff9900;
    font-size: 12px;
    border-bottom: 1px solid #585858;
    padding: 0px !important;
    font-size: 14px;
  }
  .para-two {
    padding-top: 20px;
    font-size: 13px;
  }
  .note-label {
    color: #ff9900;
    padding: 0px !important;
    font-style: italic;
  }
  .sub-label {
    font-size: 11px !important;
  }
  .para-content {
    p {
      font-size: 13px;
    }
  }
`;
const ListDataWrapper = styled.div`
  display: flex;
  align-items: baseline;
  li {
    padding-left: 10px;
    list-style-type: none;
    padding-bottom: 10px;
    font-family: 'Roboto';
    font-size: 11px;
    color: #a7a7a7;
  }
  img {
    width: 11px;
    height: auto;
  }
  .list-style {
    display: flex;
    align-items: baseline;
  }
  ul {
    margin: 0px;
  }
`;
const ButtonWrapper = styled.div`
  .horizontal-line {
    display: flex;
    align-items: center;
    margin: 20px 0;
    label {
      color: #e98d04;
      font-style: oblique;
    }

    ::after {
      content: '';
      flex-grow: 1;
      border-top: 1px solid #585858;
    }

    .content-title {
      margin: 0 10px 0px 0px;
      white-space: pre-line;
      color: #ffffff;
      font-size: 14px;
    }
  }
  span {
    color: #ffffff;
    font-style: oblique;
  }
  .content-para {
    color: #a7a7a7;
    font-family: 'Roboto';
    font-size: 12px;
  }
  .radiobtn-title {
    color: #9597a6;
    font-size: 12px;
  }
  &.prev-radio-btns {
    margin-top: 20px;
    padding: 10px 0px;
    border-top: 1px solid #585858;
  }
`;
const RadioButtonWrapper = styled.div`
  display: flex;
  gap: 40px;
  padding-bottom: 20px;
  label {
    color: #9597a6;
    font-family: 'Roboto';
    font-size: 12px;
  }
  .k-radio {
    background-color: transparent;
    border: 1px solid #ced4da;
  }
  .k-radio:checked,
  .k-radio.k-checked {
    border-color: 1px solid #0d6efd;
    color: white;
    background-color: #0d6efd;
  }
  .k-radio-list {
    flex-flow: row-reverse;
    justify-content: flex-end;
    gap: 50px;
  }
  .k-radio-item,
  .k-radio-list-item {
    color: #9597a6;
    gap: 10px;
  }
`;
const FormWrapper = styled.div`
  width: 100%;
  border: 1px solid #585858;
  border-radius: 7px;
  padding: 0px 20px 20px 20px;
  background: #27292d;
  border: 1px solid #5e5e5e;
  margin-bottom: 10px;
  p {
    font-family: 'Roboto';
  }
  .para-one {
    color: #ff9900;
    font-size: 13px;
    border-bottom: 1px solid #585858;
  }
  .para-two {
    padding-bottom: 10px;
    font-size: 13px;
  }
  span {
    color: #306fdb;
  }
`;

const NxtButtonWrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: -13px;
    left: -1px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    backdrop-filter: blur(5px);
  }
  @media (min-width: 100px) and (max-width: 1199px) {
    height: 50px;
    &.bg-color-change {
      bottom: -3px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
    &.bg-color-change {
      bottom: -3px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
    &.bg-color-change {
      bottom: -3px;
    }
  }
`;

export default IndividualPlacementFormNewRider;
