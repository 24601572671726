import React from 'react';
import styled from 'styled-components';

interface svgProps {
  color?: string;
  width?: string;
  height?: string;
  strokeWidth?: string;
  stroke?: string;
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
}
//DeleteIcon
const DeleteIcon = ({
  color,
  width,
  height,
  stroke,
  strokeWidth,
  className,
  onClick,
}: svgProps) => {
  return (
    <SvgWrapper
      onClick={onClick}
      className={`${className}`}
      width={width ? width : '15'}
      height={height ? height : '18'}
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 15.1579C14 15.9117 13.6839 16.6346 13.1213 17.1676C12.5587 17.7006 11.7956 18 11 18H4C3.20435 18 2.44129 17.7006 1.87868 17.1676C1.31607 16.6346 1 15.9117 1 15.1579V3.78947H0V0.947368H4.5L5.5 0H9.5L10.5 0.947368H15V3.78947H14V15.1579ZM2 3.78947V15.1579C2 15.6604 2.21071 16.1423 2.58579 16.4977C2.96086 16.853 3.46957 17.0526 4 17.0526H11C11.5304 17.0526 12.0391 16.853 12.4142 16.4977C12.7893 16.1423 13 15.6604 13 15.1579V3.78947H2ZM14 2.84211V1.89474H10L9 0.947368H6L5 1.89474H1V2.84211H14ZM4 5.68421H5V15.1579H4V5.68421ZM10 5.68421H11V15.1579H10V5.68421Z"
        fill={color ? color : ' #FF5252'}
      />
    </SvgWrapper>
  );
};
//EditIcon
const EditIcon = ({
  color,
  width,
  height,
  stroke,
  strokeWidth,
  className,
  onClick,
}: svgProps) => {
  return (
    <SvgWrapper
      onClick={onClick}
      className={`${className}`}
      width={width ? width : '16'}
      height={height ? height : '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1909 0.809277C14.9343 0.552704 14.6297 0.34918 14.2945 0.210324C13.9593 0.0714683 13.6 0 13.2371 0C12.8743 0 12.515 0.0714683 12.1797 0.210324C11.8445 0.34918 11.5399 0.552704 11.2833 0.809277L1.61524 10.4789C1.31037 10.7835 1.09314 11.1646 0.986382 11.5822L0.0154911 15.3765C-0.0059317 15.4605 -0.00512236 15.5487 0.0178396 15.6323C0.0408016 15.716 0.0851265 15.7922 0.14646 15.8535C0.207794 15.9149 0.284027 15.9592 0.367672 15.9822C0.451317 16.0051 0.539496 16.0059 0.623548 15.9845L4.4187 15.0148C4.83655 14.9082 5.21795 14.691 5.52281 14.386L15.1909 4.71639C15.709 4.19824 16 3.49554 16 2.76284C16 2.03013 15.709 1.32743 15.1909 0.809277ZM11.9906 1.51653C12.3212 1.18589 12.7696 1.00011 13.2372 1.00007C13.7048 1.00004 14.1532 1.18574 14.4838 1.51633C14.8145 1.84693 15.0003 2.29533 15.0003 2.76289C15.0003 3.23046 14.8146 3.67889 14.484 4.00954L13.6012 4.89241L11.1081 2.39901L11.9906 1.51653ZM10.4009 3.10626L12.8939 5.59967L4.81554 13.6787C4.63764 13.8568 4.41501 13.9837 4.17108 14.046L1.1936 14.8068L1.95527 11.8302C2.01768 11.5862 2.14449 11.3637 2.32211 11.1861L10.4009 3.10626Z"
        fill="#5E5E5E"
      />
    </SvgWrapper>
  );
};
//DetailArrowIcon
const DetailArrowIcon = ({
  color,
  width,
  height,
  stroke,
  strokeWidth,
  className,
  onClick,
}: svgProps) => {
  return (
    <SvgWrapper
      onClick={onClick}
      className={`${className}`}
      width={width ? width : '18'}
      height={height ? height : '18'}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg "
    >
      <circle cx="9" cy="9" r="9" fill="#E4E2E2" />
      <path
        d="M7.66667 12.3334L11 9.00008L7.66667 5.66675"
        stroke="#A3A3A3"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgWrapper>
  );
};
const ThreeDotIcon = ({
  color,
  width,
  height,
  stroke,
  strokeWidth,
  className,
  onClick,
}: svgProps) => {
  return (
    <SvgWrapper
      onClick={onClick}
      className={`${className}`}
      width={width ? width : '4'}
      height={height ? height : '16'}
      viewBox="0 0 4 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.625001 2.15625C0.625001 1.8843 0.705643 1.61846 0.85673 1.39234C1.00782 1.16622 1.22256 0.989987 1.47381 0.885916C1.72506 0.781846 2.00153 0.754616 2.26825 0.807671C2.53497 0.860726 2.77998 0.991682 2.97227 1.18398C3.16457 1.37628 3.29553 1.62128 3.34858 1.888C3.40164 2.15473 3.37441 2.43119 3.27034 2.68244C3.16626 2.93369 2.99003 3.14843 2.76391 3.29952C2.53779 3.45061 2.27195 3.53125 2 3.53125C1.63533 3.53125 1.28559 3.38638 1.02773 3.12852C0.769867 2.87066 0.625001 2.52092 0.625001 2.15625ZM2 6.625C1.72805 6.625 1.46221 6.70564 1.23609 6.85673C1.00997 7.00782 0.833737 7.22256 0.729666 7.47381C0.625596 7.72506 0.598366 8.00153 0.651421 8.26825C0.704476 8.53497 0.835432 8.77997 1.02773 8.97227C1.22003 9.16457 1.46503 9.29553 1.73175 9.34858C1.99848 9.40164 2.27494 9.37441 2.52619 9.27034C2.77744 9.16626 2.99218 8.99003 3.14327 8.76391C3.29436 8.53779 3.375 8.27195 3.375 8C3.375 7.63533 3.23013 7.28559 2.97227 7.02773C2.71441 6.76987 2.36467 6.625 2 6.625ZM2 12.4688C1.72805 12.4688 1.46221 12.5494 1.23609 12.7005C1.00997 12.8516 0.833737 13.0663 0.729666 13.3176C0.625596 13.5688 0.598366 13.8453 0.651421 14.112C0.704476 14.3787 0.835432 14.6237 1.02773 14.816C1.22003 15.0083 1.46503 15.1393 1.73175 15.1923C1.99848 15.2454 2.27494 15.2182 2.52619 15.1141C2.77744 15.01 2.99218 14.8338 3.14327 14.6077C3.29436 14.3815 3.375 14.1157 3.375 13.8438C3.375 13.4791 3.23013 13.1293 2.97227 12.8715C2.71441 12.6136 2.36467 12.4688 2 12.4688Z"
        fill="#A3A3A3"
      />
    </SvgWrapper>
  );
};

const FilterIcon = ({
  color,
  width,
  height,
  stroke,
  strokeWidth,
  className,
  onClick,
}: svgProps) => {
  return (
    <SvgWrapper
      onClick={onClick}
      className={`${className}`}
      width={width ? width : '15'}
      height={height ? height : '9'}
      viewBox="0 0 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.947922 0.25C0.807026 0.25 0.6719 0.305971 0.572271 0.405599C0.472643 0.505228 0.416672 0.640354 0.416672 0.78125C0.416672 0.922146 0.472643 1.05727 0.572271 1.1569C0.6719 1.25653 0.807026 1.3125 0.947922 1.3125H14.0521C14.193 1.3125 14.3281 1.25653 14.4277 1.1569C14.5274 1.05727 14.5833 0.922146 14.5833 0.78125C14.5833 0.640354 14.5274 0.505228 14.4277 0.405599C14.3281 0.305971 14.193 0.25 14.0521 0.25H0.947922ZM3.25001 4.32292C3.25001 4.18202 3.30598 4.0469 3.4056 3.94727C3.50523 3.84764 3.64036 3.79167 3.78126 3.79167H11.2188C11.3597 3.79167 11.4948 3.84764 11.5944 3.94727C11.694 4.0469 11.75 4.18202 11.75 4.32292C11.75 4.46381 11.694 4.59894 11.5944 4.69857C11.4948 4.7982 11.3597 4.85417 11.2188 4.85417H3.78126C3.64036 4.85417 3.50523 4.7982 3.4056 4.69857C3.30598 4.59894 3.25001 4.46381 3.25001 4.32292ZM6.08334 7.82067C6.08334 7.67977 6.13931 7.54464 6.23894 7.44502C6.33857 7.34539 6.47369 7.28942 6.61459 7.28942H8.38542C8.52632 7.28942 8.66144 7.34539 8.76107 7.44502C8.8607 7.54464 8.91667 7.67977 8.91667 7.82067C8.91667 7.96156 8.8607 8.09669 8.76107 8.19632C8.66144 8.29595 8.52632 8.35192 8.38542 8.35192H6.61459C6.47369 8.35192 6.33857 8.29595 6.23894 8.19632C6.13931 8.09669 6.08334 7.96156 6.08334 7.82067Z"
        fill="#1E1E1E"
      />
    </SvgWrapper>
  );
};

const LeftArrowIcon = ({
  color,
  width,
  height,
  stroke,
  strokeWidth,
  className,
  onClick,
}: svgProps) => {
  return (
    <SvgWrapper
      onClick={onClick}
      className={`${className}`}
      width={width ? width : '16'}
      height={height ? height : '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5824 6.59244H3.41244L8.29244 1.71244C8.68244 1.32244 8.68244 0.682444 8.29244 0.292444C8.19993 0.19974 8.09004 0.126193 7.96907 0.0760112C7.8481 0.0258297 7.71841 0 7.58744 0C7.45648 0 7.32679 0.0258297 7.20582 0.0760112C7.08485 0.126193 6.97496 0.19974 6.88244 0.292444L0.292444 6.88244C0.199741 6.97496 0.126193 7.08485 0.0760117 7.20582C0.0258302 7.32679 0 7.45648 0 7.58744C0 7.71841 0.0258302 7.84809 0.0760117 7.96907C0.126193 8.09004 0.199741 8.19993 0.292444 8.29244L6.88244 14.8824C6.97503 14.975 7.08494 15.0485 7.2059 15.0986C7.32687 15.1487 7.45651 15.1745 7.58744 15.1745C7.71837 15.1745 7.84802 15.1487 7.96899 15.0986C8.08995 15.0485 8.19986 14.975 8.29244 14.8824C8.38503 14.7899 8.45847 14.68 8.50857 14.559C8.55868 14.438 8.58447 14.3084 8.58447 14.1774C8.58447 14.0465 8.55868 13.9169 8.50857 13.7959C8.45847 13.6749 8.38503 13.565 8.29244 13.4724L3.41244 8.59244H14.5824C15.1324 8.59244 15.5824 8.14244 15.5824 7.59244C15.5824 7.04244 15.1324 6.59244 14.5824 6.59244Z"
        fill="#1E1E1E"
      />
    </SvgWrapper>
  );
};
const CameraIcon = ({
  color,
  width,
  height,
  stroke,
  strokeWidth,
  className,
  onClick,
}: svgProps) => {
  return (
    <SvgWrapper
      onClick={onClick}
      className={`${className}`}
      width={width ? width : '18'}
      height={height ? height : '16'}
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4907 2.86505C13.5251 2.92512 13.5852 2.96803 13.6625 2.96803C15.7245 2.96803 17.4084 4.6501 17.4084 6.70979V11.8075C17.4084 13.8672 15.7245 15.5493 13.6625 15.5493H3.97126C1.90069 15.5493 0.225342 13.8672 0.225342 11.8075V6.70979C0.225342 4.6501 1.90069 2.96803 3.97126 2.96803C4.03999 2.96803 4.10872 2.9337 4.1345 2.86505L4.18605 2.76206C4.21567 2.69971 4.24608 2.63567 4.27694 2.57065C4.49673 2.10771 4.73988 1.59554 4.89055 1.29454C5.28576 0.522155 5.95591 0.0930547 6.78929 0.0844727H10.8359C11.6693 0.0930547 12.348 0.522155 12.7432 1.29454C12.8785 1.56487 13.0847 2.00032 13.2834 2.42003C13.3244 2.50663 13.3651 2.59257 13.4048 2.67624L13.4907 2.86505ZM12.8635 6.16054C12.8635 6.58964 13.2072 6.93292 13.6368 6.93292C14.0663 6.93292 14.4186 6.58964 14.4186 6.16054C14.4186 5.73144 14.0663 5.37958 13.6368 5.37958C13.2072 5.37958 12.8635 5.73144 12.8635 6.16054ZM7.33055 7.49075C7.73436 7.0874 8.25844 6.87285 8.81689 6.87285C9.37534 6.87285 9.89943 7.0874 10.2946 7.48217C10.6898 7.87694 10.9046 8.40044 10.9046 8.95827C10.896 10.1083 9.96816 11.0437 8.81689 11.0437C8.25844 11.0437 7.73436 10.8292 7.33914 10.4344C6.94393 10.0396 6.72914 9.51611 6.72914 8.95827V8.94969C6.72055 8.40903 6.93534 7.88552 7.33055 7.49075ZM11.1968 11.3441C10.5868 11.9534 9.74478 12.331 8.81689 12.331C7.91478 12.331 7.07281 11.9791 6.42844 11.3441C5.79267 10.7004 5.44041 9.85939 5.44041 8.95827C5.43182 8.06575 5.78407 7.22471 6.41985 6.58106C7.06422 5.93741 7.91478 5.58554 8.81689 5.58554C9.719 5.58554 10.5696 5.93741 11.2053 6.57247C11.8411 7.21613 12.1934 8.06575 12.1934 8.95827C12.1848 9.89371 11.8068 10.7348 11.1968 11.3441Z"
        fill={stroke ? stroke : 'white'}
      />
    </SvgWrapper>
  );
};
export {
  DeleteIcon,
  EditIcon,
  DetailArrowIcon,
  ThreeDotIcon,
  FilterIcon,
  LeftArrowIcon,
  CameraIcon,
};
const SvgWrapper = styled('svg')({});
