import React, { useState } from 'react';
import { CardWrapper } from 'admin/components/wrappers';
import CustomDataGrid from 'app/components/CustomDataGrid/Index';
import styled from 'styled-components';
import { BackNumberCell } from 'admin/pages/TeamIndividual/components/TeamRiderListing/Cells/BackNumberCell';
import RiderProfileCell from 'admin/pages/TeamIndividual/components/TeamRiderListing/Cells/RiderProfileCell';
import DivisionClassCell from 'admin/pages/TeamIndividual/components/TeamRiderListing/Cells/DivisionClassCell';
import SIFCell from './Cells/SIFCell';
import SIFDateCell from './Cells/SIFDateCell';
import { StatusCell } from './Cells/StatusCell';
import { TotalPointCell } from './Cells/TotalPointCell';
import { CardSubWrapper } from 'admin/components/wrappers';
import { RiderWrapper } from 'services/openapi';
import { useParams } from 'react-router-dom';
import { GeneralLookup } from 'services/openapi';
import { CustomDeletePopup } from 'app/components/CustomDeletePopup';
import { TableSkeletonLoader } from 'app/components/TableSkeletonLoader';
import { MoreIconCell } from './Cells/MoreIconCell';
import { useNavigateToRoute } from 'utils/common';
interface TeamRiderProps {
  setPageSkip: React.Dispatch<React.SetStateAction<number | undefined>>;
  pageSkip: number | undefined;
  teamRiderData?: RiderWrapper;
  loading?: boolean;
  fetchTeamRiderList: (
    id: string,
    searchInput?: string,
    season?: string,
    status?: string,
    sortBy?: string,
    sortOrder?: number,
    pageNumber?: number,
    pageSize?: number,
  ) => void;
  teamRiderActiveOrInactive: (
    id: string,
    teamId?: string,
    statusId?: string | undefined,
    seasonId?: string,
    searchInput?: string | undefined,
    status?: string | undefined,
    sortBy?: string | undefined,
    sortOrder?: number | undefined,
    pageNumber?: number,
    pageSize?: number,
    item?: string,
  ) => void;
  debounceTeamSearch: string;
  lookup: GeneralLookup[];
  deleteTeamRiderList: (id?: string | undefined) => void;
  riderList?: boolean;
  selectedItemId: string | null | undefined;
  isPastDataFlag?: boolean;
}
const TeamRiderTable = ({
  teamRiderData,
  setPageSkip,
  pageSkip,
  debounceTeamSearch,
  fetchTeamRiderList,
  teamRiderActiveOrInactive,
  lookup,
  deleteTeamRiderList,
  loading,
  selectedItemId,
  riderList,
  isPastDataFlag,
}: TeamRiderProps) => {
  const redirect = useNavigateToRoute();
  const activeId = lookup.find(item => item.name === 'Active');
  const inActiveId = lookup.find(item => item.name === 'Inactive');
  const [showDeletePopup, setShowDeletePopup] = React.useState<boolean>(false);
  const [riderID, setRidderID] = useState('');
  const onDataClick = (data: any) => {
    setRidderID(data.id);
  };
  const { id } = useParams();
  const handleMoreClick = (item: string, e) => {
    const riderId = e?.dataItem.id;
    if (item === 'Make active') {
      teamRiderActiveOrInactive(
        riderId,
        id,
        activeId?.id,
        '',
        debounceTeamSearch,
        selectedItemId ?? '',
        '',
        0,
        pageSkip,
        10,
        item,
      );
    } else if (item === 'Make inactive') {
      teamRiderActiveOrInactive(
        riderId,
        id,
        inActiveId?.id,
        '',
        debounceTeamSearch,
        selectedItemId ?? '',
        '',
        0,
        pageSkip,
        10,
        item,
      );
    } else if (item === 'Delete rider') {
      setShowDeletePopup(true);
    } else if (item === 'Edit rider info') {
      redirect(`/admin/riders/edit/${e?.dataItem.id}`);
    }
  };
  const handleViewClick = e => {
    if (!isPastDataFlag) {
      redirect(`/admin/past-data/riders/view/profile-info/${e.id}`); //pass dynamic id from table
    } else {
      redirect(`/admin/riders/view/profile-info/${e.id}`); //pass dynamic id from table
    }
  };
  const handlePageChange = event => {
    setPageSkip(event.page.skip);
    fetchTeamRiderList(
      id ?? '',
      debounceTeamSearch,
      selectedItemId ?? '',
      '',
      '',
      undefined,
      event.page.skip,
      10,
    );
  };

  const tableData = [
    {
      field: 'backNumber',
      title: 'Back Number',
      cell: e => <BackNumberCell e={e} onClick={() => {}} />,
      width: '130',
    },
    {
      field: 'rider',
      title: 'Rider Name',
      cell: e => <RiderProfileCell e={e} onClick={() => {}} />,
      width: '250',
    },
    {
      field: 'divisionOrClasses',
      title: 'Division/Classes',
      cell: e => <DivisionClassCell e={e} onClick={() => {}} />,
      width: '150',
    },
    {
      field: 'sif',
      title: 'SIF',
      cell: e => <SIFCell e={e} onRowClick={() => {}} />,
      width: '100',
    },
    {
      field: 'sifJoinDate',
      title: 'SIF Join Date',
      cell: e => <SIFDateCell e={e} onRowClick={() => {}} />,
      width: '150',
    },
    {
      field: 'status',
      title: 'Status',
      cell: e => <StatusCell e={e} onRowClick={() => {}} lookup={lookup} />,
      width: '150',
    },

    {
      field: 'totalPoints',
      title: 'Total Points',
      cell: e => <TotalPointCell e={e} onRowClick={() => {}} />,
      width: '100',
    },

    {
      field: '',
      title: '',
      cell: e => (
        <MoreIconCell
          onDataClick={onDataClick}
          onMoreClick={handleMoreClick}
          e={e}
          onViewClick={e => handleViewClick(e)}
          isPastDataFlag={isPastDataFlag}
        />
      ),
      width: '100',
    },
  ];
  React.useEffect(() => {
    fetchTeamRiderList(
      id ?? '',
      debounceTeamSearch,
      '',
      '',
      '',
      undefined,
      pageSkip,
      10,
    );
  }, [riderList]);

  const handleDeleteTeamRider = () => {
    deleteTeamRiderList(riderID);
    setShowDeletePopup(false);
  };

  return (
    <CardWrapper style={{ paddingTop: '15px', width: '100%' }}>
      <CardSubWrapper className="d-flex"></CardSubWrapper>
      <GridWrapper>
        {loading ? (
          <TableSkeletonLoader rowContent={8} width={100} headingWidth={80} />
        ) : (
          <CustomDataGrid
            showPagination
            data={teamRiderData?.riders ?? []}
            columns={tableData}
            totalCount={teamRiderData?.riders?.length}
            onRowClick={() => {}}
            onPageChange={event => {
              handlePageChange(event);
            }}
            skip={pageSkip}
            pageSize={10}
          />
        )}
        <CustomDeletePopup
          deleteIcon={true}
          headerUnderline={false}
          popupSubLabel="Are you sure you want to delete this rider?"
          className="body-scroll-lock height-full delete-update"
          popupLabel="Delete rider"
          setVisible={setShowDeletePopup}
          visible={showDeletePopup}
          align="centre"
          btnLabel1="Cancel"
          btnLabel2="Delete"
          handlebtn2Click={handleDeleteTeamRider}
          handlebtn1Click={() => {
            setShowDeletePopup(false);
          }}
        />
      </GridWrapper>
    </CardWrapper>
  );
};
const GridWrapper = styled.div`
  .k-grid-content tr {
    &:hover {
      td {
        background: #f0f0f0 !important;
      }
    }
  }
  .k-grid-content tr td {
    cursor: pointer;
  }
`;
export default TeamRiderTable;
