import styled from 'styled-components';
import { TextField } from 'app/components/TextField';
import '@fontsource/roboto';
import React from 'react';
import { useState } from 'react';
import { BackNextButton } from 'app/components/BackNextButton';
import { useNavigate } from 'react-router-dom';
import SHDatePicker from 'app/components/SHDatePicker';
import SHdropdown, { DropDownModel } from 'app/components/SHdropdown';

type Props = {
  onClickBackPersonal?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onClickNxtPersonal?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};
const dummyData: DropDownModel[] = [
  { id: 1, text: 'Option 1' },
  { id: 2, text: 'Option 2' },
  { id: 3, text: 'Option 3' },
  { id: 4, text: 'Option 4' },
  { id: 5, text: 'Option 5' },
];

const TryitEWDPersonalInfoFieldsReturning = ({
  onClickNxtPersonal,
  onClickBackPersonal,
}: Props) => {
  const [detailsData, setDetailsData] = useState({
    firstName: '',
    lastName: '',
    backNumber: '',
    currentGrade: '',
    expGraduationYear: '',
    dob: '',
    height: '',
    inches: '',
    weight: '',
    ph: '',
    email: '',
    streetAddress: '',
    city: '',
    state: '',
    zip: '',
  });
  const navigate = useNavigate();
  const handleChange = event => {
    const { name, value } = event.target;
    console.log('changed', value);
    setDetailsData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };
  const [value, setValue] = React.useState(new Date());
  const changeDate = event => {
    setValue(event.value);
  };
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <MainWrapper>
      <OverflowWrapper>
        <TextFieldGrid>
          <StyledTextField
            onChange={handleChange}
            value={detailsData.firstName || ''}
            label="First Name"
            type="text"
            name="firstName"
          />
          <StyledTextField
            onChange={handleChange}
            value={detailsData.lastName || ''}
            label="Last Name"
            type="text"
            name="lastName"
          />
          <GraduationDobWrapper>
            <StyledGraduationDropdownWrapper>
              <SHdropdown
                className="drop-down"
                label="Expected Graduation Year"
              />
            </StyledGraduationDropdownWrapper>
            <StyledSHDatePicker
              onChange={changeDate}
              placeholder=""
              value={value || ''}
              name="dob"
              className="datepicker-style"
              dateLabel="Date of Birth"
            />
          </GraduationDobWrapper>
          <HeightInchWeightWrapper>
            <StyledHeightTextField
              onChange={handleChange}
              value={detailsData.height || ''}
              label="Height(Ft)"
              type="number"
              name="height"
              placeholder="Feet"
            />

            <StyledInchTextField
              onChange={handleChange}
              value={detailsData.inches || ''}
              label="Inches"
              type="number"
              name="inches"
              placeholder="Inches"
            />
            <span>eg. 5ft 10in</span>
            <StyledWeightTextField
              onChange={handleChange}
              value={detailsData.weight || ''}
              label="Weight"
              type="number"
              name="weight"
              placeholder="lbs"
            />
          </HeightInchWeightWrapper>
          <StyledTextField
            onChange={handleChange}
            value={detailsData.ph || ''}
            label="Cell/Primary Ph#"
            type="number"
            name="ph"
          />
          <StyledTextField
            onChange={handleChange}
            value={detailsData.email || ''}
            label="Email"
            type="email"
            name="email"
          />
        </TextFieldGrid>
        <HomeAddressWrapper className="horizontal-line">
          <p className="home-address">Home mailing address</p>
        </HomeAddressWrapper>
        <TextFieldGrid>
          <StyledTextField
            onChange={handleChange}
            value={detailsData.streetAddress || ''}
            label="Street Address"
            type="text"
            name="streetAddress"
          />
          <StyledTextField
            onChange={handleChange}
            value={detailsData.city || ''}
            label="City"
            type="text"
            name="city"
          />
          <DropdownWrapper>
            <SHdropdown className="drop-down" label="State" />
          </DropdownWrapper>
          <StyledTextField
            onChange={handleChange}
            value={detailsData.zip || ''}
            label="Zip"
            type="text"
            name="zip"
          />
        </TextFieldGrid>
        <ButtonWrapper className="bg-color-change">
          <BackNextButton
            classNameBack="btn-back"
            classNameNxt="btn-nxt"
            onClickNxt={onClickNxtPersonal}
            onClickBack={handleBack}
            next="Next"
            back="Cancel"
          />
        </ButtonWrapper>
      </OverflowWrapper>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  color: #9597a6;
  width: 100%;
  height: calc(100vh - 270px);
  padding: 20px 0px 0px;
  position: relative;
  // overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    // padding-top: 20px;
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    // padding-top: 20px;
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    // padding-top: 20px;
    label {
      font-size: 14px;
    }
  }
`;
const OverflowWrapper = styled.div`
  height: 100%;
  overflow: auto;
`;
const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const HomeAddressWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  width: 100%;

  ::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #585858;
  }

  p {
    margin: 0 10px 0px 0px;
    white-space: nowrap;
  }
`;
const StyledTextField = styled(TextField)`
  flex: 0 0 calc(50% - 20px);
  margin: 0px 0px 10px !important;
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const GraduationDobWrapper = styled.div`
  margin: 0px 0px 10px !important;
  display: flex;
  gap: 15px;
  position: relative;
  .k-input-solid {
    width: 150px !important;
    height: 35px;
  }
`;
const StyledGraduationDropdownWrapper = styled.div`
  padding-top: 2px;
  font-size: 13px;
  p {
    margin: 0px;
  }
  button {
    width: 155px !important;
  }
  .drop-down {
    margin: 0px;
    width: 160px !important;
    height: 35px;
    color: #9597a6;
    svg {
      width: 35px !important;
      height: auto;
      position: absolute;
      top: 20px;
      right: -45px;
    }
  }
`;
const StyledSHDatePicker = styled(SHDatePicker)`
  margin: 0px 0px 10px !important;
`;

const HeightInchWeightWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 330px;
  // align-items: center;
  span {
    // padding-top: 10px;
    font-size: 10px;
    display: flex;
    align-items: center;
  }
`;
const StyledWeightTextField = styled(TextField)`
  margin: 0px 0px 10px !important;
  input {
    width: 82px;
    height: 35px;
  }
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const StyledInchTextField = styled(TextField)`
  margin: 0px 0px 10px !important;
  input {
    width: 82px;
    height: 35px;
  }
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const StyledHeightTextField = styled(TextField)`
  margin: 0px 0px 10px !important;
  input {
    width: 82px;
    height: 35px;
  }
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const DropdownWrapper = styled.div`
  p {
    margin: 0px;
    font-family: 'Roboto';
    font-size: 14px;
  }
  .drop-down {
    margin: 0px;
    width: 326px !important;
    height: 35px;
    color: #9597a6;
  }
`;
const ButtonWrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: -65px;
    left: -5px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
  }
`;
export default TryitEWDPersonalInfoFieldsReturning;
