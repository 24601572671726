import { CommonPageWrapper, HeadingWrapper } from 'admin/components/wrappers';
import CustomBreadCrumb from 'app/components/CustomBreadCrumb';
import { PageMainLabel } from 'app/components/SHLabel';
import { ProfileSettingView } from '../profileSettingView';
import BackButton from 'app/components/BackButton';
import styled from 'styled-components';
import useUserProfileApi from 'admin/hooks/useUserProfileApi';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {};

const ProfileSettingsPage = (props: Props) => {
  const navigate=useNavigate();
  const breadCrumbItems = [
    {
      id: '',
      text: 'Showyeda admin',
      disabled: true,
    },
    {
      id: '/admin/settings',
      text: 'Settings',
    },
    {
      id: '',
      text: 'Profile-Settings',
      disabled: true,
    },
  ];
  const { userViewData, userViewLoading, getUserProfileViewData } =
    useUserProfileApi();
  const handleBackClick = () => {
    navigate('/admin/settings');
  }
  
  useEffect(() => {
    getUserProfileViewData();
  }, []);
  return (
    <CommonPageWrapper>
      <HeadingWrapper>
        <PageMainLabel>Profile Settings</PageMainLabel>
        <CustomBreadCrumb preData={breadCrumbItems} />
      </HeadingWrapper>
      <ButtonWrapper>
        <BackButton className="back-button" onClick={handleBackClick} />
      </ButtonWrapper>
      <div>
        <ProfileSettingView
          userViewData={userViewData}
          loading={userViewLoading}
        />
      </div>
    </CommonPageWrapper>
  );
};
export default ProfileSettingsPage;
const ButtonWrapper = styled.div`
  position: relative;
  &.rides-info {
    .hPRKpe {
      margin: 0 0 2px 0;
    }
  }

  .back-button {
    cursor: pointer;
    display: flex;
    justify-content: end;
    margin: 25px 0 25px 0;
    span {
      font-weight: 600;
    }
  }
`;
